import React from 'react';
import PropTypes from 'prop-types';
import { StyledCommandPanelButton, StyledImg, StyledText } from './styles';
// TODO: @marina Надо сделать картинку
import { ReplaceThisIcon as ExecuteIcon } from '../../../icons';


export const ExecuteButton = ({ content, onClick, disabled }) => (
  <StyledCommandPanelButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg style={{ width: '20px' }} src={ExecuteIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledCommandPanelButton>
);

ExecuteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ExecuteButton.defaultProps = {
  onClick: () => null,
  content: 'Провести',
  disabled: false,
};

export default ExecuteButton;
