import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import meta from '../../meta';
import { LinkItem } from './styles';

export const StyledLinkItem = styled(LinkItem)`
color: ${(props) => (props.color)};
white-space: pre-line;
padding: 10px; 
&: hover{
color: ${(props) => (props.hover)}
background-color: ${(props) => (props.background)
}
`;

const Items = ({ subSect, type }) => {
  const itemsCats = useMemo(() => Object.keys(meta.cat)
    .filter((name) => meta.cat[name].backendURL === subSect.resource)
    .map((catName) => ({ ...meta.cat[catName], id: catName })),
  [subSect]);

  const itemsDocs = useMemo(() => Object.keys(meta.doc)
    .filter((name) => meta.doc[name].backendURL === (subSect.resource))
    .map((docName) => ({ ...meta.doc[docName], id: docName })),
  [subSect]);

  const items = [...itemsCats, ...itemsDocs];

  return items.map((item) => (
    <StyledLinkItem
      key={item.id}
      to={item.frontendURL}
      color={type === 'page' ? '#426f99' : '#ced6de'}
      hover={type === 'page' ? '#0469ce' : 'white'}
      background={type === 'page' ? '' : '#cc2941'}
    >
      {item.name}
    </StyledLinkItem>
  ));
};

Items.propTypes = {
  subSect: PropTypes.shape({ }).isRequired,
  type: PropTypes.string.isRequired,
};

export default Items;
