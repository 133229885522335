import React, {
  forwardRef, memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { StyledListerTable, StyledContainer } from './styles';
// import { directions } from '../../../../constants/meta/common';
import ListerContext from '../../context';
import { Loader } from '../../../../components/bootStrap';
import FilterPanel from '../filterPanel';

const TableListerFactory = ({
  CommandPanel,
  RowComponent,
  HeaderComponent,
  ContextMenuComponent,
}) => {
  // При необходимости переместить одно ищ указанныъ нижу свойств в
  // context - удалять из свойств. Т.е. свойство должно быть или в
  // props или в context, но никак и там и там.
  const TableLister = forwardRef(({
    visibleColumns, openedItems, onRowFocus, onSetOrder, order, loading, err,
    onReoload, onScroll, areaSize,
    columnSizes, filterOpened,
    onResizeColumn, onResetColumnSize,
    maxItemLevel, commandPanelRef,
  }, ref) => {
    const {
      onShowCtxMenu, permissions, actions, selectedRows, items,
      localFilter, messages, filteringFields,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useContext(ListerContext);
    return (
      <>
        {!!messages.length && messages.map((m) => (
          <Alert key={m.id} variant={m.variant || 'info'} dismissible onClose={() => actions.onDeleteMessage(m.id)}>
            {m.title && (
              <Alert.Heading>{m.title}</Alert.Heading>
            )}
            {m.text}
          </Alert>
        ))}
        <CommandPanel
          ref={commandPanelRef}
        />
        <div className="d-flex align-items-stretch overflow-hidden">

          <StyledContainer
            width={areaSize.width}
            height={areaSize.height}
            onScroll={onScroll}
            ref={ref}
          >
            {loading && (
            <Loader text="Завантаження" />
            )}
            <StyledListerTable
              striped
              bordered
              hover
            // responsive
              role="list"
            >
              <HeaderComponent
                columns={visibleColumns}
                onSetOrder={onSetOrder}
                order={order}
                columnSizes={columnSizes}
                onResizeColumn={onResizeColumn}
                onResetColumnSize={onResetColumnSize}
                permissions={permissions}
                maxItemLevel={maxItemLevel}
                localFilter={localFilter}
              />
              <tbody>
                {items.map((row, i) => (
                  <RowComponent
                    tabIndex={i + 1}
                    key={row.id}
                    row={row}
                    columns={visibleColumns}
                    onFocus={onRowFocus}
                    selected={selectedRows.includes(row.id)}
                    onContextMenu={ContextMenuComponent ? onShowCtxMenu : null}
                    permissions={permissions}
                    actions={actions}
                    isOpened={openedItems.includes(row.id)}
                  />
                ))}

              </tbody>
            </StyledListerTable>

            {ContextMenuComponent && (
            <ContextMenuComponent />
            )}
            {err && (
            <Alert dismissible onClose={onReoload} variant="danger">
              <Alert.Heading>
                {err}
              </Alert.Heading>
            </Alert>
            )}
          </StyledContainer>
          <FilterPanel
            show={filterOpened}
            filteringFields={filteringFields}
            filter={localFilter}
            onSetFilter={actions.onSetFilter}
          />
        </div>

      </>
    );
  });

  TableLister.propTypes = {
    visibleColumns: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
    order: PropTypes.shape({
      column: PropTypes.string,
      // direction: PropTypes.oneOf(Object.values(directions)),
    }).isRequired,
    loading: PropTypes.bool,
    err: PropTypes.string,
    onRowFocus: PropTypes.func.isRequired,
    onSetOrder: PropTypes.func.isRequired,
    onReoload: PropTypes.func.isRequired,
    areaSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    onScroll: PropTypes.func.isRequired,
    columnSizes: PropTypes.shape({}),
    onResizeColumn: PropTypes.func.isRequired,
    onResetColumnSize: PropTypes.func.isRequired,
    openedItems: PropTypes.arrayOf(
      PropTypes.number,
    ),
    maxItemLevel: PropTypes.number,
    commandPanelRef: PropTypes.shape({
    }),
    filterOpened: PropTypes.bool.isRequired,
  };

  TableLister.defaultProps = {
    loading: false,
    err: null,
    areaSize: null,
    columnSizes: {},
    openedItems: [],
    maxItemLevel: 0,
    commandPanelRef: null,
  };
  return memo(TableLister);
};

export default TableListerFactory;
