import React from 'react';
import PropTypes from 'prop-types';
import { StyledCommandPanelButton, StyledImg, StyledText } from './styles';

export const CPButton = ({
  content, onClick, disabled, addText, icon, className,
}) => (
  <StyledCommandPanelButton
    onClick={onClick}
    disabled={disabled}
    title={content}
    className={className}
  >
    {icon && (
      <StyledImg src={icon} alt={content} />
    )}
    {addText
    && <StyledText>{content}</StyledText>}
  </StyledCommandPanelButton>
);

CPButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  addText: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
};

CPButton.defaultProps = {
  onClick: () => null,
  content: 'Видалити',
  disabled: false,
  addText: false,
  className: '',
  icon: null,
};

export default CPButton;
