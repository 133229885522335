const frontendURL = '/cat/typeofentrysf/';
const backendURL = '/api/references/reftypeofentrysf/';
const name = 'Показник надходження СФ';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};

export default instance;
