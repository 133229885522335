import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
} from 'react-bootstrap';
import { TableStingInput } from '../../../basicEditor/tableControls';
import { fieldPropType } from '../hooks';

const FieldItem = ({
  field, active, onRenameField, onClick,
}) => (

  <ListGroupItem
    active={active}
    onClick={onClick}
  >
    <TableStingInput
      controlId={`input-${field.itemKey}`}
      value={field.name}
      onChange={(e, v) => onRenameField(field.name, v)}
    />
  </ListGroupItem>
);

FieldItem.propTypes = {
  field: fieldPropType.isRequired,
  active: PropTypes.bool,
  onRenameField: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

FieldItem.defaultProps = {
  active: false,
};

export default FieldItem;
