const frontendURL = '/doc/budgetExecution/';
const backendURL = '/api/budgetrequest/executionbpheader/';
const name = 'Виконання бюджетної програми за показниками';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
