import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { AppContext } from '../../../../providers/authProvider';

const Editor = ({
  onSave, onClose, id,
}) => {
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields,
  } = useEditor({
    backendURL: meta.doc.bfnInstitutionStructure.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  const { currentUser } = useContext(AppContext);
  const isSuperuser = currentUser.is_superuser;

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const commentOfAutorProps = editorHooks.useTextInputProps('comment_of_author', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const budget = editorHooks.useItemInputProps('budget', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const periodBfn = editorHooks.useItemInputProps('period_bfn', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const authority = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  const filterBudget = useMemo(() => ({
    budget: budget ? budget.id : 0,
  }), [budget]);

  const filterAuthority = useMemo(() => ({
    budget: authority ? authority.id : 0,
  }), [authority]);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <DocCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
        executed={data.executed}
      >
      </DocCommandPanel>
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            {isSuperuser && (
            <Row>
              <Col>
                <EditorControls.ItemPicker {...budget} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...authority} />
              </Col>
            </Row>
            )}

            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker
                  {...disposerProps}
                  filter={filterAuthority}
                  noHierarchy
                />
              </Col>
              <Col>
                <EditorControls.ItemPicker
                  {...periodBfn}
                  filter={filterBudget}
                  noHierarchy
                />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="bfnInstitutionStructure" id="uncontrolled-tab-example">

          <Tab eventKey="bfnInstitutionStructure" title="Чисельність дітей та структура закладу дошкільної освіти">
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>

          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentOfAutorProps} />
            </TabContainer>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
