import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DDColumn from './ddColumn';

const StringFilteredHeader = ({
  columnName, label, data, value, onChange,
}) => {
  const values = useMemo(
    () => {
      const ids = new Set(data.map((row) => row[columnName]));
      return [...ids].map((v) => ({ value: v, display_name: v, selected: value.includes(v) }));
    },
    [columnName, data, value],
  );

  return (
    <DDColumn
      id={`${columnName}-dropdown`}
      value={value}
      values={values}
      label={label}
      onChange={onChange}
    />
  );
};

StringFilteredHeader.propTypes = {
  columnName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

StringFilteredHeader.defaultProps = {
  value: [],
};

export default StringFilteredHeader;
