import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { faRocket, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DKSUDates from './DKSUDates';
import DKSUData from './DKSUData';
import { AppContext } from '../../../providers/authProvider';
import { LogicaContext } from '../../../providers/authLogicaProvider';
import {
  getDateStr, LOGIKA_URL_DATA, dateToUpload, DATATYPES,
} from './consts';
import { checkTask } from '../../../api/checktask';
import { DimmableLoader } from '../../../components/Styled/Misc';
import LoginInLogica from '../Login/editor';
import LogicaPing from '../../Logica/Ping';

const H2 = styled.h2`
color: ${(props) => props.color};
`;

const Editor = () => {
  const [dataType, setDataType] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [res, setRes] = useState(null);
  const [show, setShow] = useState(false);
  const [modalLogicaLogin, setModalLogicaLogin] = useState(false);
  const [datesRes, setDatesRes] = useState();
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);

  const { auth } = useContext(AppContext);
  const token = auth().access;
  const { ticket, setTicket } = useContext(LogicaContext);

  useEffect(() => {
    if (!ticket || err === '401 Unauthorized' || err === 'Помилка сервера - 401') {
      setTicket(null);
      setShow(false);
      setModalLogicaLogin(true);
      setErr(null);
      return;
    }
    setModalLogicaLogin(false);
  }, [err, setTicket, ticket]);

  const onStart = useCallback(() => {
    setLoading(true);
    setErr(null);
    setRes(null);
    setDatesRes(null);
  }, []);

  const onEnd = useCallback(() => {
    setLoading(false);
  }, []);

  const onError = useCallback((e) => {
    setLoading(false);
    setErr(e);
  }, []);

  const onDone = useCallback((r) => {
    setLoading(false);
    setRes(r);
  }, []);

  const getDKSUData = useCallback(() => {
    const loader = async () => {
      const datetimes = dateToUpload(date);
      const params = {
        datetimes,
        attachedBudgets: false,
        ticket,
        year: year || new Date().getFullYear(),
      };

      if (dataType !== 'Всі') {
        params.datatype = dataType;
      }

      const r = await fetch(`${LOGIKA_URL_DATA}`, {
        method: 'POST',
        headers:
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        body: JSON.stringify(params),
      });

      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      // eslint-disable-next-line camelcase
      const { task_id } = await r.json();
      // eslint-disable-next-line camelcase
      return task_id;
    };

    onStart();
    loader()
      .then((d) => checkTask(d, auth, (r) => {
        if (r.result.errors) { onError(r.result.errors[0]); } else { onDone('Дані завантажено успішно!'); }
        if (r.status !== 'PENDING') { onEnd(); }
      },
      (e) => {
        onError(e ? e.message : 'Скасовано');
        onEnd();
      }))
      .catch((e) => {
        onError(e.message);
      });
  }, [auth, dataType, date, onDone, onEnd, onError, onStart, ticket, token, year]);

  const getDKSUDates = useCallback(() => {
    const loader = async () => {
      const response = await fetch(`${LOGIKA_URL_DATA}?ticket=${ticket}&year=${year}`, {
        method: 'GET',
        headers:
          {
            Authorization: `Bearer ${token}`,
          },
      });

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    };
    onStart();
    loader()
      .then((data) => {
        setDatesRes(data);
        onEnd();
      },
      (e) => {
        onError(e ? e.message : 'Скасовано');
        onEnd();
      })
      .catch((e) => {
        onError(e.message);
      });
  }, [onEnd, onError, onStart, ticket, token, year]);

  const getCurrentDKSUData = useCallback(() => {
    const loader = async () => {
      const params = {
        ticket, year: year || new Date().getFullYear(),
      };

      const r = await fetch(`${LOGIKA_URL_DATA}`, {
        method: 'POST',
        headers:
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        body: JSON.stringify(params),
      });

      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      // eslint-disable-next-line camelcase
      const { task_id } = await r.json();
      // eslint-disable-next-line camelcase
      return task_id;
    };

    onStart();
    loader()
      .then((d) => checkTask(d, auth, (r) => {
        if (r.result.errors) { onError(r.result.errors[0]); } else { onDone('Дані завантажено успішно!'); }
        if (r.status !== 'PENDING') { onEnd(); }
      },
      (e) => {
        onError(e ? e.message : 'Скасовано');
        onEnd();
      }))
      .catch((e) => {
        onError(e.message);
        onEnd();
      });
  }, [auth, onDone, onEnd, onError, onStart, ticket, token, year]);

  const datesByType = () => {
    if (dataType === 'Всі') {
      const arr = DATATYPES.filter((t) => t !== dataType)
        .map((type) => datesRes[type].map((data) => ({
          value: getDateStr(data.datefile),
          display_name: getDateStr(data.datefile),
        })))
        .flat()
        .reduce((ar, val) => (ar
          .find((el) => JSON.stringify(el) === (JSON.stringify(val))) ? ar : [...ar, val]), []);
      return arr;
    }
    return datesRes && dataType && datesRes[dataType]
      .map((data) => ({
        value: getDateStr(data.datefile),
        display_name: getDateStr(data.datefile),
      }));
  };

  const lastDownload = (datesRes && dataType && (getDateStr(datesRes.current_date[dataType]) || '-'));

  return (
    <>
      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      <DimmableLoader loading={loading}>
        <div>
          <Button onClick={getCurrentDKSUData} variant="outline-success" className="mr-2" disabled={!logicaPingSuccess}>
            <FontAwesomeIcon icon={faRocket} className="mr-2" />
Оновити все
          </Button>
          <Button onClick={() => setShow(true)} variant="outline-warning" disabled={!logicaPingSuccess}>
            <FontAwesomeIcon icon={faTools} className="mr-2" />
            Оновити з додатковими налаштуваннями
          </Button>
        </div>

        {res && !show && <H2 color="green">{res}</H2>}
        {err && !show && <H2 color="red">{err}</H2>}

        <Modal show={modalLogicaLogin} onHide={() => setModalLogicaLogin(false)} size="xl" centered>
          <Modal.Header closeButton>
            <Modal.Title>Авторизація в Логіці</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LoginInLogica />
          </Modal.Body>
        </Modal>

        <Modal show={show} onHide={() => setShow(false)} size="xl" centered>
          <Modal.Header closeButton>
            <Modal.Title>Завантаження казначейських файлів</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DimmableLoader loading={loading}>
              {res && <Alert variant="success" onClose={() => setRes(null)} dismissible>{res}</Alert>}
              {err && <Alert variant="danger" onClose={() => setErr(null)} dismissible>{err}</Alert>}
              <DKSUDates
                year={year}
                setYear={setYear}
                getDKSUDates={getDKSUDates}
                datesRes={datesRes}
                logicaPingSuccess={logicaPingSuccess}
              />
              {datesRes
                && (
                  <DKSUData
                    dataType={dataType}
                    setDataType={setDataType}
                    date={date}
                    setDate={setDate}
                    getDKSUData={getDKSUData}
                    datesByType={datesByType}
                    lastDownload={lastDownload}
                    logicaPingSuccess={logicaPingSuccess}
                  />
                )}
            </DimmableLoader>
          </Modal.Body>
        </Modal>
      </DimmableLoader>
    </>
  );
};
export default Editor;
