import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import Styled, { keyframes } from 'styled-components';

const expandAnimation = keyframes`
  from {
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
  }
  to {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const StyledFullScreen = Styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: white;
  animation: ${expandAnimation} .5s linear 1;
  display: flex;
  flex-direction: column;
  flex: 1 1;
`;

const fullscreenHOC = (WrappedComponent) => {
  class FullScreen extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        expanded: false,
      };
    }

    expand = () => this.setState({ expanded: true });

    hide = () => this.setState({ expanded: false });

    render() {
      const { expanded } = this.state;
      const { ...r } = this.props;
      const newProps = {
        ...r,
        onExpand: this.expand,
        onHide: this.hide,
        expanded,
      };
      if (expanded) {
        return createPortal((
          <StyledFullScreen>
            <WrappedComponent {...newProps} />
          </StyledFullScreen>
        ), document.body);
      }
      return (
        <WrappedComponent {...newProps} />
      );
    }
  }
  return FullScreen;
};

export default fullscreenHOC;
