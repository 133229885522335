import React, {
  useMemo, memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, Row, Col, Container,
} from 'react-bootstrap';
import { AddRowButton, RemoveRowButton } from '../../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../../components/bootStrap';
import { tablePropType } from '../hooks';
import TableItem from './item';
import TableProps from './props';

const TablesPage = ({
  tables, activeTable, onRenameTable, onChangeTable, onAddTable, onRemoveTable, onSetActiveTable,
  showModal, setShowModal, setKeyValue, keyValue,
}) => {
  const displayTables = useMemo(
    () => tables.map((t, key) => ({
      ...t,
      key,
    })), [tables],
  );

  const entries = useMemo(() => Object.entries(tables.filter((t) => t.name === activeTable)
    .reduce((R, t) => t.text, [])), [activeTable, tables]);

  return (
    <Container fluid>
      <Row>
        <Col lg={3} md={6} sm={12} className="p-3 border rounded">
          <CommandPanel>
            <AddRowButton
              title="Додати таблицю"
              content=""
              onClick={() => onAddTable(Math.random().toString(36))}
            />
            <RemoveRowButton
              title="Видалити таблицю"
              content=""
              onClick={() => onRemoveTable(activeTable)}
              disabled={activeTable === 'main' || !activeTable}
            />
          </CommandPanel>
          <ListGroup className="containerGroup">
            {displayTables.map((table) => (
              <TableItem
                key={table.key}
                active={table.name === activeTable}
                onClick={() => onSetActiveTable(table.name)}
                table={table}
                onRenameTable={onRenameTable}
              />
            ))}
          </ListGroup>
        </Col>
        <Col className="containerCol">
          <TableProps
            onChange={(v, k) => onChangeTable(activeTable, v, k)}
            entries={entries}
            showModal={showModal}
            setShowModal={setShowModal}
            keyValue={keyValue}
            setKeyValue={setKeyValue}
          />
        </Col>
      </Row>
    </Container>
  );
};

TablesPage.propTypes = {
  tables: PropTypes.arrayOf(tablePropType).isRequired,
  activeTable: PropTypes.string,
  onRenameTable: PropTypes.func.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onAddTable: PropTypes.func.isRequired,
  onRemoveTable: PropTypes.func.isRequired,
  onSetActiveTable: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  keyValue: PropTypes.string,
  setKeyValue: PropTypes.func.isRequired,
};

TablesPage.defaultProps = {
  activeTable: null,
  keyValue: null,
};

export default memo(TablesPage);
