import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchButton = styled(InputGroup.Text)`
    background: var(--backgroundButton)!important;
    color: var(--colorTextButton)!important;
    cursor: pointer!important;
        &:hover {
            background: var(--hoverButton)!important;
        }
`;
const SearchInput = ({
  value, onChange, size, disabled,
}) => (
  <InputGroup>
    <FormControl
      placeholder="Пошук"
      value={value}
      onChange={(e) => onChange(e, e.target.value)}
      size={size}
      disabled={disabled}
    />
    <InputGroup.Append>
      <SearchButton>
        <FontAwesomeIcon icon={faSearch} />
      </SearchButton>
    </InputGroup.Append>
  </InputGroup>
);

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  disabled: PropTypes.bool,
};

SearchInput.defaultProps = {
  value: '',
  size: 'sm',
  disabled: false,
};

export default SearchInput;
