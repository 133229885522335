import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const TPFooter = () => (
  <TableHeaderRow noGutters className="px-4">
    <Col
      xl={6}
      sm={6}
      className="px-1  d-flex flex-column justify-content-end"
    >
        &nbsp;
    </Col>
    <Row noGutters>
      <Col className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
        <TableHeaderCell
          className="text-right text-sm-center w-100"
          active
          highlighted={false}
        />
      </Col>
    </Row>
  </TableHeaderRow>
);

export default memo(TPFooter);
