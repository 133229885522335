import React from 'react';
import PropTypes from 'prop-types';
import {
  EditorSelectorInput,
  EditorStingInput,
  EditorMultiSelectorInput,
  EditorFormatInput,
} from '../../../basicEditor/editorControls';
import { metaFieldPropType } from '../hooks';

const MetaFieldProps = ({
  value, onChange, readOnly, allowedCtypes, allowedFields, allowedFonts,
}) => (
  <div className="containerField p-3 border rounded">
    <EditorStingInput
      controlId="metafield_label_input"
      label="Label"
      required
      description="Пользовательское название сущности"
      value={value.label}
      readOnly={readOnly}
      onChange={(e, v) => onChange({ label: v })}
    />
    <EditorMultiSelectorInput
      controlId="metafield_include_input"
      label="Include"
      required
      description="Перечень полей (из раздела fields) которые включает данная сущность. При использовании данного поля в запрос будут включены все поля из include."
      value={value.include}
      values={allowedFields}
      readOnly={readOnly}
      onChange={(e, v) => onChange({ include: v })}
    />
    <EditorSelectorInput
      controlId="metafield_key_input"
      label="Key"
      description="Поле, используемое для сравнения значений. Например для отбора или для вычисления условного оформления ."
      value={value.key}
      values={allowedFields}
      readOnly={readOnly}
      onChange={(e, v) => onChange({ key: v })}
    />
    <EditorSelectorInput
      controlId="metafield_ordered_input"
      label="Ordered"
      description="Поле, используемое для сортировки"
      value={value.ordered}
      values={allowedFields}
      readOnly={readOnly}
      onChange={(e, v) => onChange({ ordered: v })}
    />
    <EditorStingInput
      controlId="metafield_display_input"
      label="Display"
      required
      description="Является django- шаблоном для формирования представления значения."
      value={value.display}
      readOnly={readOnly}
      onChange={(e, v) => onChange({ display: v })}
    />
    <EditorSelectorInput
      controlId="metafield_ctype_input"
      label="Ctype"
      description="Тип сущности"
      value={value.ctype}
      values={allowedCtypes}
      readOnly={readOnly}
      onChange={(e, v) => onChange({ ctype: v })}
    />
    <EditorStingInput
      controlId="metafield_resource_input"
      label="Resource"
      required
      description="Url для описания типа внутренней сущности системы. Например '/api/references/refkbp7/'"
      value={value.resource}
      readOnly={readOnly || value.ctype !== 'NestedObject'}
      onChange={(e, v) => onChange({ resource: v })}
    />
    <EditorFormatInput
      controlId="metafield_format_input"
      label="Оформление поля"
      value={value.format}
      onChange={(v) => onChange({ format: { ...value.format, ...v } })}
      allowedFonts={allowedFonts}
      readOnly={readOnly}
    />

  </div>
);

MetaFieldProps.propTypes = {
  value: metaFieldPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  allowedCtypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allowedFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allowedFonts: PropTypes.arrayOf(PropTypes.string),
};

MetaFieldProps.defaultProps = {
  readOnly: false,
  allowedFonts: [],
};

export default MetaFieldProps;
