import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const targetProgramProps = tableHooks.useSelectorInputProps('targetprogram', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanTotalProps = tableHooks.useNumberInputProps('amount_plan_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanSfProps = tableHooks.useNumberInputProps('amount_plan_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanZfProps = tableHooks.useNumberInputProps('amount_plan_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactTotalProps = tableHooks.useNumberInputProps('amount_fact_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactSfProps = tableHooks.useNumberInputProps('amount_fact_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactZfProps = tableHooks.useNumberInputProps('amount_fact_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountDiffTotalProps = tableHooks.useNumberInputProps('amount_diff_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountDiffSfProps = tableHooks.useNumberInputProps('amount_diff_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountDiffZfProps = tableHooks.useNumberInputProps('amount_diff_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const explanationProps = tableHooks.useStringInputProps('explanation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  amountFactTotalProps.value = (amountFactSfProps.value || 0) + (amountFactZfProps.value || 0);
  amountDiffSfProps.value = (amountPlanSfProps.value || 0) - (amountFactSfProps.value || 0);
  amountDiffZfProps.value = (amountPlanZfProps.value || 0) - (amountFactZfProps.value || 0);
  amountDiffTotalProps.value = (amountPlanTotalProps.value || 0) - (amountFactTotalProps.value || 0);

  return (
    <>
      <Col
        sm={12}
      >
        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="targetprogram" highlighted={highlights.includes('targetprogram')}>
              <TableControls.SelectorInput {...targetProgramProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="explanation" highlighted={highlights.includes('explanation')}>
              <TableControls.StringInput {...explanationProps} />
            </TabelRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="amount_plan_sf" highlighted={highlights.includes('amount_plan_sf')}>
              <TableControls.NumberInput {...amountPlanSfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_plan_zf" highlighted={highlights.includes('amount_plan_zf')}>
              <TableControls.NumberInput {...amountPlanZfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_fact_sf" highlighted={highlights.includes('amount_fact_sf')}>
              <TableControls.NumberInput {...amountFactSfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_fact_zf" highlighted={highlights.includes('amount_fact_zf')}>
              <TableControls.NumberInput {...amountFactZfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_diff_sf" highlighted={highlights.includes('amount_diff_sf')}>
              <TableControls.NumberInput {...amountDiffSfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_diff_zf" highlighted={highlights.includes('amount_diff_zf')}>
              <TableControls.NumberInput {...amountDiffZfProps} />
            </TabelRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="amount_plan_total" highlighted={highlights.includes('amount_plan_total')}>
              <TableControls.NumberInput {...amountPlanTotalProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_fact_total" highlighted={highlights.includes('amount_fact_total')}>
              <TableControls.NumberInput {...amountFactTotalProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_diff_total" highlighted={highlights.includes('amount_diff_total')}>
              <TableControls.NumberInput {...amountDiffTotalProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
    </>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
