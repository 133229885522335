import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useMD } from '../../newLister/hooks/md';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { WinManagerContext } from '../../../providers/winManagerProvider';
import { getObjectDiff } from '../../../common/diff';

export const HEADER_KEY = '__HEADER__';

function ItemSaveException(message) {
  this.message = message;
  this.name = 'Item Save Exception';
}

function buildErrors(errorData) {
  if (!errorData) {
    return {
    // необиданный ответ сервера
      messages: ['Server error. Server returned unexpected response (error_data)'],
    };
  }
  return errorData.reduce((R, {
    messages, fields, extensions, exceptions,
  }) => ({
    messages: [...R.messages, ...(messages || [])],
    fields: {
      ...R.fields,
      ...fields,
      ...extensions,
    },
    exceptions: { ...R.exceptions, ...exceptions },
  }), { messages: [], fields: {}, exceptions: {} });
}

// *  backendURL: {string},   тип модели
// *  id: {string} - ИД,
// *  reason: {string} - Основание (при вводе на основании),
// *  isGroup: {boolean} - Это группа (при создании группы),
// *  copyFrom: {string} - Ид копируемого элемента,
// *  onSaveCallBack: (function) - callback при сохранении документа,
// *  onCloseCallBack: (function) - callback при закрытии документа,
// *  defaults: {{}} - Значения по умолчанию  для нового объекта,
// *  readOnlyGetter: {function(@param data {})} - функция,
// *                  которая вычисляет значение аттрибута readOnly,
// * }}

/**
 * @param editorParams Параметры hook редактора
*  @param editorParams.backendURL {string} тип модели
*  @param editorParams.id {string} - ИД,
*  @param editorParams.reason {string} - Основание (при вводе на основании),
*  @param editorParams.isGroup {boolean} - Это группа (при создании группы),
*  @param editorParams.copyFrom {string} - Ид копируемого элемента,
*  @param editorParams.onSaveCallBack (function) - callback при сохранении документа,
*  @param editorParams.onCloseCallBack (function) - callback при закрытии документа,
*  @param editorParams.defaults {{}} - Значения по умолчанию  для нового объекта,
*  @param editorParams.readOnlyGetter {function(@param data {})} - функция,
*                  которая вычисляет значение аттрибута readOnly,
* }}
 *
 * @returns {{
 *  data: {},
 *  fields: {},
 *  options: {},
 *  fieldErrors: {} - Ошибки, связаные с валидацией полей документа,
 *  nonFieldErrors: {} - Ошибки, связаные с валидацией документа,
 *  systemErrors: string - Общие ошибки системы,
 *  loading: boolean,
 *  changed: boolean,
 *  isNew: boolean,
 *  actions: {
 *    onReload: (function(): void),
 *    onChange: (function(@param partData {}): void),
 *    onSaveWithoutExit: (function(): void),
 *    onSaveNExit: (function(): void),
 *    onExecuteNExit: (function(): void),
 *    onExecute: (function(): void),
 *    onUnexecute: (function(): void),
 *    onUndo: (function(): void),
 *    onRedo: (function(): void),
 *    onClose: (function(): void),
 *    onErr: (function(): void),
 *    onLoading: (function(): void),
 *    onClearErrs: (function(): void),
 *    onClearNonFieldErrors: (function(): void),
 *    onDraft: (function(): void),
 *  }
 *  permissions: {
 *    canSave: boolean,
 *    canUndo: boolean,
 *    canRedo: boolean,
 *    canClose: boolean,
 *    canExecute: boolean,
 *    canUnexecute: boolean,
 *    canChange: boolean,
 *  }
 * }}
 */
const useEditor = (editorParams) => {
  const defaultParams = useMemo(() => ({
    id: null,
    reason: '',
    isGroup: false,
    copyFrom: '', // id документа, который копируется
    onSaveCallBack: null, // callback при сохранении документа
    onCloseCallBack: null, // callback при выходе
    defaults: {}, // Значения по умолчанию  для нового объекта
    readOnlyGetter: null, // функция, которая вычисляет значение аттрибута readOnly
  }),
  []);

  const {
    backendURL,
    id,
    reason, // id_документа основания
    isGroup, // это группа
    copyFrom, // id документа, который копируется
    onSaveCallBack, // callback при сохранении документа
    onCloseCallBack, // callback при выходе
    defaults, // Значения по умолчанию  для нового объекта
    // readOnlyGetter, // функция, которая вычисляет значение аттрибута readOnly
  } = useMemo(
    () => ({ ...defaultParams, ...editorParams }),
    [defaultParams, editorParams],
  );

  const { auth } = useContext(AppContext);
  const { sendUpdateSignal } = useContext(WinManagerContext);

  const meta = useMD(backendURL);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const currentData = useRef(null);
  const [data, setData] = useState({
    current: {},
    history: {
      data: [], pointer: null,
    },
  });
  const [fields, setFields] = useState(null);
  const [options, setOptions] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const updateLister = useCallback(
    async () => sendUpdateSignal(backendURL),
    [backendURL, sendUpdateSignal],
  );
  // TODO:
  const [saveListeners, setSaveListeners] = useState([updateLister]);
  // TODO:
  const [extraActions, setExtraActions] = useState([]);
  const [readOnlyFields, setReadOnlyFields] = useState({});
  // TODO:
  const [leadingFields, setLeadingFields] = useState([]);
  // TODO:
  const [fieldErrors, setFieldErrors] = useState({});
  // TODO:
  const [nonFieldErrors, setNonFieldErrors] = useState(null);

  currentData.current = data.current;

  const isNew = id === 'create' || id === 'createGroup';

  const [changed, setChanged] = useState(false);

  const loadData = useCallback(
    async (itemId, initParams) => {
      setErr(null);
      const r = await api.get$(`${backendURL}${itemId}/ `, auth, initParams);
      if (!r.ok) {
        let e;
        try {
          e = await r.text();
        } catch {
          e = `${r.status} ${r.statusText}`;
        }
        throw new Error(e);
      }
      const d = await r.json();
      setData({
        current: d,
        history: {
          data: [d],
          pointer: null,
        },
      });
      setChanged(false);
      return d;
    },
    [auth, backendURL],
  );

  const loadOptions = useCallback(
    async (itemId) => {
      const url = isNew ? backendURL : `${backendURL}${itemId}/`;
      const r = await api.options(url, auth);
      if (!r.ok) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      const o = await r.json();
      setOptions({
        name: o.name,
        description: o.description,
      });
      const saveMethod = isNew ? 'POST' : 'PUT';
      const rOnly = !(saveMethod in o.actions);
      setReadOnly(rOnly);
      setExtraActions(o.extra_actions.map((ea) => ea.name.toLowerCase()));
      setLeadingFields(o.leading_fields);
      setFields(rOnly ? o.actions.GET : o.actions[saveMethod]);
    },
    [auth, backendURL, isNew],
  );

  const draft = useCallback(
    /**
     * Execute DRAFT action
     * @param rec {{}} - rec,
     * @param requirements {{}} - requirements
     * @param updateData {boolean} - updateData
     * @returns {Promise<null|*>}
     */
    async (rec, requirements = {}, updateData = true) => {
      if (!rec) throw new Error('Rec не может быть пустым или неопределенным');
      setLoading(true);
      const params = {
        record: rec,
        requirements,
      };

      const resp = await api.post$(`${backendURL}draft/`, auth, params);
      if (resp.ok) {
        // eslint-disable-next-line camelcase
        const { record, leading_fields, read_only_fields } = await resp.json();

        const headerReadonlyFields = read_only_fields.filter((f) => f.indexOf('.') === -1);

        const tablePartsReadonlyFields = read_only_fields
          .filter((f) => f.indexOf('.') !== -1)
          .reduce((R, f) => {
            const [tpName, fName] = f.split('.');
            const o = R[tpName] || [];
            return {
              ...R,
              [tpName]: [...o, fName],
            };
          }, {});

        // Установим значение аттрибута read
        setErr(null);
        setReadOnlyFields({ [HEADER_KEY]: headerReadonlyFields, ...tablePartsReadonlyFields });
        setLeadingFields(leading_fields);
        if (updateData) {
          setData((oldData) => ({
            current: {
              ...oldData.current,
              ...record,
              // Исключения. От драфт всегда призодят пустые
              repr: oldData.current.repr,
              url: oldData.current.url,
              resource: oldData.current.resource,
            },
            history: { data: [], pointer: null },
          }));
        }
        setLoading(false);
        return record;
      }
      let e;
      try {
        e = await resp.text();
      } catch {
        e = `${resp.status} ${resp.statusText}`;
      }
      setLoading(false);
      setErr(e);
      return null;
    },
    [auth, backendURL],
  );
  const locked = useRef(false);

  const lock = useCallback(
    async () => {
      if (locked.current) return true;
      // Блокируются только справочники и документы
      if (isNew) {
        locked.current = true;
      } else {
        setLoading(true);
        setErr(null);
        const r = await api.get(`${backendURL}${id}/block/`, auth);
        if (r.ok) {
          locked.current = true;
        } else if (r.status === 423) {
          const lockInfo = await r.json();
          const dataStartBlock = new Date(lockInfo.start_at)
            .toLocaleString('uk', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            });
          throw new Error(`Цей об'єкт заблоковано користувачем: ${lockInfo.blocker.first_name} ${lockInfo.blocker.last_name} c ${dataStartBlock}`);
        } else {
          throw new Error(`Помилка при встановленні блокування ${r.status} ${r.statusText}`);
        }
        setLoading(false);
      }
      return locked.current;
    },
    [auth, backendURL, id, isNew],
  );

  const unlock = useCallback(
    async () => {
      if (!locked.current) return null;
      if (isNew) {
        locked.current = false;
      } else {
        setErr(null);
        const r = await api.get(`${backendURL}${id}/unblock/`, auth);
        if (r.ok) {
          locked.current = false;
        } else {
          throw new Error(`Помилка при знятті блокування ${r.status} ${r.statusText}`);
        }
      }
      return null;
    },
    [auth, backendURL, id, isNew],
  );

  useEffect(
    () => () => unlock(),
    [unlock],
  );

  const onChange = useCallback(
    /**
     *
     * @param partOfData {{}, function }
     */
    async (partOfData) => {
      let l = false;
      try {
        l = await lock();
      } catch (e) {
        setErr(e.message);
      }
      if (l) {
        setChanged(true);
        setData(({ current, history }) => {
          const p = typeof partOfData === 'function' ? partOfData(current) : partOfData;
          const newCurrent = { ...current, ...p };
          const hasChange = Object.keys(newCurrent)
            .reduce((Ch, k) => Ch || partOfData[k] !== current[k], false);
          const newHData = history.pointer === null
            ? [...history.data, newCurrent]
            : [...history.data.slice(0, history.pointer + 1), newCurrent];
          // Возможно если в p есть табличные части,
          // то необходимо проверять строки на равенство а в них ключи
          const diff = getObjectDiff(current, p);
          const useDraft = leadingFields && diff
            .reduce((R, field) => leadingFields.includes(field) || R, false);
          if (useDraft) {
            draft(newCurrent);
          }

          return ({
            current: newCurrent,
            history: hasChange ? {
              data: newHData,
              pointer: null,
            } : history,
          });
        });
      }
    },
    [draft, leadingFields, lock],
  );

  const save = useCallback(
    async (savableData) => {
      setLoading(true);
      setErr(null);
      setFieldErrors({});
      setNonFieldErrors(null);
      const r = isNew
        ? await api.post(backendURL, auth, savableData)
        : await api.put(`${backendURL}${id}/`, auth, savableData);
      setLoading(false);
      if (!r.ok && r.status !== 422) {
        throw new Error(`${r.status} ${r.statusText}`);
      }
      const d = await r.json();
      if (r.status === 422) {
        const errors = buildErrors(d.error_data);
        const nfes = [
          ...(errors.messages || []),
          ...Object.keys(errors.exceptions || {}).reduce((R, header) => [
            ...R,
            ...errors.exceptions[header].map((message) => ({
              header,
              message,
            })),
          ], []),
        ];
        setNonFieldErrors(nfes.length ? nfes : null);
        setFieldErrors(errors.fields);

        throw new ItemSaveException(`${r.status} ${r.statusText}`);
      }
      await Promise.all(saveListeners.map((l) => l(d)));
      await unlock();
      setChanged(false);
      setData({ current: d, history: { data: [], pointer: null } });
      return d;
    },
    [auth, backendURL, id, isNew, saveListeners, unlock],
  );

  const undo = useCallback(
    () => {
      setData(({ current, history }) => {
        if (history.pointer === null) {
          if (history.data.length > 1) {
            return ({
              current: history.data[history.data.length - 2],
              history: {
                data: history.data,
                pointer: history.data.length - 2,
              },
            });
          }
        } else if (history.pointer > 0) {
          return ({
            current: history.data[history.pointer - 1],
            history: {
              data: history.data,
              pointer: history.pointer - 1,
            },
          });
        }
        return {
          current, history,
        };
      });
    },
    [],
  );

  const redo = useCallback(
    () => {
      setData(({ current, history }) => {
        if (history.pointer !== null) {
          if (history.data.length > history.pointer + 1) {
            return ({
              current: history.data[history.pointer + 1],
              history: {
                data: history.data,
                pointer: history.data.length > history.pointer + 2
                  ? history.pointer + 1
                  : null,
              },
            });
          }
        }
        return {
          current, history,
        };
      });
    },
    [],
  );

  const onSaveWithoutExit = useCallback(
    () => save(data.current)
      .then((d) => {
        if (onSaveCallBack) onSaveCallBack(d);
      })
      .catch((e) => {
        if (!(e instanceof ItemSaveException)) setErr(e.message);
      }),
    [data, onSaveCallBack, save],
  );

  const onSaveNExit = useCallback(
    () => save(data.current)
      .then((d) => onCloseCallBack && onCloseCallBack(d))
      .catch((e) => setErr(e.message)),
    [data, onCloseCallBack, save],
  );

  const onExecuteNExit = useCallback(
    () => save({ ...data.current, executed: true })
      .then((d) => {
        if (onCloseCallBack) onCloseCallBack(d);
      }),
    [data, onCloseCallBack, save],
  );

  const onExecute = useCallback(
    () => save({ ...data.current, executed: true }).then((d) => {
      if (onSaveCallBack) onSaveCallBack(d);
    }),
    [data, onSaveCallBack, save],
  );

  const onUnexecute = useCallback(
    () => save({ ...data.current, executed: false }).then((d) => {
      if (onSaveCallBack) onSaveCallBack(d);
    }),
    [data, onSaveCallBack, save],
  );

  // TODO:
  const initParams = useMemo(
    () => {
      const oneCDefaults = Object.keys(defaults)
        .filter((jsCol) => jsCol in meta.columns)
        .reduce((R, jsCol) => ({ ...R, [meta.columns[jsCol].name]: defaults[jsCol] }), {});

      // Параметры допустимы, только если это создание нового и то только один
      if (!isNew) return {};
      if (reason) return { defaults: oneCDefaults, reason };
      if (isGroup) return { defaults: oneCDefaults, isGroup };
      if (copyFrom) return { defaults: oneCDefaults, copyFrom };
      return { defaults: oneCDefaults };
    },
    [copyFrom, defaults, isGroup, isNew, meta.columns, reason],
  );

  const onReload = useCallback(
    () => {
      setLoading(true);
      (isNew ? draft({}) : loadData(id, initParams).then((d) => draft(d, {}, false)))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [draft, id, initParams, isNew, loadData],
  );

  useEffect(
    () => {
      setLoading(true);
      loadOptions(id)
        .then(() => (isNew ? draft({}) : loadData(id, initParams).then((d) => draft(d, {}, false))))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [draft, id, initParams, isNew, loadData, loadOptions],
  );

  const permissions = useMemo(
    () => ({
      canSave: !readOnly,
      canUndo: !readOnly && data.history.pointer !== 0 && data.history.data.length > 1,
      canRedo: !readOnly && data.history.pointer !== null
          && data.history.pointer < data.history.data.length,
      canClose: !!onCloseCallBack,
      canExecute: extraActions.includes('execute'),
      canUnexecute: extraActions.includes('unexecute'),
      canChange: !readOnly,
      canHistory: extraActions.includes('history'),
    }),
    [data.history.data.length, data.history.pointer, extraActions, onCloseCallBack, readOnly],
  );

  const onClose = useCallback(
    () => onCloseCallBack && onCloseCallBack(),
    [onCloseCallBack],
  );

  const onClearErrs = useCallback(
    () => setErr(null),
    [],
  );
  const onClearNonFieldErrors = useCallback(
    () => setNonFieldErrors(null),
    [],
  );

  const registerSaveListener = useCallback(
    (f) => setSaveListeners((o) => {
      if (o.includes(f)) return o;
      return [...o, f];
    }),
    [],
  );

  const actions = useMemo(
    () => ({
      onReload,
      onChange,
      onSaveWithoutExit,
      onSaveNExit,
      onExecuteNExit,
      onExecute,
      onUnexecute,
      onRedo: redo,
      onUndo: undo,
      onClose,
      onErr: setErr,
      onLoading: setLoading,
      onClearErrs,
      onClearNonFieldErrors,
      registerSaveListener,
      onDraft: draft,
    }),
    [draft, onChange, onClearErrs, onClearNonFieldErrors, onClose, onExecute, onExecuteNExit, onReload, onSaveNExit, onSaveWithoutExit, onUnexecute, redo, registerSaveListener, undo],
  );

  const headerReadOnlyFields = useMemo(() => readOnlyFields[HEADER_KEY] || [], [readOnlyFields]);
  return {
    data: data.current,
    fields,
    options,
    fieldErrors,
    nonFieldErrors,
    loading,
    systemErrors: err,
    changed,
    permissions,
    actions,
    isNew,
    readOnlyFields,
    headerReadOnlyFields,
  };
};

export default useEditor;
