const frontendURL = '/cat/documentbasis/';
const backendURL = '/api/references/refdocumentbasis/';
const name = 'Класифікатор підстав';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
