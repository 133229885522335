import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const Header = ({
  fields, activeCol, highlights, onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col
      sm={12}
      className="px-1  d-flex flex-column justify-content-end"
    >
      <Row noGutters>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'disposer'}
            highlighted={highlights.includes('disposer')}
            onToggleHighlght={() => onToggleHighlght('disposer')}
          >
            {fields.disposer.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'kbp7_item'}
            highlighted={highlights.includes('kbp7_item')}
            onToggleHighlght={() => onToggleHighlght('kbp7_item')}
          >
            {fields.kbp7_item.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'kekv_item'}
            highlighted={highlights.includes('kekv_item')}
            onToggleHighlght={() => onToggleHighlght('kekv_item')}
          >
            {fields.kekv_item.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'disposer_acc'}
            highlighted={highlights.includes('disposer_acc')}
            onToggleHighlght={() => onToggleHighlght('disposer_acc')}
          >
            {fields.disposer_acc.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'plan_year_amount'}
            highlighted={highlights.includes('plan_year_amount')}
            onToggleHighlght={() => onToggleHighlght('plan_year_amount')}
          >
            {fields.plan_year_amount.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'plan_period_amount'}
            highlighted={highlights.includes('plan_period_amount')}
            onToggleHighlght={() => onToggleHighlght('plan_period_amount')}
          >
            {fields.plan_period_amount.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'plan_month_amount'}
            highlighted={highlights.includes('plan_month_amount')}
            onToggleHighlght={() => onToggleHighlght('plan_month_amount')}
          >
            {fields.plan_month_amount.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'funds_direction'}
            highlighted={highlights.includes('funds_direction')}
            onToggleHighlght={() => onToggleHighlght('funds_direction')}
          >
            {fields.funds_direction.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'fact_period_amount'}
            highlighted={highlights.includes('fact_period_amount')}
            onToggleHighlght={() => onToggleHighlght('fact_period_amount')}
          >
            {fields.fact_period_amount.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'balance'}
            highlighted={highlights.includes('balance')}
            onToggleHighlght={() => onToggleHighlght('balance')}
          >
            {fields.balance.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'fin_commitment_amount'}
            highlighted={highlights.includes('fin_commitment_amount')}
            onToggleHighlght={() => onToggleHighlght('fin_commitment_amount')}
          >
            {fields.fin_commitment_amount.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount'}
            highlighted={highlights.includes('amount')}
            onToggleHighlght={() => onToggleHighlght('amount')}
          >
            {fields.amount.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'template'}
            highlighted={highlights.includes('template')}
            onToggleHighlght={() => onToggleHighlght('template')}
          >
            {fields.template.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'order_distribution_remark'}
            highlighted={highlights.includes('order_distribution_remark')}
            onToggleHighlght={() => onToggleHighlght('order_distribution_remark')}
          >
            {fields.order_distribution_remark.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'purpose_of_payment'}
            highlighted={highlights.includes('purpose_of_payment')}
            onToggleHighlght={() => onToggleHighlght('purpose_of_payment')}
          >
            {fields.purpose_of_payment.label}
          </TableHeaderCell>
        </Col>
        <Col
          xl={3}
          sm={12}
        >
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'finrequest'}
            highlighted={highlights.includes('finrequest')}
            onToggleHighlght={() => onToggleHighlght('finrequest')}
          >
            {fields.finrequest.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    amount_total: PropTypes.number,
    amount_br_total: PropTypes.number,
    ...[...Array(12)].reduce((R, r, i) => ({
      ...R,
      [`amount_${i + 1}`]: PropTypes.number,
      [`amount_br_${i + 1}`]: PropTypes.number,
    }), {}),
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
