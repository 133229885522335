import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import useListerBackend from './backend';
import { RELOAD_TIMEOUT } from '../../../constants/misc';
import useListerActions from './actions';
import { WinManagerContext } from '../../../providers/winManagerProvider';
import { useDebounce } from '../../../utils/debounce';
import { periodVarians } from '../../../constants/date';

/**
 *
 * @param backendURL {string} - тип
 * @param viewType {string} - lister / selector
 * @param options {{
 *    initialValue: {
 *        id: string,
 *        repr: string,
 *    },
 *    initialFilter: {},
 *    noHierarchy: boolean,
 * }}
 * @returns {{
 * visibleColumns: {}[] Видимые колонки с опиcаниями из метаданных,
 * items: {}[] Собственно данные,
 * activeRow: string id текущей строки,
 * selectedRows: string[] массив ид выделенных строк не пуать с текущей строкой,
 * order: {
 *   column: string,
 *   isAscending: boolean,
 * },
 * loading: bool,
 * err: string,
 * onRowClick: function стандартная реакиця на клик по строке,
 * onReload: function,
 * onNextPage: function,
 * showDeleted: boolean,
 * searchString: string,
 * filterOpened: boolean,
 * period: {
 *   startDate: number,
 *   endDate: number,
 *   variant: string,
 * },
 * approveStatus: {mode: number, status: {}, history: {}},
 * messages: { title: string, text: string, variant: string }[],
 * filteringFields: {name: string, label: string, type: string}[],
 * permissions: {
 *  canNew: boolean,
 *  canEdit: boolean,
 *  canCopy: boolean,
 *  canDelete: boolean,
 *  canShowDeleted: boolean,
 *  canSearch: boolean,
 *  canNextPage: boolean,
 * },
 * actions: {
 *  onNew: function,
 *  onEdit: function,
 *  onCopy: function,
 *  onDelete: function,
 *  onNewGroup: function,
 *  onToggleShowDeleted: function,
 *  onExecute: function,
 *  onUnexecute: function,
 *  onSearch: function,
 *  onSetPeriod: function,
 *  onSetFilter: function,
 *  onClearMessages: function,
 *  onDeleteMessage: function,
 * }
 * }}
 */
const useLister = (
  backendURL, viewType,
  options,
) => {
  const { listersTimeStamps } = useContext(WinManagerContext);

  const timestamp = listersTimeStamps[backendURL] || -1;

  const opt = useMemo(() => {
    const defaults = {
      // Начальное значение
      initialValue: null,
      // Принудительное отключение иерархии
      noHierarchy: false,
      // первоначальная сортировка {column, direction}
      initialOrder: { column: '', isAscending: true },
      initialFilter: {}, // TODO:
      onSelect: null,
      onChoice: null,
      overridePermissions: {}, // TODO:
      params: [], // TODO or Remove
    };
    return { ...defaults, ...options };
  },
  [options]);

  const [search, setSearch] = useState('');

  const [backendSearch, setBackendSearch] = useState(search); // поиск, который используется для

  const [orderColumn, setOrderColumn] = useState({
    column: opt.initialOrder.column,
    isAscending: opt.initialOrder.isAscending,
  });

  // TODO: make this
  const [filterOpened, setFilterOpened] = useState(false);
  const [localFilter, setLocalFilter] = useState({}); // key: filter, где key - ключ ( для разделения в компонентах);

  const [showDeleted, setShowDeleted] = useState(false);

  const [selectedRows, setSelectedRows] = useState(opt.initialValue ? [opt.initialValue.get('id')] : []);

  const refedSelectedRows = useRef(selectedRows);

  useEffect(
    () => {
      refedSelectedRows.current = selectedRows;
    },
    [selectedRows],
  );

  const onSetOrder = useCallback(
    (e, column) => setOrderColumn((o) => {
      if (column === o.column) {
        return {
          column,
          isAscending: !o.isAscending,
        };
      }
      return {
        column,
        isAscending: true,
      };
    }),
    [],
  );

  // EndDate: 1600905599584
  // StartDate: 1600819200584
  // Variant: "Custom"
  //  TODO: Make this
  const [period, setPeriod] = useState({
    start: null,
    end: null,
    variant: null,
  });

  const [periodLoaded, setPeriodLoaded] = useState(false);
  const [pathLoaded, setPathLoaded] = useState(false);

  const [usedFilter, setUsedFilter] = useState(null);
  // TODO:
  const defaults = useMemo(
    () => ({}),
    //   opt.initialFilter
    // .filter(({ comparisonType }) => !comparisonType || comparisonType === comparisonTypes.equal)
    // .reduce((R, { fieldName, value }) => ({ ...R, [fieldName]: value }), {}),
    [],
  );

  const {
    visibleColumns, items, loading, err, onReload, onLoadOptions, onNextPage, permissions: backPermissions,
    onDeleteItems, onExecute: onExecuteItems, onUnexecute: onUnexecuteItems,
    onLoadSettings, onSaveSettings, settings, onSetSettings,
    onLoadChildren, onUnloadChildren, loadPath,
    messages, clearMessages, deleteMessage,
    pageInfo, optionsLoaded, filteringFields,
  } = useListerBackend({
    backendURL,
    viewType,
    filter: usedFilter,
    order: orderColumn,
    searchString: backendSearch,
    noHierarchy: opt.noHierarchy,
    params: opt.params,
    defaults,

  });

  const readyToLoad = periodLoaded && pathLoaded && optionsLoaded;

  const periodFilter = useMemo(
    () => {
      const { start, end } = period.variant in periodVarians ? periodVarians[period.variant].eval() : period;
      return {
        ...(start ? { doc_date_after: start } : {}),
        ...(end ? { doc_date_before: end } : {}),
      };
    },
    [period],
  );

  useEffect(
    () => {
      if (readyToLoad) {
        setUsedFilter({
          ...opt.initialFilter,
          ...(showDeleted || !backPermissions.canHideDeleted ? {} : { deleted: false }),
          ...(Object.keys(localFilter)
            .filter((lf) => localFilter[lf].use)
            .reduce((R, lf) => ({ ...R, [lf]: localFilter[lf].value }), {})
          ),
          ...periodFilter,
        });
      }
    },
    [backPermissions, localFilter, opt.initialFilter, optionsLoaded, periodFilter, readyToLoad, showDeleted],
  );

  const [openedItems, setOpenedItems] = useState([]);
  useEffect(
    () => {
      onLoadOptions();
    },
    [onLoadOptions],
  );
  useEffect(
    () => {
      if (!pathLoaded && opt.initialValue && opt.initialValue.id) {
        loadPath(
          opt.initialValue.id,
          opt.initialValue.repr,
          (path) => {
            pageInfo.current.parents = [...pageInfo.current.parents, ...path];
            setOpenedItems(path);
            setPathLoaded(true);
          },
        );
      } else {
        setPathLoaded(true);
      }
    },
    [loadPath, opt, opt.initialValue, pageInfo, pageInfo.parents, pathLoaded],
  );

  const onRowFocus = useCallback(
    (e, rowId, multiple = false) => {
      // setActiveRow(rowId);
      setSelectedRows((oldV) => {
        if (multiple && oldV.includes(rowId)) return oldV.filter((o) => o !== rowId);
        if (multiple && !oldV.includes(rowId)) return [...oldV, rowId];
        return [rowId];
      });
      if (opt.onSelect) {
        const selectedItem = items
          .filter((item) => item.id === rowId)
          .reduce((R, row) => row, null);
        opt.onSelect(e, selectedItem);
      }
    },
    [items, opt],
  );

  const displayItems = useMemo(
    () => {
      if (backendSearch || !backPermissions.canHierarchy) return items;
      const getChildren = (parent = null, level = 0) => items
        .filter((item) => (typeof item.parent === 'object' && item.parent ? item.parent.id === parent : item.parent === parent))
        .reduce((R, item) => [
          ...R,
          { ...item, level },
          ...openedItems.includes(item.id) ? getChildren(item.id, level + 1) : [],
        ], []);
      return getChildren();
      //
    },
    [backPermissions.canHierarchy, backendSearch, items, openedItems],
  );

  const debouncedBackendSearch = useDebounce(setBackendSearch);

  const actions = useListerActions({
    backendURL,
    viewType,
    backPermissions,
    items,
    refedSelectedRows,
    openedItems,
    setOpenedItems,
    onExecuteItems,
    onDeleteItems,
    onUnexecuteItems,
    setShowDeleted,
    onUnloadChildren,
    onLoadChildren,
    setSearch,
    debouncedBackendSearch,
    setPeriod,
    onSetSettings,
    onChoice: opt.onChoice,
    setLocalFilter,
    onReload,
    defaults,
    clearMessages,
    deleteMessage,
    setFilterOpened,
  });

  const permissions = useMemo(
    () => ({
      ...backPermissions,
      canNew: backPermissions.canNew,
      canNewFolder: backPermissions.canNewFolder,
      canCopy: backPermissions.canCopy && selectedRows.length > 0,
      canEdit: backPermissions.canEdit && selectedRows.length > 0,
      canDelete: backPermissions.canDelete && selectedRows.length > 0,
      canSearch: items.length > 0,
      canShowDeleted: backPermissions.canHideDeleted,
      canExecute: backPermissions.canExecute && selectedRows.length > 0,
      canUnexecute: backPermissions.canUnexecute && selectedRows.length > 0,
      canHierarchy: backPermissions.canHierarchy && !opt.noHierarchy,
      canFilter: true,
      ...opt.overridePermissions,
    }),
    [backPermissions, items.length, opt.noHierarchy, opt.overridePermissions, selectedRows.length],
  );

  useEffect(
    () => {
      onLoadSettings();
      return () => onSaveSettings();
    },
    [onLoadSettings, onSaveSettings],
  );

  useEffect(
    () => {
      if (settings && !periodLoaded) {
        if ('period' in settings) {
          setPeriod({
            startDate: settings.period.StartDate,
            endDate: settings.period.EndDate,
            variant: settings.period.Variant,
          });
        } else {
          setPeriod({
            startDate: null,
            endDate: null,
            variant: null, // enums.PeriodSelection.Custom.name,
          });
        }
        setPeriodLoaded(true);
      }
    },
    [period.EndDate, period.StartDate, period.Variant, period.startDate, periodLoaded, settings],
  );

  const reloadInterval = useRef(null);

  useEffect(
    () => {
      if (optionsLoaded && usedFilter && timestamp && pathLoaded) {
        onReload();
        reloadInterval.current = setInterval(onReload, RELOAD_TIMEOUT);
      }
      return () => {
        if (reloadInterval.current && pathLoaded) clearInterval(reloadInterval.current);
      };
    },
    [onReload, optionsLoaded, pathLoaded, timestamp, usedFilter],
  );

  return {
    visibleColumns,
    items: displayItems,
    openedItems,
    selectedRows,
    order: orderColumn,
    loading,
    err,
    showDeleted,
    searchString: search,
    filterOpened,
    settings,
    period,
    localFilter,

    onRowFocus,
    onSetOrder,
    onReload,
    onNextPage,
    permissions,
    onSetSettings,

    actions,
    messages,
    filteringFields,
  };
};

export default useLister;
