import React, {
  useEffect, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown, Overlay, Badge } from 'react-bootstrap';
import { metaBackends } from '../../../meta';

const StyledLink = styled(Link)
  .attrs({
    target: '_blank',
  })`
  color: #488de4;
  &:hover{
    color: #1a4aac;
  };
  &:visited{
    color: #3f51b4;
  };
`;

const StyledDDMenu = styled(Dropdown.Menu)`
  opacity: 1 !important;
`;

const StyledDDToggle = styled(Dropdown.Toggle)`
  display: none !important;
`;

const ContextMenu = ({
  onCloseMenu, detail, onDetailProcessing, allowedDetails, opened, target,
}) => {
  const menuRef = useRef(null);
  useEffect(
    () => {
      const clicker = (e) => {
        if (!e.path.includes(menuRef.current)) {
          onCloseMenu(e);
        }
      };
      document.addEventListener('click', clicker);

      return function cleanup() {
        document.removeEventListener('click', clicker);
      };
    },
  );
  return (
    <Overlay target={target} show={opened}>
      {({
        placement, arrowProps, show: _show, popper, ...props
      }) => (
        <div
          {...props}
        >
          <Dropdown>
            <StyledDDToggle />
            <StyledDDMenu show>
              <Dropdown.Header>Що зробити?</Dropdown.Header>
              <Dropdown.Item
                as={StyledLink}
                to={`${metaBackends[detail.currentItem.backend].frontendURL}${detail.currentItem.id}`}
                onClick={onCloseMenu}
              >
                {`Відкрити ${detail.currentItem.repr}`}
              </Dropdown.Item>
              <Dropdown.Divider />
              {onDetailProcessing && allowedDetails.map((c) => (
                <Dropdown.Item
                  key={c.key}
                  onClick={(e) => onDetailProcessing(e, c)}
                >
                  Розшифрувати по
                  {' '}
                  <Badge variant="primary">{c.label}</Badge>
                </Dropdown.Item>
              ))}
            </StyledDDMenu>
          </Dropdown>
        </div>
      )}
    </Overlay>
  );
};

ContextMenu.propTypes = {
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  detail: PropTypes.shape({
    currentItem: PropTypes.shape({
      backend: PropTypes.string,
      id: PropTypes.number,
      repr: PropTypes.string,
    }).isRequired,
  }),
  onDetailProcessing: PropTypes.func,
  allowedDetails: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  opened: PropTypes.bool,
  target: PropTypes.shape({}),
};

ContextMenu.defaultProps = {
  onDetailProcessing: null,
  opened: false,
  target: null,
  detail: null,
};

export default ContextMenu;
