import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  OverlayTrigger, Popover,
} from 'react-bootstrap';
import { useLocation } from 'react-router';
import { StyledEm, StyledMenuDiv } from './styles';
import { childrenPropType } from '../../common/proptypes';

const MenuItem = ({
  icon, label, children, name,
}) => {
  const [opened, setOpened] = useState(false);
  const ddRef = useRef();
  const onDocumentClick = useCallback(
    (e) => {
      if (!ddRef.current.contains(e.target)) {
        setOpened(false);
      }
    },
    [],
  );
  useEffect(
    () => {
      if (opened) {
        document.body.addEventListener('click', onDocumentClick);
        return () => document.body.removeEventListener('click', onDocumentClick);
      }
      return () => null;
    },
    [onDocumentClick, opened],
  );
  const l = useLocation();
  useEffect(
    () => {
      // ПРи изменении текущего расположения закрываем панель
      if (l) setOpened(false);
    },
    [l],
  );
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        onToggle={() => setOpened(!opened)}
        show={opened}
        overlay={(
          <Popover id={name} style={{ maxWidth: 'calc(100vw - 10%)' }}>
            {/* <Popover.Title as="h1"> */}
            {/*  <img src={icon} width="20px" alt="" /> */}
            {/*  <StyledEm>{label}</StyledEm> */}
            {/* </Popover.Title> */}
            <Popover.Content ref={ddRef}>
              {children}
            </Popover.Content>
          </Popover>
        )}
      >
        <StyledMenuDiv>
          <img src={icon} width="20px" alt="" />
          <StyledEm>{label}</StyledEm>
        </StyledMenuDiv>
      </OverlayTrigger>

    </>

  );
};

MenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: childrenPropType.isRequired,
};

export default MenuItem;
