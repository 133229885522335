import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';
import OrdersList from './ordersList';
import { availableOrdersPropType, getPreviousOrder, ordersPropType } from '../../hooks';

const OrdersEditor = ({ availableOrders, orders, ordersHandlers }) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);

  const onActiveSelectionHandler = useCallback(
    (oname) => setActiveOrder(oname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const ordersDragEndHanlder = useCallback(
    ({ name, type }, oname) => {
      if (type === itemTypes.availableField) {
        ordersHandlers.addOrderHandler(name, oname);
      } else if (type === itemTypes.order) {
        ordersHandlers.swapOrderHandler(name, oname);
      }
    },
    [ordersHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.order) {
        ordersHandlers.removeOrderHandler(name);
        if (activeOrder === name) setActiveOrder(null);
      }
    },
    [activeOrder, ordersHandlers],
  );


  return (
    <Row>
      <Col _lg={6}>
        <AvailableFieldList
          fields={availableOrders}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={ordersHandlers.addOrderHandler}
          label="Доступні поля"
          onDragEnd={avaDragEndHanlder}
        />
      </Col>
      <Col lg={0.1}>
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => ordersHandlers.clearAllOrdersHandler()}
            disabled={!orders.length}
          />
          <LeftButton
            onClick={() => {
              ordersHandlers.removeOrderHandler(activeOrder);
              setActiveOrder(getPreviousOrder(orders, activeOrder));
            }}
            disabled={activeOrder === null}
          />
          <RightButton
            onClick={() => ordersHandlers.addOrderHandler(activeAvaField)}
            disabled={!activeAvaField}
          />
        </ButtonGroup>
      </Col>
      <Col _lg={6}>
        <OrdersList
          orders={orders}
          activeOrder={activeOrder}
          onClick={onActiveSelectionHandler}
          onDblClick={ordersHandlers.removeOrderHandler}
          onDragEnd={ordersDragEndHanlder}
          onDirectionChange={ordersHandlers.changeOrderDirectionHandler}
        />
      </Col>
    </Row>
  );
};

OrdersEditor.propTypes = {
  orders: ordersPropType.isRequired,
  availableOrders: availableOrdersPropType.isRequired,
  ordersHandlers: PropTypes.shape({
    addOrderHandler: PropTypes.func,
    removeOrderHandler: PropTypes.func,
    swapOrderHandler: PropTypes.func,
    clearAllOrdersHandler: PropTypes.func,
    changeOrderDirectionHandler: PropTypes.func,
  }).isRequired,
};

export default OrdersEditor;
