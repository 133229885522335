import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, {
  DocCommandPanel,
  getFieldsPropTypes,
  getErrorsPropTypes,
} from '../../../basicEditor';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { HeaderText } from '../../../../components/Styled/Forms';
import { DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import {
  FlexContainer,
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import { PlanningRows } from './tp';

const Editor = ({
  data,
  options,
  isNew,
  isReadOnly,
  isModified,
  onSave,
  onClose,
  onExecute,
  onCancelExecute,
  fields,
  errors,
  onChange,
  onDraft,
}) => {
  const mfoArr = [1];
  const grkArr = [2, 4, 5];

  let activeKey = '';

  const disposerKindProps = editorHooks.useCheckboxInputProps(
    'disposer_kind',
    data,
    fields,
    errors,
    onChange,
  );
  const localcodeProps = editorHooks.useStringInputProps(
    'localcode',
    data,
    fields,
    errors,
    onChange,
  );
  const yearProps = editorHooks.useNumberInputProps(
    'year',
    data,
    fields,
    errors,
    onChange,
  );
  const edrpouProps = editorHooks.useStringInputProps(
    'edrpou',
    data,
    fields,
    errors,
    onChange,
  );
  const nameProps = editorHooks.useStringInputProps(
    'name',
    data,
    fields,
    errors,
    onChange,
  );
  const codeDksuProps = editorHooks.useStringInputProps(
    'code_dksu',
    data,
    fields,
    errors,
    onChange,
  );
  const koatuuProps = editorHooks.useStringInputProps(
    'koatuu',
    data,
    fields,
    errors,
    onChange,
  );
  const postCodeProps = editorHooks.useStringInputProps(
    'post_code',
    data,
    fields,
    errors,
    onChange,
  );
  const addressProps = editorHooks.useStringInputProps(
    'address',
    data,
    fields,
    errors,
    onChange,
  );
  const phoneNumberProps = editorHooks.useStringInputProps(
    'phone_number',
    data,
    fields,
    errors,
    onChange,
  );
  const emailProps = editorHooks.useStringInputProps(
    'email',
    data,
    fields,
    errors,
    onChange,
  );
  const webSiteProps = editorHooks.useStringInputProps(
    'web_site',
    data,
    fields,
    errors,
    onChange,
  );
  const skypeProps = editorHooks.useStringInputProps(
    'skype',
    data,
    fields,
    errors,
    onChange,
  );
  const executiveNameProps = editorHooks.useStringInputProps(
    'executive_name',
    data,
    fields,
    errors,
    onChange,
  );
  const executiveEdrpouProps = editorHooks.useStringInputProps(
    'executive_edrpou',
    data,
    fields,
    errors,
    onChange,
  );
  const directorProps = editorHooks.useItemInputProps(
    'director',
    data,
    fields,
    errors,
    onChange,
  );
  const directorFsProps = editorHooks.useItemInputProps(
    'director_fs',
    data,
    fields,
    errors,
    onChange,
  );
  const disposerProps = editorHooks.useItemInputProps(
    'disposer',
    data,
    fields,
    errors,
    onChange,
  );

  if (mfoArr.includes(disposerKindProps.value)) {
    activeKey = 'participantsmfo';
  } else if (grkArr.includes(disposerKindProps.value)) {
    activeKey = 'participantsgrk';
  }
  return (
    <FlexContainer>
      <HeaderText>
        {isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}
      </HeaderText>
      <DocCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        onSave={() => onSave()}
        onClose={onClose}
        executed={data.executed}
        onExecute={() => onExecute()}
        onCancelExecute={() => onCancelExecute()}
      />
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...localcodeProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>

            <hr />

            <h3>Загальні відомості</h3>
            <Row>
              <Col>
                <EditorControls.StringInput {...nameProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...edrpouProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...codeDksuProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.StringInput {...koatuuProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.PhoneInput {...phoneNumberProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...postCodeProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...addressProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.StringInput {...emailProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...webSiteProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...skypeProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.ItemPicker {...directorProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...directorFsProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                {data.disposer_in_header && (
                  <EditorControls.ItemPicker
                    {...disposerProps}
                    filter={{ parent: data.authority.id }}
                    noHierarchy
                  />
                )}
              </Col>
            </Row>

            <hr />

            <h3>Установа, що здійснює функцію виконавчого органу</h3>

            <Row>
              <Col>
                <EditorControls.StringInput {...executiveNameProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.StringInput {...executiveEdrpouProps} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab
          defaultActiveKey={
            activeKey === 'participantsmfo'
              ? 'participantsmfo'
              : 'participantsgrk'
          }
          id="uncontrolled-tab-example"
        >
          {mfoArr.includes(disposerKindProps.value) && (
            <Tab
              eventKey="participantsmfo"
              title={fields.participantsmforow_set.label}
            >
              <TabContainer>
                <PlanningRows
                  data={data.participantsmforow_set}
                  fields={fields.participantsmforow_set.child.children}
                  disposer={disposerProps}
                  onDraft={() => onDraft(data, {
                    fill_rows: true,
                  })}
                  // readOnly={fields.participantsmforow_set.read_only}
                  errors={errors.participantsmforow_set}
                  onChange={(set) => onChange({ participantsmforow_set: set })}
                />
              </TabContainer>
            </Tab>
          )}
          {grkArr.includes(disposerKindProps.value) && (
            <Tab
              eventKey="participantsgrk"
              title={fields.participantsgrkrow_set.label}
            >
              <TabContainer>
                <PlanningRows
                  data={data.participantsgrkrow_set}
                  fields={fields.participantsgrkrow_set.child.children}
                  // readOnly={fields.participantsgrkrow_set.read_only}
                  errors={errors.participantsgrkrow_set}
                  onChange={(set) => onChange({ participantsgrkrow_set: set })}
                />
              </TabContainer>
            </Tab>
          )}
        </LabelTab>
      </DocumentBody>
    </FlexContainer>
  );
};

const dataPropTypes = {
  edrpou: PropTypes.string,
  code_dksu: PropTypes.string,
  koatuu: PropTypes.string,
  post_code: PropTypes.string,
  address: PropTypes.string,
  phone_number: PropTypes.string,
  email: PropTypes.string,
  web_site: PropTypes.string,
  skype: PropTypes.string,
  number: PropTypes.string,
  disposer: PropTypes.shape({}),
  director: PropTypes.shape({}),
  director_fs: PropTypes.shape({}),
};

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape(dataPropTypes).isRequired,
  fields: PropTypes.shape(getFieldsPropTypes(dataPropTypes)).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onExecute: PropTypes.func.isRequired,
  onCancelExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.doc.registrationcard.backendURL)(Editor);
