const frontendURL = '/srv/groupManagement/';
const name = 'Управління Групами';
const getVisibility = (user) => user.is_superuser;

const instance = {
  frontendURL,
  name,
  getVisibility,
};

export default instance;
