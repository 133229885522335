import React from 'react';
import PropTypes from 'prop-types';
import StdReportEditor from './editor';

const StdRouterReportEditor = ({ id }) => (
  <StdReportEditor reportId={id} />
);

StdRouterReportEditor.propTypes = {
  id: PropTypes.string.isRequired,
};

export default StdRouterReportEditor;
