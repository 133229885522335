import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const typesOfEntrySfProps = tableHooks.useItemInputProps('types_of_entry_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanProps = tableHooks.useNumberInputProps('amount_plan', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactProps = tableHooks.useNumberInputProps('amount_fact', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountDeviationProps = tableHooks.useNumberInputProps('amount_deviation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const explanationProps = tableHooks.useStringInputProps('explanation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  amountDeviationProps.value = (amountPlanProps.value || 0) - (amountFactProps.value || 0);

  return (
    <>
      <Col sm={12}>
        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="types_of_entry_sf" highlighted={highlights.includes('types_of_entry_sf')}>
              <TableControls.ItemPicker {...typesOfEntrySfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_plan" highlighted={highlights.includes('amount_plan')}>
              <TableControls.NumberInput {...amountPlanProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_fact" highlighted={highlights.includes('amount_fact')}>
              <TableControls.NumberInput {...amountFactProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_deviation" highlighted={highlights.includes('amount_deviation')}>
              <TableControls.NumberInput {...amountDeviationProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="explanation" highlighted={highlights.includes('explanation')}>
              <TableControls.StringInput {...explanationProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
    </>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
