import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalButton, SModal } from './style';
import FitlerEditor from './filterEditor';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

const LoadAllocationPlanButton = ({
  authority, budget, docDate, year,
  onLoadHandler,
}) => {
  const [opened, setOpened] = useState(false);
  const overrideFilter = useMemo(() => ({
    authority,
    budget,
    year,
  }),
  [authority, budget, year]);

  const defaultFilter = useMemo(() => {
    const d = docDate ? new Date(docDate) : new Date();
    return {
      begin_date: d.toISOString().substr(0, 10),
      end_date: docDate,
    };
  }, [docDate]);

  return (
    <>
      <ModalButton variant="primary" onClick={() => setOpened(true)}>
        Заповнити з данних планів асигнувань
      </ModalButton>
      <SModal show={opened} onHide={() => setOpened(false)} scrollable size="xl">
        <FitlerEditor
          overrideFilter={overrideFilter}
          defaultFilter={defaultFilter}
          onClose={() => setOpened(false)}
          onOk={(ids) => {
            onLoadHandler(ids);
            setOpened(false);
          }}
        />
      </SModal>
    </>
  );
};

LoadAllocationPlanButton.propTypes = {
  authority: foreignPropType.isRequired,
  budget: foreignPropType.isRequired,
  docDate: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  onLoadHandler: PropTypes.func.isRequired,
};
export default LoadAllocationPlanButton;
