import React, {
  forwardRef, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { FormControl, InputGroup, Dropdown } from 'react-bootstrap';
import { shortFormat } from '../../../constants/date';

const SFormControl = styled(FormControl)`
  &[readonly] {
    background: var(--white) !important;
  }
`;

const StyledCalendar = styled(Calendar)`
  border: none !important;
`;

export const DropButton = styled(Dropdown.Toggle)`
  background-color: var(--backgroundButton) !important;
  color: var(--colorTextButton)!important;
    &:hover {
      background-color: var(--hoverButton) !important;
    };
`;

const DateInput = forwardRef(({
  id, value, onChange, disabled, readOnly,
  errored,
}, ref) => {
  const [newDate, setNewDate] = useState('');
  const [view, setView] = useState('month');
  const [open, setOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [validateError, setValidateError] = useState(false);

  const dateRef = ref || useRef();
  const dropRef = useRef();

  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition, dateRef, displayValue]);

  useEffect(() => {
    if (value) {
      const d = new Date(value);
      // Из-за этого смещается дата
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      setNewDate(d);
      setDisplayValue(d.toLocaleString('uk', shortFormat));
    }
  },
  [value]);

  const validateDate = useCallback((v) => {
    if (v) {
      if (v.length !== 10 && v.length !== 8) return false;
      const arrD = v.split('.');
      arrD[1] -= 1;
      const d = new Date(arrD[2], arrD[1], arrD[0]);
      if ((d.getFullYear() === Number(arrD[2])
          || (d.getFullYear() - Math.floor(d.getFullYear() / 100) * 100 === Number(arrD[2])))
        && (d.getMonth() === Number(arrD[1]))
        && (d.getDate() === Number(arrD[0]))) {
        return true;
      }
      return false;
    }
    return true;
  }, []);

  useEffect(() => {
    if (validateDate(displayValue)) {
      setValidateError(false);
    }
  }, [validateDate, displayValue]);

  const onChangeDisplayValue = useCallback((v) => {
    setDisplayValue(v);
    if (validateDate(v)) {
      const date = new Date(v.substr(6), v.substr(3, 2) - 1, v.substr(0, 2));
      // Из-за этого смещается дата
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth() + 1 < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth() + 1;
      const day = new Date(date).getDate() < 10 ? `0${new Date(date).getDate()}` : new Date(date).getDate();
      const formatDate = `${year}-${month}-${day}`;
      return onChange(null, formatDate);
    }
    setValidateError(true);
    return true;
  }, [onChange, validateDate]);

  const keyPressHandler = useCallback((e) => {
    const valid = (e.charCode >= 48 && e.charCode <= 59);

    const idx = dateRef.current.selectionStart;
    if (valid) {
      if (idx < displayValue.length
          && idx !== 2
          && idx !== 5) {
        // Меняем цифру по индексу
        onChangeDisplayValue(`${displayValue.substring(0, idx)}${e.key}${displayValue.substring(idx + 1)}`);
        if (idx === 1 || idx === 4) {
          setCursorPosition(idx + 2);
          return;
        }
        setCursorPosition(idx + 1);
        return;
      }
      if (displayValue.length === 10) {
        return;
      }
      if (idx === displayValue.length) {
        if (idx === 1 || idx === 4) {
          onChangeDisplayValue(`${displayValue.substring(0, idx)}${e.key}${displayValue.substring(idx + 1)}.`);
          setCursorPosition(idx + 2);
          return;
        }
        onChangeDisplayValue(`${displayValue.substring(0, idx)}${e.key}${displayValue.substring(idx + 1)}`);
        setCursorPosition(idx + 1);
        return;
      }
    }
    e.preventDefault();
    // eslint-disable-next-line
  }, [displayValue]);

  const onCalendarChange = useCallback(
    (date) => {
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth() + 1 < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth() + 1;
      const day = new Date(date).getDate() < 10 ? `0${new Date(date).getDate()}` : new Date(date).getDate();
      const formatDate = `${year}-${month}-${day}`;
      onChange(null, formatDate);
      // setOpen(false);
    },
    [onChange],
  );

  const keyDownHandler = useCallback((e) => { if (e.keyCode === 8) { setDisplayValue(''); onChange(null, null); } }, [onChange]);

  const handleUserKeyPress = useCallback((event) => {
    if (newDate
      && (dateRef.current.contains(event.target) || dropRef.current.contains(event.target))) {
      const { keyCode } = event;
      if (event.shiftKey && keyCode === 33 && view === 'month') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        onCalendarChange(newDate);
      } else if (event.shiftKey && keyCode === 34 && view === 'month') {
        newDate.setFullYear(newDate.getFullYear() - 1);
        onCalendarChange(newDate);
      } else {
        switch (keyCode) {
          case 13: {
            // Enter
            event.preventDefault();
            if (view === 'month') {
              setOpen(false);
            }
            break;
          }
          case undefined: {
            // onClick
            setOpen(false);
            break;
          }
          case 39: {
            // rigth
            if (open) {
              if (view === 'month') {
                newDate.setDate(newDate.getDate() + 1);
                onCalendarChange(newDate);
              } else if (view === 'year') {
                newDate.setMonth(newDate.getMonth() + 1);
                onCalendarChange(newDate);
              } else if (view === 'decade') {
                newDate.setFullYear(newDate.getFullYear() + 1);
                onCalendarChange(newDate);
              } else {
                newDate.setFullYear(newDate.getFullYear() + 10);
                onCalendarChange(newDate);
              }
            }
            break;
          }
          case 37: {
            // left
            if (open) {
              if (view === 'month') {
                newDate.setDate(newDate.getDate() - 1);
                onCalendarChange(newDate);
              } else if (view === 'year') {
                newDate.setMonth(newDate.getMonth() - 1);
                onCalendarChange(newDate);
              } else if (view === 'decade') {
                newDate.setFullYear(newDate.getFullYear() - 1);
                onCalendarChange(newDate);
              } else {
                newDate.setFullYear(newDate.getFullYear() - 10);
                onCalendarChange(newDate);
              }
            }
            break;
          }
          case 40: {
            // down
            if (open) {
              if (view === 'month') {
                newDate.setDate(newDate.getDate() + 7);
                onCalendarChange(newDate);
              } else if (view === 'year') {
                newDate.setMonth(newDate.getMonth() + 3);
                onCalendarChange(newDate);
              } else if (view === 'decade') {
                newDate.setFullYear(newDate.getFullYear() + 3);
                onCalendarChange(newDate);
              } else {
                newDate.setFullYear(newDate.getFullYear() + 30);
                onCalendarChange(newDate);
              }
            }
            break;
          }
          case 38: {
            // up
            if (open) {
              if (view === 'month') {
                newDate.setDate(newDate.getDate() - 7);
                onCalendarChange(newDate);
              } else if (view === 'year') {
                newDate.setMonth(newDate.getMonth() - 3);
                onCalendarChange(newDate);
              } else if (view === 'decade') {
                newDate.setFullYear(newDate.getFullYear() - 3);
                onCalendarChange(newDate);
              } else {
                newDate.setFullYear(newDate.getFullYear() - 30);
                onCalendarChange(newDate);
              }
            }
            break;
          }
          case 33: {
            // Pg Up
            if (open) {
              if (view === 'month') {
                newDate.setMonth(newDate.getMonth() + 1);
                onCalendarChange(newDate);
              } else if (view === 'year') {
                newDate.setFullYear(newDate.getFullYear() + 1);
                onCalendarChange(newDate);
              } else if (view === 'decade') {
                newDate.setFullYear(newDate.getFullYear() + 10);
                onCalendarChange(newDate);
              } else {
                newDate.setFullYear(newDate.getFullYear() + 100);
                onCalendarChange(newDate);
              }
            }
            break;
          }
          case 34: {
            // Pg Dn
            if (open) {
              if (view === 'month') {
                newDate.setMonth(newDate.getMonth() - 1);
                onCalendarChange(newDate);
              } else if (view === 'year') {
                newDate.setFullYear(newDate.getFullYear() - 1);
                onCalendarChange(newDate);
              } else if (view === 'decade') {
                newDate.setFullYear(newDate.getFullYear() - 10);
                onCalendarChange(newDate);
              } else {
                newDate.setFullYear(newDate.getFullYear() - 100);
                onCalendarChange(newDate);
              }
            }
            break;
          }
          case 27: {
            // Esc
            setOpen(false);
            break;
          }
          case 115: {
            // F4 (choice selector)
            setOpen(true);
            break;
          }
          default:
            break;
        }
      }
    }
  }, [dateRef, view, newDate, onCalendarChange, open]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress, open]);

  const groupClassName = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => errored ? 'is-invalid' : '',
    [errored],
  );
  return (
    <InputGroup className={groupClassName}>
      <SFormControl
        id={id}
        ref={dateRef}
        value={displayValue}
        isInvalid={errored || validateError}
        maxLength={10}
        onKeyPress={keyPressHandler}
        onKeyDown={keyDownHandler}
        onChange={() => null}
        readOnly={readOnly}
      />
      <Dropdown
        ref={dropRef}
        as={InputGroup.Append}
        show={open}
        onToggle={(isOpen) => setOpen(isOpen)}
        alignRight
      >
        <DropButton
          id={`drpdn-${id}`}
          variant="outline-secondary"
          disabled={disabled || readOnly}
          onClick={() => setOpen(true)}
          split
          tabIndex={-1}
          style={{ borderRadius: '0 .25rem .25rem 0' }}
        />
        <Dropdown.Menu>
          <StyledCalendar
            locale="uk-UA"
            value={newDate}
            onChange={onCalendarChange}
            /* eslint-disable-next-line no-shadow */
            tileContent={({ view }) => setView(view)}
            onClickDay={() => setOpen(false)}
          />
        </Dropdown.Menu>
      </Dropdown>
      {newDate && <FormControl.Feedback type="invalid">Невірне значення дати</FormControl.Feedback>}
    </InputGroup>
  );
});

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errored: PropTypes.bool,
};

DateInput.defaultProps = {
  value: null,
  disabled: false,
  readOnly: false,
  errored: false,
};

export default DateInput;
