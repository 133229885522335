import React from 'react';
import PropTypes from 'prop-types';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const SettingsButton = ({
  content, onClick, disabled,
}) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    title={content}
    icon={faCogs}
  />
);

SettingsButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SettingsButton.defaultProps = {
  onClick: () => null,
  content: 'Налаштування',
  disabled: false,
};

export default SettingsButton;
