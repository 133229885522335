import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { ContentLabel } from '../../../../../../components/Styled/Misc';
import { ETextInput } from '../../../../../../components/Controls/withErrors';
import { dataPropTypes, errorsPropTypes, fieldsPropTypes } from '../factWorkerCategory/propTypes';

const StyledLabel = Styled.label`
  position: relative;
  color: #605b5b;
  font-weight: 700;
  width: 100%
`;

const ConsequencesPage = (props) => {
  const {
    data, fields, errors, onChange,
  } = props;

  return (
    <StyledLabel>
      {fields.note.label}
      <ETextInput
        value={data.note}
        onChange={(e, v) => onChange({ note: v })}
        readOnly={fields.note.read_only}
        errors={errors.note}
      />
    </StyledLabel>
  );
};

ConsequencesPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: PropTypes.arrayOf(errorsPropTypes),
  onChange: PropTypes.func.isRequired,
};

ConsequencesPage.defaultProps = {
  errors: [],
};

export default ConsequencesPage;
