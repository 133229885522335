const frontendURL = '/doc/finorder/';
const backendURL = '/api/financials/finorderheader/';
// const printURL = '';
const name = 'Розпорядження на фінансування';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
