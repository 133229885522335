const frontendURL = '/cat/executor/';
const backendURL = '/api/references/refexecutor/';
const name = 'Відділи виконавці';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
