import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0%,
  100% {
    box-shadow: 10px 10px rgb(79,77,73), -10px 10px rgb(223,223,223), -10px -10px rgb(79,77,73), 10px -10px rgb(223,223,223);  
  }
  25% {
    box-shadow: -10px 10px rgb(223,223,223), -10px -10px rgb(79,77,73), 10px -10px rgb(223,223,223), 10px 10px rgb(79,77,73);
  }
  50% {
    box-shadow: -10px -10px rgb(79,77,73), 10px -10px rgb(223,223,223), 10px 10px rgb(79,77,73), -10px 10px rgb(223,223,223);
  }
  75% {
    box-shadow: 10px -10px #dfdfdf, 10px 10px #4f4d49, -10px 10px #dfdfdf, -10px -10px #4f4d49;
  }
`;

const smallFadeIn = keyframes`
 0%, 100% { box-shadow: 4px 4px rgb(79,77,73), -4px 4px rgb(223,223,223), -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223);}
 25% { box-shadow: -4px 4px rgb(223,223,223), -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223), 4px 4px rgb(79,77,73);}
 50% { box-shadow: -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223), 4px 4px rgb(79,77,73), -4px 4px rgb(223,223,223);}
 75% { box-shadow: 4px -4px #dfdfdf, 4px 4px #4f4d49, -3px 3px #dfdfdf, -4px -4px #4f4d49;}
`;

const DivLoadStyled = styled.div`
  margin-top: 5px;          
  width: 11px;  
  height: 11px;
  border-radius: 100%;
  box-shadow: 3px 3px rgb(79,77,73), -3px 3px rgb(223,223,223), -3px -3px rgb(79,77,73), 3px -3px rgb(223,223,223);
  animation: ${fadeIn} ease infinite 4.6s;
`;

const SmallDivLoadStyled = styled(DivLoadStyled)`
  width: 5px;
  height: 5px;
  border-radius: 100%;
  animation: ${smallFadeIn} ease infinite 4.6s;
`;

const LoadStyled = styled.div`
  padding: 8px 10px;
  border: none;
  border-left: none;
  background-color: transparent;
  display: flex;
  }
`;

const SmallLoadStyled = styled(LoadStyled)`
  padding: 4px 10px;
`;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DimmableContainer = styled.div`
  background: none;
  position: relative;
`;


const TextContainer = styled.div`
  margin: 0 20px;
  font-size: 18px;
  font-weight: 700;
  color: #5f635c;
  text-shadow: 1px 1px 2px #dce6e6, 0 0 1em white; 
`;

const DimContainer = styled.div`
  ${({ isLoading }) => (isLoading ? 'filter: blur(3px) saturate(25%);' : '')}
`;

export const Loader = ({ text }) => (
  <Container>
    <LoadStyled>
      <DivLoadStyled />
      <TextContainer>{text}</TextContainer>
    </LoadStyled>
  </Container>
);

Loader.propTypes = {
  text: PropTypes.string,
};

Loader.defaultProps = {
  text: 'Завантаження',
};

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  display: flex;
  align-items: center;
  justify-content: center;

`;

// eslint-disable-next-line
export const DimmableLoader = ({ text, children, loading, fullscreen }) => (
  <DimmableContainer className={fullscreen ? 'fullscreenContainer fullscreenParent' : ''}>
    <DimContainer isLoading={loading} className={fullscreen ? 'fullscreenContainer fullscreenParent' : ''}>
      {children}
    </DimContainer>
    {loading && (
      <SpinnerContainer>
        <LoadStyled>
          <DivLoadStyled />
          <TextContainer>{text}</TextContainer>
        </LoadStyled>
      </SpinnerContainer>
    )}
  </DimmableContainer>
);

DimmableLoader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  loading: PropTypes.bool,
  fullscreen: PropTypes.bool,
};

DimmableLoader.defaultProps = {
  text: '',
  children: [],
  loading: false,
  fullscreen: true,
};

export const Spiner = () => (
  <SmallLoadStyled>
    <SmallDivLoadStyled />
  </SmallLoadStyled>
);
