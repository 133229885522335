import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from 'react-bootstrap';
import { ErroredTooltip } from '../../../components/bootStrap';
import { getDisplayName } from '../../../api/utils';

const withTPControl = (WrappedControl) => {
  const TPInput = ({
    controlId, errors, ...restProps
  }) => {
    const controlRef = useRef(null);
    return (
      <>
        <WrappedControl
          {...restProps}
          id={controlId}
          ref={controlRef}
          errored={errors && !!errors.length}
        />
        <Overlay target={controlRef.current} show={!!errors} placement="auto">
          {({ show, ...props }) => (
            <ErroredTooltip id={`error-${controlId}`} {...props}>
              {String(errors)}
            </ErroredTooltip>
          )}
        </Overlay>
      </>
    );
  };

  TPInput.displayName = `${getDisplayName(WrappedControl)} wrapped by withEditorControl`;

  TPInput.propTypes = {
    controlId: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  TPInput.defaultProps = {
    errors: null,
  };
  return TPInput;
};

export default withTPControl;
