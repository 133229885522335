import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, { FieldPropType, CatCommandPanel, getErrorsPropTypes } from '../../../basicEditor';
import { HeaderText } from '../../../../components/Styled/Forms';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';

const Editor = ({
  data, options, isNew, isReadOnly, isModified, onSave, onClose, fields, onChange, errors,
}) => {
  const codeProps = editorHooks.useStringInputProps('code', data, fields, errors, onChange);
  const nameProps = editorHooks.useStringInputProps('name', data, fields, errors, onChange);
  const documentTypeProps = editorHooks.useSelectorInputProps('document_type', data, fields, errors, onChange);

  return (
    <div>
      <HeaderText>{isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}</HeaderText>
      <CatCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        canDeleted={false}
        onSave={() => onSave()}
        onClose={onClose}
      />
      <LabelTab defaultActiveKey="bringdocument" id="uncontrolled-tab-example">
        <Tab eventKey="bringdocument" title="Основні властивості">
          <TabContainer>
            <Container fluid style={{ padding: '0' }}>
              <Row>
                <Col>
                  <EditorControls.StringInput {...codeProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.SelectorInput {...documentTypeProps} />
                </Col>
                <Col />
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>

    </div>
  );
};

const dataPropTypes = {
  id: PropTypes.number,
  repr: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  document_type: PropTypes.number,

  settings: PropTypes.shape({}),
  history: PropTypes.shape({}),
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  fields: PropTypes.shape(
    fieldsPropTypes,
  ).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.cat.bringdocument.backendURL)(Editor);
