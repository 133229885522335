import SKDMaster from './SKDMaster';
import ExchangePlan from './ExchangePlan';
import ExchangeIncome from './ExchangeIncome';
import ExchangeExpenses from './ExchangeExpenses';
import Login from './Login';
import RoleManagement from './RoleManagement';
import GroupManagement from './GroupManagement';
import ImportTreasureFiles from './ImportTreasureFiles';
import TreasureExport from './TheasureExport';
import ExchangeSocialPayment from './ExchangeSocialPayments';
import User from '../cat/user';

const srv = {
  SKDMaster,
  ExchangePlan,
  ExchangeIncome,
  ExchangeExpenses,
  Login,
  RoleManagement,
  GroupManagement,
  ImportTreasureFiles,
  TreasureExport,
  ExchangeSocialPayment,
  User,
};
//   logica: {
//     name: 'Робота з Логіка',
//     items: {
//       Login,
//       ImportTreasureFiles,
//     },
//   },
//   dbf: {
//     name: 'Завантаження файлів *.dbf',
//     items: {
//       ExchangePlan,
//       ExchangeIncome,
//       ExchangeExpenses,
//       ExchangeSocialPayment,
//       TreasureExport,
//     },
//   },
//   auth: {
//     name: 'Доступ до системи',
//     items: {
//       User,
//       RoleManagement,
//       GroupManagement,
//     },
//   },
//   rep: {
//     name: 'Звітність',
//     items: {
//       SKDMaster,
//     },
//   },
// };

export default srv;
