import meta, { metaBackends } from '../../../meta';
import catListersSelectors from '../individual/cat';
import ListerFactory from '../../newLister';
import { CatTableListerFactory, DocTableListerFactory } from '../../newLister/tableComponent';
import { CatListerCommandPanel } from '../../newLister/tableComponent/commandpanel';

/*

В случае добавления особенного селектора
необходимо его вклбючить сюда

 */
const catSelectors = Object.keys(catListersSelectors)
  .filter((c) => !!catListersSelectors[c].selector)
  .reduce((R, c) => ({ ...R, [c]: catListersSelectors[c].selector }), {});

const stdSelectors = {
  cat: Object.keys(meta.cat)
    .filter((mName) => !Object.keys(catSelectors).includes(mName))
    .reduce((RN, mName) => ({
      ...RN,
      [mName]: ListerFactory({
        backendURL: meta.cat[mName].backendURL,
        viewType: 'selector',
      })(CatTableListerFactory({
        CommandPanel: CatListerCommandPanel,
      })),
    }), catSelectors),
  doc: Object.keys(meta.doc).reduce((RN, mName) => ({
    ...RN,
    [mName]: ListerFactory({
      backendURL: meta.doc[mName].backendURL,
      viewType: 'selector',
    })(DocTableListerFactory({
      CommandPanel: CatListerCommandPanel,
    })),
  }), {}),
};

// const stdSelectors = Object.keys(meta).reduce((RT, mType) => ({
//   ...RT,
//   [mType]: Object.keys(meta[mType])
//     .filter((mName) => !Object.keys(catSelectors).includes(mName))
//     .reduce((RN, mName) => {
//       const { backendURL, useHierarchyPagination } = meta[mType][mName];
//       const params = {
//         backendURL,
//         CommandPanel: CommandPanels.selectCommandPanel,
//         actionHandler: null,
//         useHierarchyPagination,
//       };
//       return {
//         ...RN,
//         [mName]: GetLister(params)(StandartTableComponent),
//       };
//     }, catSelectors),
// }), {});

export const SelectorsByBackend = Object.keys(metaBackends).reduce((R, r) => ({
  ...R,
  [r]: stdSelectors[metaBackends[r].modelType][metaBackends[r].modelName],
}), {});

export default stdSelectors;
