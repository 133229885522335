import React, {
  useMemo, memo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { CommandPanel } from '../../../../components/bootStrap';
import { tablePropType } from '../hooks';
import SettingItem from './item';
import SettingProps from './props';
import { RemoveRowButton, AddRowButton } from '../../../../components/bootStrap/buttons';

const SettingsPage = ({
  settings, activeSetting, onRenameSetting, onChangeSetting,
  onAddSetting, onRemoveSetting, onSetActiveSetting,
}) => {
  const displaySettings = useMemo(
    () => settings.map((t, key) => ({
      ...t,
      key,
    })), [settings],
  );

  const entries = useMemo(() => Object.entries(settings.filter((t) => t.name === activeSetting)
    .reduce((R, t) => t.text, [])), [activeSetting, settings]);

  return (
    <Container fluid>
      <Row>
        <Col lg={3} md={6} sm={12} className="p-3 border rounded">
          <CommandPanel>
            <AddRowButton
              title="Додати налаштування"
              content=""
              onClick={() => onAddSetting(Math.random()
                .toString(36))}
            />
            <RemoveRowButton
              title="Видалити налаштування"
              content=""
              onClick={() => onRemoveSetting(activeSetting)}
              disabled={activeSetting === 'main' || !activeSetting}
            />
          </CommandPanel>
          <div>
            {displaySettings.map((setting) => (
              <SettingItem
                key={setting.key}
                active={setting.name === activeSetting}
                onClick={() => onSetActiveSetting(setting.name)}
                setting={setting}
                onRenameSetting={onRenameSetting}
              />
            ))}
          </div>
        </Col>
        <Col className="containerCol">
          <SettingProps
            onChange={(v, k) => onChangeSetting(activeSetting, v, k)}
            entries={entries}
          />
        </Col>
      </Row>
    </Container>
  );
};

SettingsPage.propTypes = {
  settings: PropTypes.arrayOf(tablePropType).isRequired,
  activeSetting: PropTypes.string,
  onRenameSetting: PropTypes.func.isRequired,
  onChangeSetting: PropTypes.func.isRequired,
  onAddSetting: PropTypes.func.isRequired,
  onRemoveSetting: PropTypes.func.isRequired,
  onSetActiveSetting: PropTypes.func.isRequired,
};

SettingsPage.defaultProps = {
  activeSetting: null,
};

export default memo(SettingsPage);
