const frontendURL = '/cat/remarknote/';
const backendURL = '/api/references/refremarknote/';
const name = 'Примітка';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
