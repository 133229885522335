import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, ButtonToolbar, Navbar } from 'react-bootstrap';
import {
  faPlusSquare, faCaretSquareUp, faCaretSquareDown,
  faClone, faMinusSquare, faSearchDollar, faThumbtack,
} from '@fortawesome/free-solid-svg-icons';
import { AddRowButton, RemoveRowButton } from '../../../components/bootStrap/buttons';
import { CPButton } from '../../../components/bootStrap/buttons/styles';

const TPCommandPanel = ({
  children, tableActions, readOnly, canAdd, canDelete, canMove, activeRow, pinHeader, showFooter,
}) => (
  <Navbar className="bg-white border rounded-top border-top-0">
    <ButtonToolbar className="w-100">
      <ButtonGroup className="mr-1">
        <AddRowButton
          onClick={() => tableActions.onAddRow()}
          disabled={readOnly || !canAdd}
          label="Додати (Insert)"
          icon={faPlusSquare}
        />
      </ButtonGroup>
      <ButtonGroup className="mr-1">
        <CPButton
          onClick={() => tableActions.onMoveUpRow(activeRow)}
          disabled={readOnly || !canMove || activeRow === null}
          label="Перемістити вище"
          icon={faCaretSquareUp}
        />
        <CPButton
          onClick={() => tableActions.onMoveDownRow(activeRow)}
          disabled={readOnly || !canMove || activeRow === null}
          label="Перемістити нижче"
          icon={faCaretSquareDown}
        />
      </ButtonGroup>
      <ButtonGroup className="mr-1">
        <CPButton
          onClick={() => tableActions.onCopyRow(activeRow)}
          disabled={readOnly || !canAdd || activeRow === null}
          label="Копіювати (F9)"
          icon={faClone}
        />
        <RemoveRowButton
          onClick={() => tableActions.onDeleteRow(activeRow)}
          disabled={readOnly || !canDelete || activeRow === null}
          label="Видалити (F8)"
          variant="danger"
          icon={faMinusSquare}
        />
      </ButtonGroup>
      {children}
      <ButtonGroup className="ml-auto">
        <CPButton
          onClick={() => tableActions.onToggleFooter()}
          label="Показати підвал"
          variant={showFooter ? 'info' : 'outline-info'}
          icon={faSearchDollar}
        />
        <CPButton
          onClick={() => tableActions.onTogglePin()}
          label="Закріпити заговолок та підвал"
          variant={pinHeader ? 'info' : 'outline-info'}
          icon={faThumbtack}
        />
      </ButtonGroup>
    </ButtonToolbar>
  </Navbar>
);

TPCommandPanel.propTypes = {
  tableActions: PropTypes.shape({
    onAddRow: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired,
    onMoveUpRow: PropTypes.func.isRequired,
    onMoveDownRow: PropTypes.func.isRequired,
    onCopyRow: PropTypes.func.isRequired,
    onToggleFooter: PropTypes.func.isRequired,
    onTogglePin: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.symbol, PropTypes.arrayOf(PropTypes.symbol),
  ]),
  canAdd: PropTypes.bool,
  canDelete: PropTypes.bool,
  canMove: PropTypes.bool,
  activeRow: PropTypes.number,
  showFooter: PropTypes.bool.isRequired,
  pinHeader: PropTypes.bool.isRequired,
};

TPCommandPanel.defaultProps = {
  readOnly: false,
  children: null,
  canAdd: true,
  canDelete: true,
  canMove: true,
  activeRow: null,
};

export default TPCommandPanel;
