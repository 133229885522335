import styled from 'styled-components';
import {
  ItemPicker, ItemField, TextInput, Selector, NumberInput, DateInput, StringInput,
} from '../../../components/Controls';
import withTPControl from './tpControl';

const TPStyle = `
  &>:not([readOnly]) {
    border: none !important;
    background: none !important;
    color: inherit !important;
  }
`;

export const TPItemPcicker = styled(ItemPicker)`
  ${TPStyle}
`;
export const TPItemField = styled(ItemField)`
  ${TPStyle}
`;
export const TPTextInput = styled(TextInput)`
  ${TPStyle}
`;
export const TPSelector = styled(Selector)`
  ${TPStyle}
`;
export const TPNumberInput = styled(NumberInput)`
  ${TPStyle}
`;
export const TPDateInput = styled(DateInput)`
  ${TPStyle}
`;
export const TPStringInput = styled(StringInput)`
  ${TPStyle}
`;
export const TPControls = {
  ItemPicker: withTPControl(TPItemPcicker),
  ItemField: withTPControl(TPItemField),
  TextInput: withTPControl(TPTextInput),
  Selector: withTPControl(TPSelector),
  NumberInput: withTPControl(TPNumberInput),
  DateInput: withTPControl(TPDateInput),
  StringInput: withTPControl(TPStringInput),
};
