const frontendURL = '/cat/conrtragent/';
const backendURL = '/api/references/refconrtragent/';
const name = 'Постачальники';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
