import {
  useCallback, useContext, useMemo, useRef, useState,
} from 'react';
import api from '../../../../api/req';
import { useMD } from '../md';
// import {
//   comparisonTypes, emptyUid, hierarchyTypes, saveModes,
// } from '../../../../constants/meta/common';
// import useSettings from './settings';
import useMessages from './messages';
import { AppContext } from '../../../../providers/authProvider';
import useSettings from './settings';
/**
 *
 * @param backendURL {string}
 * @param viewType {string}
 * @param filter {{}}
 * @param order {{
 *     column: string,
 *     isAscending: boolean,
 * }}
 * @param searchString {string}
 * @param noHierarchy {boolean}
 * @returns {{
 * meta: *,
 * visibleColumns: {
 *   name: string,
 *   label: string,
 *   key: string,
 *   type: string,
 * },
 * items: [{ id: string, deleted: boolean, executed: boolean }],
 * options: {
 *    name: string,
 *    description: string,
 *    actions: {
 *        POST: {},
 *        GET: {},
 *    },
 *    hidden_fields: string[],
 *    filtering_fields: {name: string}[],
 *    ordering_fields: {}
 * }
 * loading: boolean,
 * err: string,
 * pageInfo: {
 *  current: {
 *   currentPage: number,
 *   allLoaded: boolean,
 *   parents: string[],
 * }},
 * settings: {},
 * onReload: function,
 * onLoadOptions: function,
 * optionsLoaded: boolean,
 * onNextPage: function,
 * onExecute: function,
 * onUnExecute: function,
 * permissions: {
 *   canNew: boolean,
 *   canEdit: boolean,
 *   canCopy: boolean,
 *   canDelete: boolean,
 *   canHideDeleted: boolean,
 *   canExecute: boolean,
 *   canUnexecute: boolean,
 *   canNextPage: boolean,
 *   canHierarchy: boolean,
 *   foldersUsed: boolean,
 *   canNewFolder: boolean,
 *   canNextPage: boolean,
 *   canFilterByPeriod: boolean,
 * },
 * onDeleteItems: function,
 * onLoadSettings: function,
 * onSaveSettings: function,
 * loadPath: function,
 * messages: { title: string, text: string, variant: string }[],
 * clearMessages: function,
 * deleteMessage: function,
 * filteringFields: {
 *   type: string,
 *   label: string,
 *   name: string,
 * }[]
 * }}
 */
// type: "string", required: true, read_only: false, label: "Назва", name: "name"

const useListerBackend = ({
  backendURL, viewType,
  filter, order, searchString, noHierarchy,
}) => {
  const meta = useMD(backendURL);

  const { auth } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState(null);
  const optionsLoaded = !!options;
  const columns = useMemo(
    () => {
      if (!options) return [];
      const fields = options.actions.GET || {};
      const oFields = Object.keys(options.ordering_fields || {});
      return Object.keys(fields).reduce((R, f) => (
        {
          ...R,
          [f]: {
            ...fields[f],
            visible: !(options.hidden_fields || []).includes(f),
            orderable: oFields.includes(f),
          },
        }),
      {});
    },
    [options],
  );

  const filteringFields = useMemo(
    () => (options ? options.filtering_fields
      .filter((f) => f.name in options.actions.GET)
      .map((f) => ({
        ...options.actions.GET[f.name],
        name: f.name,
      })) : []),
    [options],
  );

  const visibleColumns = useMemo(
    () => Object.keys(columns)
      .filter((c) => columns[c].visible)
      .reduce((R, k) => [...R, {
        key: k,
        ...columns[k],
      }], []),
    [columns],
  );

  const isHierarhical = !!columns.parent; // Это иерархичекий справочник

  const {
    messages, clearMessages, addMessage, deleteMessage,
  } = useMessages();
    // Текущая страница загрузки данных
  const pageInfo = useRef({
    currentPage: 1,
    allLoaded: false,
    parents: [null],
  });

  const {
    onLoadSettings,
    onSaveSettings,
    settings,
    onSetSettings,

  } = useSettings(backendURL, viewType, setLoading, setErr);

  const loadRequestController = useRef(null);

  // TODO:
  const loadPath = useCallback(
    (id, repr, onSucces) => {
      const asyncLoader = async () => {
        const response = await api.get$(`${backendURL}/${id}/getPath`, auth);
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Помилка при завантаженні шляху до  ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      asyncLoader()
        .then((data) => onSucces(data))
        .catch((e) => setErr(e.message));
    },
    [auth, backendURL],
  );

  const loadOptions = useCallback(
    async (url) => {
      const r = await api.options$(url, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth],
  );

  const orderingFields = useMemo(
    () => (options ? options.ordering_fields : {}),
    [options],
  );
  const load = useCallback(
    /**
       *
       * @param p {{
       *     page: number,
       *     filter: {},
       *     clear: boolean,
       *     order: {
       *         column: string,
       *         isAsceding: boolean,
       *     },
       *     search: string,
       * }}
       */
    (p) => {
      const asyncLoader = async () => {
        loadRequestController.current = new AbortController();
        const response = await api.get$(backendURL, auth, {
          ...p.filter,
          ...(p.page === 1 ? {} : { page: p.page }),
          ...(p.order && p.order.column ? { ordering: `${p.order.isAscending ? '' : '-'}${orderingFields[p.order.column]}` } : {}),
          ...(p.search ? { search: p.search } : {}),
        }, false, loadRequestController.current);
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Помилка при завантаженні списку ${meta.label} (${response.status} ${response.statusText})`);
      };
      if (loadRequestController.current) loadRequestController.current.abort();
      setLoading(true);
      setErr(null);
      asyncLoader()
        .then((data) => setItems((oldData) => {
          setLoading(false);
          const usePagination = 'results' in data;

          pageInfo.current.currentPage = p.page;
          pageInfo.current.allLoaded = !usePagination || !data.next;

          if (p.clear) return usePagination ? data.results : data;

          const existedKeys = new Set(oldData.map((r) => r.id));
          return [
            ...oldData,
            ...(usePagination ? data.results : data).filter((r) => !existedKeys.has(r.id)),
          ];
        }))
        .catch((e) => {
          if (e instanceof DOMException) {
            console.debug('aborted');
          } else {
            setErr(e.message);
            setLoading(false);
          }
        });
    },
    [auth, backendURL, meta.label, orderingFields],
  );

  const onReload = useCallback(
    () => {
      if (meta.useHierarchyPagination && !searchString && !noHierarchy) {
        const parents = pageInfo.current.parents.filter((p) => !!p);
        load({
          filter: {
            ...filter,
            parent_null: true, // Загружаем корень обязательно
            ...(parents.length ? { parent__in: parents } : {}),
          },
          page: 1,
          clear: true,
          order,
          search: searchString,
        });
      } else {
        load({
          filter,
          page: 1,
          clear: true,
          order,
          search: searchString,
        });
      }
    },
    [meta.useHierarchyPagination, searchString, noHierarchy, load, filter, order],
  );
  const onLoadOptions = useCallback(
    () => loadOptions(backendURL)
      .then((d) => setOptions(d))
      .catch((e) => setErr(e.message)),
    [backendURL, loadOptions],
  );

  const onNextPage = useCallback(
    () => {
      if (!pageInfo.current.allLoaded) {
        load({
          filter,
          order,
          page: pageInfo.current.currentPage + 1,
          clear: false,
          search: searchString,
        });
      }
    },
    [filter, load, order, searchString],
  );

  const onLoadChildren = useCallback(
    (parentId) => {
      pageInfo.current.parents.push(parentId);
      load({
        filter: {
          ...filter,
          parent: parentId,
        },
        order,
        page: pageInfo.current.currentPage,
        clear: false,
        search: searchString,
      });
    },
    [filter, load, order, searchString],
  );

  const onUnloadChildren = useCallback(
    (parentId) => {
      pageInfo.current.parents = pageInfo.current.parents.filter((p) => p !== parentId);
      setItems((oi) => oi.filter(
        (item) => item.parent === null || pageInfo.current.parents.includes(typeof item.parent === 'object' ? item.parent.id : item.parent),
      ));
    },
    [],
  );

  const onDeleteItems = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const del = async (id, repr) => {
        const response = await api.patch$(`${backendURL}${id}/delete/`, auth);
        if (response.ok) {
          return null;
        }
        if (response.status === 422) {
          const d = await response.json();
          // eslint-disable-next-line no-underscore-dangle
          throw new Error(`Помилка встановлення / знаття позначки на видалення ${repr}: ${d._Error}`);
        }
        if (response.status === 403) {
          const d = await response.json();
          throw new Error(`Помилка встановлення / знаття позначки на видалення ${repr}: ${d.detail}`);
        }
        throw new Error(`Помилка встановлення / знаття позначки на видалення ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      Promise.allSettled(ids.map((item) => del(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            onReload();
          }
        });
    },
    [auth, backendURL, onReload],
  );

  const onExecute = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const exec = async (id, repr) => {
        const response = await api.patch(`${backendURL}${id}/execute/`, auth);
        if (response.ok) {
          return null;
        }
        if (response.status === 422) {
          const errData = await response.json();
          throw new Error(String(errData.error_data.map((er) => String(er.messages))));
        }
        throw new Error(`Помилка при проведенні ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      Promise.allSettled(ids.map((item) => exec(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            onReload();
          }
        });
    },
    [auth, backendURL, onReload],
  );

  // TODO:
  const onUnexecute = useCallback(
    /**
     *
     * @param ids {string[]}
     * @param reprs {{}} - id: repr
     */
    (ids, reprs = {}) => {
      const unexec = async (id, repr) => {
        const response = await api.patch(`${backendURL}${id}/unexecute/`, auth);
        if (response.ok) {
          return null;
        }
        if (response.status === 422) {
          const errData = await response.json();
          throw new Error(String(errData.error_data.map((er) => String(er.messages))));
        }
        throw new Error(`Помилка при разпроведенні ${repr} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      Promise.allSettled(ids.map((item) => unexec(item, reprs[item])))
        .then((results) => {
          const failedResults = results.filter((r) => r.status === 'rejected');
          if (failedResults.length) {
            setErr(failedResults.map((e) => e.reason.message).join('; '));
            setLoading(false);
          } else {
            onReload();
          }
        });
    },
    [auth, backendURL, onReload],
  );

  const actionKeys = useMemo(
    () => (options ? new Set(options.extra_actions.map((ea) => ea.name.toLowerCase())) : new Set()),
    [options],
  );

  // Разрешения:
  /*
Заполняются исключительно возможносятми.
Здесь нет анализа на выбрано что-то или нет
 */
  const permissions = useMemo(
    () => ({
      canNew: options && options.actions.POST,
      canEdit: true,
      canCopy: false,
      canDelete: actionKeys.has('delete'),
      canHideDeleted: filteringFields.map((f) => f.name).includes('deleted'),
      canExecute: options && actionKeys.has('execute'),
      canUnexecute: options && actionKeys.has('unexecute'),
      canHierarchy: !noHierarchy && isHierarhical,
      canCompare: options && actionKeys.has('compare'),
      foldersUsed: false,
      canNewFolder: false,
      canNextPage: !pageInfo.current.allLoaded,
      canFilterByPeriod: filteringFields.map((f) => f.name).includes('doc_date'),
    }),
    [actionKeys, filteringFields, isHierarhical, noHierarchy, options],
  );

  return {
    meta,
    visibleColumns,
    items,
    options,
    loading,
    err,
    pageInfo,
    onReload,
    onLoadOptions,
    optionsLoaded,
    onNextPage,
    onDeleteItems,
    onExecute,
    onUnexecute,
    permissions,
    onLoadSettings,
    onSaveSettings,
    settings,
    onSetSettings,
    onLoadChildren,
    onUnloadChildren,
    loadPath,
    messages,
    clearMessages,
    deleteMessage,
    addMessage,
    filteringFields,
  };
};
export default useListerBackend;
