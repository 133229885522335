import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { dopFilters } from './consts';

const DopFilters = ({ checkedDopFilters, handleSetDopFilters }) => (
  <>
    {dopFilters && dopFilters.map((filter) => (
      <div key={filter.id} className="mb-3" onClick={() => handleSetDopFilters(filter)}>
        <Form.Check type="switch">
          <Form.Check.Input readOnly type="checkbox" checked={checkedDopFilters.includes(filter)} />
          <Form.Check.Label>{filter.name}</Form.Check.Label>
        </Form.Check>
      </div>
    ))}
  </>
);

DopFilters.propTypes = {
  handleSetDopFilters: PropTypes.func.isRequired,
  checkedDopFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
};

export default DopFilters;
