import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
// import { DefaultButton } from './defaultButton';

const StyledExitButton = styled(Button)`
  // position: absolute;
  // margin-bottom: 15px;
  // padding: ${(props) => (props.isVisible ? '8px 15px' : '8px 1px')};
  // border: 1px solid rgb(177,216,255);
  // background: linear-gradient(0deg,rgba(204,230,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  // color: #4272d7;
  // font-weight: 700;
  // width: 200px;
  //   &:hover{
  //     background: #AAC6E6;
  //   };
`;

export const ExitButton = ({ content, onClick, disabled }) => (
  <StyledExitButton variant="outline-light" onClick={onClick} disabled={disabled} title={content}>
    Вийти
  </StyledExitButton>
);

ExitButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ExitButton.defaultProps = {
  onClick: () => null,
  content: 'Закрити',
  disabled: false,
};

export default ExitButton;
