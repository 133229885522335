import styled from 'styled-components';
import { Button, Modal, ListGroup } from 'react-bootstrap';

export const ModalButton = styled(Button)`
    background-color: var(--backgroundBrightButton) !important;
    color: var(--colorTextBrightButton) !important;
    :hover {
        background-color: var(--hoverBrightButton) !important;
    };
`;

export const SModal = styled(Modal)`
    padding-right: 0 !important;
    >div {
    margin: 0 !important;
    
    @media (min-width: 320px) and (max-width: 100vw) {
        max-width: 100vw !important;   
    }
    
        >div {
        width: 100vw !important;
        height: 100vh !important;
        border: none !important;
        border-radius: 0 !important;
        
         @media (min-width: 320px) and (max-width: 100vw) {
            max-height: 100vh !important; 
    }
    
        }
    }
`;
export const HeaderText = styled(Modal.Title)`
  color: var(--headerTextH);
  font-weight: 700 !important;
`;

export const FooterText = styled.span`
    color: var(--headerTextH);
    font-weight: 700;
`;

export const FooterLabel = styled.span`
    background-color: var(--labelBackground);
    color: var(--labelColor);
    border-radius: 4px;
    padding: 2px 3px;
`;

export const HeaderTable = styled.th`
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: var(--backgroundTableHeader);
    color: var(--colorTextTableHeader);
`;

export const ContainerTable = styled.div`
    max-height: 63vh;
    overflow: auto;
`;

export const StyleDiv = styled.div`
    line-height: 1.25;
    overflow: hidden;
    padding: 5px;
    word-break: break-all;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;
export const StyleTD = styled.td`
    padding: 0px !important;
    background: white;
`;
export const ContainerDiv = styled.div`
    padding: 5px;
    border: 1px solid #ced4da;
    background: #f7f7f7;
`;
export const HeaderItem = styled(ListGroup.Item)`
    position: sticky;
    z-index: 1;
    top: 0;
    background: red;
    background-color: var(--backgroundTableHeader)!important;
    color: var(--colorTextTableHeader)!important;
    font-weight: 700;
`;
