import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import { StyledRow, SColLeft, SColRight } from '../styles';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';
import SelectionList from './selectionList';
import { availableSelectionsPropType, getPreviousSelection, selectionsPropType } from '../../hooks';

const SelectionEditor = ({ availableSelections, selections, selectionHandlers }) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeSelection, setActiveSelection] = useState(null);

  const onActiveSelectionHandler = useCallback(
    (sname) => setActiveSelection(sname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const selectionDragEndHanlder = useCallback(
    ({ name, type }, sname) => {
      if (type === itemTypes.availableField) {
        selectionHandlers.addSelectionHandler(name, sname);
      } else if (type === itemTypes.selection) {
        selectionHandlers.swapSelectionsHandler(name, sname);
      }
    },
    [selectionHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.selection) {
        selectionHandlers.removeSelectionHandler(name);
        if (activeSelection === name) setActiveSelection(null);
      }
    },
    [activeSelection, selectionHandlers],
  );


  return (
    <StyledRow>
      <SColLeft _lg={6}>
        <AvailableFieldList
          fields={availableSelections}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={selectionHandlers.addSelectionHandler}
          label="Доступні поля"
          onDragEnd={avaDragEndHanlder}
        />
      </SColLeft>
      <Col lg={0.1}>
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => selectionHandlers.clearAllSelections()}
            disabled={!selections.length}
            title="Перемістити всі вліво"
          />
          <LeftButton
            onClick={() => {
              selectionHandlers.removeSelectionHandler(activeSelection);
              setActiveSelection(getPreviousSelection(selections, activeSelection));
            }}
            disabled={activeSelection === null}
            title="Перемістити вліво"
          />
          <RightButton
            onClick={() => selectionHandlers.addSelectionHandler(activeAvaField)}
            disabled={!activeAvaField}
            title="Перемістити вправо"
          />
        </ButtonGroup>
      </Col>
      <SColRight _lg={6}>
        <SelectionList
          selections={selections}
          activeSelection={activeSelection}
          onClick={onActiveSelectionHandler}
          onDblClick={selectionHandlers.removeSelectionHandler}
          onDragEnd={selectionDragEndHanlder}
        />
      </SColRight>
    </StyledRow>
  );
};

SelectionEditor.propTypes = {
  selections: selectionsPropType.isRequired,
  availableSelections: availableSelectionsPropType.isRequired,
  selectionHandlers: PropTypes.shape({
    clearAllSelections: PropTypes.func,
    removeSelectionHandler: PropTypes.func,
    addSelectionHandler: PropTypes.func,
    swapSelectionsHandler: PropTypes.func,
    changeFunctionHandler: PropTypes.func,
  }).isRequired,
};

export default SelectionEditor;
