import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import { DimableLoader } from '../../../components/bootStrap';

const StyledTable = styled(Table)`
  margin-bottom: 0 !important;
  position: relative;
  & tr{
    transition: all 0.1s linear 0s;
  }
`;

const StyledTH = styled.th`
  vertical-align: middle !important;
  position: sticky;
  top: 60px;
  z-index: 11;
  background: var(--backgroundTableHeader);
  line-height: 1.2;
  // background: #E0E4CC;
  color: var(--colorTextTableHeader);
  border: 1px solid var(--tableBorder)!important;
  cursor: ${({ orderable }) => (orderable ? 'pointer' : 'unset')};
`;

const CatalogsInLogica = ({
  list, onCheck, isLoading,
}) => (
  <DimableLoader loading={isLoading}>
    <StyledTable bordered hover>

      <thead>
        <tr>
          <StyledTH>Код</StyledTH>
          <StyledTH>Зміни</StyledTH>
          <StyledTH>Найменування Логіка</StyledTH>
          <StyledTH>Найменування Мінфін</StyledTH>
        </tr>
      </thead>
      <tbody>
        {list.map((el) => (
          <tr key={el.code} onClick={() => onCheck(el.code)} style={{ backgroundColor: el.check && '#4b91d4' }}>
            <td>{el.code}</td>
            <td>{el.description.reason}</td>
            <td>{el.logika_name}</td>
            <td>{el.minfin_name}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  </DimableLoader>
);

CatalogsInLogica.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    check: PropTypes.bool.isRequired,
    code: PropTypes.string.isRequired,
    is_different: PropTypes.bool.isRequired,
    logika_name: PropTypes.string.isRequired,
    minfin_name: PropTypes.string.isRequired,
    description: PropTypes.shape({
      reason: PropTypes.string.isRequired,
      compare_result: PropTypes.string.isRequired,
      deleted: PropTypes.array.isRequired,
      added: PropTypes.array.isRequired,
    }),
  })).isRequired,
  onCheck: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CatalogsInLogica;
