import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Popover, Badge, Modal, Overlay,
} from 'react-bootstrap';
import {
  NewButton, SaveButton, DeleteButton,
} from '../../components/bootStrap/buttons';
import {
  ContentLabel,
} from '../../components/Styled/Misc';
import { DateInput, Selector } from '../../components/Controls';
import { CommandPanel } from '../../components/bootStrap';

const HistoryCommandPanel = ({
  currentStartDate, histories, historyLoading, onLoadHistories, onSelectHistory, onSaveHistory,
  onCreateHistory, onDeleteHistory,
  isNew,
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [date, setDate] = useState(new Date().toISOString());
  const deleteBtnRef = useRef();
  return (
    <div>
      <CommandPanel>
        <Badge variant="primary" className="d-flex align-items-center">Параметри діють в періоді</Badge>
        <Selector
          id="history_selector"
          value={currentStartDate}
          values={histories}
          isLoading={historyLoading}
          onDropDown={onLoadHistories}
          onChange={(e, v) => onSelectHistory(v)}
          disabled={isNew}
        />
        <NewButton
          content="Створити нову історію"
          onClick={() => setModalOpened(true)}
          disabled={isNew}
        />
        <SaveButton
          content="Зберегти зміни"
          onClick={() => onSaveHistory()}
          disabled={isNew}
        />
        <DeleteButton
          content="Видалити "
          disabled={isNew}
          ref={deleteBtnRef}
          onClick={() => setShowDeleteWarning(true)}
        />
        <Overlay
          show={showDeleteWarning}
          target={deleteBtnRef.current}
        >
          <Popover id="delete-history" placement="bottom">
            <Popover.Title style={{ color: 'var(--red)' }}>Ця операція незворотна</Popover.Title>
            <Popover.Content>
              <Button
                variant="danger"
                onClick={() => {
                  setShowDeleteWarning(false);
                  onDeleteHistory();
                }}
              >
                Видалити
              </Button>
            </Popover.Content>
          </Popover>
        </Overlay>
        <Modal
          show={modalOpened}
          onHide={() => setModalOpened(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Створення нової історії</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContentLabel text="Дата початку нової історії" required>
              <DateInput
                id="historyDate"
                value={date}
                onChange={(e, v) => setDate(v)}
              />
            </ContentLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                onCreateHistory(date);
                setModalOpened(false);
              }}
            >
              Створити
            </Button>
          </Modal.Footer>
        </Modal>
      </CommandPanel>
      {/* <Line /> */}
    </div>
  );
};

HistoryCommandPanel.propTypes = {
  currentStartDate: PropTypes.string,
  histories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    display_name: PropTypes.string,
  })),
  historyLoading: PropTypes.bool,
  onLoadHistories: PropTypes.func.isRequired,
  onSaveHistory: PropTypes.func.isRequired,
  onCreateHistory: PropTypes.func.isRequired,
  onDeleteHistory: PropTypes.func.isRequired,
  onSelectHistory: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
};

HistoryCommandPanel.defaultProps = {
  historyLoading: false,
  currentStartDate: null,
  histories: [],
};

export default HistoryCommandPanel;
