import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Button, Form, InputGroup, FormControl, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { DimableLoader } from '../../../components/bootStrap';

const H2 = styled.h2`
  ${({ color }) => `color: ${color}`}`;

const CreateGroup = ({ onClose, setUpdateList }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [groupParams, setGroupParams] = useState({ name: '', description: '', rolepriorityforgroup_set: [] });
  const [roles, setRoles] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/auth/role/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const newData = rData.results.map((el) => ({
          ...el,
          check: false,
          priority: null,
        }));
        setRoles(newData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const updateGroupParams = (e) => {
    const { name, value } = e.target;
    setGroupParams({
      ...groupParams,
      [name]: value,
    });
  };

  const createGroup = () => {
    setErr(null);
    const checkedRoles = roles.filter((el) => el.check);
    const checkedRolesWithPriority = checkedRoles.filter((el) => el.priority);

    if (checkedRolesWithPriority.length < checkedRoles.length) {
      setErr('Не для всіх ролей вказано пріоритет!');
      return;
    }

    const rolepriorityforgroupset = checkedRolesWithPriority
      .map((r) => ({ role: { id: r.id }, priority: r.priority, group: null }));

    const params = {
      ...groupParams,
      rolepriorityforgroup_set: rolepriorityforgroupset,
    };
    const loader = async () => {
      const r = await api.post('/api/auth/permissiongroup/', auth, params);
      if (r.status === 400) {
        const error = await r.json();
        Object.entries(error).map((e) => {
          if (e) {
            throw new Error(`${e[0]}: ${e[1]}`);
          }
          return error;
        });
        return error;
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onClose();
        setUpdateList((prev) => !prev);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  };

  const handleCheckbox = useCallback((e, key) => {
    if (key === 'all') {
      setRoles((prev) => prev.map((i) => ({ ...i, check: true })));
    } else if (key === 'nothing') {
      setRoles((prev) => prev.map((i) => ({ ...i, check: false, priority: '' })));
    } else {
      const { id, checked } = e.target;
      setRoles((prev) => prev
        .map((i) => (i.id === parseInt(id, 10)
          ? { ...i, check: checked, priority: '' } : i)));
    }
  }, []);

  const handlePriority = useCallback((e) => {
    const { id, value } = e.target;
    setRoles((prev) => prev
      .map((i) => (i.id === parseInt(id, 10)
        ? { ...i, priority: value } : i)));
  },
  []);

  return (
    <>
      <DimableLoader loading={loading}>
        {err
      && <H2 color="red">{err}</H2>}

        <>
          <Form>
            <Form.Group>
              <Form.Label htmlFor="name">Назва групи</Form.Label>
              <Form.Control
                type="text"
                id="name"
                name="name"
                onChange={updateGroupParams}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor="description">Опис групи</Form.Label>
              <Form.Control
                type="text"
                id="description"
                name="description"
                as="textarea"
                rows={3}
                onChange={updateGroupParams}
              />
            </Form.Group>
          </Form>

          <H2 color="blue">Ролі:</H2>

          <div className="mb-2">
            <Button className="mr-2" onClick={() => handleCheckbox(null, 'all')}>
              <FontAwesomeIcon className="mr-2" icon={faCheckSquare} size="lg" />
              Включити всі
            </Button>
            <Button variant="outline-primary" onClick={() => handleCheckbox(null, 'nothing')}>
              <FontAwesomeIcon icon={faSquare} className="mr-2" size="lg" />
              Виключити всі
            </Button>
          </div>

          <Table hover>
            <thead>
              <tr>
                <th style={{ width: '5px' }}>Включити в список</th>
                <th>Назва</th>
                <th style={{ width: '5px' }}>Пріоритет</th>
              </tr>
            </thead>
            <tbody>
              {roles && roles.map((el) => (
                <tr key={el.id}>
                  <td>
                    <Form.Check
                      type="switch"
                      id={el.id}
                      label=""
                      checked={el.check}
                      onChange={handleCheckbox}
                    />
                  </td>
                  <td>{el.name}</td>
                  <td>
                    <InputGroup className="mb-3">
                      <FormControl
                        id={el.id}
                        type="number"
                        aria-describedby="basic-addon3"
                        onChange={handlePriority}
                        required
                        value={el.priority}
                        disabled={!el.check}
                      />
                    </InputGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Button className="mt-2" onClick={createGroup}>Створити</Button>
        </>

      </DimableLoader>
    </>
  );
};

CreateGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
};

export default CreateGroup;
