import SKDMaster from './SKDMaster';
import Exchange from './Exchange';
import Login from './Login';
import RoleManagement from './RoleManagement';
import GroupManagement from './GroupManagement';
import TreasureExport from './TreasureExport';
import ImportTreasureFiles from './ImportTreasureFiles';

const services = {
  SKDMaster,
  ...Exchange,
  Login,
  RoleManagement,
  GroupManagement,
  ImportTreasureFiles,
  TreasureExport,
};

export default services;
