import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import { StyledFieldList } from '../styles';
import itemTypes from '../itemTypes';
import OrdersItem from './ordersItem';
import { ordersPropType } from '../../hooks';

const OrdersList = ({
  orders, activeOrder, onClick, onDblClick, onDragEnd, onDirectionChange,
}) => {
  const [padded, setPadded] = useState(null);
  const onDragEndHandler = useCallback((item) => {
    onDragEnd(item, padded);
  },
  [onDragEnd, padded]);
  const [{
    isOver, isOverCurrent, didDrop,
  }, ref] = useDrop({
    accept: [itemTypes.availableField, itemTypes.order],
    drop: (item) => onDragEndHandler(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    if ((!isOver && !isOverCurrent && !didDrop) || (isOver && isOverCurrent && !didDrop)) {
      setPadded(null);
    }
  }, [didDrop, isOver, isOverCurrent]);
  return (
    <Card>
      <Card.Header>Сортування</Card.Header>
      <StyledFieldList
        ref={ref}
      >
        {orders.map((f) => (
          <OrdersItem
            key={f.name}
            name={f.name}
            label={f.label}
            onClick={onClick}
            onDblClick={onDblClick}
            active={activeOrder === f.name}
            onHover={setPadded}
            padded={padded === f.name}
            direction={f.direction}
            onDirectionChange={onDirectionChange}
            errored={f.errored}
          />
        ))}
      </StyledFieldList>
    </Card>
  );
};


OrdersList.propTypes = {
  orders: ordersPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  activeOrder: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  onDirectionChange: PropTypes.func.isRequired,
};


OrdersList.defaultProps = {
  activeOrder: null,
  onDblClick: () => null,
};

export default OrdersList;
