import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const kbp7Props = tableHooks.useItemInputProps(
    'kbp7_item',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const kekvProps = tableHooks.useItemInputProps(
    'kekv_item',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const disposerProps = tableHooks.useItemInputProps(
    'disposer',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const disposerAccProps = tableHooks.useItemInputProps(
    'disposer_acc',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const planYearAmountProps = tableHooks.useNumberInputProps(
    'plan_year_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const planPeriodAmountProps = tableHooks.useNumberInputProps(
    'plan_period_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const planMouthAmountProps = tableHooks.useNumberInputProps(
    'plan_month_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const factPeriodAmountProps = tableHooks.useNumberInputProps(
    'fact_period_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const balanceProps = tableHooks.useNumberInputProps(
    'balance',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const finCommitmentAmountProps = tableHooks.useNumberInputProps(
    'fin_commitment_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const amountProps = tableHooks.useNumberInputProps(
    'amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const fundsDirectionProps = tableHooks.useItemInputProps(
    'funds_direction',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const templateProps = tableHooks.useItemInputProps(
    'template',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const orderDistributionRemarkProps = tableHooks.useStringInputProps(
    'order_distribution_remark',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const purposeOfPaymentProps = tableHooks.useItemInputProps(
    'purpose_of_payment',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const finrequestProps = tableHooks.useItemInputProps(
    'finrequest',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  return (
    <Col>
      <Row noGutters>
        <Col xl={3} sm={12}>
          <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
            <TableControls.ItemPicker
              {...disposerProps}
              noHierarchy
            />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
            <TableControls.ItemPicker {...kbp7Props} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="kekv_item" highlighted={highlights.includes('kekv_item')}>
            <TableControls.ItemPicker {...kekvProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
            <TableControls.ItemPicker {...disposerAccProps} />
          </TabelRowCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col xl={3} sm={12}>
          <TabelRowCell column="plan_year_amount" highlighted={highlights.includes('plan_year_amount')}>
            <TableControls.NumberInput {...planYearAmountProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="plan_period_amount" highlighted={highlights.includes('plan_period_amount')}>
            <TableControls.NumberInput {...planPeriodAmountProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="plan_month_amount" highlighted={highlights.includes('plan_month_amount')}>
            <TableControls.NumberInput {...planMouthAmountProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="funds_direction" highlighted={highlights.includes('funds_direction')}>
            <TableControls.ItemPicker {...fundsDirectionProps} />
          </TabelRowCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col xl={3} sm={12}>
          <TabelRowCell column="fact_period_amount" highlighted={highlights.includes('fact_period_amount')}>
            <TableControls.NumberInput {...factPeriodAmountProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="balance" highlighted={highlights.includes('balance')}>
            <TableControls.NumberInput {...balanceProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="fin_commitment_amount" highlighted={highlights.includes('fin_commitment_amount')}>
            <TableControls.NumberInput {...finCommitmentAmountProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
            <TableControls.NumberInput {...amountProps} />
          </TabelRowCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col xl={3} sm={12}>
          <TabelRowCell column="template" highlighted={highlights.includes('template')}>
            <TableControls.ItemPicker {...templateProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="order_distribution_remark" highlighted={highlights.includes('order_distribution_remark')}>
            <TableControls.StringInput {...orderDistributionRemarkProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="purpose_of_payment" highlighted={highlights.includes('purpose_of_payment')}>
            <TableControls.ItemPicker {...purposeOfPaymentProps} />
          </TabelRowCell>
        </Col>
        <Col xl={3} sm={12}>
          <TabelRowCell column="finrequest" highlighted={highlights.includes('finrequest')}>
            <TableControls.ItemPicker {...finrequestProps} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
