import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import Pane from './pane';
import { StyledContainer, StyledSeparator } from './styles';

function clearSelection () {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.collapse();
    range.select();
  } else if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    document.selection.empty();
  }
}

const DEFAULT_SPLITTER_SIZE = 4;

class SplitterLayout extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    vertical: PropTypes.bool,
    percentage: PropTypes.bool,
    primaryIndex: PropTypes.oneOf([0, 1]),
    primaryMinSize: PropTypes.number,
    secondaryInitialSize: PropTypes.number,
    secondaryMinSize: PropTypes.number,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    onSecondaryPaneSizeChange: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.node)
  };

  static defaultProps = {
    className: null,
    vertical: false,
    percentage: false,
    primaryIndex: 0,
    primaryMinSize: 0,
    secondaryInitialSize: null,
    secondaryMinSize: 0,
    onDragStart: null,
    onDragEnd: null,
    onSecondaryPaneSizeChange: null,
    children: []
  };

  constructor (props) {
    super(props);

    this.state = {
      secondaryPaneSize: 0,
      resizing: false
    };
    this.container = createRef();
    this.splitter = createRef();
  }

  componentDidMount () {
    const { secondaryInitialSize } = this.props;
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('touchend', this.handleMouseUp);
    document.addEventListener('touchmove', this.handleTouchMove);

    let secondaryPaneSize;
    if (secondaryInitialSize) {
      secondaryPaneSize = secondaryInitialSize;
    } else {
      const containerRect = this.container.current.getBoundingClientRect();
      let splitterRect;
      if (this.splitter.current) {
        splitterRect = this.splitter.current.getBoundingClientRect();
      } else {
        // Simulate a splitter
        splitterRect = { width: DEFAULT_SPLITTER_SIZE, height: DEFAULT_SPLITTER_SIZE };
      }
      secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
        left: containerRect.left + ((containerRect.width - splitterRect.width) / 2),
        top: containerRect.top + ((containerRect.height - splitterRect.height) / 2)
      }, false);
    }
    this.setState({ secondaryPaneSize });
  }

  componentDidUpdate (prevProps, prevState) {
    const { onSecondaryPaneSizeChange, onDragStart, onDragEnd } = this.props;
    const { secondaryPaneSize, resizing } = this.state;

    if (prevState.secondaryPaneSize !== secondaryPaneSize && onSecondaryPaneSizeChange) {
      onSecondaryPaneSizeChange(secondaryPaneSize);
    }
    if (prevState.resizing !== resizing) {
      if (resizing) {
        if (onDragStart) {
          onDragStart();
        }
      } else if (onDragEnd) {
        onDragEnd();
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchend', this.handleMouseUp);
    document.removeEventListener('touchmove', this.handleTouchMove);
  }

  getSecondaryPaneSize (containerRect, splitterRect, clientPosition, offsetMouse) {
    const {
      vertical, primaryIndex, percentage, primaryMinSize, secondaryMinSize
    } = this.props;
    let totalSize;
    let splitterSize;
    let offset;
    if (vertical) {
      totalSize = containerRect.height;
      splitterSize = splitterRect.height;
      offset = clientPosition.top - containerRect.top;
    } else {
      totalSize = containerRect.width;
      splitterSize = splitterRect.width;
      offset = clientPosition.left - containerRect.left;
    }
    if (offsetMouse) {
      offset -= splitterSize / 2;
    }
    if (offset < 0) {
      offset = 0;
    } else if (offset > totalSize - splitterSize) {
      offset = totalSize - splitterSize;
    }

    let secondaryPaneSize;
    if (primaryIndex === 1) {
      secondaryPaneSize = offset;
    } else {
      secondaryPaneSize = totalSize - splitterSize - offset;
    }
    let primaryPaneSize = totalSize - splitterSize - secondaryPaneSize;
    if (percentage) {
      secondaryPaneSize = (secondaryPaneSize * 100) / totalSize;
      primaryPaneSize = (primaryPaneSize * 100) / totalSize;
      splitterSize = (splitterSize * 100) / totalSize;
      totalSize = 100;
    }

    if (primaryPaneSize < primaryMinSize) {
      secondaryPaneSize = Math.max(secondaryPaneSize - (primaryMinSize - primaryPaneSize), 0);
    } else if (secondaryPaneSize < secondaryMinSize) {
      secondaryPaneSize = Math.min(totalSize - splitterSize - primaryMinSize, secondaryMinSize);
    }

    return secondaryPaneSize;
  }

  handleResize = () => {
    const { percentage } = this.props;
    if (this.splitter.current && !percentage) {
      const containerRect = this.container.current.getBoundingClientRect();
      const splitterRect = this.splitter.current.getBoundingClientRect();
      const secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
        left: splitterRect.left,
        top: splitterRect.top
      }, false);
      this.setState({ secondaryPaneSize });
    }
  };

  handleMouseMove = (e) => {
    const { resizing } = this.state;
    if (resizing) {
      const containerRect = this.container.current.getBoundingClientRect();
      const splitterRect = this.splitter.current.getBoundingClientRect();
      const secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
        left: e.clientX,
        top: e.clientY
      }, true);
      clearSelection();
      this.setState({ secondaryPaneSize });
    }
  };

  handleTouchMove = e => this.handleMouseMove(e.changedTouches[0]);

  handleSplitterMouseDown = () => {
    clearSelection();
    this.setState({ resizing: true });
  };

  handleMouseUp = () => {
    const { resizing } = this.state;
    if (resizing) this.setState({ resizing: false });
  };

  render () {
    const {
      className, vertical, children, primaryIndex, percentage
    } = this.props;
    const { resizing, secondaryPaneSize } = this.state;
    return (
      <StyledContainer
        ref={this.container}
        vertical={vertical}
        resizing={resizing}
        className={className}
      >
        <Pane
          vertical={vertical}
          percentage={percentage}
          primary={primaryIndex === 0}
          size={primaryIndex === 0 ? null : secondaryPaneSize}
        >
          {children[0]}
        </Pane>
        <StyledSeparator
          ref={this.splitter}
          vertical={vertical}
          resizing={resizing}
          onMouseDown={this.handleSplitterMouseDown}
          onTouchStart={this.handleSplitterMouseDown}
        />
        <Pane
          vertical={vertical}
          percentage={percentage}
          primary={primaryIndex === 1}
          size={primaryIndex === 1 ? null : secondaryPaneSize}
        >
          {children[1]}
        </Pane>
      </StyledContainer>
    );
  }
}

export default SplitterLayout;
