import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { FieldPropType } from '../../../basicEditor';
import useHistory from '../../../newEditor/hook/useHistory';
import meta from '../../../../meta';

const HistoryBudget = ({
  data, fields, actions, id,
}) => {
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.disposers.backendURL,
    id,
    fields,
    onChange: actions.onChange,
  });

  //   address, budget, code_dksu, deleted, disposer_kind, edrpou, email,
  // history, id, koatuu, level,  name, parent, phone_number, post_code,
  // recipient, repr, resource,  skype, url, web_site,

  const editorStringHooks = (name) => editorHooks.useStringInputProps(name, historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const editorSelectorHooks = (name) => editorHooks.useItemInputProps(name, historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const editorCheckboxHooks = (name) => editorHooks.useCheckboxInputProps(name, historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  // console.log(historyData);
  const kvk = editorSelectorHooks('kvk');
  // const chief = editorSelectorHooks('chief');
  // const buhgalter = editorSelectorHooks('buhgalter');

  const isActive = editorCheckboxHooks('is_active');

  // const disposer_kind = models.PositiveSmallIntegerField(
  //       choices=DISPOSER_KIND__CHOICES,
  //       default=KindOfDisposer.OTHER.value, null=False, blank=False,
  //       verbose_name='Вид распрядителя',
  //   )

  // const level = models.PositiveSmallIntegerField(
  //       choices=DISPOSER_LEVEL__CHOICES,
  //       null=True, blank=True,
  //       verbose_name='Рівень розпорядника',
  //   )

  const fullName = editorStringHooks('fullname');

  const transfername = editorStringHooks('transfername');
  const networkCode = editorStringHooks('network_code');

  return (
    <>
      <HistoryCommandPanel
        currentStartDate={historyData.datefrom}
        histories={historiesList}
        historyLoading={historyLoading}
        onLoadHistories={historyActions.onLoadHistories}
        onSelectHistory={historyActions.onSelectHistory}
        onSaveHistory={historyActions.onSaveHistory}
        onCreateHistory={historyActions.onCreateHistory}
        onDeleteHistory={historyActions.onDeleteHistory}
        isNew={isNew}
      />
      <Container fluid style={{ padding: '0' }}>
        <Row>
          <Col>
            <EditorControls.StringInput {...fullName} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput {...transfername} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.ItemPicker {...kvk} modelType="cat" modelName="kvk" />
          </Col>
          <Col>
            <EditorControls.StringInput {...networkCode} />
          </Col>
          <Col>
            <EditorControls.CheckboxInput {...isActive} />
          </Col>
        </Row>
      </Container>
    </>
  );
};
const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  fullname: PropTypes.string,
  genetivename: PropTypes.string,
  is_active: PropTypes.bool,
  parent: PropTypes.shape({ }),
  settings: PropTypes.shape({}),
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryBudget.propTypes = {
  data: PropTypes.shape({
    history: PropTypes.shape(dataPropTypes),
  }).isRequired,
  id: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    history: PropTypes.shape({
      fieldsPropTypes,
    }),
  }).isRequired,
  actions: PropTypes.shape({
    registerSaveListener: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default HistoryBudget;

// import React from 'react';
// import PropTypes from 'prop-types';
// import { Col, Container, Row } from 'react-bootstrap';
// import withHistory, { HistoryCommandPanel } from '../../../historyValues';
// import { FieldPropType, FieldErrorPropType } from '../../../basicEditor';
// import { ContentLabel } from '../../../../components/Styled/Misc';
// import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
//
// const HistoryBudget = ({
//   data, fields, errors, onChange, onSave, onNew, onDelete, isNew,
//   history_values, onHistoryDropDown, history_loading, onChangeHistoryVariant,
// }) => {
//   const fullNameProps = editorHooks.useStringInputProps('fullname', data, fields, errors, onChange);
//   const transferNameProps = editorHooks.useStringInputProps('transfername', data, fields, errors, onChange);
//   const kvkProps = editorHooks.useItemInputProps('kvk', data, fields, errors, onChange);
//   const isActiveProps = editorHooks.useCheckboxInputProps('is_active', data, fields, errors, onChange);
//   return (
//     <>
//       <HistoryCommandPanel
//         history_value={data.datefrom}
//             // eslint-disable-next-line camelcase
//         history_values={history_values}
//         onChangeHistoryVariant={onChangeHistoryVariant}
//         onHistoryDropDown={onHistoryDropDown}
//             // eslint-disable-next-line camelcase
//         history_loading={history_loading}
//         onSave={onSave}
//         onNew={onNew}
//         onDelete={onDelete}
//         isNew={isNew}
//       />
//       <Container fluid style={{ padding: '0' }}>
//         <Row>
//           <Col>
//             <EditorControls.StringInput {...fullNameProps} />
//           </Col>
//           <Col>
//             <EditorControls.StringInput {...transferNameProps} />
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <EditorControls.ItemPicker {...kvkProps} modelType="cat" modelName="kvk" />
//           </Col>
//           <Col>
//             <EditorControls.CheckboxInput {...isActiveProps} />
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <ContentLabel
//               text={fields.buhgalter.label}
//               required={fields.buhgalter.required}
//             >
//               <span>Not impemented</span>
//             </ContentLabel>
//           </Col>
//           <Col>
//             <ContentLabel
//               text={fields.chief.label}
//               required={fields.chief.required}
//             >
//               <span>Not impemented</span>
//             </ContentLabel>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// };
//
// const dataPropTypes = {
//   id: PropTypes.number,
//   datefrom: PropTypes.string,
//   dateto: PropTypes.string,
//   buhgalter: PropTypes.number, // пока не сделан
//   chief: PropTypes.number, // пока не сделан
//   fullname: PropTypes.string,
//   is_active: PropTypes.bool,
//   kvk: PropTypes.shape({ }),
//   transfername: PropTypes.string,
// };
//
// const errorsPropTypes = Object.keys(dataPropTypes)
//   .reduce((R, r) => ({ ...R, [r]: FieldErrorPropType }), {});
// const fieldsPropTypes = Object.keys(dataPropTypes)
//   .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});
//
// HistoryBudget.propTypes = {
//   data: PropTypes.shape(
//     dataPropTypes,
//   ).isRequired,
//   errors: PropTypes.shape(
//     errorsPropTypes,
//   ).isRequired,
//   fields: PropTypes.shape(
//     fieldsPropTypes,
//   ).isRequired,
//   history_values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   onHistoryDropDown: PropTypes.func.isRequired,
//   history_loading: PropTypes.bool,
//   onChangeHistoryVariant: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
//   onSave: PropTypes.func.isRequired,
//   onNew: PropTypes.func.isRequired,
//   onDelete: PropTypes.func.isRequired,
//   isNew: PropTypes.bool.isRequired, // Признак того что создается ноывй элемнт
//   // ( не элемент истории а элемент классификатора)
// };
//
// HistoryBudget.defaultProps = {
//   history_loading: false,
// };
//
// export default withHistory(HistoryBudget);
//
// // TODO: @jaksam backendURL KVK
