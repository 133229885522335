import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import Placeholder from '../../../../../newEditor/tablePart/placeholder'; import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TPFooter from './footer';

const PlanningRows = ({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) => {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'executionbpactivityanalogysfrow_set', readOnlyFields, errors, fields,
  });

  const totals = useMemo(
    () => (tableData.reduce((res, r) => {
      res.sumPrFactGenF += r.amount_pr_fact_zf || 0;
      res.sumPrFactSpecF += r.amount_pr_fact_sf || 0;
      res.sumFactGenF += r.amount_fact_zf || 0;
      res.sumFactSpecF += r.amount_fact_sf || 0;
      res.sumDeviationGenF += (r.amount_plan_zf || 0)
        - (r.amount_fact_zf || 0);
      res.sumDeviationSpecF += (r.amount_plan_sf || 0)
        - (r.amount_fact_sf || 0);
      res.sumPrFactTotal += r.amount_plan_total || 0;
      res.sumFactTotal += (r.amount_fact_zf || 0)
        + (r.amount_fact_sf || 0);
      res.sumDeviationTotal += (r.amount_plan_sf || 0)
        - (r.amount_fact_sf || 0)
        + (r.amount_plan_zf || 0)
        - (r.amount_fact_zf || 0);
      return res;
    },
    {
      sumPrFactGenF: 0,
      sumPrFactSpecF: 0,
      sumFactGenF: 0,
      sumFactSpecF: 0,
      sumDeviationGenF: 0,
      sumDeviationSpecF: 0,
      sumPrFactTotal: 0,
      sumFactTotal: 0,
      sumDeviationTotal: 0,
    })
    ), [tableData],
  );

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          totals={totals}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow key={index} rowIndex={index} active={activeRow === index} activeCol={activeCol}>
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={(e) => tableActions.onAddRow(e)} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
};

PlanningRows.propTypes = {
  data: PropTypes.shape({
    executionbpactivityanalogysfrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    executionbpactivityanalogysfrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    executionbpactivityanalogysfrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    executionbpactivityanalogysfrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
