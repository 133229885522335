import React from 'react';
import PropTypes from 'prop-types';

const TabelRowCell = ({ column, highlighted, children }) => (
  <div data-col={column} className={highlighted ? 'bg-warning' : ''}>
    {children}
  </div>
);

TabelRowCell.propTypes = {
  column: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

TabelRowCell.defaultProps = {
  highlighted: false,
};

export default TabelRowCell;
