import React from 'react';
import PropTypes from 'prop-types';
// import { EditorControls } from '../../../BaseEditor';
import {
  EditorControls,
} from '../../../basicEditor/editorControls';

const SettingProps = ({
  onChange, entries,
}) => (
  <>
    {entries.length > 0 && entries.map((el) => (
      <EditorControls.TextInput
        key={el[0]}
        controlId={el[0]}
        value={el[1]}
        label={el[0]}
        required
        description="Застосування параметрів - :, наприклад :bdate або :year"
        onChange={(e, v) => onChange(v, el[0])}
      />
    ))}
  </>
);
SettingProps.propTypes = {
  onChange: PropTypes.func.isRequired,
  entries: PropTypes.node,
};

SettingProps.defaultProps = {
  entries: [],
};

export default SettingProps;
