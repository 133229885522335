const frontendURL = '/doc/passport/';
const backendURL = '/api/budgetrequest/passportbpheader/';
const name = 'Паспорт бюджетної програми';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
