import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { ContentLabel } from '../../../../../../components/Styled/Misc';
import { EItemPicker } from '../../../../../../components/Controls/withErrors';
import { dataPropTypes, errorsPropTypes, fieldsPropTypes } from '../factWorkerCategory/propTypes';

const StyledLabel = Styled.label`
  position: relative;
  color: #605b5b;
  font-weight: 700;
  width: 100%
`;

const ResultAnalysisPage = (props) => {
  const {
    data, fields, errors, onChange,
  } = props;

  return (
    <>
      <StyledLabel>
        {fields.passport.label}
        <EItemPicker
          value={data.passport}
          onChange={(e, v) => onChange({ passport: v })}
          readOnly={fields.passport.read_only}
          errors={errors.passport}
          modelType="doc"
          modelName="budgetpassport"
        />
      </StyledLabel>
      <StyledLabel>
        {fields.passport_accurate.label}
        <EItemPicker
          value={data.passport_accurate}
          onChange={(e, v) => onChange({ passport_accurate: v })}
          readOnly={fields.passport_accurate.read_only}
          errors={errors.passport_accurate}
          modelType="doc"
          modelName="budgetpassport"
        />
      </StyledLabel>
    </>
  );
};

ResultAnalysisPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: PropTypes.arrayOf(errorsPropTypes),
  onChange: PropTypes.func.isRequired,
};

ResultAnalysisPage.defaultProps = {
  errors: [],
};

export default ResultAnalysisPage;
