import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tab } from 'react-bootstrap';
import meta from '../../../../meta';
import HistoricalValues from './history';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { AppContext } from '../../../../providers/authProvider';

const Editor = ({ id, onClose, onSave }) => {
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,

  } = useEditor({
    backendURL: meta.cat.disposers.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  const { currentUser } = useContext(AppContext);
  const isSuperuser = currentUser.is_superuser;

  const editorStringHooks = (name) => editorHooks.useStringInputProps(name, data, fields, fieldErrors, actions.onChange);
  const editorSelectorHooks = (name) => editorHooks.useItemInputProps(name, data, fields, fieldErrors, actions.onChange);
  // const editorCheckboxHooks = (name) => editorHooks.useCheckboxInputProps(name, data, fields, fieldErrors, actions.onChange);

  // const recipient = editorSelectorHooks('recipient');
  // const bank = editorSelectorHooks('bank');
  const parent = editorSelectorHooks('parent');
  const budget = editorSelectorHooks('budget');
  const chief = editorSelectorHooks('chief');
  const buhgalter = editorSelectorHooks('buhgalter');

  const name = editorStringHooks('name');
  const edrpou = editorStringHooks('edrpou');
  // const code_dksu = editorStringHooks('code_dksu');
  // const koatuu = editorStringHooks('koatuu');
  // const post_code = editorStringHooks('post_code');
  // const address = editorStringHooks('address');
  // const phone_number = editorStringHooks('phone_number');
  // const email = editorStringHooks('email');
  // const web_site = editorStringHooks('web_site');
  // const skype = editorStringHooks('skype');

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <CatCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
      />
      <>
        { isSuperuser && (
        <Row>
          <Col>
            <EditorControls.ItemPicker {...budget} modelType="cat" modelName="budget" />
          </Col>
          <Col>
            <EditorControls.ItemPicker {...parent} modelType="cat" modelName="disposers" />
          </Col>
        </Row>
        )}
        <LabelTab defaultActiveKey="disposers" id="uncontrolled-tab-example">
          <Tab eventKey="disposers" title="Основні властивості">
            <TabContainer>
              <Row>
                <Col>
                  <EditorControls.StringInput {...name} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...edrpou} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.ItemPicker {...chief} modelType="cat" modelName="disposers" readOnly />
                </Col>

                <Col>
                  <EditorControls.ItemPicker {...buhgalter} modelType="cat" modelName="disposers" readOnly />
                </Col>
              </Row>

            </TabContainer>
          </Tab>
        </LabelTab>

        <LabelTab defaultActiveKey="table" id="uncontrolled-tab-example1">
          <Tab eventKey="table" title="Додаткові властивості">
            <TabContainer>
              <HistoricalValues
                id={id}
                data={data}
                actions={actions}
                fields={fields}
              />
            </TabContainer>
          </Tab>
        </LabelTab>
      </>
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
