import React, { forwardRef, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, ButtonToolbar, ButtonGroup,
} from 'react-bootstrap';
import { SearchInput } from '../../../../components/bootStrap';
import { StyledButtonToolbar } from './styles';
import ListerContext from '../../context';
import {
  ShowDeletedToggler, FiltersButton, NewButton, EditButton, DeleteButton, RefreshButton,
  ExecuteButton, UnExecuteButton,
} from '../../../../components/bootStrap/buttons';
import { SetPeriodButton } from './buttons';

const DocListerCommandPanel = forwardRef(({
  children,
}, ref) => {
  const {
    actions, permissions, showDeleted, searchString, period, localFilter,
  } = useContext(ListerContext);

  const filterApplied = !!Object.keys(localFilter).filter((k) => localFilter[k].use).length;
  return (
    <Navbar sticky="top" className="px-0 bg-white" ref={ref}>
      <StyledButtonToolbar className="mr-auto">
        <ButtonGroup>
          <NewButton onClick={actions.onNew} disabled={!(permissions.canNew && actions.onNew)} />
          <EditButton
            onClick={actions.onEdit}
            disabled={!(permissions.canEdit && actions.onEdit)}
          />
          <DeleteButton
            onClick={actions.onDelete}
            disabled={!(permissions.canDelete && actions.onDelete)}
          />
          <RefreshButton
            onClick={actions.onRefresh}
          />
        </ButtonGroup>
        <ButtonGroup>
          <ExecuteButton
            onClick={actions.onExecute}
            disabled={!(permissions.canExecute && actions.onExecute)}
          />
          <UnExecuteButton
            onClick={actions.onUnexecute}
            disabled={!(permissions.canUnexecute && actions.onUnexecute)}
          />
        </ButtonGroup>
        <ButtonGroup>
          <SetPeriodButton value={period} onChange={actions.onSetPeriod} />
          <FiltersButton
            onClick={() => actions.onToggleFilterOpened()}
            disabled={!permissions.canFilter}
            animation={filterApplied}
          />
        </ButtonGroup>
        {children}
      </StyledButtonToolbar>
      <ButtonToolbar>
        <ShowDeletedToggler
          value={showDeleted}
          disabled={!(permissions.canShowDeleted && actions.onToggleShowDeleted)}
          onChange={actions.onToggleShowDeleted}
        />
        <SearchInput
          onChange={actions.onSearch}
          value={searchString}
          disabled={!permissions.canSearch && !searchString}
        />
      </ButtonToolbar>
    </Navbar>
  );
});

DocListerCommandPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

DocListerCommandPanel.defaultProps = {
  children: null,
};

export default memo(DocListerCommandPanel);
