import React from 'react';
import PropTypes from 'prop-types';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const RemoveRowButton = ({
  content, title, onClick, disabled,
}) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faTrashAlt}
    title={title}
  />
);

RemoveRowButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

RemoveRowButton.defaultProps = {
  onClick: () => null,
  content: 'Видалити рядок',
  disabled: false,
  title: '',
};

export default RemoveRowButton;
