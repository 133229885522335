import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from 'react-bootstrap';
import { ErroredTooltip } from '../../../components/bootStrap';
import { getDisplayName } from '../../../api/utils';

const withTableControl = (WrappedControl) => {
  const TableInput = ({
    controlId, required, errors, ...restProps
  }) => {
    const controlRef = useRef(null);
    return (
      <>
        <WrappedControl
          {...restProps}
          id={controlId}
          ref={controlRef}
          errored={errors && !!errors.length}
        />
        <Overlay target={controlRef.current} show={!!errors} placement="auto">
          {({ show, ...props }) => (
            <ErroredTooltip id={`error-${controlId}`} {...props}>
              {String(errors)}
            </ErroredTooltip>
          )}
        </Overlay>
      </>
    );
  };

  TableInput.displayName = `${getDisplayName(WrappedControl)} wrapped by withEditorControl`;

  TableInput.propTypes = {
    controlId: PropTypes.string.isRequired,
    required: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  TableInput.defaultProps = {
    required: false,
    errors: null,
  };
  return memo(TableInput);
};

export default withTableControl;
