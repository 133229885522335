import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import {
  FactKDB,
  ForecastKDB,
  FactKFB,
  ForecastKFB,
  ExtraMoney,
  ExtraIndicator,
  Consequences,
  FactKEKV,
  ForecastKEKV,
  FactActivity,
  ForecastActivity,
  FactIndicator,
  ForecastIndicator,
  FactRemunerationStructure,
  ForecastRemunerationStructure,
  FactTargetProgram,
  ForecastTargetProgram,
  ResultAnalysis,
  LoanDebtTables,
  CommitmentManagmentAnalysis,
  NormativeAct,
  GoalAndLawRealiseBasis,
  Note,
  Task,
  InvestmentProgram,
  FactWorkerCategory,
  ForecastWorkerCategory,
  PagePassport,
} from './tp';
import { LabelTab, TabContainer } from '../../../basicEditor/tpController/styles';
import Tab from '../../../../components/Tab';
import useEditor from '../../../newEditor/hook/editor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import EditorContainer from '../../../newEditor/editorContainer';

const Editor = ({
  onSave, onClose, id,
}) => {
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields,
  } = useEditor({
    backendURL: meta.doc.budgetrequest.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const invoiceProps = editorHooks.useStringInputProps('invoice', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const budgetProps = editorHooks.useItemInputProps('budget', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const kbpProps = editorHooks.useStringInputProps('kbp', data, fields, fieldErrors, actions.onChange, true, headerReadOnlyFields);
  const kfkProps = editorHooks.useStringInputProps('kfk', data, fields, fieldErrors, actions.onChange, true, headerReadOnlyFields);
  const kbp7Props = editorHooks.useStringInputProps('kbp7', data, fields, fieldErrors, actions.onChange, true, headerReadOnlyFields);
  const approvedProps = editorHooks.useCheckboxInputProps('approved', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <DocCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
        executed={data.executed}
      />
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...invoiceProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...disposerProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...budgetProps} />
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col>
                <EditorControls.StringInput {...kbpProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...kfkProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...kbp7Props} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...approvedProps} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>

        <LabelTab defaultActiveKey="income" id="uncontrolled-tab-example">
          <Tab eventKey="income" title="Надходження, грн">
            <LabelTab defaultActiveKey="budgetrequestkdb" id="uncontrolled-tab-example">
              {fields && fields.budgetrequestkdbrow_set && (
                <Tab eventKey="budgetrequestkdb" title="Фактичні доходи">
                  <TabContainer>
                    <FactKDB
                      data={data}
                      fields={fields}
                      errors={fieldErrors}
                      readOnlyFields={readOnlyFields}
                      onChange={actions.onChange}
                    />
                  </TabContainer>
                </Tab>
              )}

              {fields && fields.budgetrequestkdbrow_set && (
              <Tab eventKey="budgetrequestkdb2" title="Прогноз надходжень">
                <TabContainer>
                  <ForecastKDB
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestkfbrow_set && (
              <Tab eventKey="budgetrequestkfb" title="Факт джерел фінансування">
                <TabContainer>
                  <FactKFB
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestkfbrow_set && (
              <Tab eventKey="budgetrequestkfb2" title="Прогноз джерел фінансування">
                <TabContainer>
                  <ForecastKFB
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
            </LabelTab>
          </Tab>

          <Tab eventKey="expenses" title="Видатки, грн">
            <LabelTab defaultActiveKey="budgetrequestkekv" id="uncontrolled-tab-example">
              {fields && fields.budgetrequestkekvrow_set && (
                <Tab eventKey="budgetrequestkekv" title="Фактичні/планові видатки за КЕКВ">
                  <TabContainer>
                    <FactKEKV
                      data={data}
                      fields={fields}
                      errors={fieldErrors}
                      readOnlyFields={readOnlyFields}
                      onChange={actions.onChange}
                    />
                  </TabContainer>
                </Tab>
              )}

              {fields && fields.budgetrequestkekvrow_set && (
                <Tab eventKey="budgetrequestkekv2" title="Проект/прогноз видатків за КЕКВ">
                  <TabContainer>
                    <ForecastKEKV
                      data={data}
                      fields={fields}
                      errors={fieldErrors}
                      readOnlyFields={readOnlyFields}
                      onChange={actions.onChange}
                    />
                  </TabContainer>
                </Tab>
              )}

              {fields && fields.budgetrequestproblemrow_set && (
              <Tab eventKey="budgetrequestproblem" title="Завдання">
                <TabContainer>
                  <Task
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestactivityrow_set && (
              <Tab eventKey="budgetrequestactivity" title="Фактичні/планові видатки за напрямами використання">
                <TabContainer>
                  <FactActivity
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestactivityrow_set && (
              <Tab eventKey="budgetrequestactivity2" title="Проект/прогноз видатків за напрямами використання">
                <TabContainer>
                  <ForecastActivity
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestindicatorrow_set && (
              <Tab eventKey="budgetrequestindicator" title="Фактичні/планові видатки за показниками">
                <TabContainer>
                  <FactIndicator
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestindicatorrow_set && (
              <Tab eventKey="budgetrequestindicator2" title="Проект/прогноз видатків за показниками">
                <TabContainer>
                  <ForecastIndicator
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestremunerationstructurerow_set && (
              <Tab eventKey="budgetrequestremunerationstructure" title="Фактична структура видатків на оплату праці">
                <TabContainer>
                  <FactRemunerationStructure
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestremunerationstructurerow_set && (
              <Tab eventKey="budgetrequestremunerationstructure2" title="Проект/прогноз cтруктури видатків на оплату праці">
                <TabContainer>
                  <ForecastRemunerationStructure
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestworkercategoryrow_set && (
              <Tab eventKey="budgetrequestworkercategory" title="Фактична чисельність зайнятих у бюджетних установах">
                <TabContainer>
                  <FactWorkerCategory
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestworkercategoryrow_set && (
              <Tab eventKey="budgetrequestworkercategory2" title="Проект/прогноз чисельністі зайнятих у бюджетних установах">
                <TabContainer>
                  <ForecastWorkerCategory
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequesttargetprogramrow_set && (
              <Tab eventKey="budgetrequesttargetprogram" title="Фактичні цільові програми">
                <TabContainer>
                  <FactTargetProgram
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequesttargetprogramrow_set && (
              <Tab eventKey="budgetrequesttargetprogram2" title="Проект/прогноз цільових програм">
                <TabContainer>
                  <ForecastTargetProgram
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestinvestmentprogramrow_set && (
              <Tab eventKey="budgetrequestinvestmentprogram" title="Інвестиційні проекти, в межах бюджетної програми">
                <TabContainer>
                  <InvestmentProgram
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && (
              <Tab eventKey="analysis" title="Аналіз результатів">
                <TabContainer>
                  <ResultAnalysis
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestloandebtrow_set && (
              <Tab eventKey="budgetrequestloandebt" title="Аналіз управління заборгованістю">
                <TabContainer>
                  <LoanDebtTables
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                  <CommitmentManagmentAnalysis
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestnormativeactrow_set && (
              <Tab eventKey="budgetrequestnormativeact" title="Перелік положень нормативно-правових актів, що не забезпечуються видатками">
                <TabContainer>
                  <NormativeAct
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields
              && (
              <Tab eventKey="goalAndLawRealiseBasis" title="Мета, законодавчі підстави">
                <TabContainer>
                  <GoalAndLawRealiseBasis
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                    year={data.year}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields
              && (
              <Tab eventKey="note" title="Примітка">
                <TabContainer>
                  <Note
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
            </LabelTab>
          </Tab>

          {fields
          && (
          <Tab eventKey="passport" title="Заповнення за даними паспорту та звіту з виконання паспорту">
            <PagePassport
              data={data}
              fields={fields}
              errors={fieldErrors}
              readOnlyFields={readOnlyFields}
              onChange={actions.onChange}
            />
          </Tab>
          )}

          <Tab eventKey="addMoney" title="Додаткові кошти (форма 3), грн">
            <LabelTab defaultActiveKey="budgetrequestextramoney" id="uncontrolled-tab-example">
              {fields && fields.budgetrequestextramoneyrow_set && (
              <Tab eventKey="budgetrequestextramoney" title="Додаткові кошти">
                <TabContainer>
                  <ExtraMoney
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields && fields.budgetrequestextraindicatorrow_set && (
              <Tab eventKey="budgetrequestextraindicator" title="Зміна показників, у разі виділення додаткових коштів">
                <TabContainer>
                  <ExtraIndicator
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}

              {fields
              && (
              <Tab eventKey="consequences" title="Наслідки, у разі невиділення додаткових коштів">
                <TabContainer>
                  <Consequences
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                    year={data.year}
                  />
                </TabContainer>
              </Tab>
              )}
            </LabelTab>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
