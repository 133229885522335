import React from 'react';
import PropTypes from 'prop-types';
import { TPGridHeader } from '../../../../../basicEditor/index';
import { fieldsPropTypes } from './propTypes';

const Header = ({ fields, year }) => (
  <>
    <TPGridHeader gridArea="1/kvk3/3/kvk3">{fields.kvk3.label}</TPGridHeader>
    <TPGridHeader gridArea="3/activity/">{fields.activity.label}</TPGridHeader>

    <TPGridHeader gridArea="1/amount_total_2">{`Сума на ${year - 2} рік`}</TPGridHeader>
    <TPGridHeader gridArea="1/amount_total_1">{`Сума на ${year - 1} рік`}</TPGridHeader>
    <TPGridHeader gridArea="1/amount_total0">{`Сума на ${year} рік`}</TPGridHeader>
    <TPGridHeader gridArea="1/amount_total1">{`Сума на ${year + 1} рік`}</TPGridHeader>
    <TPGridHeader gridArea="1/amount_total2">{`Сума на ${year + 2} рік`}</TPGridHeader>

    <TPGridHeader gridArea="2/amount_zf_2/4/amount_zf_2">З.Ф.</TPGridHeader>
    <TPGridHeader gridArea="2/amount_zf_1/4/amount_zf_1">З.Ф.</TPGridHeader>
    <TPGridHeader gridArea="2/amount_zf0/4/amount_zf0">З.Ф.</TPGridHeader>
    <TPGridHeader gridArea="2/amount_zf1/4/amount_zf1">З.Ф.</TPGridHeader>
    <TPGridHeader gridArea="2/amount_zf2/4/amount_zf2">З.Ф.</TPGridHeader>

    <TPGridHeader gridArea="2/amount_sf_2">С.Ф.</TPGridHeader>
    <TPGridHeader gridArea="2/amount_sf_1">С.Ф.</TPGridHeader>
    <TPGridHeader gridArea="2/amount_sf0">С.Ф.</TPGridHeader>
    <TPGridHeader gridArea="2/amount_sf1">С.Ф.</TPGridHeader>
    <TPGridHeader gridArea="2/amount_sf2">С.Ф.</TPGridHeader>

    <TPGridHeader gridArea="3/amount_br_total_2">у т.ч. Б.Р.</TPGridHeader>
    <TPGridHeader gridArea="3/amount_br_total_1">у т.ч. Б.Р.</TPGridHeader>
    <TPGridHeader gridArea="3/amount_br_total0">у т.ч. Б.Р.</TPGridHeader>
    <TPGridHeader gridArea="3/amount_br_total1">у т.ч. Б.Р.</TPGridHeader>
    <TPGridHeader gridArea="3/amount_br_total2">у т.ч. Б.Р.</TPGridHeader>
  </>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  year: PropTypes.number.isRequired,
};

export default Header;
