import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, {
  DocCommandPanel,
  getFieldsPropTypes,
  getErrorsPropTypes,
} from '../../../basicEditor';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { stdDocDataPropTypes, foreignPropType } from '../../common';
import { HeaderText } from '../../../../components/Styled/Forms';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { RevenuesRows } from './tp';
import {
  FlexContainer,
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import { PrintButton } from './printButton';

const Editor = ({
  data,
  options,
  isNew,
  isReadOnly,
  isModified,
  onSave,
  onClose,
  onExecute,
  onCancelExecute,
  fields,
  errors,
  onChange,
}) => {
  const numberProps = editorHooks.useStringInputProps('number', data, fields, errors, onChange);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, errors, onChange);

  const yearProps = editorHooks.useNumberInputProps('year', data, fields, errors, onChange);

  const commentProps = editorHooks.useTextInputProps('note', data, fields, errors, onChange);

  const fieldsTabl = {
    ...fields.forecastadd1row_set.child.children,
  };

  delete fieldsTabl.table_name;

  const dataRew = data.forecastadd1row_set && data.forecastadd1row_set.map((row, rowIndex) =>{
    row.table_name = 1;
    return row;
  });

  console.log('fields', fields);

  return (
    <FlexContainer>
      <HeaderText>
        {isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}
      </HeaderText>
      <DocCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        onSave={() => onSave()}
        onClose={onClose}
        executed={data.executed}
        onExecute={() => onExecute()}
        onCancelExecute={() => onCancelExecute()}
      >
        <PrintButton id={`${data.id}`} />
      </DocCommandPanel>
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.StringInput {...commentProps} />
              </Col>
            </Row>

          </Container>
        </HidableContainer>

        <LabelTab defaultActiveKey="forecastAdd1" id="uncontrolled-tab-example">
          <Tab
            eventKey="forecastAdd1revenues"
            title={fields.forecastadd1row_set.label}
          >
            <TabContainer>
              <RevenuesRows
                data={dataRew}
                fields={fieldsTabl}
                readOnly={fields.forecastadd1row_set.read_only}
                errors={errors.forecastadd1row_set}
                onChange={(set) => onChange({ forecastadd1row_set: set })}
                year={yearProps.value}
              />
            </TabContainer>
          </Tab>

          {/* <Tab */}
          {/*  eventKey="forecastAdd1revenues" */}
          {/*  title={fields.forecastadd1row_set.label} */}
          {/* > */}
          {/*  <TabContainer> */}
          {/*    <RevenuesRows */}
          {/*      data={data.forecastadd1row_set} */}
          {/*      fields={fields.forecastadd1row_set.child.children} */}
          {/*      errors={errors.forecastadd1row_set} */}
          {/*      onChange={(set) => onChange({ forecastadd1row_set: set })} */}
          {/*    /> */}
          {/*  </TabContainer> */}
          {/* </Tab> */}

          {/* <Tab */}
          {/*  eventKey="forecastAdd1revenues" */}
          {/*  title={fields.forecastadd1row_set.label} */}
          {/* > */}
          {/*  <TabContainer> */}
          {/*    <RevenuesRows */}
          {/*      data={data.forecastadd1row_set} */}
          {/*      fields={fields.forecastadd1row_set.child.children} */}
          {/*      errors={errors.forecastadd1row_set} */}
          {/*      onChange={(set) => onChange({ forecastadd1row_set: set })} */}
          {/*    /> */}
          {/*  </TabContainer> */}
          {/* </Tab> */}

          {/* <Tab */}
          {/*  eventKey="forecastAdd1revenues" */}
          {/*  title={fields.forecastadd1row_set.label} */}
          {/* > */}
          {/*  <TabContainer> */}
          {/*    <RevenuesRows */}
          {/*      data={data.forecastadd1row_set} */}
          {/*      fields={fields.forecastadd1row_set.child.children} */}
          {/*      errors={errors.forecastadd1row_set} */}
          {/*      onChange={(set) => onChange({ forecastadd1row_set: set })} */}
          {/*    /> */}
          {/*  </TabContainer> */}
          {/* </Tab> */}

          {/* <Tab */}
          {/*  eventKey="forecastAdd1revenues" */}
          {/*  title={fields.forecastadd1row_set.label} */}
          {/* > */}
          {/*  <TabContainer> */}
          {/*    <RevenuesRows */}
          {/*      data={data.forecastadd1row_set} */}
          {/*      fields={fields.forecastadd1row_set.child.children} */}
          {/*      errors={errors.forecastadd1row_set} */}
          {/*      onChange={(set) => onChange({ forecastadd1row_set: set })} */}
          {/*    /> */}
          {/*  </TabContainer> */}
          {/* </Tab> */}

        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </FlexContainer>
  );
};

const dataPropTypes = {
  ...stdDocDataPropTypes,
  approved: PropTypes.bool,
  fond: foreignPropType,
  plan_kind: PropTypes.number,
  comment: PropTypes.string, // автоматическое примечание
  comment_of_author: PropTypes.string, // Примечания пользователя
  disable_control: PropTypes.bool,
  number: PropTypes.string,
  doc_date: PropTypes.data,
  authority_acc: PropTypes.foreignPropType,
  is_financed: PropTypes.bool,
};

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape(dataPropTypes).isRequired,
  fields: PropTypes.shape(getFieldsPropTypes(dataPropTypes)).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onExecute: PropTypes.func.isRequired,
  onCancelExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.doc.forecastAdd1.backendURL)(Editor);
