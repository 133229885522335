const frontendURL = '/srv/skd-master/';
const name = 'Конструктор СКД';

const getVisibility = (user) => user.is_superuser;

const instance = {
  frontendURL,
  name,
  getVisibility,
};

export default instance;
