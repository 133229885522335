const frontendURL = '/cat/kvk/';
const backendURL = '/api/references/refkvk/';
const name = 'Відомча класифікація (КВК)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
