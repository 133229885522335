import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer,
  StyledCellItem,
  StyledTableRow,
  StyledTD,
} from '../../../../../basicEditor';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';

const Row = ({
  rowIndex,
  active,
  selected,
  onActivateRow,
  onChangeRow,
  row,
  fields,
  errors,
  disposer,
}) => {
  const disposerProps = tableHooks.useItemInputProps(
    'disposer',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const nameProps = tableHooks.useStringInputProps(
    'name',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const edrpouProps = tableHooks.useStringInputProps(
    'edrpou',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const kbpProps = tableHooks.useItemInputProps(
    'kbp',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const numberVvProps = tableHooks.useStringInputProps(
    'number_vv',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const kvkProps = tableHooks.useItemInputProps(
    'kvk',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const roleProps = tableHooks.useSelectorInputProps(
    'role',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  return (
    <StyledTableRow
      active={active}
      selected={selected}
      onClick={(e) => onActivateRow(e, rowIndex)}
    >
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...disposerProps} filter={{ parent: disposer.value.id }} noHierarchy />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.StringInput {...edrpouProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.StringInput {...nameProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...kvkProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.StringInput {...numberVvProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...kbpProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.SelectorInput {...roleProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
    </StyledTableRow>
  );
};

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
};

Row.defaultProps = {
  active: false,
  selected: false,
  errors: {},
};

export default memo(Row);
