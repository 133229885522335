import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CPButton } from './buttons/styles';

const FileInput = styled.input.attrs(({ multiple }) => ({
  type: 'file',
  multiple,
}))`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;


export const FileUploadButton = ({
  content, disabled, title, icon, multiple, readAs, onChange,
}) => {
  const inputRef = useRef(null);
  const files = useRef({});
  const onFileSelect = useCallback(
    (e) => {
      const fileNames = [...e.target.files].map((f) => f.name);
      [...e.target.files].forEach((file) => {
        const fr = new FileReader();
        if (readAs === 'text') {
          fr.readAsText(file);
        } else if (readAs === 'dataURL') {
          fr.readAsDataURL(file);
        } else {
          throw new Error(`Read method ${readAs} not implemented`);
        }
        fr.onloadend = () => {
          files.current[file.name] = fr.result;
          if (fileNames.reduce((R, f) => R && Object.keys(files.current).includes(f), true)) {
            onChange(e, files.current);
          }
        };
      });
    },
    [onChange, readAs],
  );
  return (
    <>
      <FileInput
        ref={inputRef}
        onChange={onFileSelect}
        multiple={multiple}
      />
      <CPButton
        onClick={() => inputRef.current.click()}
        disabled={disabled}
        content={content}
        icon={icon}
        title={title}
      />
    </>
  );
};

FileUploadButton.propTypes = {
  content: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.shape({}),
  multiple: PropTypes.bool,
  readAs: PropTypes.oneOf(['text', 'dataURL']),
};

FileUploadButton.defaultProps = {
  onChange: () => null,
  content: '',
  disabled: false,
  title: '',
  icon: null,
  multiple: false,
  readAs: 'text',
};

export default FileUploadButton;
