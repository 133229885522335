import React, {
  useState, useMemo, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container, Dropdown, Row, Col, Button,
} from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import moment from 'moment';
import DateInput from '../../Controls/dates/dateInput';
import { MonthGrid } from './styles';
import MonthButton from './monthButton';
import { periodVarians } from '../../../constants/date';

const PeriodForm = ({ value, onChange }) => {
  const [currentYear, setCurrentYear] = useState(moment(value.start).year());
  const currMode = useRef(0);

  const years = useMemo(
    () => [currentYear - 1, currentYear, currentYear + 1],
    [currentYear],
  );

  const months = useMemo(
    () => [...Array(12)].map((_, m) => {
      // const sd = new Date(currentYear, m, 1, 0, 0, 0, 0);
      // const ed = new Date(currentYear, m + 1, 0, 23, 59, 59, 0);
      // sd.setMinutes(sd.getMinutes() - sd.getTimezoneOffset());
      // ed.setMinutes(ed.getMinutes() - ed.getTimezoneOffset());
      const mnt = moment({ y: currentYear, M: m, d: 1 });
      return {
        key: m,
        start: mnt.startOf('month').format('YYYY-MM-DD'),
        end: mnt.endOf('month').format('YYYY-MM-DD'),
        label: mnt.format('MMM'),
      };
    }),
    [currentYear],
  );

  const onMonthClick = useCallback(
    (e, v) => {
      switch (currMode.current) {
        case 0: // первый клик
          onChange(e, { ...value, start: v.start, end: v.end });
          currMode.current = 1;
          break;
        case 1: // воторой клик
          onChange(e, { ...value, end: v.end });
          currMode.current = 0;
          break;
        default:
          throw new Error('Unknow mode');
      }
    },
    [onChange, value],
  );

  const onVariantChange = useCallback(
    (e, v) => {
      onChange(e, {
        ...periodVarians[v].eval(),
        variant: v,
      });
    },
    [onChange],
  );

  return (
    <Container>
      <Row className="mb-2">
        <Col>
          <Dropdown>
            <DropdownToggle variant="outline-secondary" id="period-variant-toggler" className="w-100">
              {periodVarians[value.variant] ? periodVarians[value.variant].label : 'Довільний період'}
            </DropdownToggle>
            <Dropdown.Menu>
              {Object.keys(periodVarians).map((v) => (
                <Dropdown.Item
                  key={v}
                  active={v === value.variant}
                  onSelect={(e) => onVariantChange(e, v)}
                >
                  {periodVarians[v].label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            id="start_date"
            value={value.start}
            onChange={(e, v) => onChange(e, { ...value, start: v })}
          />
        </Col>
        <Col>
          <DateInput
            id="end_date"
            value={value.end}
            onChange={(e, v) => onChange(e, { ...value, end: v })}
            endOfDay
          />
        </Col>
      </Row>
      <Row className="mt-2 text-center">
        {years.map((y) => (
          <Col key={y}>
            <Button
              variant={y === currentYear ? 'outline-primary' : 'outline-secondary'}
              className="w-100"
              onClick={() => setCurrentYear(y)}
            >
              {y}
            </Button>
          </Col>
        ))}
      </Row>
      <MonthGrid className="mt-2">
        {months.map((m) => (
          <MonthButton
            key={m.key}
            onClick={onMonthClick}
            value={m}
            start={value.start}
            end={value.end}
          >
            {m.label}
          </MonthButton>
        ))}
      </MonthGrid>

    </Container>
  );
};

PeriodForm.propTypes = {
  value: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    variant: Object.keys(periodVarians),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PeriodForm;
