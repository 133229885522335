const frontendURL = '/doc/incomeproject/';
const backendURL = '/api/planproject/incomeprojectheader/';
// const printURL = '';
const name = 'Проект доходів';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
