import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { ETextInput } from '../../../../../../components/Controls/withErrors';
import { dataPropTypes, errorsPropTypes, fieldsPropTypes } from '../factWorkerCategory/propTypes';

const StyledLabel = Styled.label`
  position: relative;
  color: #605b5b;
  font-weight: 700;
  width: 100%
`;

const ConsequencesPage = (props) => {
  const {
    data, fields, errors, onChange, year,
  } = props;

  const yearDef = year || new Date().getFullYear();
  const year1 = `${(year || yearDef) + 1}`;
  const year2 = `${(year || yearDef) + 2}`;

  return (
    <>
      <StyledLabel>
        {`${fields.goal.label} на ${yearDef} рік`}
        <ETextInput
          value={data.goal}
          onChange={(e, v) => onChange({ goal: v })}
          readOnly={fields.goal.read_only}
          errors={errors.goal}
        />
      </StyledLabel>
      {' '}
      <StyledLabel>
        {`${fields.law_realise_basis.label} на ${year1}-${year2} роки`}
        <ETextInput
          value={data.law_realise_basis}
          onChange={(e, v) => onChange({ law_realise_basis: v })}
          readOnly={fields.law_realise_basis.read_only}
          errors={errors.law_realise_basis}
        />
      </StyledLabel>
    </>
  );
};

ConsequencesPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: PropTypes.arrayOf(errorsPropTypes),
  year: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

ConsequencesPage.defaultProps = {
  errors: [],
};

export default ConsequencesPage;
