import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import { SelectButton, CancelButton, FiltersButton } from '../../../components/bootStrap/buttons';
import { actions } from './actions';
import { CommandPanel } from '../../../components/bootStrap';

export const selectorCP = ({
  onActionClick, canSelect, canFilter, filterApplied,
}) => (
  <CommandPanel>
    <ButtonGroup>
      <SelectButton content="Обрати" addText onClick={(e) => onActionClick(e, actions.select)} disabled={!canSelect} />
      <CancelButton onClick={(e) => onActionClick(e, actions.close)} />
    </ButtonGroup>
    <FiltersButton
      disabled={!canFilter}
      onClick={(e) => onActionClick(e, actions.toggleFilter)}
      animated={filterApplied}
    />
  </CommandPanel>
);

selectorCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  canSelect: PropTypes.bool,
  canFilter: PropTypes.bool,
  filterApplied: PropTypes.bool,
};

selectorCP.defaultProps = {
  canSelect: true,
  canFilter: true,
  filterApplied: false,
};

export default selectorCP;
