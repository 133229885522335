import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;HOME SWEET HOME
`;

const Home = () => (
  <Container className="fullscreenParent" />
);

export default Home;
