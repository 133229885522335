const frontendURL = '/cat/bringdocument/';
const backendURL = '/api/references/refbringdocument/';
const name = 'Документи, що додаються до зобов\'язань';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
