import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import {
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import { PrintButton } from './printButton';
import LoadLevelDownButton from './loadLeveldown';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';

const Editor = ({
  onSave, onClose, id,
}) => {
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields,
  } = useEditor({
    backendURL: meta.doc.finorder.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fondProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const authorityAccProps = editorHooks.useItemInputProps('authority_acc', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const commentOfAutorProps = editorHooks.useTextInputProps('comment_of_author', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const importAllNotesProps = editorHooks.useCheckboxInputProps('import_all_notes', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const isFinancedProps = editorHooks.useCheckboxInputProps('is_financed', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const isTransferProps = editorHooks.useCheckboxInputProps('is_transfer', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const isRefundProps = editorHooks.useCheckboxInputProps('is_refund', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const execIfMoneyProps = editorHooks.useCheckboxInputProps('exec_if_nomoney', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <DocCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
        executed={data.executed}
      >
        <PrintButton
          id={`${data.id}`}
        />
      </DocCommandPanel>
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.ItemPicker {...fondProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...authorityAccProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.CheckboxInput {...isTransferProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...importAllNotesProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...isRefundProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.CheckboxInput {...execIfMoneyProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...isFinancedProps} />
              </Col>
            </Row>

            <LoadLevelDownButton
              authority={data.authority}
              fond={data.fonditem}
              docDate={data.doc_date}
              year={data.year}
              planKind={data.plan_kind}
              onLoadHandler={(rows) => actions.onDraft(data, {
                load_leveldown_allcplnchng_rows: {
                  rows,
                  clear: true,
                },
              })}
            />
          </Container>
        </HidableContainer>

        <LabelTab defaultActiveKey="finorder" id="uncontrolled-tab-example">
          {fields && fields.finorderrow_set && (
          <Tab eventKey="finorder" title={fields.finorderrow_set.label}>
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>
          )}
          <Tab eventKey="#" title="Підлеглі документи">
            <TabContainer>
              <div>Some logic</div>
            </TabContainer>
          </Tab>

          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentOfAutorProps} />
            </TabContainer>
          </Tab>
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
