import React from 'react';
import PropTypes from 'prop-types';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const GenerateButton = ({
  content, onClick, disabled,
}) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faPlay}
  />
);

GenerateButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

GenerateButton.defaultProps = {
  onClick: () => null,
  content: 'Сформувати',
  disabled: false,
};

export default GenerateButton;
