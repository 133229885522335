import budget from './budgets';
import authoritysignvariant from './authoritysignvariant';
import fond from './fond';
import kbp from './kbp';
import kbp7 from './kbp7';
import kdb from './kdb';
import kekv from './kekv';
import kfb from './kfb';
import kfk from './kfk';
import kvk from './kvk';
import kvk3 from './kvk3';
import fonditem from './fondItem';
import kekvitem from './kekvItem';
import kbp7item from './kbp7item';

const cats = {
  budget,
  authoritysignvariant,
  fond,
  kbp,
  kbp7,
  kdb,
  kekv,
  kfb,
  kfk,
  kvk,
  kvk3,
  fonditem,
  kekvitem,
  kbp7item,
};

export default cats;
