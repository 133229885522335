const frontendURL = '/cat/kfb/';
const backendURL = '/api/references/refkfb/';
const name = 'Класифікація фінансування (КФБ)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
