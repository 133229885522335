import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer } from '../../../../components/Containers/docs';
import { AppContext } from '../../../../providers/authProvider';

const Editor = (props) => {
  const { id, onClose, onSave } = props;
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields,
    fieldErrors,

  } = useEditor({
    backendURL: meta.cat.bfnGroupName.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  const { currentUser } = useContext(AppContext);
  const { authority } = currentUser;

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange, !permissions.canChange);
  const disposer = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields, !permissions.canChange);
  const groupFullness = editorHooks.useItemInputProps('group_fullness', data, fields, fieldErrors, actions.onChange, !permissions.canChange);

  const { budget } = data;

  const groupFullnessFilter = useMemo(() => ({
    budget: budget ? budget.id : 0,
  }), [budget]);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <CatCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
      />
      <Container fluid style={{ padding: '0' }}>
        <Row>
          <Col>
            <EditorControls.ItemPicker
              {...disposer}
              filter={(authority === undefined) ? null : { parent: authority.id }}
              noHierarchy
            />
          </Col>
          <Col>
            <EditorControls.ItemPicker
              {...groupFullness}
              filter={groupFullnessFilter}
              noHierarchy
            />
          </Col>

          <Col>
            <EditorControls.StringInput {...nameProps} />
          </Col>
        </Row>
      </Container>

      <Footer
        authority={data.parent}
      />

    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
