import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  disposerInHeader,
  authorityId,
  activeObjectsBuilding,
  ABVisible,
  isComposeSettingsChecked,
}) => {
  const kbp7Props = tableHooks.useItemInputProps(
    'kbp7_item',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const kekvProps = tableHooks.useItemInputProps(
    'kekv_item',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const disposerProps = tableHooks.useItemInputProps(
    'disposer',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const disposerAccProps = tableHooks.useItemInputProps(
    'disposer_acc',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const buildObgectProps = tableHooks.useItemInputProps(
    'buildObgect',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const typeRepairProps = tableHooks.useItemInputProps(
    'typeRepair',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const planYearAmountProps = tableHooks.useNumberInputProps(
    'plan_year_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const planPeriodAmountProps = tableHooks.useNumberInputProps(
    'plan_period_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const planMouthAmountProps = tableHooks.useNumberInputProps(
    'plan_month_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const factPeriodAmountProps = tableHooks.useNumberInputProps(
    'fact_period_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const balanceProps = tableHooks.useNumberInputProps(
    'balance',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const isReturnProps = tableHooks.useNumberInputProps(
    'isReturn',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const balanceRegAccountsProps = tableHooks.useNumberInputProps(
    'balanceRegAccounts',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const balancePersAccountsProps = tableHooks.useNumberInputProps(
    'balancePersAccounts',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const finCommitmentAmountProps = tableHooks.useNumberInputProps(
    'fin_commitment_amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const amountProps = tableHooks.useNumberInputProps(
    'amount',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const templateProps = tableHooks.useItemInputProps(
    'template',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );
  const orderDistributionRemarkProps = tableHooks.useStringInputProps(
    'order_distribution_remark',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const purposeOfPaymentProps = tableHooks.useItemInputProps(
    'purpose_of_payment',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  const fullremarkProps = tableHooks.useItemInputProps(
    'fullremark',
    row,
    fields,
    errors,
    onChange,
    rowIndex,
    readOnly,
    readOnlyFields,
  );

  return (
    <>
      <Col>
        {!disposerInHeader
          && (
            <Row noGutters>
              <Col
                xl={4}
                sm={12}
              >
                <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
                  <TableControls.ItemPicker
                    {...disposerProps}
                    filter={{ parent: authorityId }}
                    noHierarchy
                  />
                </TabelRowCell>
              </Col>
              <Col
                xl={8}
                sm={12}
              >
                <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
                  <TableControls.ItemPicker
                    {...disposerAccProps}
                  />
                </TabelRowCell>
              </Col>
            </Row>
          )}
        <Row noGutters>
          <Col
            xl={4}
            sm={12}
            className="d-flex"
          >
            <Row className="w-100" noGutters>
              <Col>
                <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
                  <TableControls.ItemPicker
                    {...kbp7Props}
                  />
                </TabelRowCell>
                <TabelRowCell column="kekv_item" highlighted={highlights.includes('kekv_item')}>
                  <TableControls.ItemPicker
                    {...kekvProps}
                  />
                </TabelRowCell>
                {disposerInHeader
          && (
            <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
              <TableControls.ItemPicker
                {...disposerAccProps}
              />
            </TabelRowCell>
          )}
              </Col>
            </Row>
          </Col>
          {activeObjectsBuilding
             && (
               <Col xl={2} sm={12} className="d-flex">
                 <Row className="w-100" noGutters>
                   <Col>
                     <TabelRowCell column="buildObgect" highlighted={highlights.includes('buildObgect')}>
                       <TableControls.ItemPicker
                         {...buildObgectProps}
                       />
                     </TabelRowCell>
                     <TabelRowCell column="typeRepair" highlighted={highlights.includes('typeRepair')}>
                       <TableControls.StringInput
                         {...typeRepairProps}
                       />
                     </TabelRowCell>
                   </Col>
                 </Row>
               </Col>
             )}
          <Col xl={activeObjectsBuilding ? 6 : 8} sm={12}>
            <Row className="w-100" noGutters>
              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                <TabelRowCell column="plan_year_amount" highlighted={highlights.includes('plan_year_amount')}>
                  <TableControls.NumberInput
                    {...planYearAmountProps}
                  />
                </TabelRowCell>
                <TabelRowCell column="plan_period_amount" highlighted={highlights.includes('plan_period_amount')}>
                  <TableControls.NumberInput
                    {...planPeriodAmountProps}
                  />
                </TabelRowCell>
                <TabelRowCell column="plan_month_amount" highlighted={highlights.includes('plan_month_amount')}>
                  <TableControls.NumberInput
                    {...planMouthAmountProps}
                  />
                </TabelRowCell>
              </Col>

              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                <TabelRowCell column="fact_period_amount" highlighted={highlights.includes('fact_period_amount')}>
                  <TableControls.NumberInput
                    {...factPeriodAmountProps}
                  />
                </TabelRowCell>
                <TabelRowCell column="balance" highlighted={highlights.includes('balance')}>
                  <TableControls.NumberInput
                    {...balanceProps}
                  />
                </TabelRowCell>
                <TabelRowCell column="fin_commitment_amount" highlighted={highlights.includes('fin_commitment_amount')}>
                  <TableControls.NumberInput
                    {...finCommitmentAmountProps}
                  />
                </TabelRowCell>
              </Col>

              {(ABVisible || isComposeSettingsChecked)
              && (
                <Col
                  sm={12}
                  lg={3}
                >
                  {ABVisible && (
                    <>
                      <TabelRowCell column="balanceRegAccounts" highlighted={highlights.includes('balanceRegAccounts')}>
                        <TableControls.NumberInput
                          {...balanceRegAccountsProps}
                        />
                      </TabelRowCell>
                      <TabelRowCell column="balancePersAccounts" highlighted={highlights.includes('balancePersAccounts')}>
                        <TableControls.NumberInput
                          {...balancePersAccountsProps}
                        />
                      </TabelRowCell>
                    </>
                  )}
                  {(ABVisible || isComposeSettingsChecked) && (
                    <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
                      <TableControls.NumberInput
                        {...amountProps}
                      />
                    </TabelRowCell>
                  )}
                </Col>
              )}
              <Col
                sm={12}
                lg={(ABVisible || isComposeSettingsChecked)
                  ? 3 : 4}
              >
                {!isComposeSettingsChecked && !ABVisible && (
                <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
                  <TableControls.NumberInput
                    {...amountProps}
                  />
                </TabelRowCell>
                )}
                <TabelRowCell column="purpose_of_payment" highlighted={highlights.includes('purpose_of_payment')}>
                  <TableControls.ItemPicker
                    {...purposeOfPaymentProps}
                  />
                </TabelRowCell>
                <TabelRowCell column="template" highlighted={highlights.includes('template')}>
                  <TableControls.ItemPicker
                    {...templateProps}
                  />
                </TabelRowCell>
                {isComposeSettingsChecked && (
                <TabelRowCell column="isReturn" highlighted={highlights.includes('isReturn')}>
                  <TableControls.SelectorInput
                    {...isReturnProps}
                  />
                </TabelRowCell>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row noGutters>
          <Col sm={12} xl={6}>
            <TabelRowCell column="order_distribution_remark" highlighted={highlights.includes('order_distribution_remark')}>
              <TableControls.TextInput
                {...orderDistributionRemarkProps}
              />
            </TabelRowCell>
          </Col>
          <Col sm={12} xl={6}>
            <TabelRowCell column="fullremark" highlighted={highlights.includes('fullremark')}>
              <TableControls.TextInput
                {...fullremarkProps}
              />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
    </>
  );
};

TRow.propTypes = {
  row: PropTypes.shape(dataPropTypes).isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  onChange: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  disposerInHeader: PropTypes.bool,
  authorityId: PropTypes.number,
  activeObjectsBuilding: PropTypes.bool,
  ABVisible: PropTypes.bool,
  isComposeSettingsChecked: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  active: false,
  activeCol: '',
  readOnly: false,
  authorityId: null,
  disposerInHeader: false,
  activeObjectsBuilding: false,
  ABVisible: false,
  isComposeSettingsChecked: false,
  readOnlyFields: [],
};

export default memo(TRow);
