import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const Header = ({
  fields, activeCol, highlights, onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col
      xl={9}
      sm={9}
      className="px-1  d-flex flex-column justify-content-end"
    >
      <Row sm={2} noGutters>
        <TableHeaderCell
          className="text-center text-xl-left col-sm-6"
          active={activeCol === 'kbp7_item'}
          highlighted={highlights.includes('kbp7_item')}
          onToggleHighlght={() => onToggleHighlght('kbp7_item')}
        >
          {(fields.kbp7_item === undefined) ? '' : fields.kbp7_item.label}
        </TableHeaderCell>

        {/*<TableHeaderCell*/}
        {/*  className="text-center text-xl-left  col-sm-3"*/}
        {/*  active={activeCol === 'bfn_amount'}*/}
        {/*  highlighted={highlights.includes('bfn_amount')}*/}
        {/*  onToggleHighlght={() => onToggleHighlght('bfn_amount')}*/}
        {/*  title={`Всього:${totals}`}*/}
        {/*>*/}
        {/*  {(fields.bfn_amount === undefined) ? '' : fields.bfn_amount.label}*/}
        {/*</TableHeaderCell>*/}

      </Row>
    </Col>
  </TableHeaderRow>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  totals: PropTypes.number.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
