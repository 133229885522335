import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import {
  getTPController, ExpandableTPCommandPanel,
} from '../../../../../basicEditor';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import { useTPFilter } from '../../../../../basicEditor/tpController/TPHeader/filtered/useTPFIlter';

const PlanningRows = ({
  onAddRow, onChangeRow, onCopyRow, onDeleteSelectedRows, onActivateRow,
  fields, data, errors, activeRow, selectedRows, readOnly,
  onHide, onExpand, expanded, disposer, onDraft,
}) => {
  const [filter, onFilter] = useTPFilter(['edrpou', 'kvk', 'kbp']);
  const displayData = useMemo(
    () => data.map((row, idx) => ({ ...row, idx })).filter(
      (row) => (!filter.edrpou.length || filter.edrpou.includes(row.edrpou))
        && (!filter.kvk.length || filter.kvk.includes(row.kvk.id))
        && (!filter.kbp.length || filter.kbp.includes(row.kbp.id))
      ,
    ),
    [data, filter.edrpou, filter.kbp, filter.kvk],
  );
  // console.log(filter, data);
  return (
    <>
      <ExpandableTPCommandPanel
        onAdd={onAddRow}
        onCopy={onCopyRow}
        onDelete={onDeleteSelectedRows}
        onFillIn={onDraft}
        selectedRows={selectedRows}
        onHide={onHide}
        expanded={expanded}
        onExpand={onExpand}
        canModified={!readOnly}
      />
      <div>
        <Table bordered>
          <thead>
            <Header
              fields={fields}
              data={data}
              filter={filter}
              onFilter={onFilter}
            />
          </thead>
          <tbody>
            {displayData && displayData.map((row) => (
              <Row
                key={row.idx}
                rowIndex={row.idx}
                active={activeRow === row.idx}
                selected={selectedRows.includes(row.idx)}
                onActivateRow={onActivateRow}
                onChangeRow={onChangeRow}
                row={row}
                fields={fields}
                errors={errors[row.idx]}
                readOnly={readOnly}
                disposer={disposer}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

PlanningRows.propTypes = {
  onAddRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  onDeleteSelectedRows: PropTypes.func.isRequired,
  onCopyRow: PropTypes.func.isRequired,
  onFillInTheTable: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: PropTypes.arrayOf(errorsPropTypes),
  activeRow: PropTypes.number,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  onActivateRow: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,

  onHide: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};

PlanningRows.defaultProps = {
  errors: [],
  activeRow: null,
  selectedRows: [],
  readOnly: false,
};

export default getTPController(PlanningRows);
