import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const Header = ({
  fields, activeCol, highlights, onToggleHighlght, totals,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'activity'}
            highlighted={highlights.includes('activity')}
            onToggleHighlght={() => onToggleHighlght('activity')}
          >
            {fields.activity.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'indicator'}
            highlighted={highlights.includes('indicator')}
            onToggleHighlght={() => onToggleHighlght('indicator')}
          >
            {fields.indicator.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'indicator_type'}
            highlighted={highlights.includes('indicator_type')}
            onToggleHighlght={() => onToggleHighlght('indicator_type')}
          >
            {fields.indicator_type.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'trait_type'}
            highlighted={highlights.includes('trait_type')}
            onToggleHighlght={() => onToggleHighlght('trait_type')}
          >
            {fields.trait_type.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'unit_of_measure'}
            highlighted={highlights.includes('unit_of_measure')}
            onToggleHighlght={() => onToggleHighlght('unit_of_measure')}
          >
            {fields.unit_of_measure.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <TableHeaderCell
            title={totals.sumPlanSpecF.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_plan_sf'}
            highlighted={highlights.includes('amount_plan_sf')}
            onToggleHighlght={() => onToggleHighlght('amount_plan_sf')}
          >
            {fields.amount_plan_sf.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            title={totals.sumPlanGenF.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_plan_zf'}
            highlighted={highlights.includes('amount_plan_zf')}
            onToggleHighlght={() => onToggleHighlght('amount_plan_zf')}
          >
            {fields.amount_plan_zf.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            title={totals.sumFactSpecF.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_fact_sf'}
            highlighted={highlights.includes('amount_fact_sf')}
            onToggleHighlght={() => onToggleHighlght('amount_fact_sf')}
          >
            {fields.amount_fact_sf.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            title={totals.sumFactGenF.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_fact_zf'}
            highlighted={highlights.includes('amount_fact_zf')}
            onToggleHighlght={() => onToggleHighlght('amount_fact_zf')}
          >
            {fields.amount_fact_zf.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            title={totals.sumDeviationSpecF.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_diff_sf'}
            highlighted={highlights.includes('amount_diff_sf')}
            onToggleHighlght={() => onToggleHighlght('amount_diff_sf')}
          >
            {fields.amount_diff_sf.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            title={totals.sumDeviationGenF.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_diff_zf'}
            highlighted={highlights.includes('amount_diff_zf')}
            onToggleHighlght={() => onToggleHighlght('amount_diff_zf')}
          >
            {fields.amount_diff_zf.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <TableHeaderCell
            title={totals.sumPlanTotal.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_plan_total'}
            highlighted={highlights.includes('amount_plan_total')}
            onToggleHighlght={() => onToggleHighlght('amount_plan_total')}
          >
            {fields.amount_plan_total.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            title={totals.sumFactTotal.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_fact_total'}
            highlighted={highlights.includes('amount_fact_total')}
            onToggleHighlght={() => onToggleHighlght('amount_fact_total')}
          >
            {fields.amount_fact_total.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            title={totals.sumDeviationTotal.toLocaleString('uk', moneysStingOptions)}
            className="text-center text-xl-left"
            active={activeCol === 'amount_diff_total'}
            highlighted={highlights.includes('amount_diff_total')}
            onToggleHighlght={() => onToggleHighlght('amount_diff_total')}
          >
            {fields.amount_diff_total.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'description'}
            highlighted={highlights.includes('description')}
            onToggleHighlght={() => onToggleHighlght('description')}
          >
            {fields.description.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    sumPlanGenF: PropTypes.number,
    sumPlanSpecF: PropTypes.number,
    sumFactGenF: PropTypes.number,
    sumFactSpecF: PropTypes.number,
    sumDeviationGenF: PropTypes.number,
    sumDeviationSpecF: PropTypes.number,
    sumPlanTotal: PropTypes.number,
    sumFactTotal: PropTypes.number,
    sumDeviationTotal: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
