const frontendURL = '/srv/exchangePlan/';
const name = 'Завантажити виписку планів';
const getVisibility = (user) => user.is_superuser
  || (user.profile && user.profile.perms.cityplans_upload);

const instance = {
  frontendURL,
  name,
  getVisibility,
};

export default instance;
