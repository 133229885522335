import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const targetProgramProps = tableHooks.useSelectorInputProps('targetprogram', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const activityProps = tableHooks.useSelectorInputProps('activity', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const fondProps = tableHooks.useSelectorInputProps('fond', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kdbProps = tableHooks.useSelectorInputProps('kdb', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kfbProps = tableHooks.useSelectorInputProps('kfb', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountTotalProps = tableHooks.useNumberInputProps('amount_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountTotalSfProps = tableHooks.useNumberInputProps('amount_total_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountTotalZfProps = tableHooks.useNumberInputProps('amount_total_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactTotalProps = tableHooks.useNumberInputProps('amount_fact_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactSfProps = tableHooks.useNumberInputProps('amount_fact_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountFactZfProps = tableHooks.useNumberInputProps('amount_fact_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanTotalProps = tableHooks.useNumberInputProps('amount_plan_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanSfProps = tableHooks.useNumberInputProps('amount_plan_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountPlanZfProps = tableHooks.useNumberInputProps('amount_plan_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountExecutedTotalProps = tableHooks.useNumberInputProps('amount_executed_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountExecutedSfProps = tableHooks.useNumberInputProps('amount_executed_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountExecutedZfProps = tableHooks.useNumberInputProps('amount_executed_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountForecastTotalProps = tableHooks.useNumberInputProps('amount_forecast_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountForecastSfProps = tableHooks.useNumberInputProps('amount_forecast_sf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountForecastZfProps = tableHooks.useNumberInputProps('amount_forecast_zf', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const explanationProps = tableHooks.useStringInputProps('explanation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const explanationDescriptionProps = tableHooks.useStringInputProps('explanation_description', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashCostDiffProps = tableHooks.useStringInputProps('cash_cost_diff', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  amountTotalProps.value = (amountTotalSfProps.value || 0) + (amountTotalZfProps.value || 0);
  amountPlanTotalProps.value = (amountPlanSfProps.value || 0) + (amountPlanZfProps.value || 0);
  amountFactTotalProps.value = (amountFactSfProps.value || 0) + (amountFactZfProps.value || 0);
  amountExecutedTotalProps.value = (amountExecutedSfProps.value || 0)
    + (amountExecutedZfProps.value || 0);
  amountForecastTotalProps.value = (amountForecastSfProps.value || 0)
    + (amountForecastZfProps.value || 0);

  return (
    <>
      <Col sm={12}>
        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="targetprogram" highlighted={highlights.includes('targetprogram')}>
              <TableControls.SelectorInput {...targetProgramProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="activity" highlighted={highlights.includes('activity')}>
              <TableControls.SelectorInput {...activityProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="fond" highlighted={highlights.includes('fond')}>
              <TableControls.SelectorInput {...fondProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="kdb" highlighted={highlights.includes('kdb')}>
              <TableControls.SelectorInput {...kdbProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="kfb" highlighted={highlights.includes('kfb')}>
              <TableControls.SelectorInput {...kfbProps} />
            </TabelRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="amount_total_sf" highlighted={highlights.includes('amount_total_sf')}>
              <TableControls.NumberInput {...amountTotalSfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_total_zf" highlighted={highlights.includes('amount_total_zf')}>
              <TableControls.NumberInput {...amountTotalZfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_fact_sf" highlighted={highlights.includes('amount_fact_sf')}>
              <TableControls.NumberInput {...amountFactSfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_fact_zf" highlighted={highlights.includes('amount_fact_zf')}>
              <TableControls.NumberInput {...amountFactZfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_plan_sf" highlighted={highlights.includes('amount_plan_sf')}>
              <TableControls.NumberInput {...amountPlanSfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_plan_zf" highlighted={highlights.includes('amount_plan_zf')}>
              <TableControls.NumberInput {...amountPlanZfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_executed_sf" highlighted={highlights.includes('amount_executed_sf')}>
              <TableControls.NumberInput {...amountExecutedSfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_executed_zf" highlighted={highlights.includes('amount_executed_zf')}>
              <TableControls.NumberInput {...amountExecutedZfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_forecast_sf" highlighted={highlights.includes('amount_forecast_sf')}>
              <TableControls.NumberInput {...amountForecastSfProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_forecast_zf" highlighted={highlights.includes('amount_forecast_zf')}>
              <TableControls.NumberInput {...amountForecastZfProps} />
            </TabelRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="amount_total" highlighted={highlights.includes('amount_total')}>
              <TableControls.NumberInput {...amountTotalProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_fact_total" highlighted={highlights.includes('amount_fact_total')}>
              <TableControls.NumberInput {...amountFactTotalProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_plan_total" highlighted={highlights.includes('amount_plan_total')}>
              <TableControls.NumberInput {...amountPlanTotalProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_executed_total" highlighted={highlights.includes('amount_executed_total')}>
              <TableControls.NumberInput {...amountExecutedTotalProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="amount_forecast_total" highlighted={highlights.includes('amount_forecast_total')}>
              <TableControls.NumberInput {...amountForecastTotalProps} />
            </TabelRowCell>
          </Col>
        </Row>

        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="explanation" highlighted={highlights.includes('explanation')}>
              <TableControls.StringInput {...explanationProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="explanation_description" highlighted={highlights.includes('explanation_description')}>
              <TableControls.StringInput {...explanationDescriptionProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="cash_cost_diff" highlighted={highlights.includes('cash_cost_diff')}>
              <TableControls.StringInput {...cashCostDiffProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
    </>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
