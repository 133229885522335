import React, {
  Children, cloneElement, useMemo, useState, lazy, Suspense, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spinner } from 'react-bootstrap';
import { childrenPropType } from '../../../../../common/proptypes';
import { ACTIVE_FIELD, DATE_PARAMETER } from '../../../../../constants/backend';

const ClassifierListerSelector = ({
  filter, children, ...restProps
}) => {
  const EditorDateInput = lazy(() => import('../../../../basicEditor/editorControls/lazyControls/dateInput'));
  const EditorCheckboxInput = lazy(() => import('../../../../basicEditor/editorControls/lazyControls/checkboxInput'));
  const checkBoxCtrlId = useRef(Math.random().toString(36));
  const [cDate, setCDate] = useState(
    () => (filter && filter[DATE_PARAMETER] ? filter[DATE_PARAMETER] : new Date().toJSON().slice(0, 10)),
  );
  const [showInactive, setShowInactive] = useState(false);

  const reqDate = useMemo(
    () => cDate.replace('-', '').replace('-', ''),
    [cDate],
  );

  const flt = useMemo(
    () => ({
      ...(showInactive ? {} : { [ACTIVE_FIELD]: true }),
      [DATE_PARAMETER]: reqDate,
      ...filter,
    }),
    [filter, reqDate, showInactive],
  );

  const newChild = cloneElement(Children.only(children), {
    ...restProps,
    filter: flt,
  });

  return (
    <>
      <Suspense fallback={<Spinner animation="grow" />}>
        <Row>
          <Col>
            <EditorDateInput
              label="Показувати на дату"
              value={cDate}
              onChange={(e, v) => setCDate(v)}
              controlId="date_filter"
            />
          </Col>
          <Col sm={2} className="d-flex align-items-end text-right">
            <EditorCheckboxInput
              controlId={checkBoxCtrlId.current}
              label="Показати недіючи"
              value={showInactive}
              onChange={(e, v) => setShowInactive(v)}
              description="Якщо обрано, будуть показані діючи та недіючи коди"
            />
          </Col>
        </Row>
      </Suspense>
      {newChild}
    </>
  );
};

ClassifierListerSelector.propTypes = {
  filter: PropTypes.shape({
    [ACTIVE_FIELD]: PropTypes.bool,
    [DATE_PARAMETER]: PropTypes.string,
  }),
  children: childrenPropType.isRequired,
};

ClassifierListerSelector.defaultProps = {
  filter: {},
};

export default ClassifierListerSelector;
