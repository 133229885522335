import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { EditorNumberInput } from '../../../basicEditor/editorControls';
import { CPButton } from '../../../../components/bootStrap/buttons/styles';
import { StyleTable, Title } from '../styles';

const TableRecords = ({
  tableName, fields, records, howMany, onChangeHowMany, onRequestRecords,
}) => (
  <>
    <Title>{tableName}</Title>
    <EditorNumberInput
      controlId={`how-many-input-${tableName}`}
      label="Количество записей"
      description="Чтобы получить все записи - введите -1"
      value={howMany}
      onChange={(e, v) => onChangeHowMany(v)}
      minValue={-1}
      append={(
        <InputGroup.Append>
          <CPButton
            content="Получить записи"
            onClick={() => onRequestRecords()}
            icon={faDatabase}
          />
        </InputGroup.Append>
          )}
    />
    <div className="containerTable">
      <StyleTable striped bordered hover>
        <thead>
          <tr>
            {fields.map((f) => (
              <th key={f}>
                {f}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((row, k) => (
          // eslint-disable-next-line react/no-array-index-key
            <tr key={k}>
              {fields.map((f, fk) => (
                <td key={f}>
                  {row[fk]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyleTable>
    </div>
  </>
);

TableRecords.propTypes = {
  tableName: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  records: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  howMany: PropTypes.number.isRequired,
  onChangeHowMany: PropTypes.func.isRequired,
  onRequestRecords: PropTypes.func.isRequired,
};

TableRecords.defaultProps = {

  records: [],
};

export default TableRecords;
