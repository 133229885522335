import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonGroup, ButtonToolbar, Navbar } from 'react-bootstrap';
import {
  SaveButton, CloseButton, ExecuteButton, UnExecuteButton, UndoButton, RedoButton,
} from '../../../components/bootStrap/buttons';

export const StyledButtonToolbar = styled(ButtonToolbar)`
  & > *+* {
    padding-left: .5rem;
  }
`;

const DocCommandPanel = forwardRef(({
  permissions, modified, executed,
  actions, children,
}, ref) => (
  <Navbar sticky="top" ref={ref} className="border rounded bg-white">
    <StyledButtonToolbar className="mr-auto">
      <SaveButton
        onClick={actions.onSaveWithoutExit}
        disabled={!permissions.canSave || !modified}
      />
      <ButtonGroup>
        <ExecuteButton onClick={actions.onExecute} disabled={!permissions.canExecute} />
        <UnExecuteButton
          onClick={actions.onUnexecute}
          disabled={!permissions.canUnexecute || !executed}
        />
      </ButtonGroup>
      <ButtonGroup>
        <UndoButton onClick={actions.onUndo} disabled={!permissions.canUndo} />
        <RedoButton
          onClick={actions.onRedo}
          disabled={!permissions.canRedo}
        />
      </ButtonGroup>
      <ButtonGroup />

      <CloseButton onClick={actions.onClose} disabled={!permissions.canClose} />
      {children}
    </StyledButtonToolbar>
  </Navbar>
));

DocCommandPanel.propTypes = {
  permissions: PropTypes.shape({
    canSave: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
    canClose: PropTypes.bool,
    canExecute: PropTypes.bool,
    canUnexecute: PropTypes.bool,
    canChange: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onSaveWithoutExit: PropTypes.func,
    onSaveNExit: PropTypes.func,
    onExecuteNExit: PropTypes.func,
    onExecute: PropTypes.func,
    onUnexecute: PropTypes.func,
    onUndo: PropTypes.func,
    onRedo: PropTypes.func,
    onClose: PropTypes.func,
    onLoading: PropTypes.func,

  }).isRequired,
  modified: PropTypes.bool,
  executed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]),
};

DocCommandPanel.defaultProps = {
  modified: false,
  children: null,
  executed: false,
};

export default DocCommandPanel;
