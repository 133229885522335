import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import LoadLevelDownButton from './loadLeveldown';
import { PrintButton } from './printButton';
import useEditor from '../../../newEditor/hook/editor';
import EditorContainer from '../../../newEditor/editorContainer';

const Editor = ({
  onSave, onClose, id,
}) => {
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields,
  } = useEditor({
    backendURL: meta.doc.allocationplanchanges.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  console.log(data)
  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerInHeaderProps = editorHooks.useCheckboxInputProps('disposer_in_header', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const onlyYearProps = editorHooks.useCheckboxInputProps('only_year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const vt4brProps = editorHooks.useCheckboxInputProps('vt4br', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const isCreditProps = editorHooks.useCheckboxInputProps('is_credit', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const commentOfAutorProps = editorHooks.useTextInputProps('comment_of_author', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fondProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  const disableControlProps = editorHooks.useCheckboxInputProps('disable_control', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const noteNumberProps = editorHooks.useNumberInputProps('note_number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const internalProps = editorHooks.useCheckboxInputProps('internal', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const approvedProps = editorHooks.useCheckboxInputProps('approved', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  const sourceOfCoverageProps = editorHooks.useItemInputProps('source_of_coverage', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const executorProps = editorHooks.useItemInputProps('executor', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  const templateDocBasisProps = editorHooks.useItemInputProps('template_doc_basis', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const noteBasisProps = editorHooks.useTextInputProps('note_basis', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <DocCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
        executed={data.executed}
      >
        <PrintButton
          id={`${data.id}`}
        />
      </DocCommandPanel>
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...noteNumberProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...fondProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} />
              </Col>
              <Col>
                {data.disposer_in_header && (
                <EditorControls.ItemPicker
                  {...disposerProps}
                  filter={{ parent: data.authority.id }}
                  noHierarchy
                />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...sourceOfCoverageProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...executorProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...templateDocBasisProps} />
              </Col>
              <Col>
                <EditorControls.TextInput {...noteBasisProps} />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <EditorControls.CheckboxInput {...disposerInHeaderProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...onlyYearProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...vt4brProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...isCreditProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput {...disableControlProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...internalProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...approvedProps} />
              </Col>
            </Row>
            <LoadLevelDownButton
              authority={data.authority}
              fondItem={data.fonditem}
              docDate={data.doc_date}
              year={data.year}
              planKind={data.plan_kind}
              onLoadHandler={(rows) => actions.onDraft(
                data,
                {
                  load_leveldown_allcplnchng_rows: {
                    rows,
                    clear: true,
                  },
                },
              )}
            />
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="allocationplanchanges" id="uncontrolled-tab-example">
          {fields && fields.allocationplanchangesrow_set && (
          <Tab eventKey="allocationplanchanges" title={fields.allocationplanchangesrow_set.label}>
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>
          )}
          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentOfAutorProps} />
            </TabContainer>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;

/*
TODO: При изменении даты меняь год
TODO: устанавливать распорядитель в шапке на сервере
TODO: втчбр доступно только для 7 фонда
TODO: колонки КДБ и КФБ недоступно только для 1 фонда

TODO: Сделать обработку ошибок при записи / проведения документа

 */
