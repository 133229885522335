import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

const Header = ({
  fields, activeCol, highlights, onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col sm={12} className="px-1  d-flex flex-column justify-content-end">
      <Row noGutters>
        <Col className="px-1 border-right border-bottom">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'targetprogram'}
            highlighted={highlights.includes('targetprogram')}
            onToggleHighlght={() => onToggleHighlght('targetprogram')}
          >
            {fields.targetprogram.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right border-bottom">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'activity'}
            highlighted={highlights.includes('activity')}
            onToggleHighlght={() => onToggleHighlght('activity')}
          >
            {fields.activity.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right border-bottom">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'fond'}
            highlighted={highlights.includes('fond')}
            onToggleHighlght={() => onToggleHighlght('fond')}
          >
            {fields.fond.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right border-bottom">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'kdb'}
            highlighted={highlights.includes('kdb')}
            onToggleHighlght={() => onToggleHighlght('kdb')}
          >
            {fields.kdb.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right border-bottom">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'kfb'}
            highlighted={highlights.includes('kfb')}
            onToggleHighlght={() => onToggleHighlght('kfb')}
          >
            {fields.kfb.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_total_sf'}
            highlighted={highlights.includes('amount_total_sf')}
            onToggleHighlght={() => onToggleHighlght('amount_total_sf')}
          >
            {fields.amount_total_sf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_total_zf'}
            highlighted={highlights.includes('amount_total_zf')}
            onToggleHighlght={() => onToggleHighlght('amount_total_zf')}
          >
            {fields.amount_total_zf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_fact_sf'}
            highlighted={highlights.includes('amount_fact_sf')}
            onToggleHighlght={() => onToggleHighlght('amount_fact_sf')}
          >
            {fields.amount_fact_sf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_fact_zf'}
            highlighted={highlights.includes('amount_fact_zf')}
            onToggleHighlght={() => onToggleHighlght('amount_fact_zf')}
          >
            {fields.amount_fact_zf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_plan_sf'}
            highlighted={highlights.includes('amount_plan_sf')}
            onToggleHighlght={() => onToggleHighlght('amount_plan_sf')}
          >
            {fields.amount_plan_sf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_plan_zf'}
            highlighted={highlights.includes('amount_plan_zf')}
            onToggleHighlght={() => onToggleHighlght('amount_plan_zf')}
          >
            {fields.amount_plan_zf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_executed_sf'}
            highlighted={highlights.includes('amount_executed_sf')}
            onToggleHighlght={() => onToggleHighlght('amount_executed_sf')}
          >
            {fields.amount_executed_sf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_executed_zf'}
            highlighted={highlights.includes('amount_executed_zf')}
            onToggleHighlght={() => onToggleHighlght('amount_executed_zf')}
          >
            {fields.amount_executed_zf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_forecast_sf'}
            highlighted={highlights.includes('amount_forecast_sf')}
            onToggleHighlght={() => onToggleHighlght('amount_forecast_sf')}
          >
            {fields.amount_forecast_sf.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_forecast_zf'}
            highlighted={highlights.includes('amount_forecast_zf')}
            onToggleHighlght={() => onToggleHighlght('amount_forecast_zf')}
          >
            {fields.amount_forecast_zf.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col className="px-1 border-right border-bottom border-top">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_total'}
            highlighted={highlights.includes('amount_total')}
            onToggleHighlght={() => onToggleHighlght('amount_total')}
          >
            {fields.amount_total.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right border-bottom border-top">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_fact_total'}
            highlighted={highlights.includes('amount_fact_total')}
            onToggleHighlght={() => onToggleHighlght('amount_fact_total')}
          >
            {fields.amount_fact_total.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right border-bottom border-top">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_plan_total'}
            highlighted={highlights.includes('amount_plan_total')}
            onToggleHighlght={() => onToggleHighlght('amount_plan_total')}
          >
            {fields.amount_plan_total.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right border-bottom border-top">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_executed_total'}
            highlighted={highlights.includes('amount_executed_total')}
            onToggleHighlght={() => onToggleHighlght('amount_executed_total')}
          >
            {fields.amount_executed_total.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right border-bottom border-top">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_forecast_total'}
            highlighted={highlights.includes('amount_forecast_total')}
            onToggleHighlght={() => onToggleHighlght('amount_forecast_total')}
          >
            {fields.amount_forecast_total.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row noGutters>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'explanation'}
            highlighted={highlights.includes('explanation')}
            onToggleHighlght={() => onToggleHighlght('explanation')}
          >
            {fields.explanation.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'explanation_description'}
            highlighted={highlights.includes('explanation_description')}
            onToggleHighlght={() => onToggleHighlght('explanation_description')}
          >
            {fields.explanation_description.label}
          </TableHeaderCell>
        </Col>
        <Col className="px-1 border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'cash_cost_diff'}
            highlighted={highlights.includes('cash_cost_diff')}
            onToggleHighlght={() => onToggleHighlght('cash_cost_diff')}
          >
            {fields.cash_cost_diff.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>

);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
