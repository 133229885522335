import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

const Header = ({
  activeCol, highlights, onToggleHighlght,
}) => (
  <TableHeaderRow>
    <Col>
      <Row noGutters>
        <Col lg={6}>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'normative_act'}
            highlighted={highlights.includes('normative_act')}
            onToggleHighlght={() => onToggleHighlght('normative_act')}
          >
            Нормативний акт
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'law_item'}
            highlighted={highlights.includes('law_item')}
            onToggleHighlght={() => onToggleHighlght('law_item')}
          >
            Перелік відповідних статей (пунктів) приведеного документа
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'kekv'}
            highlighted={highlights.includes('kekv')}
            onToggleHighlght={() => onToggleHighlght('kekv')}
          >
            КЕКВ
          </TableHeaderCell>
        </Col>

        <Col lg={3}>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_limit'}
            highlighted={highlights.includes('amount_limit')}
            onToggleHighlght={() => onToggleHighlght('amount_limit')}
          >
            Граничний обсяг
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_required'}
            highlighted={highlights.includes('amount_required')}
            onToggleHighlght={() => onToggleHighlght('amount_required')}
          >
            Необхідна сума
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_unsecured'}
            highlighted={highlights.includes('amount_unsecured')}
            onToggleHighlght={() => onToggleHighlght('amount_unsecured')}
          >
            Не забезпечені видатки
          </TableHeaderCell>
        </Col>

        <Col lg={3}>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'action_description'}
            highlighted={highlights.includes('action_description')}
            onToggleHighlght={() => onToggleHighlght('action_description')}
          >
            Необхідні заходи
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
