import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';
import FiltersList from './filtersList';
import { availableFiltersPropType, getPreviousFilter, filtersPropType } from '../../hooks';

const FiltersEditor = ({
  availableFilters, filters, filtersHandlers,
}) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);

  const onActiveSelectionHandler = useCallback(
    (oname) => setActiveFilter(oname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const filtersDragEndHanlder = useCallback(
    ({ name, type }, oname) => {
      if (type === itemTypes.availableField) {
        filtersHandlers.addFilterHandler(name, oname);
      } else if (type === itemTypes.filter) {
        filtersHandlers.swapFilterHandler(name, oname);
      }
    },
    [filtersHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.filter) {
        filtersHandlers.removeFilterHandler(name);
        if (activeFilter === name) setActiveFilter(null);
      }
    },
    [activeFilter, filtersHandlers],
  );

  return (
    <Row>
      <Col _lg={5}>
        <AvailableFieldList
          fields={availableFilters}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={filtersHandlers.addFilterHandler}
          label="Доступні поля"
          onDragEnd={avaDragEndHanlder}
        />
      </Col>
      <Col lg={0.1}>
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => filtersHandlers.clearAllFiltersHandler()}
            disabled={!filters.length}
          />
          <LeftButton
            onClick={() => {
              filtersHandlers.removeFilterHandler(activeFilter);
              setActiveFilter(getPreviousFilter(filters, activeFilter));
            }}
            disabled={activeFilter === null}
          />
          <RightButton
            onClick={() => filtersHandlers.addFilterHandler(activeAvaField)}
            disabled={!activeAvaField}
          />
        </ButtonGroup>
      </Col>
      <Col _lg={6}>
        <FiltersList
          filters={filters}
          activeFilter={activeFilter}
          onClick={onActiveSelectionHandler}
          onDblClick={filtersHandlers.removeFilterHandler}
          onDragEnd={filtersDragEndHanlder}
          changeFilterUse={filtersHandlers.changeFilterUse}
          changeFilterOperation={filtersHandlers.changeFilterOperation}
          changeFilterValue={filtersHandlers.changeFilterValue}
        />
      </Col>
    </Row>
  );
};

FiltersEditor.propTypes = {
  filters: filtersPropType.isRequired,
  availableFilters: availableFiltersPropType.isRequired,
  filtersHandlers: PropTypes.shape({
    addFilterHandler: PropTypes.func,
    removeFilterHandler: PropTypes.func,
    swapFilterHandler: PropTypes.func,
    clearAllFiltersHandler: PropTypes.func,
    changeFilterUse: PropTypes.func,
    changeFilterOperation: PropTypes.func,
    changeFilterAdd: PropTypes.func,
    changeFilterValue: PropTypes.func,
    changeFilterRemove: PropTypes.func,
  }).isRequired,
};

export default FiltersEditor;
