import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer,
  StyledCellItemHeader,
  StyledTableHeaderCell,
} from '../../../../../basicEditor';
import { fieldsPropTypes } from './propTypes';

const Header = ({ fields, on3Years }) => (
  <tr>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.kdb.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.first_year_zf.label}
        </StyledCellItemHeader>
        {on3Years && (
          <>
            <StyledCellItemHeader>
              {fields.second_year_zf.label}
            </StyledCellItemHeader>
            <StyledCellItemHeader>
              {fields.third_year_zf.label}
            </StyledCellItemHeader>
          </>
        )}
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.first_year_sf.label}
        </StyledCellItemHeader>
        {on3Years && (
          <>
            <StyledCellItemHeader>
              {fields.second_year_sf.label}
            </StyledCellItemHeader>
            <StyledCellItemHeader>
              {fields.third_year_sf.label}
            </StyledCellItemHeader>
          </>
        )}
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.first_year_br.label}
        </StyledCellItemHeader>
        {on3Years && (
          <>
            <StyledCellItemHeader>
              {fields.second_year_br.label}
            </StyledCellItemHeader>
            <StyledCellItemHeader>
              {fields.third_year_br.label}
            </StyledCellItemHeader>
          </>
        )}
      </StyledCellContainer>
    </StyledTableHeaderCell>
  </tr>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  on3Years: PropTypes.bool.isRequired,
};

export default Header;
