import React from 'react';
import PropTypes from 'prop-types';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const ExpandButton = ({ content, onClick, disabled }) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faExpandArrowsAlt}
  />
);

ExpandButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ExpandButton.defaultProps = {
  onClick: () => null,
  content: 'Розгорнути',
  disabled: false,
};

export default ExpandButton;
