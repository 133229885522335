import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, Row, Col, Container,
} from 'react-bootstrap';
import { AddRowButton, RemoveRowButton } from '../../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../../components/bootStrap';
import { calcPropType } from '../hooks';
import CalcItem from './item';
import CalcProps from './props';

const CalcsPage = ({
  calcs, activeCalc, onRenameCalc, onChangeCalc,
  onAddCalc, onRemoveCalc, onSetActiveCalc, allowedCtypes, fields,
}) => {
  const displayCalcs = useMemo(
    () => calcs.map((t, itemKey) => ({
      ...t,
      itemKey,
    })), [calcs],
  );
  const cValue = useMemo(
    () => calcs.filter((c) => c.name === activeCalc)
      .reduce((R, f) => f, {}),
    [activeCalc, calcs],
  );

  const displayAlowedCtypes = useMemo(
    () => allowedCtypes.map((value) => ({
      value,
      display_name: value,
    })),
    [allowedCtypes],
  );

  const allowedFields = useMemo(
    () => fields.map((f) => ({
      value: f.name,
      display_name: f.name,
    })),
    [fields],
  );

  return (
    <Container fluid>
      <Row>
        <Col lg={3} md={6} sm={12} className="p-3">
          <CommandPanel>
            <AddRowButton
              title="Додати поле"
              content=""
              onClick={() => onAddCalc(Math.random()
                .toString(36))}
            />
            <RemoveRowButton
              title="Видалити поле"
              content=""
              onClick={() => onRemoveCalc(activeCalc)}
              disabled={!activeCalc}
            />
          </CommandPanel>
          <ListGroup className="containerGroup">
            {displayCalcs.map((calc) => (
              <CalcItem
                key={calc.itemKey}
                active={calc.name === activeCalc}
                onClick={() => onSetActiveCalc(calc.name)}
                field={calc}
                onRenameField={onRenameCalc}
              />
            ))}
          </ListGroup>
        </Col>
        <Col className="containerCol">
          <CalcProps
            onChange={(v) => onChangeCalc(activeCalc, v)}
            value={cValue}
            readOnly={!('name' in cValue)}
            allowedCtypes={displayAlowedCtypes}
            fields={allowedFields}
          />
        </Col>
      </Row>
    </Container>
  );
};

CalcsPage.propTypes = {
  calcs: PropTypes.arrayOf(calcPropType).isRequired,
  activeCalc: PropTypes.string,
  onRenameCalc: PropTypes.func.isRequired,
  onChangeCalc: PropTypes.func.isRequired,
  onAddCalc: PropTypes.func.isRequired,
  onRemoveCalc: PropTypes.func.isRequired,
  onSetActiveCalc: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CalcsPage.defaultProps = {
  activeCalc: null,
  allowedCtypes: [],
};

export default memo(CalcsPage);
