import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
} from 'react-bootstrap';
import { TableStingInput } from '../../../basicEditor/tableControls';
import { metaFieldPropType } from '../hooks/metafields';

const MetaFieldItem = ({
  metaField, active, onRenameField, onClick,
}) => (

  <ListGroupItem
    active={active}
    onClick={onClick}
  >
    <TableStingInput
      controlId={`input-metafield-${metaField.itemKey}`}
      value={metaField.name}
      onChange={(e, v) => onRenameField(metaField.name, v)}
    />
  </ListGroupItem>
);

MetaFieldItem.propTypes = {
  metaField: metaFieldPropType.isRequired,
  active: PropTypes.bool,
  onRenameField: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

MetaFieldItem.defaultProps = {
  active: false,
};

export default MetaFieldItem;
