import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCP = Styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  // border: 1px solid rgba(34,36,38,.15);
  border-radius: .3rem;
  padding: 5px;
  margin-bottom: 5px;
`;

export const CommandPanel = ({ children, ...props }) => (
  <StyledCP {...props}>
    {children}
  </StyledCP>
);

CommandPanel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any)
};

CommandPanel.defaultProps = {
  children: null
};

export const StyledRightGroupCP = Styled.div`
  margin: 0 0 0 auto;
`;

export default CommandPanel;
