import {
  useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router';
import { WinManagerContext } from '../../providers/winManagerProvider';

const LinkComp = ({
  component, title,
}) => {
  const {
    addComponentToWindowsManager, linkComponents, currentURL, switchWindow,
  } = useContext(WinManagerContext);
  const url = useLocation();
  const routerProps = useRef({});
  routerProps.current = useParams();

  useEffect(
    () => {
      if (linkComponents.filter((l) => l.url === url.pathname).length) {
        if (currentURL !== url.pathname) {
          switchWindow(url.pathname);
        }
      }
    },
    [currentURL, linkComponents, switchWindow, url.pathname],
  );
  useEffect(
    () => {
      if (!linkComponents.filter((l) => l.url === url.pathname).length) {
        addComponentToWindowsManager(
          component, url.pathname, title, { ...routerProps.current, url: url.pathname },
        );
      }
    },
    [addComponentToWindowsManager, component, linkComponents, title, url.pathname],
  );
  return null;
};

LinkComp.propTypes = {
  component: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  props: PropTypes.shape({}),
};

LinkComp.defaultProps = {
  props: {
  },
};

export default LinkComp;
