import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TableRowNoRecords = ({ colCount, readOnly, canAdd }) => (
  <tr>
    <td
      colSpan={colCount}
    >
      {readOnly ? (
        <Alert variant="warning">Немає записів. Зверніться до адміністратора системи.</Alert>
      ) : (
        <>
          <Alert variant="warning">
          Немає записів.
            {' '}
            {canAdd && (
              <>
               Натисніть &nbsp;
                <Link to="create">тут</Link>
              &nbsp; для створення нового.
              </>
            )}
          </Alert>
        </>
      )}
    </td>
  </tr>
);

TableRowNoRecords.propTypes = {
  colCount: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  canAdd: PropTypes.bool,
};

TableRowNoRecords.defaultProps = {
  readOnly: false,
  canAdd: true,
};

export default TableRowNoRecords;
