import React from 'react';
import Editor from './defaultEditor';

const ExchangeSocialPayment = () => (
  <Editor
    title="Завантажити виписку соціальних виплат"
    backendUrl="/api/references/socialpayment/importdbf/"
  />
);

export default ExchangeSocialPayment;
