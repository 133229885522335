import React from 'react';
import styled from 'styled-components';
import Routes from './routes';
import WindowsManager from '../windowsManager';
import ManagerLink from '../windowsManager/managerLink';

const StyledBody = styled.div`
  grid-area: body;  
  padding: 0 15px;
  height: calc(100vh - 60px - 35px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: white;
  justify-content: space-between;
`;

const Body = () => (
  <StyledBody id="docBody">
    <div>
      <Routes />
      <WindowsManager />
    </div>
    <ManagerLink />
  </StyledBody>
);
export default Body;
