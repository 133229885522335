import React from 'react';
import Editor from './defaultEditor';

const RevenueExchange = () => (
  <Editor
    title="Завантажити фактичні доходи"
    backendUrl="/api/cityplans/revenuedbf/"
  />
);

export default RevenueExchange;
