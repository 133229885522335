import React from 'react';
import PropTypes from 'prop-types';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const OneRightButton = ({
  content, onClick, disabled, title,
}) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faAngleRight}
    title={title}
  />
);

OneRightButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

OneRightButton.defaultProps = {
  onClick: () => null,
  content: '',
  disabled: false,
  title: '',
};

export default OneRightButton;
