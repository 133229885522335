import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer, StyledCellItem, StyledTableRow, StyledTD,
} from '../../../../../basicEditor';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';

const Row = ({
  rowIndex, active, selected, onActivateRow, onChangeRow, row, fields, errors, quartal,
}) => {
  const targetprogramProps = tableHooks.useItemInputProps('targetprogram', row, fields, errors, onChangeRow, rowIndex);
  const kbp7Props = tableHooks.useItemInputProps('kbp7', row, fields, errors, onChangeRow, rowIndex);
  const amountZfqProps = tableHooks.useNumberInputProps('amount_zfq', row, fields, errors, onChangeRow, rowIndex);
  const amountSfqProps = tableHooks.useNumberInputProps('amount_sfq', row, fields, errors, onChangeRow, rowIndex);
  const amountSf9mProps = tableHooks.useNumberInputProps('amount_sf9m', row, fields, errors, onChangeRow, rowIndex);
  const amountSfProps = tableHooks.useNumberInputProps('amount_sf0', row, fields, errors, onChangeRow, rowIndex);
  const amountZfProps = tableHooks.useNumberInputProps('amount_zf0', row, fields, errors, onChangeRow, rowIndex);
  const amountTotalProps = tableHooks.useNumberInputProps('amount_total0', row, fields, errors, onChangeRow, rowIndex);

  return (
    <StyledTableRow
      active={active}
      selected={selected}
      onClick={(e) => onActivateRow(e, rowIndex)}
    >
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...targetprogramProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.ItemPicker {...kbp7Props} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      {quartal
      && (
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...amountZfqProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountSfqProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
      )}

      {quartal
      && (
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...amountSf9mProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
      )}

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...amountZfProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountSfProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...amountTotalProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

    </StyledTableRow>
  );
};

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  quartal: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  active: false,
  selected: false,
  errors: {},
};

export default memo(Row);
