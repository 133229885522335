import React, {
  useState, useCallback, useMemo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card, Container,
} from 'react-bootstrap';
import {
  faClipboardList, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FileUploadDropZone, FileUploadButton } from '../../../components/bootStrap';
import { EditorNumberInput, EditorCheckboxInput } from '../../basicEditor/editorControls';
import { CPButton } from '../../../components/bootStrap/buttons/styles';
import { AppContext } from '../../../providers/authProvider';
import api, { NetworkException } from '../../../api/req';
import { checkTask } from '../../../api/checktask';
import { FILE_STATES } from './consts';
import File from './file';

// TODO: Реалиация последовательной обработки + отслеживание механизма задач
// TODO: Фиьтр файлов по маске
// TODO: отображение результатов задачи

const Editor = ({ title, backendUrl }) => {
  const [data, setData] = useState(() => {
    const today = new Date();
    return ({
      autoexecute: true,
      year: today.getFullYear(),
      curryear: today.getFullYear(),
    });
  });

  const [files, setFiles] = useState({});

  const onFilesAdd = useCallback(
    (e, v) => {
      setFiles(Object.keys(v).reduce((R, fName) => ({
        ...R,
        [fName]: {
          content: v[fName],
          state: FILE_STATES.prepared,
          result: null,
        },
      }), {})); // oldF - Закоментировано, чтобы после загрузки файлы очищались
    },
    [],
  );

  const [isProcessing, setIsProcessing] = useState(false);

  const displayFiles = useMemo(
    () => Object.keys(files).map((fName) => ({ name: fName, ...files[fName] })),
    [files],
  );

  const { auth } = useContext(AppContext);

  const processHandler = useCallback(
    () => {
      let fls = Object.keys(files);

      const getTaskId = async (fileName) => {
        const r = await api.post(backendUrl, auth, {
          auto_execute: data.autoexecute,
          YEAR: data.year,
          file: {
            name: fileName,
            content: files[fileName].content,
          },
        });
        if (!r.ok && r.status !== 400) throw new NetworkException(`${r.status} ${r.statusText}`);
        const d = await r.json();
        if (r.status === 400) {
          throw new NetworkException(`${d.error_data.reduce((R, ed) => [...R, ...ed.messages], []).join(',\n')}`);
        }

        return d.task_id;
      };

      const processFile = () => {
        const nextFile = (fname) => {
          fls = fls.filter((n) => n !== fname);
          if (fls.length) {
            processFile();
          } else {
            setIsProcessing(false);
          }
        };

        const onErr = (fname) => (ed) => {
          setFiles((of) => ({
            ...of,
            [fname]: {
              ...of[fname],
              state: FILE_STATES.errored,
              result: ed.error,
            },
          }));
          nextFile(fname);
        };

        const onDone = (fname) => (d) => {
          if (d && d.result && d.result.errors) {
            const errs = d.result.errors.reduce((R, e) => {
              if (!e) return R;
              if (e.constructor.name === 'Object') {
                return [
                  R,
                  ...Object.keys(e).map((key) => `${key}: ${e[key]}`),
                ]
                  .filter((r) => r)
                  .join(', ');
              }
              if (Array.isArray(e)) {
                return [
                  R,
                  ...e.map((ee) => `${ee}`),
                ]
                  .filter((r) => r)
                  .join(', ');
              }
              return `${R} ${e}`;
            }, '');
            return onErr(fname)({ error: errs });
          }
          setFiles((of) => ({
            ...of,
            [fname]: {
              ...of[fname],
              state: FILE_STATES.done,
              result: d,
            },
          }));
          return nextFile(fname);
        };

        if (fls.length) {
          const fname = fls[0];
          setFiles((of) => ({ ...of, [fname]: { ...of[fname], state: FILE_STATES.pending } }));
          getTaskId(fname)
            .then((taskId) => checkTask(taskId, auth, onDone(fname), onErr(fname)))
            .catch((e) => onErr(fname)({ error: e.message }));
        }
      };

      setIsProcessing(true);
      processFile();
    },
    [auth, backendUrl, data.autoexecute, data.year, files],
  );

  return (
    <Card>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <EditorNumberInput
          controlId="year"
          label="Рік"
          required
          value={data.year}
          onChange={(e, v) => setData((d) => ({ ...d, year: v }))}
          minValue={data.curryear - 3}
          maxValue={data.curryear + 1}
          disabled={isProcessing}
        />
        <FileUploadDropZone
          title={title}
          readAs="dataURL"
          multiple
          onChange={onFilesAdd}
          disabled={isProcessing}
        >
          <div>
            Перетяніть файли до блакитної зони, або оберить іх нажав на кнопку &nbsp;
            <FileUploadButton
              content="Обрати файли"
              icon={faUpload}
              onChange={onFilesAdd}
              readAs="dataURL"
              multiple
              title={title}
              disabled={isProcessing}
            />
          </div>
          <Container>
            {displayFiles.map((f) => (
              <File
                key={f.name}
                name={f.name}
                state={f.state}
                result={f.result}
              />
            ))}
          </Container>
        </FileUploadDropZone>
        <CPButton
          title="Відправити на обробку"
          icon={faClipboardList}
          content="Відправити на обробку"
          disabled={isProcessing}
          onClick={processHandler}
        />
      </Card.Body>
      <Card.Footer>
        <EditorCheckboxInput
          controlId="autoexecute"
          label="Автопроведення"
          description="Знимати цю галку не рекомендується!"
          value={data.autoexecute}
          onChange={(e, v) => setData((d) => ({ ...d, autoexecute: v }))}
          disable={isProcessing}
        />
      </Card.Footer>
    </Card>
  );
};

Editor.propTypes = {
  title: PropTypes.string.isRequired,
  backendUrl: PropTypes.string.isRequired,
};

export default Editor;
