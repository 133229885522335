import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const kekvProps = tableHooks.useItemInputProps(
    'kekv', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountTotal0Props = tableHooks.useNumberInputProps(
    'amount_total0', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountTotal1Props = tableHooks.useNumberInputProps(
    'amount_total1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountTotal2Props = tableHooks.useNumberInputProps(
    'amount_total2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const reason1Props = tableHooks.useStringInputProps(
    'reason1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const reason2Props = tableHooks.useStringInputProps(
    'reason2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  return (
    <Col>
      <Row noGutters>
        <Col>
          <TabelRowCell column="kekv" highlighted={highlights.includes('kekv')}>
            <TableControls.ItemPicker {...kekvProps} />
          </TabelRowCell>
        </Col>
      </Row>

      <Row noGutters>
        <Col>
          <TabelRowCell column="amount_total0" highlighted={highlights.includes('amount_total0')}>
            <TableControls.NumberInput {...amountTotal0Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_total1" highlighted={highlights.includes('amount_total1')}>
            <TableControls.NumberInput {...amountTotal1Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_total2" highlighted={highlights.includes('amount_total2')}>
            <TableControls.NumberInput {...amountTotal2Props} />
          </TabelRowCell>
        </Col>
      </Row>

      <Row noGutters>
        <Col>
          <TabelRowCell column="reason1" highlighted={highlights.includes('reason1')}>
            <TableControls.TextInput {...reason1Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="reason2" highlighted={highlights.includes('reason2')}>
            <TableControls.TextInput {...reason2Props} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
