import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal } from 'react-bootstrap';
import { HeaderText } from '../../components/Styled/Forms';
import { Loader } from '../../components/bootStrap';

const EditorContainer = ({
  isNew,
  name,
  repr,
  children,
  err,
  isLoading,
  onClearErrors,
  nonFieldErrors,
  onClearNonFieldErrors,
  changed,
  onClose,
}) => {
  const [opened, setOpened] = useState();

  const errView = (msg) => msg.replace('{"detail":"', '').replace('"}', '');

  return (
    <div className="flex flex-grow-1 flex-shrink-1">
      <div className="d-flex align-items-center">
        <HeaderText>
          {isNew ? `Створення: ${name}` : `${name} ${repr}`}
        </HeaderText>
        {onClose && (
          <button
            type="button"
            className="close ml-auto"
            aria-label="Close"
            onClick={() => (changed ? setOpened(true) : onClose())}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      {err && (
        <Alert dismissible={onClearErrors} onClose={onClearErrors} variant="danger">
          <Alert.Heading>
            {errView(err)}
          </Alert.Heading>
        </Alert>
      )}
      {nonFieldErrors && (

        <Alert dismissible={onClearNonFieldErrors} onClose={onClearNonFieldErrors} variant="danger">
          <Alert.Heading>
            Помилки заповнення даних:
          </Alert.Heading>
          <ul>
            {nonFieldErrors.map((nfe, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i}>{nfe.message || nfe}</li>
            ))}
          </ul>
        </Alert>
      )}
      {isLoading && (
        <Loader text="Завантаження" />
      )}
      {children}
      <Modal show={opened} onHide={() => setOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Вийти без запису?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Мабудь ви забули зберегти зміни. Вийти без запису змін?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onClose}>Так</Button>
          <Button variant="success" onClick={() => setOpened(false)}>Ні</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

EditorContainer.propTypes = {
  isNew: PropTypes.bool.isRequired,
  name: PropTypes.string,
  repr: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.symbol,
    PropTypes.arrayOf(PropTypes.symbol),
  ]).isRequired,
  err: PropTypes.string,
  nonFieldErrors: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      header: PropTypes.string,
      message: PropTypes.string,
    }),
    PropTypes.string,
  ])),
  isLoading: PropTypes.bool,
  onClearErrors: PropTypes.func,
  onClearNonFieldErrors: PropTypes.func,
  changed: PropTypes.bool,
  onClose: PropTypes.func,
};

EditorContainer.defaultProps = {
  repr: '',
  err: null,
  nonFieldErrors: null,
  isLoading: false,
  onClearErrors: null,
  onClearNonFieldErrors: null,
  name: '🍵',
  changed: null,
  onClose: null,
};

export default EditorContainer;
