import withErrors from './withErrors';
import StringInput from '../stringInput';
import NumberInput from '../numberInput';
import DateInput from '../dates';
import Checkbox from '../checkbox';
import Selector from '../selector';
import TextInput from '../textInput';
import { ItemField, ItemPicker } from '../itemField';

export default withErrors;

const ESelector = withErrors(Selector);
ESelector.propTypes = Selector.propTypes;
ESelector.defaultProps = Selector.defaultProps;

const EStringInput = withErrors(StringInput);
EStringInput.propTypes = StringInput.propTypes;
EStringInput.defaultProps = StringInput.defaultProps;

const EDateInput = withErrors(DateInput);
EDateInput.propTypes = DateInput.propTypes;
EDateInput.defaultProps = DateInput.defaultProps;

const ECheckbox = withErrors(Checkbox);
ECheckbox.propTypes = Checkbox.propTypes;
ECheckbox.defaultProps = Checkbox.defaultProps;

const EItemField = withErrors(ItemField);
EItemField.propTypes = ItemField.propTypes;
EItemField.defaultProps = ItemField.defaultProps;

const EItemPicker = withErrors(ItemPicker);
EItemPicker.propTypes = ItemPicker.propTypes;
EItemPicker.defaultProps = ItemPicker.defaultProps;

const ENumerInput = withErrors(NumberInput);
ENumerInput.propTypes = NumberInput.propTypes;
ENumerInput.defaultProps = NumberInput.defaultProps;

const ETextInput = withErrors(TextInput);
ETextInput.propTypes = TextInput.propTypes;
ETextInput.defaultProps = TextInput.defaultProps;

export {
  EStringInput,
  EDateInput,
  ECheckbox,
  EItemField,
  EItemPicker,
  ENumerInput,
  ESelector,
  ETextInput,
};
