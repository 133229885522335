import React from 'react';
import PropTypes from 'prop-types';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const ShowDeletedToggler = ({
  disabled, value, onChange, content,
}) => (
  <CPButton
    onClick={(e) => onChange(e, !value)}
    title={content}
    disabled={disabled}
    variant={value ? 'outline-info' : 'info'}
    icon={value ? faEye : faEyeSlash}
  />
);

ShowDeletedToggler.propTypes = {
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  content: PropTypes.string,
};

ShowDeletedToggler.defaultProps = {
  disabled: false,
  onChange: null,
  content: 'Показувати видаленні',
};

export default ShowDeletedToggler;
