import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, {
  CatCommandPanel,
  getFieldsPropTypes,
  getErrorsPropTypes,
} from '../../../basicEditor';
import { HeaderText } from '../../../../components/Styled/Forms';
import { SignvariantRows } from './tp';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';

const Editor = ({
  data, options, fields, errors, isNew, isReadOnly, isModified, onSave, onClose, onChange,
}) => {
  const nameProps = editorHooks.useStringInputProps('name', data, fields, errors, onChange);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, errors, onChange);
  return (
    <div>
      <HeaderText>{isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}</HeaderText>
      <CatCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        canDeleted={false}
        onSave={() => onSave()}
        onClose={onClose}
      />
      <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <Container fluid style={{ padding: '0' }}>
              <Row>
                <Col>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
                <Col>
                  <EditorControls.ItemPicker {...disposerProps} modelType="cat" modelName="disposers" />
                </Col>
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>
      <div style={{ marginTop: '15px' }} />
      <LabelTab defaultActiveKey="table" id="uncontrolled-tab-example1">
        <Tab eventKey="table" title={fields.refauthoritysignvariantrow_set.label}>
          <TabContainer>
            <SignvariantRows
              data={data.refauthoritysignvariantrow_set}
              fields={fields.refauthoritysignvariantrow_set.child.children}
              readOnly={fields.refauthoritysignvariantrow_set.read_only}
              errors={errors.refauthoritysignvariantrow_set}
              onChange={(set) => onChange({ refauthoritysignvariantrow_set: set })}
            />
          </TabContainer>
        </Tab>
      </LabelTab>
    </div>
  );
};

const dataPropTypes = {
  id: PropTypes.number,
  repr: PropTypes.string,
  name: PropTypes.string,
  disposer: PropTypes.shape({ id: PropTypes.number, repr: PropTypes.string }),

  settings: PropTypes.shape({}),
  history: PropTypes.shape({}),
};

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,

  }).isRequired,
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  fields: PropTypes.shape(getFieldsPropTypes(dataPropTypes)).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.cat.authoritysignvariant.backendURL)(Editor);
