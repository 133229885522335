import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, {
  DocCommandPanel,
  getFieldsPropTypes,
  getErrorsPropTypes,
} from '../../../basicEditor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { stdDocDataPropTypes, foreignPropType } from '../../common';
import { HeaderText } from '../../../../components/Styled/Forms';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import {
  UsingBudgetFunds,
  Task,
  Indicators,
  RegionalTargetProgram,
  ProgramFinancingSource,
  PublicPolicyTarget,
} from './tp';
import { FlexContainer, TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import { DocGridWithNColumns } from '../../../../components/Styled/grids/editorGrid';

const Editor = ({
  data, options, isNew, isReadOnly, isModified, onSave, onClose,
  onExecute, onCancelExecute, fields, errors, onChange,
}) => {
  const numberProps = editorHooks.useStringInputProps('number', data, fields, errors, onChange);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, errors, onChange);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, errors, onChange);
  const quartalProps = editorHooks.useCheckboxInputProps('quartal', data, fields, errors, onChange);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, errors, onChange);
  const kbpProps = editorHooks.useItemInputProps('kbp', data, fields, errors, onChange);
  const budgetrequestProps = editorHooks.useItemInputProps('budgetrequest', data, fields, errors, onChange);
  const approvedProps = editorHooks.useCheckboxInputProps('approved', data, fields, errors, onChange);
  const isGoalProgramByYearProps = editorHooks.useCheckboxInputProps('is_goal_program_by_year', data, fields, errors, onChange);

  const kpkvProps = editorHooks.useStringInputProps('kpkv', data, fields, errors, onChange);
  const authoritySignVariantProps = editorHooks.useSelectorInputProps('authority_sign_variant', data, fields, errors, onChange);
  const lawRealiseBasisProps = editorHooks.useStringInputProps('law_realise_basis', data, fields, errors, onChange);
  const goalProps = editorHooks.useStringInputProps('goal', data, fields, errors, onChange);
  const imperative1Props = editorHooks.useItemInputProps('imperative1', data, fields, errors, onChange);
  const imperative1strProps = editorHooks.useStringInputProps('imperative1str', data, fields, errors, onChange);
  const imperative2Props = editorHooks.useItemInputProps('imperative2', data, fields, errors, onChange);
  const imperative2strProps = editorHooks.useStringInputProps('imperative2str', data, fields, errors, onChange);

  const noteProps = editorHooks.useStringInputProps('note', data, fields, errors, onChange);

  lawRealiseBasisProps.description = '';
  return (
    <FlexContainer>
      <HeaderText>{isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}</HeaderText>
      <DocCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        onSave={() => onSave()}
        onClose={onClose}
        executed={data.executed}
        onExecute={() => onExecute()}
        onCancelExecute={() => onCancelExecute()}
      />
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.ItemPicker {...kbpProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...disposerProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...budgetrequestProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput {...approvedProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...quartalProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...isGoalProgramByYearProps} />
              </Col>
            </Row>

          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="passportbpusingbudgetfunds" id="uncontrolled-tab-example">
          <Tab eventKey="passportbppublicpolicytarget" title="Цілі державної політики">
            <TabContainer>
              <PublicPolicyTarget
                data={data.passportbppublicpolicytarget_set}
                fields={fields.passportbppublicpolicytarget_set.child.children}
                // readOnly={fields.passportbppublicpolicytarget_set.read_only}
                errors={errors.passportbppublicpolicytarget_set}
                onChange={(set) => onChange({ passportbppublicpolicytarget_set: set })}
              />
            </TabContainer>
          </Tab>

          <Tab eventKey="passportbpusingbudgetfunds" title="Напрями використання коштів">
            <TabContainer>
              <UsingBudgetFunds
                data={data.passportbpusingbudgetfundsrow_set}
                fields={fields.passportbpusingbudgetfundsrow_set.child.children}
                // readOnly={fields.passportbpusingbudgetfundsrow_set.read_only}
                errors={errors.passportbpusingbudgetfundsrow_set}
                onChange={(set) => onChange({ passportbpusingbudgetfundsrow_set: set })}
              />
            </TabContainer>
          </Tab>

          <Tab eventKey="passportbproblem" title="Завдання">
            <TabContainer>
              <Task
                data={data.passportbpproblemrow_set}
                fields={fields.passportbpproblemrow_set.child.children}
                // readOnly={fields.passportbpproblemrow_set.read_only}
                errors={errors.passportbpproblemrow_set}
                onChange={(set) => onChange({ passportbpproblemrow_set: set })}
              />
            </TabContainer>
          </Tab>

          <Tab eventKey="passportbpindicator" title="Показники">
            <TabContainer>
              <Indicators
                data={data.passportbpindicatorrow_set}
                fields={fields.passportbpindicatorrow_set.child.children}
                // readOnly={fields.passportbpindicatorrow_set.read_only}
                errors={errors.passportbpindicatorrow_set}
                onChange={(set) => onChange({ passportbpindicatorrow_set: set })}
              />
            </TabContainer>
          </Tab>

          <Tab eventKey="passportbpregionaltargetprogram" title="Регіональні цільові програми">
            <TabContainer>
              <RegionalTargetProgram
                data={data.passportbpregionaltargetprogramrow_set}
                fields={fields.passportbpregionaltargetprogramrow_set.child.children}
                // readOnly={fields.passportbpregionaltargetprogramrow_set.read_only}
                errors={errors.passportbpregionaltargetprogramrow_set}
                onChange={(set) => onChange({ passportbpregionaltargetprogramrow_set: set })}
                quartal={data.quartal}
              />
            </TabContainer>
          </Tab>

          <Tab eventKey="passportbpprogramfinancingsource" title="Джерела фінансування інвестиційних програм">
            <TabContainer>
              <ProgramFinancingSource
                data={data.passportbpprogramfinancingsourcerow_set}
                fields={fields.passportbpprogramfinancingsourcerow_set.child.children}
                // readOnly={fields.passportbpprogramfinancingsourcerow_set.read_only}
                errors={errors.passportbpprogramfinancingsourcerow_set}
                onChange={(set) => onChange({ passportbpprogramfinancingsourcerow_set: set })}
                isGoalProgramByYear={data.is_goal_program_by_year}
              />
            </TabContainer>
          </Tab>

          <Tab eventKey="grounds" title="Підстави">
            <TabContainer>
              <DocGridWithNColumns columns={2}>
                <EditorControls.StringInput {...kpkvProps} />
                <EditorControls.SelectorInput {...authoritySignVariantProps} />
              </DocGridWithNColumns>

              <h3>Мета та законодавчі підстави бюдж.програми</h3>
              <DocGridWithNColumns columns={2}>
                <EditorControls.TextInput {...lawRealiseBasisProps} />
                <EditorControls.TextInput {...goalProps} />
              </DocGridWithNColumns>

              <h3>Накази про затвердження бюдж.програми</h3>
              <DocGridWithNColumns columns={2}>
                <EditorControls.ItemPicker {...imperative1Props} />
                <EditorControls.ItemPicker {...imperative2Props} />
                <EditorControls.TextInput {...imperative1strProps} />
                <EditorControls.TextInput {...imperative2strProps} />
              </DocGridWithNColumns>
            </TabContainer>

          </Tab>

          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...noteProps} />
            </TabContainer>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </FlexContainer>
  );
};

const dataPropTypes = {
  ...stdDocDataPropTypes,
  disposer: foreignPropType,
  approved: PropTypes.bool,
  note: PropTypes.string,
  fond: foreignPropType,
  plan_kind: PropTypes.number,
  disposer_in_header: PropTypes.bool,
  comment: PropTypes.string, // автоматическое примечание
  comment_of_author: PropTypes.string, // Примечания пользователя
  only_year: PropTypes.bool,
  vt4br: PropTypes.bool,
  is_credit: PropTypes.bool,
  source_of_coverage: foreignPropType,
  executor: foreignPropType,
  template_doc_basis: foreignPropType,
  disable_control: PropTypes.bool,
  note_number: PropTypes.string,
};

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape(dataPropTypes).isRequired,
  fields: PropTypes.shape(getFieldsPropTypes(dataPropTypes)).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onExecute: PropTypes.func.isRequired,
  onCancelExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.doc.budgetpassport.backendURL)(Editor);
