import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const TPFooter = ({
  totals,
  activeCol,
  highlights,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col
      sm={12}
      className="px-1 text-center"
    >
      РАЗОМ
    </Col>
    <Col sm={12} className="px-1 d-flex justify-content-center justify-content-xl-start flex-sm-column flex-row flex-xl-column">
      <TableHeaderCell
        className="text-right text-sm-center w-100"
        active={activeCol === 'amount'}
        highlighted={highlights.includes('amount')}
      >
        {totals.toLocaleString('uk', moneysStingOptions)}
      </TableHeaderCell>
    </Col>
  </TableHeaderRow>
);

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.number,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
  totals: 0,
};

export default memo(TPFooter);
