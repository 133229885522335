import styled from 'styled-components';
import { Tabs } from 'react-bootstrap';

export const TPGrid = styled.div`
  display: grid;
  // height: 100vh;
  overflow: auto;
  grid-auto-rows: max-content;
  border-bottom: 1px solid #dfe0e0;
`;

const gridcell = styled.div.attrs((props) => (props.gridArea ? { style: { gridArea: props.gridArea } } : {}))`
  border: 1px solid rgba(34,36,38,.15);
`;

export const TPGridCell = styled(gridcell)`

`;

export const TPGridFooter = styled(gridcell)`
`;

export const TPGridHeader = styled(gridcell)`
  font-weight: 700;
  font-size: 0.95em;
  // margin-bottom: 5px;
  padding: 3px;
  background: #e5eef8;
  color: #605c5c;  
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TPGridRow = styled(gridcell)`
  background: ${({ active, selected }) => ((active || selected) ? '#AAC6E6' : 'white')};
  // border: 1px solid rgba(34,36,38,.15);
  border-radius: .3rem;
  // z-index: -1;
  // margin-bottom: 3px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
`;

export const TabContainer = styled.div`
  border: 1px solid var(--borderTab);
  border-top: none;
  padding: 10px;
`;

export const LabelTab = styled(Tabs)`
  > a{
      font-weight: 700;
      color: var(--colorTextTab) !important;
  }
`;

export const StyledCellContainer = styled.div`
 display: flex;
 flex-direction: column;
 &>:not(:last-child) {
  border-bottom: 1px solid var(--tableBorder) ;
 }
`;

export const StyledCellItem = styled.div`
  padding: 0;
`;

export const StyledCellItemHeader = styled.div`
  padding: 5px;
`;

export const StyledTableRow = styled.tr.attrs(({ active, selected }) => ({
  style: (active || selected) ? {
    background: 'var(--backgroundActiveRow)',
    color: 'var(--colorTextActiveRow)',
  } : {},
}))`
`;

export const StyledTableHeaderCell = styled.th`
  padding: 0px !important;
  vertical-align: middle !important;
  position: sticky;
  top: 20px;
  z-index: 1;
  background: var(--backgroundTableHeader);
  line-height: 1.2;
  color: var(--colorTextTableHeader);
  // border: 1px solid var(--tableBorder)!important;
`;

export const StyledTD = styled.td`
  border: 1px solid var(--tableBorder) !important;
  padding: 0px !important;
`;
