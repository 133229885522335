import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledListerTable = styled(Table)`
  table-layout: fixed;
  & thead th{
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    background: var(--backgroundTableHeader);
    border: 1px solid var(--tableBorder)!important;
    color: var(--colorTextTableHeader);
    position:sticky;
    z-index: 1;
    top: 0;
    padding-right: 1rem;
  }
  & tbody td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & thead th>svg {
    position: absolute;
    right: .5rem;
    top: calc(50% - .5em)
  }
  & tbody td.bool {
    text-align: center;
  }
  & tbody td.decimal {
    text-align: right;
  }
  & tbody tr{
    transition: all 0.1s linear 0s;
  }
  & tr.active, tr.selected {
    background: var(--backgroundActiveRow)!important;
  }
  & tbody tr.unexecuted {
    color: rgb(184, 184, 184);
  }
  & tbody tr.deleted{
    text-decoration: line-through;
  }
`;

export const StyledContainer = styled.div.attrs(({ height }) => ({
  style: { height: `${height}px` },
}))`
        width: 100%;
  overflow: auto;
`;

export const StyledTh = styled.th.attrs(({ top, width, orderable }) => ({
  style: {
    top: `${top}px`,
    ...width && { width: `${width}` },
    cursor: orderable ? 'pointer' : 'unset',
  },
}))`
`;

export const StyledToggler = styled.td.attrs(({ level }) => ({
  style: {
    paddingLeft: `${level + 1}rem`,
  },
}))`
`;
