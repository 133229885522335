import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const TPFooter = ({
  activeCol, highlights, totals,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col sm={12} className="px-1 d-flex flex-column justify-content-end">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_zf1'}
            highlighted={highlights.includes('amount_zf1')}
          >
            {totals.amount_zf1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_sf1'}
            highlighted={highlights.includes('amount_sf1')}
          >
            {totals.amount_sf1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_br_total1'}
            highlighted={highlights.includes('amount_br_total1')}
          >
            {totals.amount_br_total1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_zf2'}
            highlighted={highlights.includes('amount_zf2')}
          >
            {totals.amount_zf2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_sf2'}
            highlighted={highlights.includes('amount_sf2')}
          >
            {totals.amount_sf2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_br_total2'}
            highlighted={highlights.includes('amount_br_total2')}
          >
            {totals.amount_br_total2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_total1'}
            highlighted={highlights.includes('amount_total1')}
          >
            {totals.amount_total1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_total2'}
            highlighted={highlights.includes('amount_total2')}
          >
            {totals.amount_total2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    amount_zf1: PropTypes.number,
    amount_zf2: PropTypes.number,
    amount_br_total1: PropTypes.number,
    amount_br_total2: PropTypes.number,
    amount_sf1: PropTypes.number,
    amount_sf2: PropTypes.number,
    amount_total1: PropTypes.number,
    amount_total2: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
