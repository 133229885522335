const frontendURL = '/cat/kdb/';
const backendURL = '/api/references/refkdb/';
const name = 'Класифікація доходів (КДБ)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
