import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const TPFooter = ({
  activeCol, highlights, totals,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col sm={12} className="px-1 d-flex flex-column justify-content-end">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_zf_2'}
            highlighted={highlights.includes('amount_zf_2')}
          >
            {totals.amount_zf_2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_sf_2'}
            highlighted={highlights.includes('amount_sf_2')}
          >
            {totals.amount_sf_2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_br_total_2'}
            highlighted={highlights.includes('amount_br_total_2')}
          >
            {totals.amount_br_total_2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_zf_1'}
            highlighted={highlights.includes('amount_zf_1')}
          >
            {totals.amount_zf_1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_sf_1'}
            highlighted={highlights.includes('amount_sf_1')}
          >
            {totals.amount_sf_1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col className="border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_br_total_1'}
            highlighted={highlights.includes('amount_br_total_1')}
          >
            {totals.amount_br_total_1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_zf0'}
            highlighted={highlights.includes('amount_zf0')}
          >
            {totals.amount_zf0.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_sf0'}
            highlighted={highlights.includes('amount_sf0')}
          >
            {totals.amount_sf0.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_br_total0'}
            highlighted={highlights.includes('amount_br_total0')}
          >
            {totals.amount_br_total0.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_total_2'}
            highlighted={highlights.includes('amount_total_2')}
          >
            {totals.amount_total_2.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_total_1'}
            highlighted={highlights.includes('amount_total_1')}
          >
            {totals.amount_total_1.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_total0'}
            highlighted={highlights.includes('amount_total0')}
          >
            {totals.amount_total0.toLocaleString('uk', moneysStingOptions)}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPFooter.propTypes = {
  activeCol: PropTypes.string,
  totals: PropTypes.shape({
    amount_zf0: PropTypes.number,
    amount_zf_1: PropTypes.number,
    amount_zf_2: PropTypes.number,
    amount_br_total0: PropTypes.number,
    amount_br_total_1: PropTypes.number,
    amount_br_total_2: PropTypes.number,
    amount_sf0: PropTypes.number,
    amount_sf_1: PropTypes.number,
    amount_sf_2: PropTypes.number,
    amount_total0: PropTypes.number,
    amount_total_1: PropTypes.number,
    amount_total_2: PropTypes.number,
  }).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TPFooter.defaultProps = {
  activeCol: '',
};

export default memo(TPFooter);
