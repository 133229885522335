import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Overlay } from 'react-bootstrap';
import { ErroredTooltip, RequiredLabel } from '../../../components/bootStrap';
import { getDisplayName } from '../../../api/utils';

const withEditorControl = (WrappedControl) => {
  const EditorInput = ({
    controlId, label, required, errors, description, ...restProps
  }) => {
    const controlRef = useRef(null);
    return (
      <Form.Group>
        {label && (
          <Form.Label>
            {label}
            {required && <RequiredLabel /> }
          </Form.Label>
        )}
        <WrappedControl
          {...restProps}
          id={controlId}
          ref={controlRef}
          errored={errors && !!errors.length}
          required={required}
        />
        <Overlay target={controlRef.current} show={!!errors} placement="auto">
          {({ show, ...props }) => (
            <ErroredTooltip id={`error-${controlId}`} {...props}>
              {String(errors)}
            </ErroredTooltip>
          )}
        </Overlay>
        {description && (
          <Form.Text className="text-muted">
            {description}
          </Form.Text>
        )}
      </Form.Group>
    );
  };

  EditorInput.displayName = `${getDisplayName(WrappedControl)} wrapped by withEditorControl`;

  EditorInput.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
  };

  EditorInput.defaultProps = {
    label: null,
    required: false,
    errors: null,
    description: null,
  };
  return memo(EditorInput);
};

export default withEditorControl;
