import React from 'react';
import {
  InputGroup, DropdownButton, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { listOfTypes } from './consts';

const Type = ({ type, setType }) => (
  <InputGroup className="mb-3">
    <DropdownButton
      as={InputGroup.Prepend}
      variant="outline-secondary"
      title="Тип періоду"
      id="input-group-dropdown-1"
    >
      {listOfTypes && listOfTypes.map((el) => <Dropdown.Item key={el.id} onClick={() => setType(el)}>{el.name}</Dropdown.Item>)}
    </DropdownButton>
    <InputGroup.Text>{type.name || 'Оберіть тип'}</InputGroup.Text>
  </InputGroup>
);

Type.propTypes = {
  type: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  setType: PropTypes.func.isRequired,
};

Type.defaultProps = {
  type: {},
};


export default Type;
