import React from 'react';
import PropTypes from 'prop-types';
import { StyledCommandPanelButton, StyledImg } from './styles';
import { ExpandIcon } from '../../../icons';

export const ExpandButton = ({ content, onClick, disabled }) => (
  <StyledCommandPanelButton
    style={{ marginRight: '0' }}
    onClick={onClick}
    disabled={disabled}
    title={content}
  >
    <StyledImg style={{ width: '20px' }} src={ExpandIcon} alt={content} />
  </StyledCommandPanelButton>
);

ExpandButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ExpandButton.defaultProps = {
  onClick: () => null,
  content: 'Розгорнути',
  disabled: false,
};

export default ExpandButton;
