import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const TPFooter = ({
  totals,
}) => (
  <TableHeaderRow noGutters className="px-1 col-lg-12 col-md-12 text-right w-100">

    <TableHeaderCell
      className="col-lg-3 col-md-8"
      active
      highlighted={false}
    >
      Всього:
    </TableHeaderCell>
    <TableHeaderCell
      className="col-lg-3 col-md-4"
      active
      highlighted={false}
    >
      {totals.bfn_amount.toFixed(2)}
    </TableHeaderCell>
    <TableHeaderCell
      className="col-lg-2 col-md-3"
      active
      highlighted={false}
    >
      {totals.salary.toFixed(2)}
    </TableHeaderCell>
    <TableHeaderCell
      className="col-lg-1 col-md-3"
      active
      highlighted={false}
    >
      {totals.medicament.toFixed(2)}
    </TableHeaderCell>
    <TableHeaderCell
      className="col-lg-1 col-md-3"
      active
      highlighted={false}
    >
      {totals.current_expenses.toFixed(2)}
    </TableHeaderCell>
    <TableHeaderCell
      className="col-lg-2 col-md-3"
      active
      highlighted={false}
    >
      {totals.utilities.toFixed(2)}
    </TableHeaderCell>

    {/*  </Row> */}
    {/* </Col> */}
  </TableHeaderRow>
);

TPFooter.propTypes = {
  totals: PropTypes.shape({
    bfn_amount: PropTypes.number,
    salary: PropTypes.number,
    medicament: PropTypes.number,
    current_expenses: PropTypes.number,
    utilities: PropTypes.number,
  }),
};

TPFooter.defaultProps = {
  totals: {
    bfn_amount: 0,
    salary: 0,
    medicament: 0,
    current_expenses: 0,
    utilities: 0,
  },

};

export default memo(TPFooter);
