import React from 'react';
import PropTypes from 'prop-types';
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const CollapseButton = ({ content, onClick, disabled }) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faCompressArrowsAlt}
  />
);

CollapseButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CollapseButton.defaultProps = {
  onClick: () => null,
  content: 'Згорнути',
  disabled: false,
};

export default CollapseButton;
