import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { paramPropType } from '../hooks';
import { EditorSelectorInput, EditorStingInput, EditorCheckboxInput } from '../../../basicEditor/editorControls';
import { metaBackends } from '../../../../meta';

const ParamsProps = ({
  value, readOnly, onChange, allowedCtypes,
}) => {
  const ctypeValues = useMemo(
    () => allowedCtypes.map((ac) => ({ value: ac, display_name: ac })),
    [allowedCtypes],
  );
  const resourceValues = useMemo(
    () => Object.keys(metaBackends).map((k) => ({ value: k, display_name: metaBackends[k].name })),
    [],
  );
  return (
    <div className="containerField p-3 border rounded">
      <EditorStingInput
        controlId="param_label_input"
        label="Label"
        required
        description="Представление параметра для пользователя"
        value={value.label}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ label: v })}
      />
      <EditorSelectorInput
        controlId="param_ctype_input"
        label="Тип"
        required
        description="Описание типа параметра"
        value={value.ctype}
        values={ctypeValues}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ ctype: v, resource: '' })}
      />
      <EditorSelectorInput
        controlId="param_resource_input"
        label="Resouce"
        description="Url для описания типа внутренней сущности системы. Например '/api/references/refkbp7/'"
        value={value.resource}
        readOnly={readOnly || value.ctype !== 'NestedObject'}
        onChange={(e, v) => onChange({ resource: v })}
        values={resourceValues}
      />
      <EditorCheckboxInput
        controlId="param_plural_input"
        label="Доступен список значений"
        value={value.plural}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ plural: v })}
      />
    </div>
  );
};

ParamsProps.propTypes = {
  value: paramPropType.isRequired,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
};

ParamsProps.defaultProps = {
  readOnly: false,
  allowedCtypes: [],
};

export default ParamsProps;
