/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TPFooter from './footer';

const BfnAllocationRows = ({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) => {
  const {
    tableData, tableActions, pinHeader, showFooter,
    tableContainerRef, tableReadOnlyFields, tableErrors, tableFields,
    activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'allocationbfnrow_set', readOnlyFields, errors, fields,
  });
  // Необходимые данные из шапки документа

  const totals = useMemo(() => tableData.reduce((Res, r) => ({
    bfn_amount: Res.bfn_amount + (r.bfn_amount || 0),
    salary: Res.salary + (r.salary || 0),
    medicament: Res.medicament + (r.medicament || 0),
    current_expenses: Res.current_expenses + (r.current_expenses || 0),
    utilities: Res.utilities + (r.utilities || 0),
  }),
  {
    bfn_amount: 0,
    salary: 0,
    medicament: 0,
    current_expenses: 0,
    utilities: 0,
  }),
  [tableData]);

  const authorityId = useMemo(() => (data.authority ? data.authority.id : ''), [data.authority]);

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <Header
          fields={tableFields}
          title={totals}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow
            className="col-sm-12"
            key={index}
            rowIndex={index}
            active={activeRow === index}
            activeCol={activeCol}
          >
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
              authorityId={authorityId}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={(e) => tableActions.onAddRow(e)} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        //
        />
      </TableFooter>
    </Card>
  );
};

BfnAllocationRows.propTypes = {
  data: PropTypes.shape({
    onlyYear: PropTypes.bool,
    vt4br: PropTypes.bool,
    disposerInHeader: PropTypes.bool,
    authority: foreignPropType,
    budget: foreignPropType,
    allocationplanrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    allocationplanrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    allocationplanrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    allocationplanrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

BfnAllocationRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default BfnAllocationRows;
