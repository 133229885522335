const frontendURL = '/cat/treasurebank/';
const backendURL = '/api/references/reftreasurebank/';
const name = 'Органи ДКУ';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
