import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import SelectSchemaButton from '../selectShemaButton';

const NewReport = ({ onCreate, handleChangeNewReportParams }) => (
  <>

    <Form onSubmit={(e) => { e.preventDefault(); return (onCreate()); }}>
      <Form.Group>
        <SelectSchemaButton
          content="Завантажити схему"
          icon={faFolderOpen}
          onSelect={handleChangeNewReportParams}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label htmlFor="name">Назва звіту</Form.Label>
        <Form.Control
          type="text"
          id="name"
          name="name"
          onChange={handleChangeNewReportParams}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label htmlFor="description">Опис звіту</Form.Label>
        <Form.Control
          type="text"
          id="description"
          name="description"
          as="textarea"
          rows={3}
          onChange={handleChangeNewReportParams}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
    Створити
      </Button>

    </Form>
  </>
);

NewReport.propTypes = {
  handleChangeNewReportParams: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default NewReport;
