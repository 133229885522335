const frontendURL = '/cat/documentnote/';
const backendURL = '/api/references/refdocumentnote/';
const name = 'Примітка (док)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
