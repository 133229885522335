import {
  useMemo, useState, useCallback,
} from 'react';
import { useLocation, useHistory } from 'react-router';

const useWinManager = () => {
  const [linkComponents, setLinkComponents] = useState([]);
  const [currentURL, setCurrentURL] = useState('');
  // Порядок окон для переключения (след, пред)
  const [windowsStack, setWindowsStack] = useState([]);
  const [listersTimeStamps, setListersTimeStamps] = useState({});

  const history = useHistory();
  const historyUrl = useLocation();

  const addComponentToWindowsManager = useCallback(
    (
      newComponent, newURL, newWindowTitle, newProps,
    ) => {
      const newComp = {
        title: newWindowTitle,
        component: newComponent,
        props: newProps,
        url: newURL,
      };

      setLinkComponents((oldComp) => [...oldComp, newComp]);
      setWindowsStack((o) => [
        newURL,
        ...o,
      ]);
    },
    [],
  );

  /**
   * Получает следующее или предыдущее окно
   * @type {function(number): string}
   */
  const getNewPath = useCallback((stepWin = 0) => {
    const idx = linkComponents.reduce((R, r, i) => (currentURL === r.url ? i : R), null);

    if (idx === null) return '';
    if (stepWin > 0) {
      return linkComponents.length > idx + stepWin
        ? linkComponents[idx + stepWin].url : linkComponents[0].url;
    }
    return idx + stepWin >= 0
      ? linkComponents[idx + stepWin].url : linkComponents[linkComponents.length - 1].url;
  },
  [currentURL, linkComponents]);

  const switchWindow = useCallback(
    (switchURL) => {
      const oldUrl = currentURL;

      setCurrentURL(switchURL);
      setWindowsStack((o) => [
        switchURL,
        ...(oldUrl ? [oldUrl] : []),
        ...o.filter((u) => ![switchURL, oldUrl].includes(u)),
      ]);
      if (switchURL !== historyUrl.pathname) history.push(switchURL);
    },
    [currentURL, history, historyUrl.pathname],
  );

  const { currentComponent, currentProps } = useMemo(
    () => linkComponents.filter((l) => l.url === currentURL).reduce((R, r) => ({
      currentComponent: r.component,
      currentProps: r.props,
    }), {}),
    [currentURL, linkComponents],
  );

  const dellComponentFromWindowsManager = useCallback(
    (url = null) => {
      const delUrl = url === null ? currentURL : url;

      // Переключаем окошки только в том случае если закрываем текущее окно
      if (currentURL === delUrl) {
        switchWindow(windowsStack.filter((u) => u !== delUrl).reduce((R, r) => r, '/'));
      }
      setLinkComponents((o) => o.filter((lc) => lc.url !== delUrl));
      setWindowsStack((o) => o.filter((u) => u !== delUrl));
    },
    [currentURL, switchWindow, windowsStack],
  );

  const nextWindow = useCallback(
    () => {
      const newPath = getNewPath(1);
      switchWindow(newPath);
    },
    [getNewPath, switchWindow],
  );

  const prevWindow = useCallback(
    () => {
      const newPath = getNewPath(-1);
      switchWindow(newPath);
    },
    [getNewPath, switchWindow],
  );

  // Посылает сигнал на обновление данных компоненту, зарегесрированному по указанному url
  const sendUpdateSignal = useCallback(
    (url) => {
      setListersTimeStamps((old) => ({ ...old, [url]: new Date().valueOf() }));
    },
    [],
  );

  const wmValue = useMemo(
    () => ({
      linkComponents,
      currentComponent,
      currentProps,
      currentURL,
      getNewPath,
      dellComponentFromWindowsManager,
      switchWindow,
      addComponentToWindowsManager,
      listersTimeStamps,
      nextWindow,
      prevWindow,
      // hidenWinManager,
      sendUpdateSignal,
    }),
    [addComponentToWindowsManager, currentComponent, currentProps, currentURL, dellComponentFromWindowsManager, getNewPath, linkComponents, listersTimeStamps, nextWindow, prevWindow, sendUpdateSignal, switchWindow],
  );

  return wmValue;
};

export default useWinManager;
