import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer,
  StyledCellItem,
  StyledTableRow,
  StyledTD,
} from '../../../../../basicEditor';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';

const Row = ({
  rowIndex,
  active,
  selected,
  onActivateRow,
  onChangeRow,
  row,
  fields,
  errors,
  on3Years,
}) => {
  const kdbProps = tableHooks.useItemInputProps(
    'kdb',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const firstYearZfProps = tableHooks.useNumberInputProps(
    'first_year_zf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const secondYearZfProps = tableHooks.useNumberInputProps(
    'second_year_zf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const thirdYearZfProps = tableHooks.useNumberInputProps(
    'third_year_zf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const firstYearSfProps = tableHooks.useNumberInputProps(
    'first_year_sf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const secondYearSfProps = tableHooks.useNumberInputProps(
    'second_year_sf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const thirdYearSfProps = tableHooks.useNumberInputProps(
    'third_year_sf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const firstYearBrProps = tableHooks.useNumberInputProps(
    'first_year_br',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const secondYearBrProps = tableHooks.useNumberInputProps(
    'second_year_br',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const thirdYearBrProps = tableHooks.useNumberInputProps(
    'third_year_br',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  return (
    <StyledTableRow
      active={active}
      selected={selected}
      onClick={(e) => onActivateRow(e, rowIndex)}
    >
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...kdbProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...firstYearZfProps} />
            {on3Years && (
              <>
                <TableControls.NumberInput {...secondYearZfProps} />
                <TableControls.NumberInput {...thirdYearZfProps} />
              </>
            )}
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...firstYearSfProps} />
            {on3Years && (
              <>
                <TableControls.NumberInput {...secondYearSfProps} />
                <TableControls.NumberInput {...thirdYearSfProps} />
              </>
            )}
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...firstYearBrProps} />
            {on3Years && (
              <>
                <TableControls.NumberInput {...secondYearBrProps} />
                <TableControls.NumberInput {...thirdYearBrProps} />
              </>
            )}
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
    </StyledTableRow>
  );
};

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  disposerInHeader: PropTypes.bool.isRequired,
  authorityId: PropTypes.number,
  on3Years: PropTypes.bool,
};

Row.defaultProps = {
  active: false,
  selected: false,
  errors: {},
  authorityId: null,
  on3Years: false,
};

export default memo(Row);
