import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const getPing = async () => {
  const res = await fetch('https://logica.minfin.gov.ua/ws/api/PING');
  return res.status;
};

const TimeOut = 12000;

const LogicaPing = ({ available, handleAvailable }) => {
  useEffect(() => {
    const ping = () => getPing().then((res) => {
      if (res !== 200) { return handleAvailable(false); }
      if (res === 200) { return handleAvailable(true); }
      return null;
    });
    ping();
    const isPing = setInterval(ping, TimeOut);
    return () => clearInterval(isPing);
  }, [handleAvailable]);
  return (
    <>
      {!available && (
      <Alert variant="danger">
          Нажаль, сервіс Логіки не відповідає
      </Alert>
      )}
    </>
  );
};

LogicaPing.propTypes = {
  available: PropTypes.bool.isRequired,
  handleAvailable: PropTypes.func.isRequired,
};

export default LogicaPing;
