import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer,
  StyledCellItem,
  StyledTableRow,
  StyledTD,
} from '../../../../../basicEditor';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';

const Row = ({
  rowIndex,
  active,
  selected,
  onActivateRow,
  onChangeRow,
  row,
  fields,
  errors,
  disposerInHeader,
}) => {
  const kbp4Props = tableHooks.useStringInputProps(
    'kbp_4',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const kpkvCodeProps = tableHooks.useStringInputProps(
    'kpkv_code',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  // const disposerProps = tableHooks.useItemInputProps(
  //   'disposer',
  //   row,
  //   fields,
  //   errors,
  //   onChangeRow,
  //   rowIndex,
  // );

  const kbp7Props = tableHooks.useItemInputProps(
    'kbp7_item',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const targetProgramProps = tableHooks.useItemInputProps(
    'target_programm',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const checkNegativeSumProps = tableHooks.useCheckboxInputProps(
    'check_negative_sum',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const totalZagFondProps = tableHooks.useNumberInputProps(
    'total_zag_fond',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const consumptionExpendituresZfProps = tableHooks.useNumberInputProps(
    'consumption_expenditures_zf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const utilityBillsZfProps = tableHooks.useNumberInputProps(
    'utility_bills_zf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const jobPaymentZfProps = tableHooks.useNumberInputProps(
    'job_payment_zf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const developmentCostZfProps = tableHooks.useNumberInputProps(
    'development_cost_zf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  const totalSpecFondProps = tableHooks.useNumberInputProps(
    'total_spec_fond',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const consumptionExpendituresSfProps = tableHooks.useNumberInputProps(
    'consumption_expenditures_sf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const utilityBillsSfProps = tableHooks.useNumberInputProps(
    'utility_bills_sf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const jobPaymentSfProps = tableHooks.useNumberInputProps(
    'job_payment_sf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const developmentCostSfProps = tableHooks.useNumberInputProps(
    'development_cost_sf',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const totalDevelopmentBudgetProps = tableHooks.useNumberInputProps(
    'total_development_budget',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const socEconimicalProgramsProps = tableHooks.useNumberInputProps(
    'soc_econimical_programs',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );
  const noteProps = tableHooks.useStringInputProps(
    'note',
    row,
    fields,
    errors,
    onChangeRow,
    rowIndex,
  );

  if (!consumptionExpendituresSfProps.value) {
    consumptionExpendituresSfProps.value = 0;
  }

  if (!developmentCostSfProps.value) {
    developmentCostSfProps.value = 0;
  }

  if (!consumptionExpendituresZfProps.value) {
    consumptionExpendituresZfProps.value = 0;
  }
  if (!developmentCostZfProps.value) {
    developmentCostZfProps.value = 0;
  }

  if (consumptionExpendituresSfProps.value || developmentCostSfProps.value) {
    totalSpecFondProps.value = consumptionExpendituresSfProps.value + developmentCostSfProps.value;
  }

  if (consumptionExpendituresZfProps.value || developmentCostZfProps.value) {
    totalZagFondProps.value = consumptionExpendituresZfProps.value + developmentCostZfProps.value;
  }

  checkNegativeSumProps.label = '';

  return (
    <StyledTableRow
      active={active}
      selected={selected}
      onClick={(e) => onActivateRow(e, rowIndex)}
    >
      <StyledTD>
        <StyledCellContainer>
          {/* {!disposerInHeader && ( */}
          {/*  <StyledCellItem> */}
          {/*    <TableControls.StringInput {...disposerProps} /> */}
          {/*  </StyledCellItem> */}
          {/* )} */}
          <StyledCellItem>
            <TableControls.StringInput {...kbp4Props} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.StringInput {...kpkvCodeProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...kbp7Props} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.ItemPicker {...targetProgramProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem style={{ textAlign: 'center' }}>
            <TableControls.CheckboxInput {...checkNegativeSumProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...totalZagFondProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...totalSpecFondProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...consumptionExpendituresZfProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...utilityBillsZfProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...jobPaymentZfProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...developmentCostZfProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...consumptionExpendituresSfProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...utilityBillsSfProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...jobPaymentSfProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...developmentCostSfProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...totalDevelopmentBudgetProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...socEconimicalProgramsProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.StringInput {...noteProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
    </StyledTableRow>
  );
};

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  disposerInHeader: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  active: false,
  selected: false,
  errors: {},
};

export default memo(Row);
