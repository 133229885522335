import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { StyledNumber, StyledString, StyledTD } from './styles';

const TableCell = ({
  onClick, onDoubleClick, value, type, choices,
}) => {
  const presentedValue = useMemo(() => {
    switch (type) {
      case 'string':
        return (<StyledString>{value}</StyledString>);
      case 'email':
        return (<StyledString>{value}</StyledString>);
      case 'url':
        return (<StyledString>{value}</StyledString>);
      case 'integer':
        return (<StyledNumber>{value}</StyledNumber>);
      case 'float':
        return (<StyledNumber>{value}</StyledNumber>);
      case 'decimal':
        return (<StyledNumber>{value.toFixed(2)}</StyledNumber>);
      case 'choice':
        return (
          <StyledString>
            {
          // eslint-disable-next-line
          choices.reduce((R, c) => c.value === value ? c.display_name : R, '')
        }
          </StyledString>
        );
      case 'date':
        if (!value) return '-';
        return (
          <StyledString>
            {(new Date(value)).toLocaleString('uk', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </StyledString>
        );
      case 'datetime':
        if (!value) return '-';
        return (
          <StyledString>
            {(new Date(value)).toLocaleString('uk', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </StyledString>
        );
      case 'nested object':
        return (<StyledString>{value && value.repr}</StyledString>);
      case 'boolean':
        return (
          <FontAwesomeIcon icon={value ? faCheck : faMinus} color={value ? 'green' : 'red'} />
        );
      default:
        return `Type "${type}" unsupported`;
    }
  }, [choices, type, value]);
  return (
    <StyledTD
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {presentedValue}
    </StyledTD>
  );
};

TableCell.propTypes = {
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.string,
    }).isRequired,
  ]),
  type: PropTypes.oneOf([
    'string', 'email', 'url', 'integer', 'float', 'decimal', 'choice', 'date', 'datetime', 'nested object', 'boolean', 'field',
  ]).isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    display_name: PropTypes.string,
  })),
};

TableCell.defaultProps = {
  onClick: () => null,
  onDoubleClick: () => null,
  choices: [],
  value: null,
};

export default memo(TableCell);
