import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router';
import cat from '../../catalogs';
import doc from '../../documents';
import rep from '../../reports';
import meta from '../../../meta';
import { WinManagerContext } from '../../../providers/winManagerProvider';

const editors = {
  cat,
  doc,
  rep,
};

const Editors = Object.keys(editors).reduce((RT, mType) => ({
  ...RT,
  [mType]: Object.keys(editors[mType]).reduce((RN, mName) => {
    if (!(mName in meta[mType])) throw new Error(`Различаются свойства редакторов (${mName}) и метаданных`);
    const WindowedEditor = ({
      id, url,
    }) => {
      const history = useHistory();
      const {
        dellComponentFromWindowsManager,
      } = useContext(WinManagerContext);
      const onSave = useCallback(
        (item) => {
          if (String(item.id) !== id) {
            dellComponentFromWindowsManager(url);
            history.push(`${meta[mType][mName].frontendURL}${item.id}/`);
          }
        },
        [dellComponentFromWindowsManager, history, id, url],
      );

      return React.createElement(
        editors[mType][mName].Editor,
        {
          id,
          onClose: () => dellComponentFromWindowsManager(url),
          onSave,
        },
      );
    };
    WindowedEditor.propTypes = {
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    };
    return {
      ...RN,
      [mName]: WindowedEditor,
    };
  }, {}),
}), {});

export default Editors;
