import React from 'react';
import Editor from './defaultEditor';

const PlanExchange = () => (
  <Editor
    title="Завантажити плани та зміни"
    backendUrl="/api/cityplans/importdbf/"
  />
);

export default PlanExchange;
