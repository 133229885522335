import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button, ButtonGroup, Card,
  Col, Collapse, Container, Modal, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import FilterGrid from '../../../../basicEditor/stdFilters/filterGrid';
import DocList from './docs';
import withFilter from '../../../../basicEditor/stdFilters/withFilter';
import DocDetails from './docDetails';
import { dataPropTypes, fieldsPropTypes } from './dataPropTypes';
import { EditorDateInput } from '../../../../basicEditor/editorControls';
import { CommandPanel, DimableLoader } from '../../../../../components/bootStrap';
import {
  HeaderText, FooterText, FooterLabel, ContainerDiv,
} from './style';
import { CPButton } from '../../../../../components/bootStrap/buttons/styles';

const API_URL = '/api/authorityplans/allocationplanrow/';

const LOCAL_FILTERS = ['begin_date', 'end_date'];

const FilterEditor = ({
  filters, filteringFields, setFilterHandler, items, fields, loading,
  onClose, onOk,
}) => {
  const displayFilteringFields = useMemo(
    () => filteringFields.filter((ff) => !LOCAL_FILTERS.includes(ff.name)),
    [filteringFields],
  );

  const [docs, setDocs] = useState([]);
  const [activeDoc, setActiveDoc] = useState(null);
  const onToggleDoc = useCallback(
    (docId) => setDocs(docs.map((d) => ({ ...d, use: d.id === docId ? !d.use : d.use }))),
    [docs],
  );
  useEffect(() => {
    const docIds = new Set(items.map((d) => d.header.id));
    const newDocs = [...docIds].map((dId) => {
      const f0 = items.filter((item) => item.header.id === dId)[0];
      return {
        id: f0.header.id,
        repr: f0.header.repr,
        use: true,
      };
    });
    setDocs(newDocs);
  }, [items]);

  const selectedItems = useMemo(
    () => docs.filter((d) => d.use)
      .reduce((R, d) => [...R, ...items.filter((i) => i.header.id === d.id)], []),
    [docs, items],
  );

  const totals = useMemo(
    () => ({
      docsCount: docs.filter((d) => d.use).length,
      sum: selectedItems.reduce((R, i) => R + i.amount_total, 0),
    }),
    [docs, selectedItems],
  );

  const docDetails = useMemo(
    () => items.filter((item) => item.header.id === activeDoc),
    [activeDoc, items],
  );

  const formatter = (f) => f.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <Modal.Header closeButton>
        <HeaderText>Завантаження планів розпорядників нижчого рівня</HeaderText>
      </Modal.Header>
      <Modal.Body>
        <DimableLoader loading={loading}>
          <Container fluid>
            <Row>
              <Col>
                <EditorDateInput
                  controlId="begin_date"
                  label="Відібрати документи за період з"
                  required
                  value={filters.begin_date.value}
                  onChange={(e, v) => setFilterHandler('begin_date', v, true)}
                />
              </Col>
              <Col>
                <EditorDateInput
                  controlId="end_date"
                  label="по"
                  required
                  value={filters.end_date.value}
                  onChange={(e, v) => setFilterHandler('end_date', v, true)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CommandPanel>
                  <CPButton
                    onClick={() => setShowFilters(!showFilters)}
                    title="Фільтрування"
                    icon={faFilter}
                  />
                  <ButtonGroup>
                    <CPButton
                      onClick={() => setDocs(docs.map((d) => ({ ...d, use: true })))}
                      title="Обрати всі"
                      icon={faCheckSquare}
                    />
                    <CPButton
                      onClick={() => setDocs(docs.map((d) => ({ ...d, use: false })))}
                      title="Зняти відмітки"
                      icon={faSquare}
                    />
                  </ButtonGroup>
                </CommandPanel>
                <Card style={{ border: 'none' }}>
                  <Collapse in={showFilters} appear>
                    <Card.Body style={{ padding: '0px' }}>
                      <FilterGrid
                        filters={filters}
                        filteringFields={displayFilteringFields}
                        onSetFilter={setFilterHandler}
                      />
                    </Card.Body>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            <ContainerDiv>
              <Row>
                <Col>
                  <DocList
                    docs={docs}
                    onSelectDoc={setActiveDoc}
                    onToggleDoc={onToggleDoc}
                    activeDoc={activeDoc}
                  />
                </Col>
                <Col sm={8}>
                  <DocDetails
                    rows={docDetails}
                    fields={fields}
                  />
                </Col>
              </Row>
            </ContainerDiv>
          </Container>
        </DimableLoader>
      </Modal.Body>
      <Modal.Footer>
        <FooterText>
          Буде завантажено
          {' '}
          <FooterLabel>{`${totals.docsCount}`}</FooterLabel>
          {' '}
          документів на суму
          {' '}
          <FooterLabel>{`${formatter(totals.sum.toFixed(2))}`}</FooterLabel>
          {' '}
          грн.
        </FooterText>
        <Button
          variant="success"
          onClick={() => onOk(selectedItems.map((i) => i.id))}
        >
          Завантажити
        </Button>
        <Button variant="dark" onClick={onClose}>Закрити</Button>
      </Modal.Footer>
    </>
  );
};

FilterEditor.propTypes = {
  filters: PropTypes.shape({
    begin_date: PropTypes.shape({
      value: PropTypes.string,
    }),
    end_date: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  filteringFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    use: PropTypes.string,
  })).isRequired,
  setFilterHandler: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default withFilter(API_URL)(FilterEditor);
