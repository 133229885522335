import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { moneysStingOptions } from '../../../../../../constants/moneys';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

const Header = ({
  activeCol, highlights, onToggleHighlght, year, totals,
}) => {
  const yearDef = year || new Date().getFullYear();
  const year1 = `${yearDef + 1}p.`;
  const year2 = `${yearDef + 2}p.`;
  return (
    <TableHeaderRow noGutters className="px-4">
      <Col sm={12} className="px-1 d-flex flex-column justify-content-end">
        <Row sm={12} noGutters>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'group'}
              highlighted={highlights.includes('group')}
              onToggleHighlght={() => onToggleHighlght('group')}
            >
              Група
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'structure_of_labor_cost'}
              highlighted={highlights.includes('structure_of_labor_cost')}
              onToggleHighlght={() => onToggleHighlght('structure_of_labor_cost')}
            >
              Структура оплати
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf1'}
              highlighted={highlights.includes('amount_zf1')}
              onToggleHighlght={() => onToggleHighlght('amount_zf1')}
              title={totals.amount_zf1.toLocaleString('uk', moneysStingOptions)}
            >
              Загальний фонд
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf1'}
              highlighted={highlights.includes('amount_sf1')}
              onToggleHighlght={() => onToggleHighlght('amount_sf1')}
              title={totals.amount_sf1.toLocaleString('uk', moneysStingOptions)}
            >
              Спеціальний фонд
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_br_total1'}
              highlighted={highlights.includes('amount_br_total1')}
              onToggleHighlght={() => onToggleHighlght('amount_br_total1')}
              title={totals.amount_br_total1.toLocaleString('uk', moneysStingOptions)}
            >
              в т.ч. БР
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_zf2'}
              highlighted={highlights.includes('amount_zf2')}
              onToggleHighlght={() => onToggleHighlght('amount_zf2')}
              title={totals.amount_zf2.toLocaleString('uk', moneysStingOptions)}
            >
              Загальний фонд
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_sf2'}
              highlighted={highlights.includes('amount_sf2')}
              onToggleHighlght={() => onToggleHighlght('amount_sf2')}
              title={totals.amount_sf2.toLocaleString('uk', moneysStingOptions)}
            >
              Спеціальний фонд
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
          <Col className="border-right">
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_br_total2'}
              highlighted={highlights.includes('amount_br_total2')}
              onToggleHighlght={() => onToggleHighlght('amount_br_total2')}
              title={totals.amount_br_total2.toLocaleString('uk', moneysStingOptions)}
            >
              в т.ч. БР
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total1'}
              highlighted={highlights.includes('amount_total1')}
              onToggleHighlght={() => onToggleHighlght('amount_total1')}
              title={totals.amount_total1.toLocaleString('uk', moneysStingOptions)}
            >
              Сума
              {' '}
              {year1}
            </TableHeaderCell>
          </Col>
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'amount_total2'}
              highlighted={highlights.includes('amount_total2')}
              onToggleHighlght={() => onToggleHighlght('amount_total2')}
              title={totals.amount_total2.toLocaleString('uk', moneysStingOptions)}
            >
              Сума
              {' '}
              {year2}
            </TableHeaderCell>
          </Col>
        </Row>
      </Col>
    </TableHeaderRow>
  );
};

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    amount_zf1: PropTypes.number,
    amount_zf2: PropTypes.number,
    amount_br_total1: PropTypes.number,
    amount_br_total2: PropTypes.number,
    amount_sf1: PropTypes.number,
    amount_sf2: PropTypes.number,
    amount_total1: PropTypes.number,
    amount_total2: PropTypes.number,
  }).isRequired,
  year: PropTypes.number,
};

Header.defaultProps = {
  activeCol: null,
  year: null,
};

export default Header;
