import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

const Header = ({ activeCol, highlights, onToggleHighlght }) => (
  <TableHeaderRow noGutters className="px-4">
    <Col sm={12} className="px-1 d-flex flex-column justify-content-end">
      <Row sm={12} noGutters>
        <Col sm={12}>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'problem'}
            highlighted={highlights.includes('problem')}
            onToggleHighlght={() => onToggleHighlght('problem')}
          >
            Завдання
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

Header.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
