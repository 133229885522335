import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  disposerInHeader,
  authority,
}) => {
  const treasureDateProps = tableHooks.useDateInputProps('treasure_date', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7Props = tableHooks.useItemInputProps('kbp7_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kekvProps = tableHooks.useItemInputProps('kekv_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerAccProps = tableHooks.useItemInputProps('disposer_acc', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const finCommitmentAmountProps = tableHooks.useNumberInputProps('fin_commitment_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const balanceProps = tableHooks.useNumberInputProps('balance', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountProps = tableHooks.useNumberInputProps('amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const fundsDirectionProps = tableHooks.useItemInputProps('funds_direction', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const remarkTemplateProps = tableHooks.useItemInputProps('remark_template', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const remarkNoteProps = tableHooks.useStringInputProps('remark_note', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const finrequestProps = tableHooks.useItemInputProps('finrequest', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const Span = styled.span`
    display: block;
    width: 100%;
    margin: 0;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: rgb(233, 236, 239);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  `;

  return (
    <>
      <Col>
        <Row noGutters>
          <Col>
            <TabelRowCell column="treasure_date" highlighted={highlights.includes('treasure_date')}>
              <TableControls.DateInput {...treasureDateProps} />
            </TabelRowCell>
            {!disposerInHeader && (
            <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
              <TableControls.ItemPicker
                {...disposerProps}
                filter={{ parent: authority }}
                noHierarchy
              />
            </TabelRowCell>
            )}
            <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
              <TableControls.ItemPicker {...disposerAccProps} />
            </TabelRowCell>
          </Col>

          <Col>
            <TabelRowCell column="kvk_code" highlighted={highlights.includes('kvk_code')}>
              {disposerProps.value && disposerProps.value.kvk
              && <Span>{disposerProps.value.kvk.id}</Span>}
              {!disposerProps.value && <Span />}
            </TabelRowCell>
            <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
              <TableControls.ItemPicker {...kbp7Props} />
            </TabelRowCell>
            <TabelRowCell column="kekv_item" highlighted={highlights.includes('kekv_item')}>
              <TableControls.ItemPicker {...kekvProps} />
            </TabelRowCell>
          </Col>

          <Col>
            <TabelRowCell column="fin_commitment_amount" highlighted={highlights.includes('fin_commitment_amount')}>
              <TableControls.NumberInput {...finCommitmentAmountProps} />
            </TabelRowCell>
            <TabelRowCell column="balance" highlighted={highlights.includes('balance')}>
              <TableControls.NumberInput {...balanceProps} />
            </TabelRowCell>
            <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
              <TableControls.NumberInput {...amountProps} />
            </TabelRowCell>
          </Col>

          <Col>
            <TabelRowCell column="funds_direction" highlighted={highlights.includes('funds_direction')}>
              <TableControls.ItemPicker {...fundsDirectionProps} />
            </TabelRowCell>
            <TabelRowCell column="finrequest" highlighted={highlights.includes('finrequest')}>
              <TableControls.ItemPicker {...finrequestProps} />
            </TabelRowCell>
            <TabelRowCell column="remark_template" highlighted={highlights.includes('remark_template')}>
              <TableControls.ItemPicker {...remarkTemplateProps} />
            </TabelRowCell>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <TabelRowCell column="remark_note" highlighted={highlights.includes('remark_note')}>
              <TableControls.TextInput {...remarkNoteProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
    </>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  disposerInHeader: PropTypes.bool,
  authority: foreignPropType,
  showKDBKFB: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  disposerInHeader: false,
  authority: null,
  showKDBKFB: false,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
