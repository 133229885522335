import React from 'react';
import { StyledCellContainer, StyledCellItemHeader, StyledTableHeaderCell } from '../../../../../basicEditor';

const Header = () => (
  <tr>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>Цілі державної політики</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
  </tr>
);

export default Header;
