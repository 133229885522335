import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Button, Form, Alert, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { DimableLoader } from '../../../components/bootStrap';

const H2 = styled.h2`
  ${({ color }) => `color: ${color}`}`;

const StyledDiv = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;`;

const UpdateRole = ({ onClose, activeId, setUpdateList }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [role, setRole] = useState(null);
  const [updatedRole, setUpdatedRole] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const updateRoleParams = (e) => {
    const { name, value } = e.target;
    setUpdatedRole({
      ...updatedRole,
      [name]: value,
    });
  };

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);

  const onError = useCallback((e) => setErr(e), []);

  const getPermissions = useCallback((roleData) => {
    const loader = async () => {
      const r = await api.get('/api/auth/permissions/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();

    loader()
      .then((rData) => {
        const newData = rData.map((el) => {
          if (el.key in roleData.permissions && roleData.permissions[el.key]) {
            return {
              ...el,
              check: true,
              input: true,
            };
          }
          if (el.key in roleData.permissions) {
            return {
              ...el,
              check: false,
              input: true,
            };
          }
          return {
            ...el,
            check: false,
            input: false,
          };
        });
        setRole((prev) => ({ ...prev, permissions: [...newData] }));
        setUpdatedRole((prev) => ({ ...prev, permissions: [...newData] }));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
    /*eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const loader = async () => {
      const params = null;
      const r = await api.get(`/api/auth/role/${activeId}`, auth, params);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setRole(rData);
        setUpdatedRole(rData);
        return getPermissions(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [activeId, auth, getPermissions, onError, onLoadEnd, onLoadStart]);

  const updateRole = useCallback(() => {
    const loader = async () => {
      const filteredPermissions = updatedRole.permissions.filter((perm) => perm.input)
        .reduce((prev, cur) => ({
          ...prev,
          [cur.key]: cur.check,
        }), {});
      const params = { ...updatedRole, permissions: filteredPermissions };
      const r = await api.put(`/api/auth/role/${activeId}/`, auth, params);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onClose();
        setUpdateList((prev) => !prev);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [activeId, auth, onClose, onError, onLoadEnd, onLoadStart, setUpdateList, updatedRole]);

  const handleCheckbox = useCallback((type, key, checked) => {
    if (type === 'input') {
      if (key === 'all') {
        setUpdatedRole({
          ...updatedRole,
          permissions: [...updatedRole.permissions
            .map((perm) => ({ ...perm, input: true }))],
        });
      } else if (key === 'nothing') {
        setUpdatedRole({
          ...updatedRole,
          permissions: [...updatedRole.permissions
            .map((perm) => ({ ...perm, input: false, check: false }))],
        });
      } else {
        setUpdatedRole({
          ...updatedRole,
          permissions: [...updatedRole.permissions
            .map((k) => {
              if (k.key === key && !checked) { return { ...k, check: checked, input: checked }; }
              if (k.key === key) { return { ...k, input: checked }; }
              return k;
            })],
        });
      }
    } else if (key === 'all') {
      setUpdatedRole({
        ...updatedRole,
        permissions: [...updatedRole.permissions
          .map((perm) => ({ ...perm, check: perm.input }))],
      });
    } else if (key === 'nothing') {
      setUpdatedRole({
        ...updatedRole,
        permissions: [...updatedRole.permissions
          .map((perm) => ({ ...perm, check: false }))],
      });
    } else {
      setUpdatedRole({
        ...updatedRole,
        permissions: [...updatedRole.permissions
          .map((k) => {
            if (k.key === key) { return { ...k, check: checked }; }
            return k;
          })],
      });
    }
  }, [updatedRole]);

  return (
    <>
      <DimableLoader loading={loading}>

        {err
        && (
        <Alert dismissible variant="danger" onClose={() => setErr(null)}>
          {err}
        </Alert>
        )}

        <>
          {updatedRole
            && (
              <>
                <Form>
                  <Form.Group>
                    <Form.Label htmlFor="name">Назва ролі</Form.Label>
                    <Form.Control
                      type="text"
                      id="name"
                      name="name"
                      value={updatedRole.name}
                      onChange={updateRoleParams}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="description">Опис ролі</Form.Label>
                    <Form.Control
                      type="text"
                      id="description"
                      value={updatedRole.description}
                      name="description"
                      as="textarea"
                      rows={3}
                      onChange={updateRoleParams}
                    />
                  </Form.Group>
                </Form>

                <H2 color="blue">Права доступу:</H2>
                <StyledDiv>
                  <div>
                    <Button className="mr-2" onClick={() => handleCheckbox('input', 'all')}>
                      <FontAwesomeIcon icon={faCheckSquare} className="mr-2" size="lg" />
                    Включити всі
                    </Button>
                    <Button variant="outline-primary" onClick={() => handleCheckbox('input', 'nothing')}>
                      <FontAwesomeIcon icon={faSquare} className="mr-2" size="lg" />
                      Виключити всі
                    </Button>
                  </div>
                  <div>
                    <Button className="mr-2" variant="info" onClick={() => handleCheckbox('check', 'all')}>
                      <FontAwesomeIcon icon={faCheckSquare} className="mr-2" size="lg" />
                    Надати всі доступи
                    </Button>
                    <Button variant="outline-info" onClick={() => handleCheckbox('check', 'nothing')}>
                      <FontAwesomeIcon icon={faSquare} className="mr-2" size="lg" />
                      Зняти доступи
                    </Button>
                  </div>
                </StyledDiv>
                <Table hover>
                  <thead>
                    <tr>
                      <th style={{ width: '5px' }}>Включити в список</th>
                      <th>Назва</th>
                      <th style={{ width: '5px' }}>Надати доступ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {updatedRole.permissions.length && updatedRole.permissions.map((el) => (
                      <tr key={el.key}>
                        <td>
                          <Form.Check
                            type="switch"
                            id={el.key}
                            label=""
                            checked={el.input}
                            onChange={(e) => handleCheckbox('input', el.key, e.target.checked)}
                          />
                        </td>
                        <td>{el.label}</td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            id={el.key}
                            label=""
                            onChange={(e) => handleCheckbox('check', el.key, e.target.checked)}
                            checked={el.check}
                            disabled={!el.input}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <Button className="mt-2 mr-2" variant="outline-success" onClick={updateRole}>Зберегти</Button>
                <Button className="mt-2" variant="outline-danger" onClick={() => setUpdatedRole(role)}>Повернутись до попередніх значень</Button>
              </>
            )}
        </>

      </DimableLoader>
    </>
  );
};

UpdateRole.propTypes = {
  activeId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
};

UpdateRole.defaultProps = {
  activeId: null,
};

export default UpdateRole;
