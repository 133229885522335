import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import SKDColumnItem from './columnItem';
import { StyledFieldList } from '../styles';
import itemTypes from '../itemTypes';
import { columnsPropType } from '../../hooks';

const SKDColumnsList = ({
  columns, activeColumn, onClick, onDblClick, onDragEnd, onSubColumnClick, onChangeHierarchy,
}) => {
  const [padded, setPadded] = useState(null);
  const onDragEndHandler = useCallback((item) => {
    onDragEnd(item, padded);
  },
  [onDragEnd, padded]);
  const [{
    isOver, isOverCurrent, didDrop,
  }, ref] = useDrop({
    accept: [itemTypes.availableField, itemTypes.column],
    drop: (item) => onDragEndHandler(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  const setPaddedHandler = useCallback(
    setPadded,
    [columns.length],
  );

  useEffect(() => {
    if ((!isOver && !isOverCurrent && !didDrop) || (isOver && isOverCurrent && !didDrop)) {
      setPadded(null);
    }
  }, [didDrop, isOver, isOverCurrent]);
  return (
    <Card>
      <Card.Header>Обрані группування</Card.Header>
      <StyledFieldList
        ref={ref}
      >
        {columns.map((f) => (
          <SKDColumnItem
            subcolumns={f.items}
            name={f.name}
            key={f.name}
            onClick={onClick}
            onDblClick={onDblClick}
            onSubColumnClick={onSubColumnClick}
            active={activeColumn === f.name}
            onHover={setPaddedHandler}
            padded={padded === f.name}
            onChangeHierarchy={onChangeHierarchy}
          />
        ))}
      </StyledFieldList>
    </Card>
  );
};

SKDColumnsList.propTypes = {
  columns: columnsPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubColumnClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  activeColumn: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  onChangeHierarchy: PropTypes.func.isRequired,
};


SKDColumnsList.defaultProps = {
  activeColumn: null,
  onDblClick: () => null,
};
export default SKDColumnsList;
