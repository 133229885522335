import React, {
  useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Col, Container, Row, Tab,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, { FieldPropType, CatCommandPanel, getErrorsPropTypes } from '../../../basicEditor';
import { HeaderText } from '../../../../components/Styled/Forms';
import { LabelTab, TabContainer } from '../../../basicEditor/tpController/styles';
import User from './user';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import api from '../../../../api/req';
import { AppContext } from '../../../../providers/authProvider';
import { DimableLoader } from '../../../../components/bootStrap';

const Editor = ({
  data, options, isNew, isReadOnly, isModified, onSave, onClose, fields, onChange, errors,
}) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [disposersList, setDisposersList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/references/refdisposer/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const newData = rData.map((el) => ({ ...el, value: el.id, display_name: el.name }));
        setDisposersList(newData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/auth/permissiongroup/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    loader()
      .then((rData) => {
        setGroupList(rData.results.map((r) => ({
          id: r.id, name: r.name, value: r.id, display_name: r.name,
        })));
      })
      .catch((e) => onError(e.message));
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, errors, onChange);
  const budgetProps = editorHooks.useItemInputProps('budget', data, fields, errors, onChange);
  const disposersProps = editorHooks.useSelectorInputProps('disposers', data, fields, errors, onChange);
  const groupProps = editorHooks.useSelectorInputProps('group', data, fields, errors, onChange);

  const updateGroup = useCallback((group) => groupList
    .filter((r) => r.id === group)
    .reduce((R, r) => r, null), [groupList],
  []);

  const group = useMemo(
    () => data && data.group && data.group.id,
    [data],
  );

  const onSetGroup = useCallback(
    (e, v) => onChange({ group: updateGroup(v) }),
    [onChange, updateGroup],
  );

  return (
    <DimableLoader loading={loading}>
      {err && (
      <Alert dismissible variant="danger" onClose={() => setErr(null)}>
        {err}
      </Alert>

      )}
      <HeaderText>{isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}</HeaderText>
      <CatCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        canDeleted={false}
        onSave={() => onSave()}
        onClose={onClose}
      />
      <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <Container fluid style={{ padding: '0' }}>
              <Row>
                <Col>
                  <EditorControls.ItemPicker {...authorityProps} />
                </Col>
                <Col>
                  <EditorControls.ItemPicker label="Бюджет" {...budgetProps} />
                </Col>
              </Row>
              <Row>
                {disposersList.length > 0
                && (
                <Col>
                  <EditorControls.MultiSelector {...disposersProps} values={disposersList} />
                </Col>
                )}
                <Col>
                  <EditorControls.SelectorInput
                    {...groupProps}
                    values={groupList}
                    value={group}
                    label="Група"
                    onChange={onSetGroup}
                  />
                </Col>
              </Row>
            </Container>
          </TabContainer>
        </Tab>
        <Tab eventKey="user" title="Користувач">
          <TabContainer>
            <User
              data={data.user || {}}
              errors={errors}
              fields={fields.user.children}
              onChange={(v) => onChange({ user: { ...data.user, ...v } })}
            />
          </TabContainer>
        </Tab>
      </LabelTab>
    </DimableLoader>
  );
};

const dataPropTypes = {
  id: PropTypes.number,
  repr: PropTypes.string,
  budget: PropTypes.shape({ id: PropTypes.number, repr: PropTypes.string }),
  username: PropTypes.string,
  parent: PropTypes.shape({ id: PropTypes.number, repr: PropTypes.string }),
  last_name: PropTypes.string,
  first_name: PropTypes.string,
  is_superuser: PropTypes.bool,
  is_staff: PropTypes.bool,
  email: PropTypes.string,

  settings: PropTypes.shape({}),
  history: PropTypes.shape({}),
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,

  }).isRequired,
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  fields: PropTypes.shape(
    fieldsPropTypes,
  ).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.cat.user.backendURL)(Editor);
