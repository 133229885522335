import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const DeleteButton = forwardRef(({ content, onClick, disabled }, ref) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faTrashAlt}
    ref={ref}
  />
));

DeleteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

DeleteButton.defaultProps = {
  onClick: () => null,
  content: 'Видалити',
  disabled: false,
};

export default DeleteButton;
