import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, Row, Col, Container,
} from 'react-bootstrap';
import { AddRowButton, RemoveRowButton } from '../../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../../components/bootStrap';
import { fieldPropType, metaFieldPropType } from '../hooks';
import MetaFieldItem from './item';
import MetaFieldProps from './props';

const MetaFieldsPage = ({
  metaFields, activeMetaField, onRenameField, onChangeField,
  onAddField, onRemoveField, onSetActiveField, allowedCtypes, fields, allowedFonts,
}) => {
  const displayMetaFields = useMemo(
    () => metaFields.map((t, itemKey) => ({
      ...t,
      itemKey,
    })), [metaFields],
  );
  const fValue = useMemo(
    () => metaFields.filter((t) => t.name === activeMetaField).reduce((R, f) => f, {}),
    [activeMetaField, metaFields],
  );
  // const alowedTables = useMemo(
  //   () => Object.keys(tableFields).map((value) => ({ value, display_name: value })),
  //   [tableFields],
  // );

  const displayAlowedCtypes = useMemo(
    () => allowedCtypes.map((value) => ({ value, display_name: value })),
    [allowedCtypes],
  );

  const allowedFields = useMemo(
    () => fields.map((f) => ({ value: f.name, display_name: f.name })),
    [fields],
  );
  return (
    <Container fluid>
      <Row>
        <Col lg={3} md={6} sm={12} className="p-3">
          <CommandPanel>
            <AddRowButton
              title="Додати поле"
              content=""
              onClick={() => onAddField(Math.random().toString(36))}
            />
            <RemoveRowButton
              title="Видалити поле"
              content=""
              onClick={() => onRemoveField(activeMetaField)}
              disabled={!activeMetaField}
            />
          </CommandPanel>
          <ListGroup className="containerGroup">
            {displayMetaFields.map((metaField) => (
              <MetaFieldItem
                key={metaField.itemKey}
                active={metaField.name === activeMetaField}
                onClick={() => onSetActiveField(metaField.name)}
                metaField={metaField}
                onRenameField={onRenameField}
              />
            ))}
          </ListGroup>
        </Col>
        <Col className="containerCol">
          <MetaFieldProps
            onChange={(v) => onChangeField(activeMetaField, v)}
            value={fValue}
            readOnly={!('name' in fValue)}
            allowedCtypes={displayAlowedCtypes}
            allowedFields={allowedFields}
            allowedFonts={allowedFonts}
          />
        </Col>
      </Row>
    </Container>
  );
};

MetaFieldsPage.propTypes = {
  metaFields: PropTypes.arrayOf(metaFieldPropType).isRequired,
  activeMetaField: PropTypes.string,
  onRenameField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  onSetActiveField: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.arrayOf(fieldPropType).isRequired,
  allowedFonts: PropTypes.arrayOf(PropTypes.string),
};

MetaFieldsPage.defaultProps = {
  activeMetaField: null,
  allowedCtypes: [],
  allowedFonts: [],
};

export default memo(MetaFieldsPage);
