import assigmentplaning from './assigmentPlaning';
import planBallancing from './planBallancing';
import commoninStitutionStructureBfn from './bfnCommoninStitutionstructure';

const rep = {
  assigmentplaning,
  planBallancing,
  commoninStitutionStructureBfn,
};

export default rep;
