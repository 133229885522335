import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
// import { directions } from '../../../../../constants/meta/common';
import { StyledTh } from '../styles';
// import Resizer from '../../../../tableEditor/xresize';

const CatTableListerHeader = ({
  columns,
  onSetOrder,
  order,
  columnSizes,
  onResizeColumn,
  onResetColumnSize,
  permissions,
  maxItemLevel,
}) => (
  <thead>
    <tr>
      {permissions.canHierarchy && (
      <StyledTh style={{ width: `${maxItemLevel + 1 + 2.5}rem` }}>&nbsp;</StyledTh>
      )}
      {columns.map((dc) => (
        <StyledTh
          key={dc.key}
          onClick={(e) => dc.orderable && onSetOrder(e, dc.key)}
          width={columnSizes[dc.key] === '1fr' ? null : columnSizes[dc.key]}
          orderable={dc.orderable}
        >
          {dc.label}
          {order.column === dc.key && order.isAscending && (
          <FontAwesomeIcon icon={faAngleUp} />
          )}
          {order.column === dc.key && !order.isAscending && (
          <FontAwesomeIcon icon={faAngleDown} />
          )}
          {/* <Resizer */}
          {/*  onResize={(e, v) => onResizeColumn(e, dc.key, v)} */}
          {/*  onResetColumnSize={(e) => onResetColumnSize(e, dc.key)} */}
          {/* /> */}
        </StyledTh>
      ))}
    </tr>
  </thead>

);

CatTableListerHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    orderable: PropTypes.bool,
  })).isRequired,
  onSetOrder: PropTypes.func.isRequired,
  order: PropTypes.shape({
    column: PropTypes.string,
    isAscending: PropTypes.bool,
  }).isRequired,
  columnSizes: PropTypes.shape(),
  onResizeColumn: PropTypes.func.isRequired,
  onResetColumnSize: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canHierarchy: PropTypes.bool,
  }).isRequired,
  maxItemLevel: PropTypes.number,
};

CatTableListerHeader.defaultProps = {
  columnSizes: {},
  maxItemLevel: 0,
};

export default memo(CatTableListerHeader);
