import React from 'react';
import PropTypes from 'prop-types';
import { faFolder as icon } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const NewFolderButton = ({ disabled, onClick, content }) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    icon={icon}
    content={content}
  />

);

NewFolderButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  content: PropTypes.string,
};

NewFolderButton.defaultProps = {
  disabled: false,
  onClick: null,
  content: 'Створити групу',
};

export default NewFolderButton;
