import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Overlay } from 'react-bootstrap';
import { Checkbox } from '../../../components/Controls';
import { ErroredTooltip } from '../../../components/bootStrap';

export const TableCheckbox = ({
  controlId, label, required, errors, description, ...restProps
}) => {
  const controlRef = useRef(null);
  return (
    <Form.Group controlId={controlId}>
      <Checkbox
        {...restProps}
        id={controlId}
        label={label}
        ref={controlRef}
        errored={errors && !!errors.length}
      />
      <Overlay target={controlRef.current} show={!!errors} placement="auto">
        {(props) => (
          <ErroredTooltip id={`error-${controlId}`} {...props}>
            {String(errors)}
          </ErroredTooltip>
        )}
      </Overlay>
      {description && (
        <Form.Text className="text-muted">
          {description}
        </Form.Text>
      )}
    </Form.Group>
  );
};

TableCheckbox.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
};

TableCheckbox.defaultProps = {
  label: null,
  required: false,
  errors: null,
  description: null,
};

export default TableCheckbox;
