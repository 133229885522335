import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import api, { NetworkException } from '../../api/req';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';
import { DimableLoader, ErrorMessage } from '../../components/bootStrap';


const withPrint = (apiUrl) => (WrappedComponent) => {
  class PrintShower extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        data: null,
        isLoading: false,
        errors: {},
        isErrored: false,
        errText: null,
        isPortrait: false,
        fields: {},
        savedSettings: {},
      };
    }

    componentDidMount() {
      this.processOptions();
      this.getPrintSettings();
    }

    processOptions = async () => {
      const { authF } = this.props;
      const resp = await api.options(`${apiUrl}`, authF);
      if (resp.ok) {
        const options = await resp.json();
        const fieldsOpt = options.actions.GET;
        this.setState({
          fields: {
            signvariant: {
              label: fieldsOpt.signvariant.label,
              required: fieldsOpt.signvariant.required,
              readOnly: fieldsOpt.signvariant.read_only,
            },
            all_kekv: {
              label: fieldsOpt.all_kekv.label,
              required: fieldsOpt.all_kekv.required,
              readOnly: fieldsOpt.all_kekv.read_only,
            },
            group_kekv: {
              label: fieldsOpt.group_kekv.label,
              required: fieldsOpt.group_kekv.required,
              readOnly: fieldsOpt.group_kekv.read_only,
            },
            full_kekv: {
              label: fieldsOpt.full_kekv.label,
              required: fieldsOpt.full_kekv.required,
              readOnly: fieldsOpt.full_kekv.read_only,
            },
            print_kdb: {
              label: fieldsOpt.print_kdb.label,
              required: fieldsOpt.print_kdb.required,
              readOnly: fieldsOpt.print_kdb.read_only,
            },
            print_kfb: {
              label: fieldsOpt.print_kfb.label,
              required: fieldsOpt.print_kfb.required,
              readOnly: fieldsOpt.print_kfb.read_only,
            },
            decoding_kekv: {
              label: fieldsOpt.decoding_kekv.label,
              required: fieldsOpt.decoding_kekv.required,
              readOnly: fieldsOpt.decoding_kekv.read_only,
            },
          },
        });
      } else {
        throw new NetworkException(`Could not make OPTIONS request to ${apiUrl} by reason ${resp.status} ${resp.statusText}`);
      }
    };

    showPrintedResult = async (settings) => {
      const { authF } = this.props;
      this.setState({
        isLoading: true,
      });
      const r = await api.post(`${apiUrl}`, authF, {
        settings: {
          ...settings,
        },
      });
      if (r.ok) {
        const data = await r.json();
        this.setState({
          isLoading: false,
        });
        document.getElementById('print-area').innerHTML = decodeURIComponent(escape(atob(data.result)));
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errText: `${r.status} ${r.statusText}`,
        });
      }
    };

    rewritePrintSettings = async (resultID, settings) => {
      const { authF } = this.props;
      this.setState({
        isLoading: true,
      });
      const r = await api.patch(`/api/auth/profilesettings/${resultID}/`, authF, {
        settings: {
          printform: {
            ...settings,
          },
        },
      });
      if (r.ok) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errText: `${r.status} ${r.statusText}`,
        });
      }
    };

    newPrintSettings = async (settings, profileID) => {
      const { authF } = this.props;
      this.setState({
        isLoading: true,
      });
      const r = await api.post('/api/auth/profilesettings/', authF, {
        name: `${apiUrl}`,
        settings: {
          printform: {
            ...settings,
          },
        },
        profile: profileID,
      });
      if (r.ok) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errText: `${r.status} ${r.statusText}`,
        });
      }
    };

    savePrintSettings = async (settings) => {
      const { authF, currentUser } = this.props;
      this.setState({
        isLoading: true,
      });
      const profileID = currentUser.profile.id;

      const r = await api.get('/api/auth/profilesettings/', authF, { profile: profileID, name: `${apiUrl}` });
      if (r.ok) {
        const data = await r.json();

        if (data.results.length !== 0) {
          const resultID = data.results[0].id;
          this.setState({
            isLoading: false,
          });

          this.rewritePrintSettings(resultID, settings);
        } else {
          this.newPrintSettings(settings, profileID);
        }
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errText: `${r.status} ${r.statusText}`,
        });
      }
    };

    getPrintSettings = async () => {
      const { authF, currentUser } = this.props;
      this.setState({
        isLoading: true,
      });
      const profileID = currentUser.profile.id;

      const r = await api.get('/api/auth/profilesettings/', authF, { profile: profileID, name: `${apiUrl}` });
      if (r.ok) {
        const data = await r.json();

        if (data.results.length !== 0) {
          const settings = data.results[0].settings.printform;
          this.setState({
            isLoading: false,
            savedSettings: settings,
          });
        } else {
          this.setState({
            isLoading: false,
            savedSettings: {},
          });
        }
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errText: `${r.status} ${r.statusText}`,
        });
      }
    };

    print = () => {
      const { isPortrait } = this.state;
      const table = document.getElementById('print-area');
      const el = table.cloneNode(true);
      const newEl = document.body.appendChild(el);
      newEl.className = 'print-area';
      const PageStyle = document.createElement('style');
      if (isPortrait) {
        PageStyle.innerHTML = '@page{size: landscape}';
        document.body.appendChild(PageStyle);
      }
      window.print();
      if (isPortrait) { document.body.removeChild(PageStyle); }
      document.body.removeChild(newEl);
    };

    pageSize=() => {
      const { isPortrait } = this.state;
      this.setState({ isPortrait: !isPortrait });
    };


    render() {
      const {
        data, errors, isErrored, errText, isLoading, fields, savedSettings,
      } = this.state;
      const {
        authF, ...rest
      } = this.props;
      return (
        <>
          {isErrored && (
            <ErrorMessage text={errText} />
          )}
          <DimableLoader loading={isLoading}>
            <WrappedComponent
              data={data}
              errors={errors}
              showPrintedResult={this.showPrintedResult}
              print={this.print}
              pageSize={this.pageSize}
              fields={fields}
              savePrintSettings={this.savePrintSettings}
              savedSettings={savedSettings}
              {...rest}
            />
          </DimableLoader>
        </>
      );
    }
  }

  PrintShower.propTypes = {
    id: PropTypes.string.isRequired,
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      profile: PropTypes.shape({
        id: PropTypes.number,
      }),
    }).isRequired,
  };

  return withAuthConsumer(mapStateAuth)(PrintShower);
};

export default withPrint;
