import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Button, Form, InputGroup, FormControl, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { DimableLoader } from '../../../components/bootStrap';

const H2 = styled.h2`
  ${({ color }) => `color: ${color}`}`;

const UpdateGroup = ({ onClose, activeId, setUpdateList }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [roles, setRoles] = useState(null);
  const [updatedRoles, setUpdatedRoles] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const getRoles = useCallback((permGroup) => {
    const loader = async () => {
      const r = await api.get('/api/auth/role/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const fillIn = (str) => permGroup.rolepriorityforgroup_set
          .find((perm) => perm.role.id === str.id);

        const newData = rData.results.map((el) => (fillIn(el) ? {
          role: { id: fillIn(el).role.id, name: fillIn(el).role.name },
          priority: fillIn(el).priority,
          group: null,
          check: true,
        } : {
          role: { id: el.id, name: el.name }, priority: '', group: null, check: false,
        }));

        setRoles((prev) => ({ ...prev, rolepriorityforgroup_set: [...newData] }));
        setUpdatedRoles((prev) => ({ ...prev, rolepriorityforgroup_set: [...newData] }));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
    /*eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const loader = async () => {
      const r = await api.get(`/api/auth/permissiongroup/${activeId}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setRoles(rData);
        setUpdatedRoles(rData);
        return getRoles(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [activeId, auth, getRoles, onError, onLoadEnd, onLoadStart]);

  const updateGroupParams = (e) => {
    const { name, value } = e.target;
    setUpdatedRoles({
      ...updatedRoles,
      [name]: value,
    });
  };

  const updateGroup = () => {
    setErr(null);
    const checkedRoles = updatedRoles.rolepriorityforgroup_set.filter((el) => el.check);
    const checkedRolesWithPriority = checkedRoles.filter((el) => el.priority);

    const rolepriorityforgroupset = checkedRolesWithPriority
      .map((r) => ({ role: { id: r.role.id }, priority: r.priority, group: null }));

    if (checkedRolesWithPriority.length < checkedRoles.length) {
      setErr('Не для всіх ролей вказано пріоритет!');
      return;
    }

    const params = {
      ...updatedRoles,
      rolepriorityforgroup_set: rolepriorityforgroupset,
    };
    const loader = async () => {
      const r = await api.put(`/api/auth/permissiongroup/${activeId}/`, auth, params);
      if (r.status === 400) {
        const error = await r.json();
        Object.entries(error).map((e) => {
          if (e) {
            throw new Error(`${e[0]}: ${e[1]}`);
          }
          return error;
        });
        return error;
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onClose();
        setUpdateList((prev) => !prev);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  };

  const handleCheckbox = useCallback((e, key) => {
    if (key === 'all') {
      setUpdatedRoles((prev) => ({
        ...prev,
        rolepriorityforgroup_set: [...prev.rolepriorityforgroup_set
          .map((r) => ({ ...r, check: true }))],
      }));
    } else if (key === 'nothing') {
      setUpdatedRoles((prev) => ({
        ...prev,
        rolepriorityforgroup_set: [...prev.rolepriorityforgroup_set
          .map((r) => ({ ...r, check: false, priority: '' }))],
      }));
    } else {
      const { id, checked } = e.target;
      setUpdatedRoles((prev) => ({
        ...prev,
        rolepriorityforgroup_set: [...prev.rolepriorityforgroup_set
          .map((i) => (i.role.id === parseInt(id, 10)
            ? { ...i, check: checked, priority: '' } : i))],
      }));
    }
  }, []);

  const handlePriority = useCallback((e) => {
    const { id, value } = e.target;
    setUpdatedRoles((prev) => ({
      ...prev,
      rolepriorityforgroup_set: [...prev.rolepriorityforgroup_set
        .map((i) => (i.role.id === parseInt(id, 10)
          ? { ...i, priority: value } : i))],
    }));
  },
  []);

  return (
    <>
      <DimableLoader loading={loading}>
        {err
      && <H2 color="red">{err}</H2>}
        {updatedRoles
        && (
          <>
            <Form>
              <Form.Group>
                <Form.Label htmlFor="name">Назва групи</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  onChange={updateGroupParams}
                  required
                  value={updatedRoles.name}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="description">Опис групи</Form.Label>
                <Form.Control
                  type="text"
                  id="description"
                  name="description"
                  as="textarea"
                  rows={3}
                  onChange={updateGroupParams}
                  value={updatedRoles.description}
                />
              </Form.Group>
            </Form>

            <H2 color="blue">Ролі:</H2>

            <div className="mb-2">
              <Button className="mr-2" onClick={() => handleCheckbox(null, 'all')}>
                <FontAwesomeIcon className="mr-2" icon={faCheckSquare} size="lg" />
                Включити всі
              </Button>
              <Button variant="outline-primary" onClick={() => handleCheckbox(null, 'nothing')}>
                <FontAwesomeIcon icon={faSquare} className="mr-2" size="lg" />
                Виключити всі
              </Button>
            </div>

            <Table hover>
              <thead>
                <tr>
                  <th style={{ width: '5px' }}>Включити в список</th>
                  <th>Назва</th>
                  <th style={{ width: '5px' }}>Пріоритет</th>
                </tr>
              </thead>
              <tbody>
                {updatedRoles.rolepriorityforgroup_set.map((el) => (
                  <tr key={el.id}>
                    <td>
                      <Form.Check
                        type="switch"
                        id={el.role.id}
                        label=""
                        checked={el.check}
                        onChange={handleCheckbox}
                      />
                    </td>
                    <td>{el.role.name}</td>
                    <td>
                      <InputGroup className="mb-3">
                        <FormControl
                          id={el.role.id}
                          type="number"
                          aria-describedby="basic-addon3"
                          onChange={handlePriority}
                          required
                          value={el.priority}
                          disabled={!el.check}
                        />
                      </InputGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Button className="mt-2 mr-2" variant="outline-success" onClick={updateGroup}>Зберегти</Button>
            <Button className="mt-2" variant="outline-danger" onClick={() => setUpdatedRoles(roles)}>Повернутись до попередніх значень</Button>
          </>
        )}

      </DimableLoader>
    </>
  );
};

UpdateGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
  activeId: PropTypes.number.isRequired,
};

export default UpdateGroup;
