import React, {
  useState, useEffect, useCallback, useMemo, useRef,
} from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Layout from '../../components/Layout';
import api from '../../api/req';
import { AppContext } from '../../providers/authProvider';
import { LogicaContext } from '../../providers/authLogicaProvider';
import LoginForm from '../../components/Login';
import { ErrorMessage, DimableLoader } from '../../components/bootStrap';

const Root = () => {
  const [currentUser, setCurrentUser] = useState(null);
  // const [tokens, setTokens] = useState({ accessToken: null, refreshToken: null });
  const tokens = useRef({
    accessToken: null,
    refreshToken: null,
  });
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [settings, setSettings] = useState({}); // key-value store for all settings in current session
  const [ticket, setTicket] = useState(() => localStorage.getItem('ticket')); // Видимо для Логики. Привет Макс. Не все то Тикет что от логики.
  // const [state, setState] = useState({
  //   currentUser: null,
  //   accessToken: null,
  //   refreshToken: null,
  //   isLoading: true,
  //   errorMsg: '',
  //   settings: {}, // key-value store for all settings in current session
  //   ticket: localStorage.getItem('ticket'),
  // });
  const clearSession = useCallback(
    () => {
      window.localStorage.removeItem('refreshToken');
      setCurrentUser(null);
      tokens.current.accessToken = null;
      tokens.current.refreshToken = null;
    },
    [],
  );

  // const auth = useCallback(
  //   () => ({
  //     access: tokens.accessToken,
  //     onRefresh: tryRefresh,
  //   })
  // )

  const tryLogout = useCallback(
    () => {
      setLoading(true);
      api.get$('/api-auth/logout', () => ({ access: tokens.current.accessToken })).then((r) => {
        if (r.ok) {
          clearSession();
          setLoading(false);
          setErr(null);
          localStorage.removeItem('ticket');
        } else {
          setLoading(false);
          setErr(`${r.status} ${r.statusText}`);
        }
      });
    },
    [clearSession],
  );

  const tryCurrentUser = useCallback(
    async (accessToken, refreshToken) => {
      const resp = await api.get$('/api/auth/currentuser/', () => ({ access: accessToken }));
      if (resp.ok) {
        const d = await resp.json();
        setCurrentUser(d[0]);
        setLoading(false);
        tokens.current.accessToken = accessToken;
        tokens.current.refreshToken = refreshToken;
        setErr(null);
        return true;
      }

      setLoading(false);
      setErr(`${resp.status} ${resp.statusText}`);
      clearSession();

      return false;
    },
    [clearSession],
  );

  const tryRefresh = useCallback(
    async () => {
    // eslint-disable-next-line
    const refreshToken = tokens.current.refreshToken || window.localStorage.getItem('refreshToken');
      if (refreshToken) {
        const r = await api.post$('/api/token/refresh', () => null, { refresh: refreshToken });
        if (r.ok) {
          const d = await r.json();
          window.localStorage.setItem('refreshToken', refreshToken);
          tokens.current.accessToken = d.access;
          tokens.current.refreshToken = refreshToken;
          return () => ({
            access: d.access,
          });
        }
      }
      tryLogout();
      return false;
    },
    [tryLogout],
  );

  const tryLogin = useCallback(
    async (username, password) => {
      setLoading(true);
      const r = await api.post$('/api/token/', () => null, { username, password });
      if (r.ok) {
        const d = await r.json();
        if (await tryCurrentUser(d.access, d.refresh)) {
          window.localStorage.setItem('refreshToken', d.refresh);
        }
      } else {
        try {
          const d = await r.json();
          const errors = Object.keys(d).reduce((R, k) => [...R, d[k]], []);
          const errorMsg = errors.reduce((R, e) => `${R} ${e}`, '');
          clearSession();
          setLoading(false);
          setErr(errorMsg);
        } catch {
          setLoading(false);
          setErr(`${r.status} ${r.statusText}`);
          clearSession();
        }
      }
    },
    [clearSession, tryCurrentUser],
  );

  useEffect(
    () => {
      setLoading(true);
      // const searchParams = new URLSearchParams(window.location.search);
      const re = new RegExp('ticket=([a-z,A-Z,0-9,+,\\/]*={1,})');
      const ticketString = window.location.href.match(re);
      if (ticketString) {
        const toDecode = ticketString[1];
        try {
        // const authdata = JSON.parse(toDecode);
          const { user, passwd } = JSON.parse(atob(toDecode));
          tryLogin(user, passwd);
        } catch (e) {
          console.log(`Ticket error ${e}`);
        }
      }

      tryRefresh().then(() => {
        tryCurrentUser(tokens.current.accessToken, tokens.current.refreshToken);
        setLoading(false);
      });
    },
    [tryCurrentUser, tryLogin, tryRefresh],
  );

  const onSetSettings = useCallback(
    (key, value) => setSettings((oldSettigs) => ({ ...oldSettigs, [key]: value })),
    [],
  );

  const getAuth = useCallback(
    () => ({
      access: tokens.current.accessToken,
      onRefresh: tryRefresh,
    }),
    [tryRefresh],
  );

  const onSetTicket = useCallback(
    (t) => {
      if (t) {
        setTicket(t);
        localStorage.setItem('ticket', t);
      } else {
        setTicket(null);
        localStorage.removeItem('ticket');
      }
    },
    [],
  );

  const appContextValue = useMemo(
    () => ({
      currentUser,
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
      logoutHandler: tryLogout,
      refreshHandler: tryRefresh,
      setSettings: onSetSettings,
      settings,
      auth: getAuth,
    }),
    [currentUser, getAuth, onSetSettings, settings, tokens.accessToken, tokens.refreshToken, tryLogout, tryRefresh],
  );

  const logicaContextValue = useMemo(() => ({
    ticket,
    setTicket: onSetTicket,
  }),
  [onSetTicket, ticket]);

  return (
    <div id="capture">
      <Router>
        <DimableLoader loading={loading}>
          {!currentUser ? (
            <LoginForm onLogin={tryLogin} errorMsg={err} />
          ) : (
            <AppContext.Provider value={appContextValue}>
              <LogicaContext.Provider value={logicaContextValue}>
                {err && (
                  <ErrorMessage text={err} />
                )}
                <Layout />
              </LogicaContext.Provider>
            </AppContext.Provider>
          )}
        </DimableLoader>
      </Router>
    </div>
  );
};

export default Root;
