import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, Row, Col, Container,
} from 'react-bootstrap';
import { paramPropType } from '../hooks';
import ParamsItem from './item';
import ParamsProps from './props';

const ParamsPage = ({
  params, activeParam, setActiveParam, onParamChange, allowedCtypes,
}) => {
  const displayParams = useMemo(
    () => params
      .map((param, key) => ({ ...param, key }))
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }),
    [params],
  );
  const activeProperties = useMemo(
    () => params.reduce((R, p) => (p.name === activeParam ? p : R), {}),
    [activeParam, params],
  );
  return (
    <Container fluid>
      <Row>
        <Col lg={3} md={6} sm={12} className="p-3">
          <ListGroup>
            {displayParams.map((param) => (
              <ParamsItem
                key={param.key}
                onClick={() => setActiveParam(param.name)}
                value={param}
                active={param.name === activeParam}
              />
            ))}
          </ListGroup>
        </Col>
        <Col className="containerCol">
          <ParamsProps
            onChange={(v) => onParamChange(activeParam, v)}
            allowedCtypes={allowedCtypes}
            value={activeProperties}
            readOnly={!('name' in activeProperties)}
          />
        </Col>
      </Row>
    </Container>
  );
};

ParamsPage.propTypes = {
  params: PropTypes.arrayOf(paramPropType).isRequired,
  activeParam: PropTypes.string,
  setActiveParam: PropTypes.func.isRequired,
  onParamChange: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
};

ParamsPage.defaultProps = {
  activeParam: null,
  allowedCtypes: [],
};

export default memo(ParamsPage);
