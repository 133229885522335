import {
  useCallback, useEffect, useRef, useState,
} from 'react';
// import api from '../../../../api/req';

/**
 *
 * @param modelType
 * @param modelName
 * @param viewType
 * @returns {string|null}
 */
// function getSettingsName(backendURL, viewType) {
//   return `${backendURL}/${viewType}`;
// }

/**
 *
 * @param backendURL {string}
 * @param viewType {string}
 * @param setLoading {function}
 * @param setErr {function}
 * @returns {{
 * onSaveSettings: (function(): void),
 * settings: {},
 * onLoadSettings: (function(): void),
 * onSetSettings: (function(*): void)
 * }}
 */
const useSettings = (backendURL, viewType, setLoading, setErr) => {
  const loadedSettings = useRef(null);
  const [settings, setSettings] = useState(null);

  useEffect(
    () => {
      loadedSettings.current = settings;
    },
    [settings],
  );

  const onSaveSettings = useCallback(
    () => {
      const asyncLoader = async (newSettings) => {
        const z = null;
        return z;
        // const settingsName = getSettingsName(modelType, modelName, viewType);
        //
        // const response = await api.put$(`settings/${settingsName}`, newSettings);
        // if (response.ok) {
        //   return true;
        // }
        // throw new Error(`Помилка при збереженні налаштувань  ${meta.label} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      asyncLoader(loadedSettings.current)
        .catch((e) => console.error(e.message))
        .finally(() => setLoading(false));
    },
    [setLoading],
  );

  const onSetSettings = useCallback(
    (partSettgins) => {
      setSettings((oldSettings) => {
        const newSettings = {
          ...oldSettings,
          ...partSettgins,
        };
        loadedSettings.current = newSettings;
        onSaveSettings();
        return newSettings;
      });
    },
    [onSaveSettings],
  );
  const onLoadSettings = useCallback(
    () => {
      const asyncLoader = async () => {
        const z = {};
        return z;
        // const settingsName = getSettingsName(modelType, modelName, viewType);
        //
        // const response = await api.post$(`settings/${settingsName}`);
        // if (response.ok) {
        //   return response.json();
        // }
        // throw new Error(`Помилка при завантаженні налаштувань  ${meta.label} (${response.status} ${response.statusText})`);
      };
      setLoading(true);
      setErr(null);
      asyncLoader()
        .then((data) => {
          setSettings(data);
        })
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [setErr, setLoading],
  );

  return {
    onLoadSettings,
    onSaveSettings,
    settings,
    onSetSettings,
  };
};

export default useSettings;
