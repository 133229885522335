import React from 'react';
import PropTypes from 'prop-types';
import { StyledCellContainer, StyledCellItemHeader, StyledTableHeaderCell } from '../../../../../basicEditor';
import { fieldsPropTypes } from './propTypes';

const Header = ({
  fields, isGoalProgramByYear,
}) => (
  <tr>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.targetprogram.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.is_fond.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    {isGoalProgramByYear
    && (
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.year.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
    )}

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.kdb.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.kfb.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.cashbox_expenses.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_zf_ce.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_sf_ce.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.report_plan.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_zf_rp.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_sf_rp.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.program_end_prediction.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_zf_pep.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_sf_pep.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.explanation.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.characterizing_explanation.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

  </tr>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  isGoalProgramByYear: PropTypes.bool.isRequired,
};

export default Header;
