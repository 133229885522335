import {
  StringInput, DateInput, NumberInput, Selector, ColorInput,
  TextInput, ItemField, ItemPicker, MultiValueInput, MultiSelector, FormatInput, PhoneInput,
} from '../../../components/Controls';
import EditorCheckboxInput from './checkbox';
import editorHooks from './hooks';
import dataHooks from './dataHooks';
import withEditorControl from './editorControl';

export const EditorStingInput = withEditorControl(StringInput);
export const EditorColorInput = withEditorControl(ColorInput);
export const EditorDateInput = withEditorControl(DateInput);
export const EditorNumberInput = withEditorControl(NumberInput);
export const EditorSelectorInput = withEditorControl(Selector);
export { EditorCheckboxInput };
export const EditorTextInput = withEditorControl(TextInput);
export const EditorItemField = withEditorControl(ItemField);
export const EditorItemPicker = withEditorControl(ItemPicker);
export const EditorMultiValueInput = withEditorControl(MultiValueInput);
export const EditorMultiSelectorInput = withEditorControl(MultiSelector);
export const EditorFormatInput = withEditorControl(FormatInput);
export const EditorPhoneInput = withEditorControl(PhoneInput);

export { editorHooks, dataHooks };

export const EditorControls = {
  StringInput: EditorStingInput,
  DateInput: EditorDateInput,
  NumberInput: EditorNumberInput,
  SelectorInput: EditorSelectorInput,
  CheckboxInput: EditorCheckboxInput,
  TextInput: EditorTextInput,
  ItemField: EditorItemField,
  ItemPicker: EditorItemPicker,
  MultiSelector: EditorMultiSelectorInput,
  FormatInput: EditorFormatInput,
  ColorInput: EditorColorInput,
  PhoneInput: EditorPhoneInput,
};
