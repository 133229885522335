import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer, StyledCellItem, StyledTableRow, StyledTD,
} from '../../../../../basicEditor';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';

const Row = ({
  rowIndex, active, selected, onActivateRow, onChangeRow, row, fields, errors,
  onlyYear, vt4br,
}) => {
  const amountChangeHandler = (e, newValue, m) => {
    const total = [...Array(12)].reduce((R, _i, k) => (R + (k === (m - 1) ? newValue : row[`amount_${k + 1}`] || 0)), 0);
    onChangeRow(e, rowIndex, {
      [`amount_${m}`]: newValue,
      amount_total: total,
    });
  };

  const amountBRChangeHandler = (e, newValue, m) => {
    const total = [...Array(12)].reduce((R, _i, k) => (R + (k === (m - 1) ? newValue : row[`amount_br_${k + 1}`] || 0)), 0);
    onChangeRow(e, rowIndex, {
      [`amount_br_${m}`]: newValue,
      amount_br_total: total,
    });
  };

  const kdbProps = tableHooks.useItemInputProps('kdb', row, fields, errors, onChangeRow, rowIndex);

  const amountTotalProps = tableHooks.useNumberInputProps('amount_total', row, fields, errors, onChangeRow, rowIndex, true);
  const amountBrTotalProps = tableHooks.useNumberInputProps('amount_br_total', row, fields, errors, onChangeRow, rowIndex, true);

  const amount1Props = tableHooks.useNumberInputProps('amount_1', row, fields, errors, onChangeRow, rowIndex);
  const amount2Props = tableHooks.useNumberInputProps('amount_2', row, fields, errors, onChangeRow, rowIndex);
  const amount3Props = tableHooks.useNumberInputProps('amount_3', row, fields, errors, onChangeRow, rowIndex);
  const amount4Props = tableHooks.useNumberInputProps('amount_4', row, fields, errors, onChangeRow, rowIndex);
  const amount5Props = tableHooks.useNumberInputProps('amount_5', row, fields, errors, onChangeRow, rowIndex);
  const amount6Props = tableHooks.useNumberInputProps('amount_6', row, fields, errors, onChangeRow, rowIndex);
  const amount7Props = tableHooks.useNumberInputProps('amount_7', row, fields, errors, onChangeRow, rowIndex);
  const amount8Props = tableHooks.useNumberInputProps('amount_8', row, fields, errors, onChangeRow, rowIndex);
  const amount9Props = tableHooks.useNumberInputProps('amount_9', row, fields, errors, onChangeRow, rowIndex);
  const amount10Props = tableHooks.useNumberInputProps('amount_10', row, fields, errors, onChangeRow, rowIndex);
  const amount11Props = tableHooks.useNumberInputProps('amount_11', row, fields, errors, onChangeRow, rowIndex);
  const amount12Props = tableHooks.useNumberInputProps('amount_12', row, fields, errors, onChangeRow, rowIndex);

  const amountBr1Props = tableHooks.useNumberInputProps('amount_br_1', row, fields, errors, onChangeRow, rowIndex);
  const amountBr2Props = tableHooks.useNumberInputProps('amount_br_2', row, fields, errors, onChangeRow, rowIndex);
  const amountBr3Props = tableHooks.useNumberInputProps('amount_br_3', row, fields, errors, onChangeRow, rowIndex);
  const amountBr4Props = tableHooks.useNumberInputProps('amount_br_4', row, fields, errors, onChangeRow, rowIndex);
  const amountBr5Props = tableHooks.useNumberInputProps('amount_br_5', row, fields, errors, onChangeRow, rowIndex);
  const amountBr6Props = tableHooks.useNumberInputProps('amount_br_6', row, fields, errors, onChangeRow, rowIndex);
  const amountBr7Props = tableHooks.useNumberInputProps('amount_br_7', row, fields, errors, onChangeRow, rowIndex);
  const amountBr8Props = tableHooks.useNumberInputProps('amount_br_8', row, fields, errors, onChangeRow, rowIndex);
  const amountBr9Props = tableHooks.useNumberInputProps('amount_br_9', row, fields, errors, onChangeRow, rowIndex);
  const amountBr10Props = tableHooks.useNumberInputProps('amount_br_10', row, fields, errors, onChangeRow, rowIndex);
  const amountBr11Props = tableHooks.useNumberInputProps('amount_br_11', row, fields, errors, onChangeRow, rowIndex);
  const amountBr12Props = tableHooks.useNumberInputProps('amount_br_12', row, fields, errors, onChangeRow, rowIndex);

  return (
    <StyledTableRow
      active={active}
      selected={selected}
      onClick={(e) => onActivateRow(e, rowIndex)}
    >

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...kdbProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      {onlyYear ? (
        <StyledTD>
          <StyledCellContainer>
            <StyledCellItem>
              <TableControls.NumberInput
                {...amount1Props}
                onChange={(e, v) => amountChangeHandler(e, v, 1)}
              />
            </StyledCellItem>
            {vt4br && (
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr1Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 1)}
                />
              </StyledCellItem>
            )}
          </StyledCellContainer>
        </StyledTD>
      ) : (
        <>
          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput {...amountTotalProps} />
              </StyledCellItem>
              {vt4br && (
              <StyledCellItem>
                <TableControls.NumberInput {...amountBrTotalProps} />
              </StyledCellItem>
              )}
            </StyledCellContainer>
          </StyledTD>

          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount1Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 1)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount2Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 2)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount3Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 3)}
                />
              </StyledCellItem>
            </StyledCellContainer>
          </StyledTD>

          {vt4br && (
          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr1Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 1)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr2Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 2)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr3Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 3)}
                />
              </StyledCellItem>
            </StyledCellContainer>
          </StyledTD>
          )}

          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount4Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 4)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount5Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 5)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount6Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 6)}
                />
              </StyledCellItem>
            </StyledCellContainer>
          </StyledTD>

          {vt4br && (
          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr4Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 4)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr5Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 5)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr6Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 6)}
                />
              </StyledCellItem>
            </StyledCellContainer>
          </StyledTD>
          )}

          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount7Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 7)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount8Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 8)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount9Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 9)}
                />
              </StyledCellItem>
            </StyledCellContainer>
          </StyledTD>

          {vt4br && (
          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr7Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 7)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr8Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 8)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr9Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 9)}
                />
              </StyledCellItem>
            </StyledCellContainer>
          </StyledTD>
          )}

          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount10Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 10)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount11Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 11)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amount12Props}
                  onChange={(e, v) => amountChangeHandler(e, v, 12)}
                />
              </StyledCellItem>
            </StyledCellContainer>
          </StyledTD>

          {vt4br && (
          <StyledTD>
            <StyledCellContainer>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr10Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 10)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr11Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 11)}
                />
              </StyledCellItem>
              <StyledCellItem>
                <TableControls.NumberInput
                  {...amountBr12Props}
                  onChange={(e, v) => amountBRChangeHandler(e, v, 12)}
                />
              </StyledCellItem>
            </StyledCellContainer>
          </StyledTD>
          )}
        </>
      )}
    </StyledTableRow>
  );
};

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  onlyYear: PropTypes.bool.isRequired,
  vt4br: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  active: false,
  selected: false,
  errors: {},
};

export default memo(Row);
