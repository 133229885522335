const frontendURL = '/cat/workercategory/';
const backendURL = '/api/references/refworkercategory/';
const name = 'Категорії працівників';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
