import {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import useLister from '../hooks';

/**
 * Минимальный остаток скроллинга, при котором начинается загрухзка следующей страницы
 * @type {number}
 */
const DELTA_FOR_LASY_LOADING = 200;

/**
 *
 * @param backendURL {string}
 * @param viewType {string}
 * @param options
 * @returns {{
 * tableRef: React.MutableRefObject<undefined>,
 * onRowFocusHandler: (function(*, *=): void),
 * onTableScroll:  (function(event): void)}},
 * areaSize: {
 *   width Number,
 *   height: Number,
 * }
 *
 */
// eslint-disable-next-line import/prefer-default-export
export const useTableComponent = (
  backendURL, viewType = 'lister', options,
) => {
  const {
    items, openedItems, onRowFocus, onNextPage, loading, searchString, period,
    visibleColumns, selectedRows, onSetOrder, order, err, showDeleted, settings,
    localFilter, filterOpened, filteringFields,
    onReload, onSetSettings,
    permissions,
    actions, messages,
  } = useLister(backendURL, viewType, options);

  const tableRef = useRef();
  const commandPanelRef = useRef();

  const [areaSize, setAreaSize] = useState({
    width: 0,
    height: 0,
  });

  const [ctxMenu, setCtxMenu] = useState({
    visible: false,
    node: null,
    top: 0,
    left: 0,
  });

  const columnSizes = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () => settings ? settings.columnSizes : {},
    [settings],
  );

  const onResizeColumn = useCallback(
    (e, c, newSize) => onSetSettings({ columnSizes: { ...columnSizes, [c]: `${newSize}px` } }),
    [columnSizes, onSetSettings],
  );

  const onResetColumnSize = useCallback(
    (e, c) => onSetSettings({ columnSizes: { ...columnSizes, [c]: '1fr' } }),
    [columnSizes, onSetSettings],
  );

  // Запоминаем размеры контейнера, для того чтобы
  // развернуть таблицу на весь жран и органищовать скролл
  const onResize = useCallback(
    () => {
      const rootContainer = document.getElementById('root');
      const wmContainer = document.querySelector('.LinksManager');
      const footerHight = wmContainer ? wmContainer.getBoundingClientRect().height : 0;
      const { width } = rootContainer.getBoundingClientRect();
      const top = tableRef.current ? tableRef.current.getBoundingClientRect().top : 0;
      setAreaSize({
        width,
        height: rootContainer.clientHeight - top - footerHight,
      });
    },
    [],
  );

  useEffect(
    () => {
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    },
    [onResize],
  );
  useEffect(
    () => {
      if (visibleColumns.length) onResize();
    },
    [onResize, visibleColumns.length],
  );
  const onRowFocusHandler = useCallback(
    (e, rowId) => {
      e.persist();
      onRowFocus(e, rowId, e.ctrlKey);
    },
    [onRowFocus],
  );

  // События клавиатуры
  const onKeyDown = useCallback(
    (e) => {
      if (tableRef.current.contains(e.target)) {
        if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
          e.preventDefault();
          e.stopPropagation();
          const el = tableRef.current.querySelector('tr:focus');
          if (!el) return false;
          let newTabIndex = el.tabIndex;
          switch (e.key) {
            case 'ArrowUp':
              newTabIndex -= 1;
              break;
            case 'ArrowDown':
              newTabIndex += 1;
              break;
            default:
              throw new Error('Unknow key');
          }
          const newEl = tableRef.current.querySelector(`tr[tabindex="${newTabIndex}"]`);
          if (!newEl) return false;

          newEl.focus();
          onRowFocus(e, newEl.dataset.id, e.shiftKey);
        } else if (e.key === 'Insert' && permissions.canNew && actions.onNew) {
          actions.onNew(e);
        } else if (e.key === 'Delete' && e.shiftKey && permissions.canDelete && actions.onDelete) {
          actions.onDelete(e);
        } else if (['ArrowLeft', 'ArrowRight'].includes(e.key) && permissions.canHierarchy) {
          const el = tableRef.current.querySelector('tr:focus');
          if (el) {
            actions.onToggle(e, el.dataset.id);
          }
        } else if (e.key === 'F2' && permissions.canEdit && actions.onEdit) {
          actions.onEdit(e);
        } else if (e.key === 'F9' && permissions.canCopy && actions.onCopy) {
          actions.onCopy(e);
        } else if (['F', 'f', 'а', 'А'].includes(e.key) && e.ctrlKey && permissions.canSearch) {
          if (commandPanelRef.current) {
            const el = commandPanelRef.current.querySelector('input.search');
            if (el) el.focus();
          }
        } else {
          return false;
        }

        e.stopPropagation();
        e.preventDefault();
        return true;
      }
      return false;
    },
    [actions, onRowFocus, permissions.canCopy, permissions.canDelete, permissions.canEdit,
      permissions.canHierarchy, permissions.canNew, permissions.canSearch],
  );

  const onShowCtxMenu = useCallback(
    (e, rowId) => {
      e.preventDefault();
      onRowFocusHandler(e, rowId);
      setCtxMenu({
        visible: true,
        node: e.target,
        top: e.clientY,
        left: e.clientX,
      });
    },
    [onRowFocusHandler],
  );
  const onHideCtxMenu = useCallback(
    () => setCtxMenu({
      visible: false,
      node: null,
    }),
    [],
  );

  // предыдущее значение положения скрола
  const oldScrollTop = useRef(null);

  const onTableScroll = useCallback(
    (e) => {
      const currentTop = e.target.scrollTop;
      const { scrollHeight } = e.target;
      const { height } = e.target.getBoundingClientRect();
      if (!loading && oldScrollTop.current) {
        // Скроллим вверх
        if (currentTop < oldScrollTop.current) {
        //   if (permissions.canPrevPage && currentTop < DELTA_FOR_LASY_LOADING) {
        //     onPrevPage();
        //   }
        // Скроллим вниз
        } else if (currentTop > oldScrollTop.current) {
          if (permissions.canNextPage
              && (scrollHeight - height - currentTop < DELTA_FOR_LASY_LOADING)) {
            onNextPage();
          }
        }
      }
      oldScrollTop.current = currentTop;
    },
    [loading, onNextPage, permissions.canNextPage],
  );

  useEffect(
    () => {
      const node = tableRef.current;
      node.addEventListener('keydown', onKeyDown);
      return () => node.removeEventListener('keydown', onKeyDown);
    },
  );

  // Если это документ, то после того, как все загрузилось - скролим последний элемент
  // количество items в предыдущие раз
  const prevItemsCount = useRef(0);

  useEffect(
    () => {
      if (prevItemsCount.current === 0) {
        let el = tableRef.current.querySelector('tbody tr.selected');
        // if (!el && modelType === 'doc') {
        //   el = tableRef.current.querySelector('tbody tr:last-child');
        // } else if (!el && modelType === 'cat') {
        el = tableRef.current.querySelector('tbody tr:first-child');
        // }
        if (el) {
          el.focus();
        }
      }
      prevItemsCount.current = items.length;
    },
    [items.length],
  );

  // ПРи начале загрузки запоминаем положение скролла и восстанавливаем его по окончании
  // типа getSnapshotBeforeUpdate, который react на хуках еще не придумал
  const scrollSnapShot = useRef({
    scroll: null,
    itemsCount: 0,
    loading: false,
  });

  useEffect(
    () => {
      if (!loading && items.length) {
        scrollSnapShot.current.itemsCount = items.length;
      }
      scrollSnapShot.current.loading = loading;
    },
    [items.length, loading],
  );

  const maxItemLevel = useMemo(
    () => (items.length ? Math.max(...items.map((item) => item.level)) : 0),
    [items],
  );
  return {
    tableRef,
    commandPanelRef,
    onRowFocusHandler,
    onTableScroll,
    areaSize,
    visibleColumns,
    items,
    openedItems,
    selectedRows,
    onSetOrder,
    order,
    localFilter,
    loading,
    searchString,
    err,
    showDeleted,
    period,
    onReload,
    permissions,
    actions,
    columnSizes,
    onResizeColumn,
    onResetColumnSize,
    ctxMenu,
    onShowCtxMenu,
    onHideCtxMenu,
    maxItemLevel,
    messages,
    filterOpened,
    filteringFields,
  };
};
