import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer,
  StyledCellItemHeader,
  StyledTableHeaderCell,
} from '../../../../../basicEditor';

const Header = ({ year }) => (
  <tr>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {year - 2}
          {' '}
          (звіт) Загальний фонд
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {year - 2}
          {' '}
          (звіт) Спеціальний фонд
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {year - 1}
          {' '}
          (затверджено) Загальний фонд
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {year - 1}
          {' '}
          (затверджено) Спеціальний фонд
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {year}
          {' '}
          (план) Загальний фонд
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {year}
          {' '}
          (план) Спеціальний фонд
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {year + 1}
          {' '}
          (план) Загальний фонд
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {year + 1}
          {' '}
          (план) Спеціальний фонд
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {year + 2}
          {' '}
          (план) Загальний фонд
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {year + 2}
          {' '}
          (план) Спеціальний фонд
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

  </tr>
);

Header.propTypes = {
  year: PropTypes.number,
};

Header.defaultProps = {
  year: 0,
};

export default Header;
