import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer,
  StyledCellItemHeader,
  StyledTableHeaderCell,
} from '../../../../../basicEditor';
import { fieldsPropTypes } from './propTypes';

const Header = ({ fields, on3Years, year }) => {
  const year1 = `${year + 1}p.`;
  const year2 = `${year + 2}p.`;

  return (
    <tr>
      <StyledTableHeaderCell>
        <StyledCellContainer>
          <StyledCellItemHeader>{fields.kdb.label}</StyledCellItemHeader>
        </StyledCellContainer>
      </StyledTableHeaderCell>

      <StyledTableHeaderCell>
        <StyledCellContainer>
          <StyledCellItemHeader>
            {fields.first_year_zf.label}
            {' '}
            {year}
р.
          </StyledCellItemHeader>
          {on3Years && (
            <>
              <StyledCellItemHeader>
                {fields.second_year_zf.label}
                {' '}
                {year1}
              </StyledCellItemHeader>
              <StyledCellItemHeader>
                {fields.third_year_zf.label}
                {' '}
                {year2}
              </StyledCellItemHeader>
            </>
          )}
        </StyledCellContainer>
      </StyledTableHeaderCell>

      <StyledTableHeaderCell>
        <StyledCellContainer>
          <StyledCellItemHeader>
            {fields.first_year_sf.label}
            {' '}
            {year}
р.
          </StyledCellItemHeader>
          {on3Years && (
            <>
              <StyledCellItemHeader>
                {fields.second_year_sf.label}
                {' '}
                {year1}
              </StyledCellItemHeader>
              <StyledCellItemHeader>
                {fields.third_year_sf.label}
                {' '}
                {year2}
              </StyledCellItemHeader>
            </>
          )}
        </StyledCellContainer>
      </StyledTableHeaderCell>

      <StyledTableHeaderCell>
        <StyledCellContainer>
          <StyledCellItemHeader>
            {fields.first_year_br.label}
            {' '}
            {year}
р.
          </StyledCellItemHeader>
          {on3Years && (
            <>
              <StyledCellItemHeader>
                {fields.second_year_br.label}
                {' '}
                {year1}
              </StyledCellItemHeader>
              <StyledCellItemHeader>
                {fields.third_year_br.label}
                {' '}
                {year2}
              </StyledCellItemHeader>
            </>
          )}
        </StyledCellContainer>
      </StyledTableHeaderCell>
    </tr>
  );
};

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  on3Years: PropTypes.bool.isRequired,
  year: PropTypes.number.isRequired,
};

export default Header;
