import styled from 'styled-components';
import { Table } from 'react-bootstrap';

export const StyledTableContainer = styled.div`
  max-height: 86vh;
  overflow: auto;
  border: 2px solid var(--tableBorder);
`;

export const StyledTable = styled(Table)`
  margin-bottom: 0 !important;
  position: relative;
  & tr{
    transition: all 0.1s linear 0s;
  }
`;

export const StyledTH = styled.th`
  vertical-align: middle !important;
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--backgroundTableHeader);
  line-height: 1.2;
  // background: #E0E4CC;
  color: var(--colorTextTableHeader);
  border: 1px solid var(--tableBorder)!important;
  cursor: ${({ orderable }) => (orderable ? 'pointer' : 'unset')};
`;

export const StyledTr = styled.tr`
   background: ${(props) => (props.active ? 'var(--backgroundActiveRow)!important' : 'transparent')};
   color: ${(props) => props.active && 'var(--colorTextActiveRow)!important'};
   line-height: 1.2;
`;

export const StyledTD = styled.td`
  border: 1px solid var(--tableBorder) !important;
  vertical-align: middle !important;
`;

export const StyledDiv = styled.div`
  margin-left: ${(props) => `${props.level}em`};
  cursor:pointer;
`;

export const StyledCell = styled.div`
  padding: 0.2em;
`;

export const StyledString = styled(StyledCell)`
`;

export const StyledNumber = styled(StyledCell)`
  text-align: right;
`;
