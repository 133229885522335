import styled from 'styled-components';

export const MonthGrid = styled.div`
  display: grid;
  grid-template-areas: 
          "1fr 1fr 1fr"
          "1fr 1fr 1fr"
          "1fr 1fr 1fr"
          "1fr 1fr 1fr";
  & > .btn {
    border-radius: 0;
    position: relative;
  }
  & > .btn:first-child {
    border-radius: 0.25rem 0 0 0 ;
  }
  & > .btn:nth-child(3) {
    border-radius: 0 0.25rem 0 0 ;
  }
  & > .btn:nth-child(10) {
    border-radius: 0 0 0 0.25rem ;
  }
  & > .btn:last-child {
    border-radius: 0 0 0.25rem 0 ;
  }
  & >.btn.start-0.end-0{
    background: var(--primary);
    color: white;
  }
  & >.btn.start-part.end-0{
    background: linear-gradient(to right,white 20%,var(--primary) 25%);
    color: white;
  }
  & >.btn.start-0.end-part{
    background: linear-gradient(to right,var(--primary) 75%, white 80%);
    color: white;
  }
  & >.btn.start-part.end-part{
    background: linear-gradient(to right,white 20%,var(--primary) 25%, var(--primary) 75%, white 80%);
    color: white;
  }
`;
