import React, {
  useMemo, lazy, Suspense, memo,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ListGroupItem, Spinner, Badge,
} from 'react-bootstrap';
import Checkbox from '../../../../components/Controls/checkbox';
import { foreignPropType } from '../../../../constants/backend/propTypes';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SListGroupItem = styled(ListGroupItem)`
background: var(--backgroundHoverFilter) !important;
  :hover {
    background: var(--hoverBackgroundHoverFilter) !important;
    }
`;

const FilerField = ({
  desc, value, onSetFilter,
}) => {
  const onChange = useMemo(
    () => (e, v) => onSetFilter(desc.name, v, true),
    [desc.name, onSetFilter],
  );

  const FilterComponent = useMemo(() => {
    if (desc.type === 'string') {
      return lazy(() => import('../../../../components/Controls/stringInput'));
    }
    if (desc.type === 'date') {
      return lazy(() => import('../../../../components/Controls/dates/dateInput'));
    }
    if (desc.type === 'boolean') {
      return lazy(() => import('../../../../components/Controls/selector'));
    }
    if (desc.type === 'integer') {
      return lazy(() => import('../../../../components/Controls/numberInput'));
    }
    if (desc.type === 'nested object' && desc.resource) {
      // eslint-disable-next-line import/no-cycle
      return lazy(() => import('../../../../components/Controls/itemField/itemPicker'));
    }
    return null;
  }, [desc.resource, desc.type]);

  const fcProps = useMemo(() => {
    const globalProps = {
      id: `filter-${desc.name}-changer`,
      value: value ? value.value : null,
      onChange,
    };
    if (desc.type === 'string') {
      return {
        ...globalProps,
        onChange: (e, v) => onSetFilter(desc.name, v, !!v.length),
      };
    }
    if (desc.type === 'boolean') {
      return {
        ...globalProps,
        values: [
          { value: true, display_name: 'Так' },
          { value: false, display_name: 'Ні' },
        ],
      };
    }
    if (desc.type === 'integer') {
      return {
        ...globalProps,
        minValue: desc.min_value,
        maxValue: desc.max_value,
      };
    }
    if (desc.type === 'nested object' && desc.resource) {
      return {
        ...globalProps,
        backend: desc.resource,
        maxValue: desc.max_value,
        onChange: (e, v) => onSetFilter(desc.name, v, !!v),
        canErase: true,
      };
    }
    return globalProps;
  }, [
    desc.max_value, desc.min_value, desc.name,
    desc.resource, desc.type, onChange, onSetFilter, value,
  ]);
  return (
    <SListGroupItem variant="light">
      <FilterContainer>
        {FilterComponent ? (
          <>
            <Checkbox
              id={`filter-${desc.name}-check`}
              onChange={() => (value ? onSetFilter(desc.name, value.value, !value.use) : null)}
              label={desc.label}
              value={value && value.use}
            />
            <Suspense fallback={<Spinner animation="grow" />}>
              <FilterComponent {...fcProps} />
            </Suspense>
          </>
        ) : (
          <Badge variant="danger">
            {`Type ${desc.type} not supported in filter ${desc.name}`}
          </Badge>
        )}
      </FilterContainer>
    </SListGroupItem>
  );
};

FilerField.propTypes = {
  desc: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    resource: PropTypes.string,
    min_value: PropTypes.number,
    max_value: PropTypes.number,
  }).isRequired,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string, // str & date
      PropTypes.number, // int && decimal
      PropTypes.bool, //
      foreignPropType,
    ]),
    use: PropTypes.bool,
  }),
  onSetFilter: PropTypes.func.isRequired,
};

FilerField.defaultProps = {
  value: null,
};

export default memo(FilerField);
