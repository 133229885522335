import React from 'react';
import { StyledCellContainer, StyledCellItemHeader, StyledTableHeaderCell } from '../../../../../basicEditor';
import { fieldsPropTypes } from './propTypes';

const Header = ({
  fields,
}) => (
  <tr>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>Завдання</StyledCellItemHeader>
        <StyledCellItemHeader>Заповнювати дані з</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.amount_zf0.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_sf0.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_br0.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.amount_total0.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
  </tr>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
};

export default Header;
