import React from 'react';
import PropTypes from 'prop-types';
import { faSync as icon } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const RefreshButton = ({
  content, title, onClick, disabled,
}) => (
  <CPButton
    icon={icon}
    onClick={onClick}
    content={content}
    disabled={disabled}
    title={title}
  />
);

RefreshButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

RefreshButton.defaultProps = {
  content: 'Оновити',
  disabled: false,
  title: '',
};

export default RefreshButton;
