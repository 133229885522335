import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useSelectorInputProps } from '../hooks/selectorHook';
import api from '../../../../api/req';
import { AppContext } from '../../../../providers/authProvider';

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { boolean } isReadOnly - isReadOnly
 * @param {string[]} readOnlyFelds - readOnlyFelds
 * @param {{id: number, repr: string}} owner - owner
 * @param {{id: number, repr: string}} classifier - classifier
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  label: string,
 *  value: string,
 *  required: boolean,
 *  values: Array,
 *  errors: Array}}
 */
export const useLevelUpProps = ({
  name, data, fields, errors, onChange, isReadOnly = false, readOnlyFelds = [],
  owner = null, classifier = null,
}) => {
  const baseProps = useSelectorInputProps(
    name, data, fields, errors, onChange, isReadOnly, readOnlyFelds,
  );
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  const { auth } = useContext(AppContext);

  const backend = useMemo(
    () => (fields && fields[name] ? fields[name].resource : null),
    [fields, name],
  );
  useEffect(
    () => {
      // eslint-disable-next-line no-constant-condition
      if (owner || classifier || true) setValues([]);
    }, [owner, classifier],
  );
  const onDropDown = useCallback(
    () => {
      const loader = async () => {
        const params = {
          classifier: classifier ? classifier.id : null,
          owner: owner ? owner.id : null,
        };
        const r = await api.get(`${backend}levelups/`, auth, params);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      setLoading(false);
      setErr(null);
      loader()
        .then((d) => setValues(d.map((value) => ({
          value,
          display_name: value.repr,
          disabled: !value.is_active,
        }))))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, backend, classifier, owner],
  );
  return useMemo(
    () => ({
      ...baseProps,
      values,
      onDropDown,
    }),
    [baseProps, onDropDown, values],
  );
};
export default useLevelUpProps;
