import { ButtonToolbar } from 'react-bootstrap';
import styled from 'styled-components';

export const CommandPanel = styled(ButtonToolbar)`
  margin-bottom: 10px;
  &>:not(:last-child){
    margin-right: 5px;
  }  
`;

export default CommandPanel;
