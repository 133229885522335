import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, {
  DocCommandPanel,
  getFieldsPropTypes,
  getErrorsPropTypes,
} from '../../../basicEditor';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { stdDocDataPropTypes } from '../../common';
import { HeaderText } from '../../../../components/Styled/Forms';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import {
  FlexContainer,
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import LoadAllocationPlanButton from './loadAllocaionPlan';
import { PrintButton } from './printButton';

const Editor = ({
  data,
  options,
  isNew,
  isReadOnly,
  isModified,
  onSave,
  onClose,
  onExecute,
  onCancelExecute,
  fields,
  errors,
  onChange,
  onDraft,
}) => {
  const projectVariantProps = editorHooks.useItemInputProps(
    'project_variant',
    data,
    fields,
    errors,
    onChange,
  );
  const numberProps = editorHooks.useStringInputProps(
    'number',
    data,
    fields,
    errors,
    onChange,
  );
  const dateProps = editorHooks.useDateInputProps(
    'doc_date',
    data,
    fields,
    errors,
    onChange,
  );
  const yearProps = editorHooks.useNumberInputProps(
    'year',
    data,
    fields,
    errors,
    onChange,
  );

  const disposerProps = editorHooks.useItemInputProps(
    'disposer',
    data,
    fields,
    errors,
    onChange,
  );

  const sessionSolutionProps = editorHooks.useItemInputProps(
    'session_solution',
    data,
    fields,
    errors,
    onChange,
  );

  const noteBasisProps = editorHooks.useStringInputProps(
    'note_basis',
    data,
    fields,
    errors,
    onChange,
  );

  return (
    <FlexContainer>
      <HeaderText>
        {isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}
      </HeaderText>
      <DocCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        onSave={() => onSave()}
        onClose={onClose}
        executed={data.executed}
        onExecute={() => onExecute()}
        onCancelExecute={() => onCancelExecute()}
      >
        <PrintButton id={`${data.id}`} />
      </DocCommandPanel>
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...projectVariantProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.ItemPicker {...disposerProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...sessionSolutionProps} />
              </Col>
              <Col>
                <EditorControls.StringInput {...noteBasisProps} />
              </Col>
            </Row>
            <LoadAllocationPlanButton
              authority={data.authority}
              budget={data.budget}
              docDate={data.doc_date}
              year={data.year}
              onLoadHandler={(rows) => onDraft(data, {
                load_expprojchng_rows: {
                  rows,
                  clear: true,
                },
              })}
            />
          </Container>
        </HidableContainer>

        <LabelTab
          defaultActiveKey="expensesprojectchanges"
          id="uncontrolled-tab-example"
        >
          <Tab
            eventKey="expensesprojectchanges"
            title={fields.expensesprojectchangesrow_set.label}
          >
            <TabContainer>
              <PlanningRows
                data={data.expensesprojectchangesrow_set}
                fields={fields.expensesprojectchangesrow_set.child.children}
                // readOnly={fields.expensesprojectchangesrow_set.read_only}
                errors={errors.expensesprojectchangesrow_set}
                onChange={(set) => onChange({ expensesprojectchangesrow_set: set })}
                disposerInHeader={data.disposer_in_header}
                authorityId={data.authority.id}
              />
            </TabContainer>
          </Tab>
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </FlexContainer>
  );
};

const dataPropTypes = {
  ...stdDocDataPropTypes,
  disposer_in_header: PropTypes.bool,
  comment: PropTypes.string, // автоматическое примечание
  comment_of_author: PropTypes.string, // Примечания пользователя
  disable_control: PropTypes.bool,
  number: PropTypes.string,
  doc_date: PropTypes.data,
  plan_kind: PropTypes.number,
  authority_acc: PropTypes.foreignPropType,
  is_financed: PropTypes.bool,
  is_transfer: PropTypes.bool,
  is_refund: PropTypes.bool,
  exec_if_nomoney: PropTypes.bool,
};

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape(dataPropTypes).isRequired,
  fields: PropTypes.shape(getFieldsPropTypes(dataPropTypes)).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onExecute: PropTypes.func.isRequired,
  onCancelExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.doc.expensesprojectchanges.backendURL)(Editor);
