import PropTypes from 'prop-types';
import dataPT, { foreignPropType } from '../../../constants/backend/propTypes';

export const stdDocDataPropTypes = {
  ...dataPT,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  number: PropTypes.string,
  doc_date: PropTypes.string,
  executed: PropTypes.bool,
  deleted: PropTypes.bool,
  authority: foreignPropType,
  budget: foreignPropType,
  author: foreignPropType,
};

export { foreignPropType };
