import React from 'react';
import {
  Accordion, ListGroup, Button, Card, Badge, Nav, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare, faSquare,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledAccordion = styled(Accordion)`
  position: absolute`;

const List = ({
  list, onCheck, onCheckAll, onUncheckAll,
}) => (
  <StyledAccordion defaultActiveKey="1">
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          Бюджети
          <Badge variant="success" className="ml-1">
            {list.filter((l) => l.check).length}
            {' '}
            /
            {list.length}
          </Badge>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <>
          <Nav>
            <Button className="mr-2" onClick={onCheckAll} variant="outline-success">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="mr-1"
              />
              Вибрати всі
            </Button>
            <Button onClick={onUncheckAll} variant="outline-danger">
              <FontAwesomeIcon
                icon={faSquare}
                className="mr-1"
              />
              Скасувати всі
            </Button>
          </Nav>
          <ListGroup variant="flush" className="mt-2">
            {list.map((el) => (
              <ListGroup.Item key={el.id}>
                <Form.Check
                  type="checkbox"
                >
                  <Form.Check.Input
                                  checked={el.check}
                                  onChange={() => onCheck(el.id)}
                                />
                  <Form.Check.Label>
                                  <Badge variant="info" className="mr-2">
                      {el.code}
                    </Badge>
                                  {el.name}

                                </Form.Check.Label>
                </Form.Check>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      </Accordion.Collapse>
    </Card>
  </StyledAccordion>

);

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onCheck: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func.isRequired,
  onUncheckAll: PropTypes.func.isRequired,
};

List.defaultProps = {
  list: null,
};
export default List;
