import React from 'react';
import {
  StyledCellContainer,
  StyledCellItemHeader,
  StyledTableHeaderCell,
} from '../../../../../basicEditor/index';
import { fieldsPropTypes } from './propTypes';

const Header = ({
  fields,
}) => (
  <tr>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.first_name.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.last_name.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.middle_name.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.rank.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.sign_kind.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
  </tr>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
};

export default Header;
