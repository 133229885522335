const frontendURL = '/doc/finsourceplan/';
const backendURL = '/api/authorityplans/finsourceplanheader/';
// const printURL = '';
const name = 'Розпис джерел фінансування';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
