import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile, faSpinner, faCheck, faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { FILE_STATES } from './consts';

const File = ({ name, state, result }) => {
  const { variant, icon, spin } = useMemo(
    () => {
      switch (state) {
        case FILE_STATES.prepared:
          return {
            variant: 'primary',
            icon: faFile,
            spin: false,
          };
        case FILE_STATES.pending:
          return {
            variant: 'secondary',
            icon: faSpinner,
            spin: true,
          };
        case FILE_STATES.done:
          return {
            variant: 'success',
            icon: faCheck,
            spin: false,
          };
        case FILE_STATES.errored:
          return {
            variant: 'danger',
            icon: faExclamation,
            spin: false,
          };
        default:
          return {};
      }
    },
    [state],
  );

  const displayError = useMemo(
    () => (state === FILE_STATES.errored ? result : null),
    [result, state],
  );
  return (
    <OverlayTrigger
      placement="bottom"
      trigger="focus"
      overlay={result ? (
        <Popover id={`file-tooltip-${name}`}>
          <Popover.Content>
            {displayError}
            {/*<pre className="bg-white text-body ">*/}
            {/*  displayError*/}
            {/*</pre>*/}
          </Popover.Content>
        </Popover>

      ) : <span />}
    >
      <Badge className="p-3 mx-1" variant={variant} style={{ marginTop: '.25rem' }} tabIndex="0">
        <FontAwesomeIcon icon={icon} spin={spin} className="mr-2" size="lg" />
        {name}
      </Badge>
    </OverlayTrigger>

  );
};

File.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.oneOf(Object.values(FILE_STATES)).isRequired,
  result: PropTypes.shape({}),
};

File.defaultProps = {
  result: { doc: { status: 'ssss', mmm: 'aaaa' } },
};

export default File;
