const frontendURL = '/doc/allocationplan/';
const backendURL = '/api/authorityplans/allocationplanheader/';
const printURL = '/api/authorityplans/printform/allocationplanheader/form1/';
const name = 'План асигнувань';

const instance = {
  frontendURL,
  backendURL,
  printURL,
  name,
};

export default instance;
