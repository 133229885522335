import styled from 'styled-components';
import {
  ListGroup, ListGroupItem, Badge, Row, Col,
} from 'react-bootstrap';

// .attrs(({ CanDrop, isOver }) => ({
//   style: {
//     ...(CanDrop ? { border: 'red  1px solid' } : {}),
//     ...(isOver ? { background: 'red' } : {}),
//   },
// }))
export const StyledFieldList = styled(ListGroup)`
  height: 70vh;
  overflow: auto;
`;

export const StyledFieldItem = styled(ListGroupItem)`
  font-size: medium;
  &>*+* {
    margin-left: 0.5rem;
  }
`;

export const StyledSubItem = styled.div.attrs(({ padded }) => ({
  style: {
    ...(padded ? { height: '2rem' } : { height: '0' }),
  },
}))`
  transition: height 0.3s ease-out;
`;

export const StyledBadge = styled(Badge)`
  font-weight: unset !important;
  font-size: unset !important;
`;

export const StyledRow = styled(Row)`
  margin: 0 !important;
`;
export const SColLeft = styled(Col)`
  padding-left: 0 !important;
`;
export const SColRight = styled(Col)`
  padding-right: 0 !important;
`;
