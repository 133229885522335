import React, {
  useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const HeaderRow = ({
  cells, sticked, rows, firstRow, rowLevels, onRowLevelToggle,
}) => {
  const [top, setTop] = useState(0);

  const rowRef = useCallback((node) => {
    if (node !== null) {
      setTop(node.getBoundingClientRect().top - node.parentNode.getBoundingClientRect().top);
    }
  }, []);
  return (
    <tr ref={rowRef}>
      {firstRow && (
        <th rowSpan={rows} className="d-print-none bg-white">
          <div className="d-flex flex-nowrap">
            {rowLevels.map((rl) => (
              <Button
                key={rl.level}
                variant="outline-secondary"
                className="px-1 py-0 border-0"
                onClick={(e) => onRowLevelToggle(e, rl.level)}
              >
                {rl.label}
              </Button>

            ))}
          </div>
        </th>
      )}
      {cells.filter((cell) => !!cell.repr).map((cell) => (
        <th
          className="HeaderOfTable"
          key={`row-${cell.row_num} col-${cell.col_num}`}
          colSpan={cell.colspan}
          rowSpan={cell.rowspan}
          style={sticked ? { top: `${top}px` } : { position: 'unset' }}
        >
          {cell.repr}
        </th>
      ))}
    </tr>

  );
};

HeaderRow.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.shape({
    // key: PropTypes.string.isRequired,
    colspan: PropTypes.number,
    rowSpan: PropTypes.number,
    repr: PropTypes.string.isRequired,
    col_num: PropTypes.number,
    row_num: PropTypes.number,
  })).isRequired,
  sticked: PropTypes.bool,
  firstRow: PropTypes.bool.isRequired,
  rows: PropTypes.number.isRequired,
  rowLevels: PropTypes.arrayOf(PropTypes.shape({
    level: PropTypes.number,
    label: PropTypes.string,
  })),
  onRowLevelToggle: PropTypes.func.isRequired,
};

HeaderRow.defaultProps = {
  sticked: true,
  rowLevels: null,
};

export default HeaderRow;
