const frontendURL = '/cat/structureoflaborcosts/';
const backendURL = '/api/references/refstructureoflaborcosts/';
const name = 'Структура видатків на оплату праці';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
