import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

const StyledLabel = Styled.label`
  position: relative;
  color: #605b5b;
  font-weight: 700;
`;

const StyledStar = Styled.span`
  color: #FF0000;
  ::after{
    content: '*';
  }
`;

const ContentLabel = ({ text, children, required }) => (
  <StyledLabel>
    {text}
    { required && <StyledStar />}
    {children}
  </StyledLabel>
);

ContentLabel.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  required: PropTypes.bool,
};

ContentLabel.defaultProps = {
  text: '',
  required: false,
  children: null,
};

export default ContentLabel;
