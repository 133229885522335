import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import Field from './field';
import { StyledFieldList } from '../styles';
import itemTypes from '../itemTypes';

const AvailableFieldList = ({
  fields, onClick, activeField, label, onDblClick, onDragEnd,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [_collected, ref] = useDrop({
    accept: [
      itemTypes.group, itemTypes.selection, itemTypes.order, itemTypes.filter, itemTypes.column,
    ],
    drop: (item) => onDragEnd(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  return (
    <Card>
      <Card.Header>{label}</Card.Header>
      <StyledFieldList ref={ref}>
        {fields.map((f) => (
          <Field
            key={f.name}
            label={f.label}
            name={f.name}
            onClick={onClick}
            active={activeField === f.name}
            onDblClick={onDblClick}
          />
        ))}
      </StyledFieldList>
    </Card>
  );
};

export const availablePropType = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
}));

AvailableFieldList.propTypes = {
  fields: availablePropType.isRequired,
  onClick: PropTypes.func.isRequired,
  activeField: PropTypes.string,
  label: PropTypes.string.isRequired,
  onDblClick: PropTypes.func,
  onDragEnd: PropTypes.func.isRequired,
};

AvailableFieldList.defaultProps = {
  activeField: null,
  onDblClick: () => null,
};
export default AvailableFieldList;
