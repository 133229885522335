import React, {
  Suspense,
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Alert, Button, Modal, Badge, Table,
} from 'react-bootstrap';
import styled from 'styled-components';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { DimableLoader } from '../../../components/bootStrap';
import CreateRole from './createRole';
import UpdateRole from './updateRole';


const H2 = styled.h2`
  ${({ color }) => `color: ${color}`}`;

const Editor = () => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [list, setList] = useState(null);
  const [show, setShow] = useState(false);
  const [updateList, switchUpdateList] = useState(false);
  const [res, setRes] = useState(null);
  const [openUpdatePage, setOpenUpdatePage] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/auth/role/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setList(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart, updateList]);

  const deleteRole = useCallback((id) => {
    const loader = async () => {
      const r = await api.delete(`/api/auth/role/${id}`, auth);
      if (r.status === 204) {
        switchUpdateList(!updateList);
        return 'Видалено успішно';
      }
      if (r.status === 400) {
        const error = await r.json();

        error.error_data.map((e) => {
          if (e.messages) {
            throw new Error(`${r.status}: ${e.messages}`);
          }
          return error.error_data;
        });

        return error.error_data;
      }
      if (!r.ok) throw new Error(`${r.status}: ${r.statusText}`);

      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setRes(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart, updateList]);

  const handleOpenedUpdatePage = useCallback((id) => {
    setActiveId(id);
    setOpenUpdatePage(true);
  }, []);


  return (
    <>
      <DimableLoader loading={loading}>
        {err && (
        <Alert dismissible variant="danger" onClose={() => setErr(null)}>
          {err}
        </Alert>
        )}

        {res && (
        <Alert dismissible variant="success" onClose={() => setRes(null)}>
          {res}
        </Alert>
        )}
        {list
        && (
          <>
            <Modal show={show} onHide={() => setShow(false)} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Створення ролі</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Suspense fallback={<div>Загрузка...</div>}>
                  <CreateRole onClose={() => setShow(false)} setUpdateList={switchUpdateList} />
                </Suspense>
              </Modal.Body>
            </Modal>

            <Modal show={openUpdatePage} onHide={() => setOpenUpdatePage(false)} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Редагування ролі</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Suspense fallback={<div>Загрузка...</div>}>
                  <UpdateRole
                    onClose={() => setOpenUpdatePage(false)}
                    activeId={activeId}
                    setUpdateList={switchUpdateList}
                  />
                </Suspense>
              </Modal.Body>
            </Modal>

            <H2 color="#00e7ff">Список ролей:</H2>

            <Table hover size="sm">
              <tbody>
                {list.results.map((el) => (
                  <tr key={el.id}>
                    <td style={{ width: '20%' }}>
                      {el.name}
                      <Badge variant="success">{Object.values(el.permissions).filter((perm) => perm).length}</Badge>
                      <Badge variant="danger">{Object.values(el.permissions).filter((perm) => !perm).length}</Badge>
                    </td>
                    <td>
                      {el.description}
                    </td>
                    <td style={{ width: '5px' }}>
                      <Button style={{ marginLeft: '15px', marginRight: '10px' }} variant="outline-warning" onClick={() => handleOpenedUpdatePage(el.id)}>Редагувати</Button>
                    </td>
                    <td style={{ width: '5px' }}>
                      <Button variant="outline-danger" onClick={() => deleteRole(el.id)}>Видалити</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button onClick={() => setShow(true)} className="mt-2">Створити</Button>
          </>
        )}
      </DimableLoader>
    </>
  );
};

export default Editor;
