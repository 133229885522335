import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import SKDGroupItem from './groupItem';
import { StyledFieldList } from '../styles';
import itemTypes from '../itemTypes';
import { groupsPropType } from '../../hooks';

const SKDGroupsList = ({
  groups, activeGroup, onClick, onDblClick, onDragEnd, onSubGroupClick, onChangeHierarchy,
}) => {
  const [padded, setPadded] = useState(null);
  const onDragEndHandler = useCallback((item) => {
    onDragEnd(item, padded);
  },
  [onDragEnd, padded]);
  const [{
    isOver, isOverCurrent, didDrop,
  }, ref] = useDrop({
    accept: [itemTypes.availableField, itemTypes.group],
    drop: (item) => onDragEndHandler(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  const setPaddedHandler = useCallback(
    setPadded,
    [setPadded],
  );

  useEffect(() => {
    if ((!isOver && !isOverCurrent && !didDrop) || (isOver && isOverCurrent && !didDrop)) {
      setPadded(null);
    }
  }, [didDrop, isOver, isOverCurrent]);
  return (
    <Card>
      <Card.Header>Обрані группування</Card.Header>
      <StyledFieldList
        ref={ref}
      >
        {groups.map((f) => (
          <SKDGroupItem
            subgroups={f.items}
            name={f.name}
            key={f.name}
            onClick={onClick}
            onDblClick={onDblClick}
            onSubGroupClick={onSubGroupClick}
            active={activeGroup === f.name}
            onHover={setPaddedHandler}
            padded={padded === f.name}
            onChangeHierarchy={onChangeHierarchy}
          />
        ))}
      </StyledFieldList>
    </Card>
  );
};

SKDGroupsList.propTypes = {
  groups: groupsPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubGroupClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  activeGroup: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  onChangeHierarchy: PropTypes.func.isRequired,
};

SKDGroupsList.defaultProps = {
  activeGroup: null,
  onDblClick: () => null,
};
export default SKDGroupsList;
