import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, { FieldPropType, CatCommandPanel } from '../../../basicEditor';
import { HeaderText } from '../../../../components/Styled/Forms';
import HistoricalValues from './history';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';

class Editor extends PureComponent {
  render() {
    const {
      data, options, isNew, isReadOnly, isModified, onSave, onClose, fields,
      onChange, tryBlock, blockStatus, registerSaveListener,
    } = this.props;
    return (
      <div>
        <HeaderText>{isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}</HeaderText>
        <CatCommandPanel
          canSaved={!isReadOnly}
          modified={isModified}
          canDeleted={false}
          onSave={() => onSave()}
          onClose={onClose}
        />
        <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
          <Tab eventKey="general" title="Основні властивості">
            <TabContainer>
              <HistoricalValues
                tryBlock={tryBlock}
                blockStatus={blockStatus}
                isNew={isNew}
                url={`${meta.cat.minfinindicator.backendURL}${data.id}`}
                initialData={data.history}
                fields={fields.history.children}
                onChange={(history) => onChange({ history })}
                registerSaveListener={registerSaveListener}
              />
            </TabContainer>
          </Tab>
        </LabelTab>
      </div>
    );
  }
}

const dataPropTypes = {
  id: PropTypes.number,
  repr: PropTypes.string,
  history: PropTypes.shape({
    children: PropTypes.shape({}),
  }),
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,

  }).isRequired,
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  fields: PropTypes.shape(
    fieldsPropTypes,
  ).isRequired,
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  registerSaveListener: PropTypes.func.isRequired,
  tryBlock: PropTypes.func,
  blockStatus: PropTypes.bool,
};

Editor.defaultProps = {
  onClose: null,
  tryBlock: null,
  blockStatus: false,
};

export default getEditor(meta.cat.minfinindicator.backendURL)(Editor);
