import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import Toggler from './toggler';
import { StyledTr, StyledTD } from './styles';

import TableCell from './tableCell';

const TableRow = ({
  cols,
  row,
  isHierarhical,
  level,
  active,
  onClick,
  onDoubleClick,
  opened,
  canOpened,
  onOpen,
}) => {
  const onClickHandler = useMemo(() => (e) => onClick(e, row.id, row), [onClick, row]);
  const onDoubleClickHandler = useMemo(
    () => (e) => onDoubleClick(e, row.id, row),
    [onDoubleClick, row],
  );
  return (
    <StyledTr active={active}>
      { isHierarhical && (
        <StyledTD style={{ textAlign: 'center' }}>
          <Toggler
            level={level}
            opened={opened}
            canOpened={canOpened && row.is_group}
            deleted={row.deleted}
            onOpen={(e) => onOpen(e, row.id)}
          />
        </StyledTD>
      )}
      {cols.map((col) => (
        <TableCell
          key={`cell${col.name}.${row.id}`}
          onClick={onClickHandler}
          onDoubleClick={onDoubleClickHandler}
          value={row[col.name]}
          choices={col.choices}
          type={col.type}
        />
      ))}
    </StyledTr>
  );
};

TableRow.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ).isRequired,
  row: PropTypes.shape({
    id: PropTypes.number,
    pk: PropTypes.number,
    is_group: PropTypes.bool,
    deleted: PropTypes.bool,
  }).isRequired,
  isHierarhical: PropTypes.bool,
  level: PropTypes.number,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  opened: PropTypes.bool,
  canOpened: PropTypes.bool,
  onOpen: PropTypes.func,
};

TableRow.defaultProps = {
  active: false,
  isHierarhical: false,
  level: 0,
  // eslint-disable-next-line
    onClick: () => null,
  // eslint-disable-next-line
    onDoubleClick: () => null,
  opened: false,
  canOpened: false,
  // eslint-disable-next-line
    onOpen: () => null,
};


export default memo(TableRow);
