import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyledCellContainer, StyledCellItemHeader, StyledTableHeaderCell } from '../../../../../basicEditor';
import { fieldsPropTypes } from './propTypes';
import { monthNames } from '../../../../../../constants/date';

const vt4brLabel = 'в тч БР';
const Header = ({
  fields, onlyYear, vt4br,
}) => (
  <tr>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.kdb.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.amount_total.label}</StyledCellItemHeader>
        {vt4br && (
        <StyledCellItemHeader>{vt4brLabel}</StyledCellItemHeader>
        )}
      </StyledCellContainer>
    </StyledTableHeaderCell>
    {!onlyYear && monthNames.filter((m, i) => i % 3 === 0).map((month, k) => (
      <Fragment key={month}>
        <StyledTableHeaderCell>
          <StyledCellContainer>
            <StyledCellItemHeader>{monthNames[k * 3]}</StyledCellItemHeader>
            <StyledCellItemHeader>{monthNames[k * 3 + 1]}</StyledCellItemHeader>
            <StyledCellItemHeader>{monthNames[k * 3 + 2]}</StyledCellItemHeader>
          </StyledCellContainer>
        </StyledTableHeaderCell>
        {vt4br && (
          <StyledTableHeaderCell>
            <StyledCellContainer>
              <StyledCellItemHeader>{vt4brLabel}</StyledCellItemHeader>
              <StyledCellItemHeader>{vt4brLabel}</StyledCellItemHeader>
              <StyledCellItemHeader>{vt4brLabel}</StyledCellItemHeader>
            </StyledCellContainer>
          </StyledTableHeaderCell>
        )}
      </Fragment>
    ))}

  </tr>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  onlyYear: PropTypes.bool.isRequired,
  vt4br: PropTypes.bool.isRequired,
};

export default Header;
