import React from 'react';
import PropTypes from 'prop-types';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { ButtonCollapse } from './styles';
import { CPButton } from '../../bootStrap/buttons/styles';

export const HideButton = ({ content, onClick, disabled }) => (
  <ButtonCollapse>
    <CPButton
      onClick={onClick}
      disabled={disabled}
      title={content}
      icon={faSort}
      content=""
    />
  </ButtonCollapse>
);

HideButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

HideButton.defaultProps = {
  onClick: () => null,
  content: 'Згорнути/Розгорнути',
  disabled: false,
};

export default HideButton;
