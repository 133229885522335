import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import FilterField from './filterField';

const WIDTH = '340px';

const AnimatedFiltesContainer = styled.div.attrs(({ show }) => ({
  style: show ? {
    width: WIDTH,
  } : {
    width: '0',
    opacity: '0',
  },
}))`
  flex: 0 0 auto;
  // overflow-x: hidden;
  transition: width 1s ease-out, opacity 1s ease-out;
`;

const FilterPanel = ({
  filteringFields, show, filter, onSetFilter,
}) => (
  <AnimatedFiltesContainer show={show}>
    <div style={{ width: WIDTH }}>
      <Navbar
        className="flex-column"
        style={{ padding: '0 0 0 1.2rem' }}
      >
        <ListGroup>
          {filteringFields.map((ff) => (
            <FilterField
              key={ff.name}
              desc={ff}
              value={filter[ff.name]}
              onSetFilter={onSetFilter}
            />
          ))}
        </ListGroup>

      </Navbar>
    </div>
  </AnimatedFiltesContainer>

);

FilterPanel.propTypes = {
  filteringFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    resource: PropTypes.string,
  })).isRequired,
  show: PropTypes.bool.isRequired,
  filter: PropTypes.shape({}).isRequired,
  onSetFilter: PropTypes.func.isRequired,
};

export default FilterPanel;
