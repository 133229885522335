import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Form, Col, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
} from '@fortawesome/free-solid-svg-icons';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import List from './List';
import Type from './Type';
import PeriodEdit from './PeriodEdit';
import DopFilters from './DopFilters';
import { defaultBeginDate, defaultEndDate, dopFilters as initialDoFilters } from './consts';
import { DimableLoader } from '../../../components/bootStrap';
import { downloadFileFromJSON } from '../../../api/utils';

const TreasureExport = () => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [listOfBudgets, setListOfBudgets] = useState(null);
  const [type, setType] = useState({ id: 1, name: 'Річний', type: 'R4' });
  const [beginDate, setBeginDate] = useState(defaultBeginDate());
  const [endDate, setEndDate] = useState(defaultEndDate());
  const [dopFilters, setDopFilters] = useState(initialDoFilters);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);
  const getTreasureFiles = useCallback(() => {
    const loader = async () => {
      const r = await api.get('/api/references/refbudget/?&deleted=false&is_group=false&ordering=code', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const newData = rData.map((el) => ({ ...el, check: true }));
        setListOfBudgets(newData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  useEffect(() => { getTreasureFiles(); }, [getTreasureFiles]);

  const onCheck = (id) => {
    const updateCheckedListOfBudgets = listOfBudgets.map((d) => ({
      ...d,
      check: d.id === id ? !d.check : d.check,
    }));
    setListOfBudgets(updateCheckedListOfBudgets);
  };

  const onCheckAll = () => {
    setListOfBudgets((prev) => prev.map((d) => ({ ...d, check: true })));
  };

  const onUncheckAll = () => {
    setListOfBudgets((prev) => prev.map((d) => ({ ...d, check: false })));
  };

  const handleSetDopFilters = useCallback((value) => {
    if (dopFilters.includes(value)) {
      setDopFilters((prev) => prev.filter((el) => el !== value));
    } else setDopFilters((prev) => [...prev, value]);
  }, [dopFilters]);

  const handleSubmit = useCallback(() => {
    const selectedBudgets = listOfBudgets.filter((budget) => budget.check).map((i) => `&budgets=${i.id}`).join('');
    const selectedFilters = dopFilters.map((filter) => `&forms=${filter.type}`).join('');
    const loader = async () => {
      const forms = dopFilters.reduce((R, df) => `${R}&forms=${df.type}`, '');
      const params = `begin_date=${beginDate}&end_date=${endDate}${selectedBudgets}&periodtype=${type.type}${selectedFilters}${forms}`;
      const r = await api.get(`/api/treasuries/export/?${params}`, auth);
      if (!r.ok) {
        if (r.status === 400) {
          const d = await r.json();
          const message = Object.keys(d).reduce((R, k) => `${R}${k}: ${d[k].join(', ')}, `, '');
          throw new Error(message);
        }
        throw new Error(`${r.status} ${r.statusText}`);
      }
      return r.json();
    };
    onLoadStart();
    loader()
      .then((r) => {
        const d = new Date();
        const filename = `RZD${String(d.getDate()).padStart(2, '0')}${String(d.getMonth() + 1).padStart(2, '0')}1.zip`;
        return downloadFileFromJSON(r.file, filename);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  },
  [auth, beginDate, dopFilters, endDate,
    listOfBudgets, onError, onLoadEnd, onLoadStart, type.type]);

  return (
    <DimableLoader loading={loading}>
      {err && (
      <Alert dismissible variant="danger" onClose={() => setErr(null)}>
        {err}
      </Alert>

      )}
      <Form noValidate>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Type type={type} setType={setType} />
          </Form.Group>
          <Form.Group as={Col} md="6">
            {listOfBudgets && (
            <List
              list={listOfBudgets}
              onCheck={onCheck}
              onCheckAll={onCheckAll}
              onUncheckAll={onUncheckAll}
            />
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <PeriodEdit
              beginDate={beginDate}
              setBeginDate={setBeginDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <DopFilters checkedDopFilters={dopFilters} handleSetDopFilters={handleSetDopFilters} />
          </Form.Group>
        </Form.Row>
        <Button onClick={handleSubmit}>
          <FontAwesomeIcon
            icon={faFileExport}
            className="mr-1"
          />
          Вивантажити
        </Button>
      </Form>
    </DimableLoader>
  );
};

export default TreasureExport;
