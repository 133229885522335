import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStream, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import itemTypes from '../itemTypes';
import { StyledFieldItem, StyledSubItem, StyledBadge } from '../styles';
import { subColumnPropType } from '../../hooks';

const SKDColumnItem = ({
  subcolumns, onClick, active, name, onDblClick, onHover,
  padded, onSubColumnClick, onChangeHierarchy,
}) => {
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: [itemTypes.availableField, itemTypes.column],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item, monitor) => {
      if (ref.current) {
        const gbcr = ref.current.getBoundingClientRect();
        const cursor = monitor.getClientOffset();
        if ((gbcr.top + gbcr.height / 2) > cursor.y) {
          onHover(name);
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      name,
      type: itemTypes.column,
    },
    type: itemTypes.column,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = useMemo(() => ({
    opacity: isDragging ? 0.4 : 1,
  }), [isDragging]);

  drag(drop(ref));
  return (
    <StyledFieldItem
      ref={ref}
      onClick={() => onClick(name)}
      onDoubleClick={() => onDblClick(name)}
      active={active}
      variant={isOver ? 'success' : ''}
      style={style}
    >
      <StyledSubItem padded={padded} />
      {subcolumns.map((f) => (
        <StyledBadge
          variant={f.errored ? 'danger' : 'light'}
          key={f.name}
        >
          <span
            className="mr-2"
          >
            {f.label}
          </span>
          <Button
            size="sm"
            variant="light"
            onClick={() => onChangeHierarchy(name, f.name, !f.useHierarchy)}
            disabled={!f.canHierarchy}
          >
            <FontAwesomeIcon
              icon={f.useHierarchy ? faStream : faBars}
              title={f.useHierarchy ? 'З ієрархія' : 'Без ієрархії'}
              color="var(--activeColorTextTab)"
            />
          </Button>
          {subcolumns.length > 1 && (
            <FontAwesomeIcon
              icon={faTimes}
              title="Прибрати"
              transform="shrink-6 up-6 left-8"
              color="#b90c0c"
              onClick={() => onSubColumnClick(name, f.name)}
            />
          )}
        </StyledBadge>

      ))}
    </StyledFieldItem>
  );
};

SKDColumnItem.propTypes = {
  name: PropTypes.string.isRequired,
  subcolumns: PropTypes.arrayOf(subColumnPropType).isRequired,
  onClick: PropTypes.func.isRequired,
  onSubColumnClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  active: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  padded: PropTypes.bool,
  onChangeHierarchy: PropTypes.func.isRequired,
};

SKDColumnItem.defaultProps = {
  active: false,
  onDblClick: () => null,
  padded: false,
};

export default SKDColumnItem;
