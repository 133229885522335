import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import api, { NetworkException } from '../../api/req';
import { DimableLoader, ErrorMessage, WarningMessage } from '../../components/bootStrap';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';
import { TryButton } from '../../components/bootStrap/buttons';

const buidErrors = (errorData) => {
  if (!errorData) {
    return {
    // необиданный ответ сервера
      messages: ['Server error. Server returned unexpected response (error_data)'],
    };
  }
  return errorData.reduce((R, {
    messages, fields, extensions, exceptions,
  }) => ({
    messages: [...R.messages, ...(messages || [])],
    fields: {
      ...R.fields,
      ...fields,
      ...extensions,
    },
    exceptions: { ...R.exceptions, ...exceptions },
  }), { messages: [], fields: {}, exceptions: {} });
};

const GetEditor = (apiUrl) => (WrappedComponent) => {
  class BasicEditor extends PureComponent {
    static getDerivedStateFromProps(props, state) {
      if (props.id !== state.oldId) {
        return {
          data: null,
          oldId: props.id,
          isNew: (props.id === 'create'),
        };
      }
      return null;
    }

    constructor(props) {
      super(props);
      this.state = {
        oldId: props.id,
        isLoading: false,
        isModified: false,
        isErrored: false,
        errHeader: null,
        errText: null,
        isWarning: false,
        warningHeader: null,
        warningText: null,
        options: {},
        data: null,
        fields: null,
        errors: {},
        isReadOnly: false,
        isNew: props.id === 'create',
        isBlocked: false,
        leadingFields: [], // Поля, после измеенний который требуется вызов метода DRAFT
        readOnlyFields: [], // Поля, которые в данный момент недоступны для редактирования
      };
      this.saveListeners = [];
    }

    componentDidMount() {
      this.processOptions();
      // .then(this.load);
    }

    componentDidUpdate() {
      const { data, fields } = this.state;
      const { id } = this.props;
      if (data === null && fields) {
        if (id === 'create') {
          this.getDefaultRecord();
        } else {
          this.load();
        }
      }
    }

    componentWillUnmount() {
      this.unblock();
    }

    processOptions = async () => {
      const { authF, id } = this.props;
      const isNew = id === 'create';
      const resp = await api.options(isNew ? `${apiUrl}new/` : `${apiUrl}${id}/`, authF);
      if (resp.ok) {
        const options = await resp.json();
        const fields = options.actions.GET;
        const isReadOnly = !(isNew || ('PUT' in options.actions));
        const leadingFields = options.leading_fields;
        this.setState({
          options, fields, isReadOnly, leadingFields,
        });
      } else {
        throw new NetworkException(`Could not make OPTIONS request to ${apiUrl} by reason ${resp.status} ${resp.statusText}`);
      }
    };

    getDefaultRecord = async () => {
      this.draft({});
    };

    load = async () => {
      const { authF, id } = this.props;
      const resp = await api.get(`${apiUrl}${id}/`, authF);
      if (resp.ok) {
        const data = await resp.json();
        this.setState(
          {
            data,
            isLoading: false,
            isErrored: false,
            errText: null,
          },
          () => this.draft(data, {}, false),
        );
      } else {
        this.parseErrors(resp);
      }
    };

    block = async () => {
      const { isBlocked } = this.state;
      const {
        authF, id,
      } = this.props;
      if (!isBlocked && id !== 'create') { // already blocked
        const r = await api.get(`${apiUrl}${id}/block/`, authF);
        if (r.ok) {
          this.setState({
            isBlocked: true,
          });
        } else {
          const d = await r.json();
          const dataStartBlock = new Date(d.start_at)
            .toLocaleString('uk', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            });
          const message = `Цей об'єкт заблоковано користувачем: ${d.blocker.first_name} ${d.blocker.last_name} c ${dataStartBlock}`;
          this.setState({
            isWarning: true,
            warningText: message,
            warningHeader: 'Попередження',
          });
          return false;
          // throw new Error(message);
        }
      }
      return true;
    };

    unblock = async () => {
      const { isBlocked } = this.state;
      const {
        authF, id,
      } = this.props;
      if (isBlocked) {
        const r = await api.get(`${apiUrl}${id}/unblock/`, authF);
        if (r.ok) {
          this.setState({
            isBlocked: false,
          });
        } else {
          throw new Error(`${r.status} ${r.statusText}`);
        }
      }
      return true;
    };

    onTryEdit = () => {
      this.setState({ isLoading: true });
      const { isBlocked } = this.state;
      this.block();
      this.load()
        .then(() => {
          this.setState({
            isErrored: false,
            errText: null,
            errHeader: null,
          });
        })
        .catch((e) => {
          this.setState({
            isErrored: true,
            errText: `${e.name} ${e.message}`,
          });
        });
      this.setState({
        isLoading: false,
        isWarning: false,
      });
      if (isBlocked) {
        this.setState({
          isLoading: false,
          isWarning: true,
        });
      }
    };

    dataSet = (partialContent) => {
      const {
        data, errors, leadingFields,
      } = this.state;
      const newErrors = Object.keys(errors).reduce((R, key) => ({
        ...R,
        ...(key in partialContent) ? {} : { [key]: errors[key] },
      }), {});
      const newData = {
        ...data,
        ...partialContent(),
      };
      this.setState({
        data: newData,
        errors: newErrors,
        isWarning: false,
        isModified: true,
        isErrored: false,
        errText: null,
        errHeader: null,
      });
      // leading_fields
      const useDraft = leadingFields && Object.keys(partialContent)
        .reduce((R, field) => leadingFields.includes(field) || R, false);

      if (useDraft) {
        this.draft(newData);
      }
    };

    dataSetWithBlock = (partialContent) => {
      const {
        isNew, isBlocked,
      } = this.state;
      if (isNew || isBlocked) return this.dataSet(partialContent)
      this.block().then((blocked) => blocked && this.dataSet(partialContent));
      return null;
    };

    parseErrors = async (response) => {
      const { data } = this.state;
      if (response.status >= 400 || response.status <= 499) {
        const errs = await response.json();
        this.setState({
          errors: buidErrors(errs.error_data),
          isLoading: false,
          isErrored: true,
          errText: null,
          data: data || {},
        });
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errText: `${response.status} ${response.statusText}`,
          data: data || {},
        });
        throw new NetworkException(`Could not make GET request to ${apiUrl} by reason ${response.status} ${response.statusText}`);
      }
    };

    save = async (savedData = null) => {
      const { data } = this.state;
      const dataToSave = savedData || data;
      this.setState({ isLoading: true });
      const { isNew } = this.state;
      const {
        authF, id, history, onSave,
      } = this.props;
      const r = isNew
        ? await api.post(apiUrl, authF, dataToSave)
        : await api.put(`${apiUrl}${id}/`, authF, dataToSave);
      if (r.ok) {
        await Promise.all(this.saveListeners.map((l) => l()));
        this.unblock()
          .then(() => {
            this.setState({
              isErrored: false,
              errText: null,
            });
          })
          .catch((e) => {
            this.setState({
              isErrored: true,
              errText: `${e.message}`,
            });
          });
        const d = await r.json();
        this.setState({
          // data: d,
          isLoading: false,
          isErrored: false,
          errText: null,
          isModified: false,
        });
        if (isNew && !onSave) {
          history.push(`${d.id}`);
        }
        if (onSave) {
          onSave();
        }
      } else {
        this.parseErrors(r);
      }
    };

    draft = async (rec = null, requirements = {}, updateStore = true) => {
      const { data, options } = this.state;
      const { authF } = this.props;
      this.setState({ isLoading: true });
      const params = {
        record: rec || data,
        requirements,
      };


      const resp = await api.post(`${apiUrl}draft/`, authF, params);
      if (resp.ok) {
        // eslint-disable-next-line camelcase
        const { record, leading_fields, read_only_fields } = await resp.json();
        // Обновим поля в соответствии с ситуационным read_only_fields
        const flds = options.actions.GET;

        const plainRF = read_only_fields.filter((f) => f.indexOf('.') === -1);
        const tpRF = read_only_fields
          .filter((f) => f.indexOf('.') !== -1)
          .reduce((R, f) => {
            const [tpName, fName] = f.split('.');
            const o = R[tpName] || [];
            return {
              ...R,
              [tpName]: [...o, fName],
            };
          }, {});

        // сначала обновим поля шапки
        const fields2 = Object.keys(flds).reduce((R, fname) => ({
          ...R,
          [fname]: {
            ...flds[fname],
            read_only: flds[fname].read_only || (plainRF.includes(fname)),
          },
        }), {});

        // теперь обновим поля табличных частей
        const fields = Object.keys(tpRF).reduce((R, tpName) => ({
          ...R,
          [tpName]: {
            ...R[tpName],
            child: {
              ...R[tpName].child,
              children: Object.keys(fields2[tpName].child.children).reduce((RR, fName) => ({
                ...RR,
                [fName]: {
                  ...R[tpName].child.children[fName],
                  read_only:
                    R[tpName].child.children[fName].read_only || (tpRF[tpName].includes(fName)),
                },
              }), {}),
            },
          },
        }), fields2);

        this.setState({
          ...(updateStore ? {
            data: { ...data, ...record },
          } : {}),
          fields,
          leadingFields: leading_fields,
          readOnlyFields: read_only_fields,
          isLoading: false,
          isErrored: false,
          errText: null,
        });
      } else {
        this.parseErrors(resp);
      }
    };


    execute = async () => {
      const { data } = this.state;
      data.executed = true;
      this.save(data);
    };

    unExecute = async () => {
      const { data } = this.state;
      data.executed = false;
      this.save(data);
    };

    registerSaveListener = (listener) => {
      this.saveListeners.push(listener);
    };

    render() {
      // eslint-disable-next-line
      const { id, history, authF, onSave, onDelete, location,   ...rest } = this.props;
      const {
        data, errHeader, errText, isErrored, isWarning, warningHeader, warningText, fields, isNew,
        isModified, isLoading, errors, options, isReadOnly, isBlocked, readOnlyFields,
      } = this.state;
      const allRequiredFieldsFilled = !!fields && data && Object.keys(fields).reduce(
        (R, f) => R && (!fields[f].required || !!data[f]),
        true,
      );

      const errorMessages = errors.messages || [];
      const errrorExceptions = Object.keys(errors.exceptions || {}).reduce((R, header) => [
        ...R,
        ...errors.exceptions[header].map((message) => ({
          header,
          message,
        })),
      ], []);
      return (
        <>
          {isWarning && warningText && (
          <WarningMessage
            text={warningText}
            header={warningHeader}
            actions={(
              <TryButton onClick={() => this.onTryEdit()} />
              )}
          />
          )}
          {isErrored && errText && (
            <ErrorMessage text={errText} header={errHeader} />
          )}
          {errorMessages.map((message) => (
            <ErrorMessage key={message} text={message} header="Отакої" />
          ))}
          {errrorExceptions.map((m) => (
            <ErrorMessage key={`${m.header} ${m.message}`} text={m.message} header={m.header} />
          ))}
          {data && fields && (
            <DimableLoader loading={isLoading}>
              <WrappedComponent
                data={data}
                fields={fields}
                isNew={isNew}
                errors={errors}
                isModified={isModified}
                options={options}
                onDelete={this.delete}
                onChange={this.dataSetWithBlock}
                onSave={this.save}
                onDraft={this.draft}
                onExecute={this.execute}
                onCancelExecute={this.unExecute}
                reload={this.load}
                allRequiredFieldsFilled={allRequiredFieldsFilled}
                isReadOnly={isReadOnly}
                tryBlock={this.block}
                tryUnblock={this.unblock}
                blockStatus={isBlocked}
                registerSaveListener={this.registerSaveListener}
                readOnlyFields={readOnlyFields}
                {...rest}
              />
            </DimableLoader>
          )}
        </>
      );
    }
  }
  BasicEditor.propTypes = {
    id: PropTypes.string.isRequired,
    authF: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
  };

  BasicEditor.defaultProps = {
    onSave: null,
    onDelete: null,
  };

  const enhancer = compose(
    withAuthConsumer(mapStateAuth),
    withRouter,
  );
  return enhancer(BasicEditor);
};

export const FieldPropType = PropTypes.shape({
  label: PropTypes.string,
  read_only: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['field', 'integer', 'string', 'date', 'boolean', 'decimal', 'nested object', 'choice', 'datetime', 'email', 'url']),
  max_length: PropTypes.number,
  choice: PropTypes.shape({ }),
  resource: PropTypes.string, // backend url for FK fields
});

export const FieldErrorPropType = PropTypes.arrayOf(PropTypes.string);

export const getErrorsPropTypes = (dpt) => Object.keys(dpt)
  .reduce((R, r) => ({ ...R, [r]: FieldErrorPropType }), {});

export const getFieldsPropTypes = (dpt) => Object.keys(dpt)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});


export default GetEditor;
