import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const PrintButton = ({ content, showPrintedResult, disabled }) => {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <CPButton
        onClick={() => setModalOpened(true)}
        disabled={disabled}
        content={content}
        icon={faPrint}
      />
      <Modal
        show={modalOpened}
        onHide={() => setModalOpened(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
              Друкована форма
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button showPrintedResult={showPrintedResult}>Друк</Button>
          <p>
              Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
              commodi aspernatur enim, consectetur. Cumque deleniti temporibus
              ipsam atque a dolores quisquam quisquam adipisci possimus
              laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
              accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
              reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
              deleniti rem!
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

PrintButton.propTypes = {
  content: PropTypes.string,
  showPrintedResult: PropTypes.func,
  disabled: PropTypes.bool,
};

PrintButton.defaultProps = {
  showPrintedResult: () => null,
  content: 'Друк',
  disabled: false,
};

export default PrintButton;
