import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

const TRow = ({
  rowIndex,
  onChange,
  row,
  authority,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const disposer = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const disposerFilter = useMemo(() => ({
    parent: authority ? authority.id : 0,
  }), [authority]);

  return (
    <>
      <Col
        xl={9}
        sm={9}
        className="px-1  d-flex flex-column justify-content-end"
      >
        <Row sm={1} noGutters>
          <Col className="col-sm-6">
            <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
              <TableControls.ItemPicker
                {...disposer}
                filter={disposerFilter}
                noHierarchy
              />
            </TabelRowCell>
          </Col>
          {/* <Col className="col-sm-3"> */}
          {/*  <TabelRowCell column="bfn_amount" highlighted={highlights.includes('bfn_amount')}> */}
          {/*    <TableControls.NumberInput */}
          {/*      {...amountProps} */}
          {/*    /> */}
          {/*  </TabelRowCell> */}
          {/* </Col> */}
        </Row>
      </Col>
    </>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
