import React from 'react';
import PropTypes from 'prop-types';
import { paramPropType } from '../hooks';
import { EditorMultiValueInput } from '../../../basicEditor/editorControls';
import { Title } from '../styles';

const ParamsEditor = ({ params, paramValues, onChange }) => (
  <div>
    <Title>Значения параметров</Title>
    {params.map((p) => (
      <EditorMultiValueInput
        key={p.name}
        controlId={`param_${p.name}_input`}
        label={p.label}
        ctype={p.ctype}
        resource={p.resource}
        required
        value={paramValues[p.name]}
        onChange={(e, v) => onChange(p.name, v)}
      />
    ))}
  </div>
);
ParamsEditor.propTypes = {
  params: PropTypes.arrayOf(paramPropType).isRequired,
  paramValues: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};


export default ParamsEditor;
