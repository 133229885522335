import React from 'react';
import PropTypes from 'prop-types';
import { StyledCellContainer, StyledCellItemHeader, StyledTableHeaderCell } from '../styles';

const TPHeader = ({ children }) => (
  <StyledTableHeaderCell>
    <StyledCellContainer>
      <StyledCellItemHeader>{children}</StyledCellItemHeader>
    </StyledCellContainer>
  </StyledTableHeaderCell>
);

TPHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default TPHeader;
