import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import {
  Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { DeleteButton } from '../../../components/bootStrap/buttons';
import { CPButton } from '../../../components/bootStrap/buttons/styles';

const rotate = keyframes`
  0% {
    transform: rotate3d(0, 1, 0, 60deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, -60deg);
  }
`;
const Img = styled(FontAwesomeIcon)`
  color: var(--colorQuestionIcon);
  transition: all 4s ease;
  animation: ${rotate} 1.5s linear infinite;
`;
const Text = styled.span`
  font-weight: 700;
  font-size: 18px;
  margin-left: 25px;
`;

export const DeleteButtonWithModal = ({ onClick, disabled }) => {
  const [delOpened, setDelOpened] = useState(false);
  return (
    <>
      <DeleteButton disabled={disabled} onClick={() => setDelOpened(true)} />
      <Modal show={delOpened} onHide={() => setDelOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Видалення елементу</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', alignItems: 'center' }}>
          <Img
            icon={faQuestion}
            size="4x"
          />
          <Text>
            Ви дійсно бажаєте видалити цей елемент(и)?
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <CPButton
            onClick={(e) => {
              setDelOpened(false);
              onClick(e);
            }}
            variant="info"
            content="Так"
          />
          <CPButton
            onClick={() => {
              setDelOpened(false);
            }}
            variant="secondary"
            content="Ні"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

DeleteButtonWithModal.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DeleteButtonWithModal.defaultProps = {
  disabled: false,
};

export default DeleteButtonWithModal;
