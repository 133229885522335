import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import md from '../../../../../meta/cat/budget';
import { AppContext } from '../../../../../providers/authProvider';
import ListerFactory from '../../../../newLister';
import { CatTableListerFactory } from '../../../../newLister/tableComponent';

const Lister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'lister',
})(CatTableListerFactory());

const BudgetLister = ({ noHierarchy, ...restProps }) => {
  const { currentUser } = useContext(AppContext);
  const isSuperUser = currentUser.is_superuser;

  return (
    <Lister noHierarchy={noHierarchy || !isSuperUser} {...restProps} />
  );
};

BudgetLister.propTypes = {
  noHierarchy: PropTypes.bool,
};

BudgetLister.defaultProps = {
  noHierarchy: false,
};

export default BudgetLister;
