import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const buildingPeriodProps = tableHooks.useStringInputProps(
    'building_period', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const customerProps = tableHooks.useItemInputProps(
    'customer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSf_2Props = tableHooks.useNumberInputProps(
    'amount_sf_2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSf_1Props = tableHooks.useNumberInputProps(
    'amount_sf_1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSf0Props = tableHooks.useNumberInputProps(
    'amount_sf0', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSf1Props = tableHooks.useNumberInputProps(
    'amount_sf1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSf2Props = tableHooks.useNumberInputProps(
    'amount_sf2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const readinessLevel1Props = tableHooks.useNumberInputProps(
    'readiness_level_1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const readinessLevel2Props = tableHooks.useNumberInputProps(
    'readiness_level_2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const readinessLevel3Props = tableHooks.useNumberInputProps(
    'readiness_level_3', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const readinessLevel4Props = tableHooks.useNumberInputProps(
    'readiness_level_4', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const readinessLevel5Props = tableHooks.useNumberInputProps(
    'readiness_level_5', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  return (
    <Col>
      <Row noGutters>
        <Col>
          <TabelRowCell column="customer" highlighted={highlights.includes('customer')}>
            <TableControls.ItemPicker {...customerProps} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="building_period" highlighted={highlights.includes('building_period')}>
            <TableControls.StringInput {...buildingPeriodProps} />
          </TabelRowCell>
        </Col>
      </Row>

      <Row noGutters>
        <Col>
          <TabelRowCell column="amount_sf_2" highlighted={highlights.includes('amount_sf_2')}>
            <TableControls.NumberInput {...amountSf_2Props} />
          </TabelRowCell>
          <TabelRowCell column="readiness_level_1" highlighted={highlights.includes('readiness_level_1')}>
            <TableControls.NumberInput {...readinessLevel1Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_sf_1" highlighted={highlights.includes('amount_sf_1')}>
            <TableControls.NumberInput {...amountSf_1Props} />
          </TabelRowCell>
          <TabelRowCell column="readiness_level_2" highlighted={highlights.includes('readiness_level_2')}>
            <TableControls.NumberInput {...readinessLevel2Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_sf0" highlighted={highlights.includes('amount_sf0')}>
            <TableControls.NumberInput {...amountSf0Props} />
          </TabelRowCell>
          <TabelRowCell column="readiness_level_3" highlighted={highlights.includes('readiness_level_3')}>
            <TableControls.NumberInput {...readinessLevel3Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_sf1" highlighted={highlights.includes('amount_sf1')}>
            <TableControls.NumberInput {...amountSf1Props} />
          </TabelRowCell>
          <TabelRowCell column="readiness_level_4" highlighted={highlights.includes('readiness_level_4')}>
            <TableControls.NumberInput {...readinessLevel4Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_sf2" highlighted={highlights.includes('amount_sf2')}>
            <TableControls.NumberInput {...amountSf2Props} />
          </TabelRowCell>
          <TabelRowCell column="readiness_level_5" highlighted={highlights.includes('readiness_level_5')}>
            <TableControls.NumberInput {...readinessLevel5Props} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
