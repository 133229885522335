import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from './header';
import Body from './body';
import Menu from './menu';
import useWinManager from '../windowsManager/Hooks/useWinManager';
import { WinManagerContext } from '../../providers/winManagerProvider';

const LayoutGrid = () => {
  const winManager = useWinManager();

  return (
    <WinManagerContext.Provider value={winManager}>
      <Container fluid style={{ padding: '0' }}>
        <Row noGutters>
          <Col>
            <Header />
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <Menu />
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <Body />
          </Col>
        </Row>
      </Container>
    </WinManagerContext.Provider>
  );
};

export default LayoutGrid;
