import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import moment from 'moment';

const MonthButton = ({
  onClick, value, start, end,
}) => {
  const className = useMemo(
    () => {
      if (!start || !end) return '';
      if (moment(end).isBefore(value.start)) return '';
      if (moment(start).isAfter(value.end)) return '';
      if (moment(value.start).isSameOrAfter(start) && moment(value.end).isSameOrBefore(end)) return 'start-0 end-0';
      if (moment(value.start).isSameOrAfter(start) && moment(value.end).isAfter(end)) return 'start-0 end-part';
      if (moment(value.start).isBefore(start) && moment(value.end).isSameOrBefore(end)) return 'start-part end-0';
      if (moment(value.start).isBefore(start) && moment(value.end).isAfter(end)) return 'start-part end-part';
      return '';
    },
    [end, start, value.end, value.start],
  );
  return (
    <Button variant="outline-secondary" className={className} onClick={(e) => onClick(e, value)}>
      {value.label}
    </Button>
  );
};

MonthButton.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  start: PropTypes.number,
  end: PropTypes.number,
};

MonthButton.defaultProps = {
  start: null,
  end: null,
};
export default MonthButton;
