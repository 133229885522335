import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import { StyledFieldList } from '../styles';
import itemTypes from '../itemTypes';
import SelectionItem from './selectionItem';
import { selectionsPropType } from '../../hooks';

const SelectionList = ({
  selections, activeSelection, onClick, onDblClick, onDragEnd,
}) => {
  const [padded, setPadded] = useState(null);
  const onDragEndHandler = useCallback((item) => {
    onDragEnd(item, padded);
  },
  [onDragEnd, padded]);
  const [{
    isOver, isOverCurrent, didDrop,
  }, ref] = useDrop({
    accept: [itemTypes.availableField, itemTypes.selection],
    drop: (item) => onDragEndHandler(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    if ((!isOver && !isOverCurrent && !didDrop) || (isOver && isOverCurrent && !didDrop)) {
      setPadded(null);
    }
  }, [didDrop, isOver, isOverCurrent]);
  return (
    <Card>
      <Card.Header>Обрані поля</Card.Header>
      <StyledFieldList
        ref={ref}
      >
        {selections.map((f) => (
          <SelectionItem
            key={f.name}
            name={f.name}
            label={f.label}
            onClick={onClick}
            onDblClick={onDblClick}
            active={activeSelection === f.name}
            onHover={setPadded}
            padded={padded === f.name}
            errored={f.errored}
          />
        ))}
      </StyledFieldList>
    </Card>
  );
};


SelectionList.propTypes = {
  selections: selectionsPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  activeSelection: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
};


SelectionList.defaultProps = {
  activeSelection: null,
  onDblClick: () => null,
};

export default SelectionList;
