import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer, StyledCellItem, StyledTableRow, StyledTD,
} from '../../../../../basicEditor';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';

const Row = ({
  rowIndex, active, selected, onActivateRow, onChangeRow, row, fields, errors,
}) => {
  const indicatorProps = tableHooks.useItemInputProps('indicator', row, fields, errors, onChangeRow, rowIndex);
  const indicatorTypeProps = tableHooks.useSelectorInputProps('indicator_type', row, fields, errors, onChangeRow, rowIndex);
  const unitOfMeasureProps = tableHooks.useStringInputProps('unit_of_measure', row, fields, errors, onChangeRow, rowIndex);
  const taskProps = tableHooks.useItemInputProps('problem', row, fields, errors, onChangeRow, rowIndex);
  const amountSfProps = tableHooks.useNumberInputProps('amount_sf0', row, fields, errors, onChangeRow, rowIndex);
  const amountZfProps = tableHooks.useNumberInputProps('amount_zf0', row, fields, errors, onChangeRow, rowIndex);
  const amountTotalProps = tableHooks.useNumberInputProps('amount_total0', row, fields, errors, onChangeRow, rowIndex);
  const descriptionProps = tableHooks.useStringInputProps('description', row, fields, errors, onChangeRow, rowIndex);

  return (
    <StyledTableRow
      active={active}
      selected={selected}
      onClick={(e) => onActivateRow(e, rowIndex)}
    >
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...indicatorProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.SelectorInput {...indicatorTypeProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.ItemPicker {...taskProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.StringInput {...unitOfMeasureProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...amountZfProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountSfProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...amountTotalProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.StringInput {...descriptionProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
    </StyledTableRow>
  );
};

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
};

Row.defaultProps = {
  active: false,
  selected: false,
  errors: {},
};

export default memo(Row);
