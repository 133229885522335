import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  HomePage, SrvPage, DefaultPage,
} from '../Pages';
import stdLister from '../../containers/instances/lister';
import stdEditor from '../../containers/instances/stdeditor';
import services from '../../containers/services';
import ReportsPanel from '../../containers/reportsPanel';
import StdReportEditor from '../../containers/basicReport';
import meta from '../../meta';
import { AppContext } from '../../providers/authProvider';

import LinkComponent from '../windowsManager/link';

const Routes = () => {
  const { currentUser } = useContext(AppContext);
  const mainSections = Object.entries(currentUser.available_sections).map((s) => s[1]);

  return (
    <Switch>
      <Route exact path="/" component={HomePage} />

      {mainSections.map((s) => (
        <Route exact path={`/${s.name}/`} key={s.name} component={() => <DefaultPage sections={s.children} type="page" />} />
      ))}

      {/* CATALOGS */}
      {Object.keys(meta.cat).map((cName) => (
        <Route
          key={`cat-list-${cName}`}
          exact
          path={meta.cat[cName].frontendURL}
        // component={stdLister.cat[cName]}
          render={() => (
            <LinkComponent
              component={stdLister.cat[cName]}
              title={`Список "${meta.cat[cName].name}" `}
            />
          )}
        />
      ))}
      {Object.keys(meta.cat).map((cName) => (
        <Route
          key={`cat-editor-${cName}`}
          exact
          path={`${meta.cat[cName].frontendURL}:id`}
        // component={stdEditor.cat[cName]}
          render={() => (
            <LinkComponent
              component={stdEditor.cat[cName]}
              title={`Елем.дов. "${meta.cat[cName].name} "`}
            />
          )}
        />
      ))}
      {/* DOCUMENTS */}
      {Object.keys(meta.doc).map((dName) => (
        <Route
          key={`doc-list-${dName}`}
          exact
          path={meta.doc[dName].frontendURL}
        // component={stdLister.doc[dName]}
          render={() => (
            <LinkComponent
              component={stdLister.doc[dName]}
              title={`Список "${meta.doc[dName].name}" `}
            />
          )}
        />
      ))}
      {Object.keys(meta.doc).map((dName) => (
        <Route
          key={`doc-el-${dName}`}
          exact
          path={`${meta.doc[dName].frontendURL}:id`}
                      // component={stdEditor.doc[dName]}
          render={() => (
            <LinkComponent
              component={stdEditor.doc[dName]}
              title={`Документ "${meta.doc[dName].name}" `}
            />
          )}
        />
      ))}
      {/* REPORTS */}

      <Route
        exact
        path="/rep/"
        render={() => (
          <LinkComponent
            component={ReportsPanel}
            title="Панель звітів"
          />
        )}
      />

      {Object.keys(meta.rep).map((dName) => (
        <Route
          key={`rep-el-${dName}`}
          exact
          path={`${meta.rep[dName].frontendURL}`}
          render={() => (
            <LinkComponent
              component={stdEditor.rep[dName]}
              title={`Звіт "${meta.rep[dName].name}" `}
            />
          )}
        />
      ))}

      <Route
        exact
        path="/rep/:reportName/:id"
      // component={StdReportEditor}
        render={() => (
          <LinkComponent
            component={StdReportEditor}
            title="Звіт"
          />
        )}
      />
      {/* SERVICES */}
      <Route exact path="/srv/" component={SrvPage} />

      {Object.keys(meta.srv).map((srvName) => (
        <Route
          key={srvName}
          exact
          path={`${meta.srv[srvName].frontendURL}`}
        // component={services[srvName].Editor}
          render={() => (
            <LinkComponent
              component={services[srvName].Editor}
              title={meta.srv[srvName].name}
            />
          )}
        />
      ))}
      <Route component={() => (<div>Ошиблись адресом?</div>)} />
    </Switch>
  );
};

export default Routes;
