import moment from 'moment';

export const monthNames = [...Array(12)].map((_, m) => {
  const d = new Date();
  d.setMonth(m, 1);
  return d.toLocaleString('uk', { month: 'long' });
});

export const shortFormat = { day: '2-digit', month: '2-digit', year: 'numeric' };

export const longFormat = { day: 'numeric', month: 'long', year: 'numeric' };

export const periodVarians = {
  today: {
    label: 'Сьогодні',
    eval: () => ({
      start: moment().utc().startOf('day').format('YYYY-MM-DD'),
      end: moment().utc().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  yesterday: {
    label: 'Вчора',
    eval: () => ({
      start: moment().utc().add(-1, 'days').startOf('day')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(-1, 'days').endOf('day')
        .format('YYYY-MM-DD'),
    }),
  },
  tomorrow: {
    label: 'Завтра',
    eval: () => ({
      start: moment().utc().add(1, 'days').startOf('day')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(1, 'days').endOf('day')
        .format('YYYY-MM-DD'),
    }),
  },
  thisMonth: {
    label: 'Цей місяць',
    eval: () => ({
      start: moment().utc().startOf('month')
        .format('YYYY-MM-DD'),
      end: moment().utc().endOf('month')
        .format('YYYY-MM-DD'),
    }),
  },
  nextMonth: {
    label: 'Наступний місяць',
    eval: () => ({
      start: moment().utc().add(+1, 'months').startOf('month')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(+1, 'months').endOf('month')
        .format('YYYY-MM-DD'),
    }),
  },
  prevMonth: {
    label: 'Минулий місяць',
    eval: () => ({
      start: moment().utc().add(-1, 'months').startOf('month')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(-1, 'months').endOf('month')
        .format('YYYY-MM-DD'),
    }),
  },
  thisQuarter: {
    label: 'Цей квартал',
    eval: () => ({
      start: moment().utc().startOf('quarter')
        .format('YYYY-MM-DD'),
      end: moment().utc().endOf('quarter')
        .format('YYYY-MM-DD'),
    }),
  },
  nextQuarter: {
    label: 'Наступний квартал',
    eval: () => ({
      start: moment().utc().add(1, 'quarters').startOf('quarter')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(1, 'quarters').endOf('quarter')
        .format('YYYY-MM-DD'),
    }),
  },
  prevQuarter: {
    label: 'Минулий квартал',
    eval: () => ({
      start: moment().utc().add(-1, 'quarters').startOf('quarter')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(-1, 'quarters').endOf('quarter')
        .format('YYYY-MM-DD'),
    }),
  },
  thisYear: {
    label: 'Цей рік',
    eval: () => ({
      start: moment().utc().startOf('year')
        .format('YYYY-MM-DD'),
      end: moment().utc().endOf('year')
        .format('YYYY-MM-DD'),
    }),
  },
  nextYear: {
    label: 'Наступний рік',
    eval: () => ({
      start: moment().utc().add(1, 'years').startOf('year')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(1, 'years').endOf('year')
        .format('YYYY-MM-DD'),
    }),
  },
  prevYear: {
    label: 'Минулий рік',
    eval: () => ({
      start: moment().utc().add(-1, 'years').startOf('year')
        .format('YYYY-MM-DD'),
      end: moment().utc().add(-1, 'years').endOf('year')
        .format('YYYY-MM-DD'),
    }),
  },
};
