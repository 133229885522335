import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from '../../../../components/bootStrap/buttons/styles';
import PrintEditor from './printEditor';

const SModal = styled(Modal)`
    padding-right: 0 !important;
    >div {
    margin: 0 !important;
    
    @media (min-width: 320px) and (max-width: 100vw) {
        max-width: 100vw !important;  
    }
    
        >div {
        width: 100vw !important;
        height: 100vh !important;
        border: none !important;
        border-radius: 0 !important;
        }
    }
`;
const HeaderText = styled.h2`
  color: var(--headerTextH);
  font-weight: 700;
`;

export const PrintButton = ({
  content, disabled, id,
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <CPButton
        onClick={() => setModalOpened(true)}
        disabled={disabled}
        content={content}
        icon={faPrint}
      />
      <SModal
        show={modalOpened}
        onHide={() => setModalOpened(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <HeaderText> Друкована форма</HeaderText>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrintEditor
            id={id}
          />
        </Modal.Body>
      </SModal>
    </>
  );
};

PrintButton.propTypes = {
  content: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

PrintButton.defaultProps = {
  content: 'Друк',
  disabled: false,
};

export default PrintButton;
