import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { StyledTableHeaderCell } from './styles';

const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const TableHeaderCell = ({
  active, className, children, title, canHighlight, highlighted, onToggleHighlght,
}) => {
  const clsName = `${className} px-2 ${highlighted ? 'bg-warning' : ''} `;
  const c = (
    <StyledTableHeaderCell active={active} className={clsName}>
      {children}
      {canHighlight && onToggleHighlght && (
        <StyledIcon
          className="text-muted mx-2"
          icon={highlighted ? faEyeSlash : faEye}
          title="Підсвитити"
          onClick={onToggleHighlght}
        />
      )}
    </StyledTableHeaderCell>
  );
  return title ? (
    <OverlayTrigger
      placement="auto"
      overlay={(
        <Tooltip>
          {title}
        </Tooltip>
      )}
    >
      {c}
    </OverlayTrigger>
  ) : c;
};
TableHeaderCell.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  title: PropTypes.node,
  canHighlight: PropTypes.bool,
  highlighted: PropTypes.bool,
  onToggleHighlght: PropTypes.func,
};

TableHeaderCell.defaultProps = {
  active: false,
  className: '',
  title: null,
  canHighlight: true,
  highlighted: false,
  onToggleHighlght: null,
};

export default TableHeaderCell;
