import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup,
} from 'react-bootstrap';
import { DeleteButtonWithModal } from './styles';
import {
  NewButton, EditButton, SelectAllButton,
  ExecuteButton, UnExecuteButton, FiltersButton,
} from '../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../components/bootStrap';
import { actions } from './actions';

export const DocListerCP = ({
  onActionClick, canNew, canEdit, canDelete, canExecute, canUnExecute, canFilter, filterApplied,
}) => (
  <CommandPanel>
    <ButtonGroup>
      <NewButton onClick={(e) => onActionClick(e, actions.new)} disabled={!canNew} />
      <EditButton onClick={(e) => onActionClick(e, actions.edit)} disabled={!canEdit} />
      <DeleteButtonWithModal
        disabled={!canDelete}
        onClick={(e) => onActionClick(e, actions.delete)}
      />
    </ButtonGroup>
    <SelectAllButton onClick={(e) => onActionClick(e, actions.selectAll)} />
    <ButtonGroup>
      <ExecuteButton
        onClick={(e) => onActionClick(e, actions.executeSelected)}
        disabled={!canExecute}
      />
      <UnExecuteButton
        onClick={(e) => onActionClick(e, actions.cancelExecuteSelected)}
        disabled={!canUnExecute}
      />
    </ButtonGroup>
    <FiltersButton
      disabled={!canFilter}
      onClick={(e) => onActionClick(e, actions.toggleFilter)}
      animation={filterApplied}
    />
  </CommandPanel>
);


DocListerCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  canNew: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canExecute: PropTypes.bool,
  canUnExecute: PropTypes.bool,
  canFilter: PropTypes.bool,
  filterApplied: PropTypes.bool,
};

DocListerCP.defaultProps = {
  canNew: true,
  canEdit: true,
  canDelete: true,
  canExecute: true,
  canUnExecute: true,
  canFilter: true,
  filterApplied: false,
};

export default DocListerCP;
