import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button, ButtonGroup, Nav, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { WinManagerContext } from '../../providers/winManagerProvider';

const ManagerLink = () => {
  const {
    dellComponentFromWindowsManager,
    linkComponents,
    currentURL,
    nextWindow,
    prevWindow,
    // windowsStack,
  } = useContext(WinManagerContext);
  const history = useHistory();
  return (
    <div
      className="LinksManager d-flex _border-top mr-1 w-100 fixed-bottom"
    >
      <ButtonGroup size="sm" className="rounded-0">
        <Button
          variant="outline-info"
          onClick={() => prevWindow(history)}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </Button>
        <Button
          variant="outline-info"
          onClick={() => nextWindow(history)}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </Button>
      </ButtonGroup>
      <Nav className="flex-grow-1 flex-nowrap overflow-hidden" variant="tabs" fill>
        {
          linkComponents.map(({ title, url }) => (
            <Nav.Item
              key={`link-key-${url}`}
              className="overflow-hidden"
              //   active={key === currentPathName}
            >
              <OverlayTrigger
                overlay={(
                  <Tooltip id={`tooltip-${url}`}>
                    <strong>
                      {title}
                    </strong>
                  </Tooltip>
                )}
                placement="auto"
              >
                <Nav.Link
                  as={Link}
                  to={url}
                  active={url === currentURL}
                  className="_bg-info d-flex flex-nowrap py-1"
                >
                  <span className="flex-grow-1 text-nowrap overflow-hidden">
                    {title}
                  </span>
                  {/* <span className="ml-auto_"> */}
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={(e) => {
                      e.preventDefault();
                      dellComponentFromWindowsManager(url);
                    }}
                    size="sm"
                    className="text-muted ml-1"
                  />
                  {/* </span> */}
                </Nav.Link>
              </OverlayTrigger>
            </Nav.Item>
          ))
        }
      </Nav>
    </div>
  );
};
//
export default ManagerLink;
