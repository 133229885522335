import meta from '../../../meta';
import catListersSelectors from '../individual/cat';
import ListerFactory from '../../newLister';
import { CatTableListerFactory, DocTableListerFactory } from '../../newLister/tableComponent';

/*

В случае добавления особенного листера
необходимо его вклбючить сюда

 */

const catListers = Object.keys(catListersSelectors)
  .filter((c) => !!catListersSelectors[c].lister)
  .reduce((R, c) => ({ ...R, [c]: catListersSelectors[c].lister }), {});

// console.log(catListersSelectors)

const Listers = {
  cat: Object.keys(meta.cat)
    .filter((mName) => !Object.keys(catListers).includes(mName))
    .reduce((RN, mName) => ({
      ...RN,
      [mName]: ListerFactory({
        backendURL: meta.cat[mName].backendURL,
        viewType: 'lister',
      })(CatTableListerFactory()),
    }), catListers),
  doc: Object.keys(meta.doc).reduce((RN, mName) => ({
    ...RN,
    [mName]: ListerFactory({
      backendURL: meta.doc[mName].backendURL,
      viewType: 'lister',
    })(DocTableListerFactory()),
  }), {}),
};

export default Listers;
