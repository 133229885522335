import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const ErrorMessage = ({ header, text, children }) => (
  <Alert variant="danger">
    <Alert.Heading>{header}</Alert.Heading>
    {children || (
      <p>
        {text}
      </p>
    )}
  </Alert>

);

ErrorMessage.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.symbol, PropTypes.arrayOf(PropTypes.symbol)]),
};

ErrorMessage.defaultProps = {
  header: 'Вам все вдалося, але виник несподіваний результат',
  text: 'Та ще й розробники забули щось тут написати',
  children: null,
};

export default ErrorMessage;
