import { useCallback, useMemo } from 'react';

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { boolean } isReadOnly - isReadOnly
 * @param { string[]} readOnlyFelds - readOnlyFelds
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  label: string,
 *  value: string,
 *  required: boolean,
 *  errors: Array}}
 */
export const useBaseInputProps = (
  name, data, fields, errors, onChange, isReadOnly = false, readOnlyFelds = [],
) => {
  const value = useMemo(() => data[name], [data, name]);
  const {
    readOnly, required, description, label,
  } = useMemo(
    () => {
      if (!fields) {
        return {
          readOnly: true,
          required: false,
          description: '',
          label: '',
          maxLength: null,
        };
      }
      if (!(name in fields)) {
        return {
          readOnly: true,
          required: false,
          description: '',
          label: `Поле ${name} не знайдено!`,
          maxLength: null,
        };
      }
      return {
        readOnly: fields[name].read_only || isReadOnly || readOnlyFelds.includes(name),
        label: fields[name].label,
        required: fields[name].required,
        description: fields[name].help_text,
      };
    }, [fields, isReadOnly, name, readOnlyFelds],
  );
  const onChangeControl = useCallback(
    (e, v) => onChange(() => ({ [name]: v })),
    [name, onChange],
  );

  const controlErrors = useMemo(
    () => (name in errors ? errors[name] : null),
    [errors, name],
  );

  return useMemo(
    () => ({
      controlId: name,
      value,
      readOnly,
      label,
      required,
      onChange: onChangeControl,
      errors: controlErrors,
      description,
    }),
    [controlErrors, description, label, name, onChangeControl, readOnly, required, value],
  );
};

export default useBaseInputProps;
