import React from 'react';
import PropTypes from 'prop-types';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const FiltersButton = ({
  content, onClick, disabled, animation,
}) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faFilter}
    animation={animation}
  />
);

FiltersButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  animation: PropTypes.bool,
};

FiltersButton.defaultProps = {
  onClick: () => null,
  content: 'Фільтрування',
  disabled: false,
  animation: false,
};

export default FiltersButton;
