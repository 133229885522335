import React from 'react';
import PropTypes from 'prop-types';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ButtonCollapse } from './styles';
import { CPButton } from '../../bootStrap/buttons/styles';

export const ShowButton = ({ content, onClick, disabled }) => (
  <ButtonCollapse>
    <CPButton
      onClick={onClick}
      disabled={disabled}
      title={content}
      icon={faCaretDown}
      content=""
    />
  </ButtonCollapse>
);

ShowButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ShowButton.defaultProps = {
  onClick: () => null,
  content: 'Розгорнути',
  disabled: false,
};

export default ShowButton;
