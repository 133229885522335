import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card, DropdownButton, ButtonGroup, CardColumns,
} from 'react-bootstrap';
import Items, { StyledLinkItem } from './pageItems';
import rep from '../../meta/rep';

const SButton = styled(ButtonGroup)`
button{
color: var(--textForNavBar);
border: none;
font-weight: 700;
&: hover{
background-color: #cc2941}
}
`;

const addReports = (sections) => Object.values(rep).filter((el) => el.sections === sections);

const DefaultPage = ({ sections, type, menuName }) => {
  addReports();
  return (
    <div style={{ zIndex: 9999 }}>
      {type === 'list'
        ? (
          <>
            {sections && sections.map((s) => (
              <DropdownButton
                key={s.name}
                title={s.label}
                variant="outline-danger"
                size="sm"
                drop="right"
                as={SButton}
                className="navbar-static-top"
                style={{ border: 'none' }}
              >
                {s.children.map((ss) => <Items key={ss.name} subSect={ss} type="list" />)}
              </DropdownButton>
            ))}
          </>
        )
        : (
          <>
            <CardColumns>
              {sections.map((s) => (
                <Card key={s.name}>
                  <Card.Header>
                    {s.label}
                  </Card.Header>
                  <Card.Body>
                    {s.children.map((ss) => <Items key={ss.name} subSect={ss} type="page" />)}
                  </Card.Body>
                </Card>
              ))}
            </CardColumns>

            <CardColumns>

              {(addReports(menuName).length > 0) && (
              <Card key="Reports">
                <Card.Header>
                  Звіти
                </Card.Header>
                <Card.Body>
                  {addReports(menuName).map((ss) => (
                    <StyledLinkItem
                      key={ss.frontendURL}
                      to={ss.frontendURL}
                      color={type === 'page' ? '#426f99' : '#ced6de'}
                      hover={type === 'page' ? '#0469ce' : 'white'}
                      background={type === 'page' ? '' : '#cc2941'}
                    >
                      {ss.name}
                    </StyledLinkItem>
                  ))}
                </Card.Body>
              </Card>
              )}

            </CardColumns>
          </>
        )}
    </div>
  );
};

DefaultPage.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  menuName: PropTypes.string.isRequired,
};

export default DefaultPage;
