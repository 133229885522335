/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import TPCommandPanel from '../../../../../newEditor/commandPanels/tablepart';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TPFooter from './footer';

const PlanningRows = ({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) => {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'budgetrequestactivityrow_set', readOnlyFields, errors, fields,
  });

  const {
    year,
  } = data;

  const totals = useMemo(
    () => (tableData.reduce((res, r) => {
      res.amount_zf0 += r.amount_zf0 || 0;
      res.amount_zf_1 += r.amount_zf_1 || 0;
      res.amount_zf_2 += r.amount_zf_2 || 0;
      res.amount_br_total0 += r.amount_br_total0 || 0;
      res.amount_br_total_1 += r.amount_br_total_1 || 0;
      res.amount_br_total_2 += r.amount_br_total_2 || 0;
      res.amount_sf0 += r.amount_sf0 || 0;
      res.amount_sf_1 += r.amount_sf_1 || 0;
      res.amount_sf_2 += r.amount_sf_2 || 0;
      res.amount_total0 += (r.amount_zf0 || 0) + (r.amount_sf0 || 0);
      res.amount_total_1 += (r.amount_zf_1 || 0) + (r.amount_sf_1 || 0);
      res.amount_total_2 += (r.amount_zf_2 || 0) + (r.amount_sf_2 || 0);
      return res;
    },
    {
      amount_zf0: 0,
      amount_zf_1: 0,
      amount_zf_2: 0,
      amount_br_total0: 0,
      amount_br_total_1: 0,
      amount_br_total_2: 0,
      amount_sf0: 0,
      amount_sf_1: 0,
      amount_sf_2: 0,
      amount_total0: 0,
      amount_total_1: 0,
      amount_total_2: 0,
    })
    ), [tableData],
  );

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          showFooter={showFooter}
          readOnly={readOnly}
          activeRow={activeRow}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          year={year}
          totals={totals}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow key={index} rowIndex={index} active={activeRow === index} activeCol={activeCol}>
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={(e) => tableActions.onAddRow(e)} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
};

PlanningRows.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
    budgetrequestactivityrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    budgetrequestactivityrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    budgetrequestactivityrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    budgetrequestactivityrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
