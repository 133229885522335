import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContentLabel } from '../../../../../../components/Styled/Misc';
import { ETextInput } from '../../../../../../components/Controls/withErrors';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';

const StyledHeading = styled.h2`
width: 100%
color: #2185d0;`;

const StyledDiv = styled.div`
background: #c1d4ff
padding: 10px
border-radius: 5px`;

const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  margin-bottom: 10px;
`;

const ConsequencesPage = (props) => {
  const {
    data, fields, errors, onChange,
  } = props;

  return (
    <>
      <StyledDiv>
        <StyledHeading>Пояснення до п.5.5.</StyledHeading>
        <TwoColGrid>
          <ContentLabel
            text={fields.cash_deviation.label}
            required={fields.cash_deviation.required}
          >
            <ETextInput
              value={data.cash_deviation}
              onChange={(e, v) => onChange({ cash_deviation: v })}
              readOnly={fields.cash_deviation.read_only}
              errors={errors.cash_deviation}
            />
          </ContentLabel>

          <ContentLabel
            text={fields.fact_profit_deviation.label}
            required={fields.fact_profit_deviation.required}
          >
            <ETextInput
              value={data.fact_profit_deviation}
              onChange={(e, v) => onChange({ fact_profit_deviation: v })}
              readOnly={fields.fact_profit_deviation.read_only}
              errors={errors.fact_profit_deviation}
            />
          </ContentLabel>
        </TwoColGrid>
      </StyledDiv>

      <TwoColGrid>
        <ContentLabel
          text={fields.fin_violation.label}
          required={fields.fin_violation.required}
        >
          <ETextInput
            value={data.fin_violation}
            onChange={(e, v) => onChange({ fin_violation: v })}
            readOnly={fields.fin_violation.read_only}
            errors={errors.fin_violation}
          />
        </ContentLabel>

        <ContentLabel
          text={fields.fin_discipline.label}
          required={fields.fin_discipline.required}
        >
          <ETextInput
            value={data.fin_discipline}
            onChange={(e, v) => onChange({ fin_discipline: v })}
            readOnly={fields.fin_discipline.read_only}
            errors={errors.fin_discipline}
          />
        </ContentLabel>
      </TwoColGrid>

      <StyledDiv>
        <StyledHeading>Узагальнений висновок щодо:</StyledHeading>

        <TwoColGrid>
          <ContentLabel
            text={fields.actuality.label}
            required={fields.actuality.required}
          >
            <ETextInput
              value={data.actuality}
              onChange={(e, v) => onChange({ actuality: v })}
              readOnly={fields.actuality.read_only}
              errors={errors.actuality}
            />
          </ContentLabel>

          <ContentLabel
            text={fields.utility_description.label}
            required={fields.utility_description.required}
          >
            <ETextInput
              value={data.utility_description}
              onChange={(e, v) => onChange({ utility_description: v })}
              readOnly={fields.utility_description.read_only}
              errors={errors.utility_description}
            />
          </ContentLabel>

          <ContentLabel
            text={fields.effectiveness_description.label}
            required={fields.effectiveness_description.required}
          >
            <ETextInput
              value={data.effectiveness_description}
              onChange={(e, v) => onChange({ effectiveness_description: v })}
              readOnly={fields.effectiveness_description.read_only}
              errors={errors.effectiveness_description}
            />
          </ContentLabel>

          <ContentLabel
            text={fields.impact_description.label}
            required={fields.impact_description.required}
          >
            <ETextInput
              value={data.impact_description}
              onChange={(e, v) => onChange({ impact_description: v })}
              readOnly={fields.impact_description.read_only}
              errors={errors.impact_description}
            />
          </ContentLabel>
        </TwoColGrid>
      </StyledDiv>
    </>
  );
};

ConsequencesPage.propTypes = {
  data: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  onChange: PropTypes.func.isRequired,
};

ConsequencesPage.defaultProps = {
  errors: [],
};

export default ConsequencesPage;
