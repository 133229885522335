import React, {
  useRef, useMemo, Suspense, useState,
} from 'react';
import { Modal, Tab } from 'react-bootstrap';
import {
  faDownload, faUpload, faSave, faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import { HeaderText } from '../../../components/Styled/Forms';
import { CommandPanel, ErrorMessage, FileUploadButton } from '../../../components/bootStrap';
import { useMaster } from './hooks';
import TablesPage from './tables';
import ParamsPage from './params';
import RelationsPage from './relations';
import FieldsPage from './fields';
import CalcsPage from './calcs';
import MetaFieldsPage from './metafields';
import SettingsPage from './settings';
import { CPButton } from '../../../components/bootStrap/buttons/styles';
import SelectSchemaButton from './selectShemaButton';
import TableBrowser from './tableBrowser';
import { ContainerTabs } from './styles';
import { NewButton } from '../../../components/bootStrap/buttons';
import NewScheme from './newScheme';
import NewReport from './newReport';

const Editor = () => {
  const {
    err,
    tables, tableHandlers, activeTable,
    params, activeParam, paramsHandlers,
    options, onAnalyzeHandler, tableFields,
    relations, relationHandlers, activeRelation,
    calcs, calcHandlers, activeCalc,
    fields, fieldHandlers, activeField,
    metaFields, metaFieldHandlers, activeMetaField,
    masterHandlers, tableRows, settings,
    settingHandlers, activeSetting,
  } = useMaster();

  const [showSchemePage, setShowSchemePage] = useState(false);
  const [newSchemeParams, setNewSchemeParams] = useState({
    identifier: '', name: '', version: 1, description: '', is_active: false, src: 'e30=',
  });

  const [showReportPage, setShowReportPage] = useState(false);
  const [newReportParams, setNewReportParams] = useState({
    schema: { id: null },
    name: '',
    description: '',
    options: {
      group: [], params: {}, order: [], fields: [],
    },
    permission_type: 100,
    is_active: true,
    deleted: false,
    template: '',
    user: null,
    authority: null,
    budget: null,
  });

  const handleChangeNewSchemeParams = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'is_active') {
      setNewSchemeParams((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
      return;
    }
    setNewSchemeParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeNewReportParams = (e) => {
    if (typeof (e) === 'number' || typeof (e) === 'string') {
      setNewReportParams((prevState) => ({
        ...prevState,
        schema: { id: e },
      }));
      return;
    }

    const { name, value } = e.target;
    setNewReportParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreate = (type) => {
    if (type === 'scheme') {
      masterHandlers.createNewSchema({ newSchemeParams, setShowSchemePage });
      return;
    }
    if (type === 'report') {
      masterHandlers.createNewReport({ newReportParams, setShowReportPage });
    }
  };

  const containerHeaderRef = useRef(null);
  const height = containerHeaderRef.current
    ? containerHeaderRef.current.getBoundingClientRect().height : 0;
  const allowedFields = useMemo(
    () => [...fields, ...calcs],
    [calcs, fields],
  );

  return (
    <>
      <div ref={containerHeaderRef}>
        {err && (
        <ErrorMessage text={err} />
        )}
        <HeaderText>SKD-Master</HeaderText>
        <CommandPanel>
          <SelectSchemaButton
            content="Завантажити схему"
            icon={faFolderOpen}
            onSelect={(v) => masterHandlers.loadSchemaFromSrv(v)}
          />
          <SelectSchemaButton
            content="Зберегти схему"
            icon={faSave}
            onSelect={(v) => masterHandlers.saveSchemaToSrv(v)}
          />
          <CPButton
            content="Зберегти у файл"
            icon={faDownload}
            onClick={() => {
              const blob = new Blob([masterHandlers.saveToJSON()], { type: 'json' });
              const url = window.URL.createObjectURL(blob);
              const tempLink = document.createElement('a');
              tempLink.href = url;
              tempLink.setAttribute('download', 'shema.json');
              tempLink.click();
            }}
          />
          <FileUploadButton
            content="Завантажити з файлу"
            onChange={(e, v) => masterHandlers.loadFromJSON(Object.values(v)[0])}
            icon={faUpload}
          />
          <NewButton
            content="Нова схема"
            onClick={() => setShowSchemePage(true)}
            icon={faUpload}
          />
          <NewButton
            content="Новий звіт"
            onClick={() => setShowReportPage(true)}
            icon={faUpload}
          />
        </CommandPanel>
      </div>

      <Modal show={showSchemePage} onHide={() => setShowSchemePage(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Параметри схеми</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={<div>Загрузка...</div>} />
          <NewScheme onCreate={() => { handleCreate('scheme'); }} handleChangeNewSchemeParams={handleChangeNewSchemeParams} />
        </Modal.Body>
      </Modal>

      <Modal show={showReportPage} onHide={() => setShowReportPage(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Параметри звіту</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={<div>Загрузка...</div>} />
          <NewReport onCreate={() => { handleCreate('report'); }} handleChangeNewReportParams={handleChangeNewReportParams} />
        </Modal.Body>
      </Modal>

      <ContainerTabs
        onSelect={onAnalyzeHandler}
      >
        <Tab
          title="Таблиці"
          eventKey="tables"
          style={{ height: `calc(90vh - ${height}px)` }}
          className="containerTab"
        >
          <TablesPage
            tables={tables}
            onChangeTable={tableHandlers.onChangeTable}
            onRenameTable={tableHandlers.onRenameTable}
            onAddTable={tableHandlers.onAddTable}
            onRemoveTable={tableHandlers.onRemoveTable}
            activeTable={activeTable}
            onSetActiveTable={tableHandlers.setActiveTable}
            showModal={tableHandlers.showModal}
            setShowModal={tableHandlers.setShowModal}
            keyValue={tableHandlers.key}
            setKeyValue={tableHandlers.setKey}
          />
        </Tab>
        <Tab
          title="Параметри"
          eventKey="params"
          style={{ height: `calc(90vh - ${height}px)` }}
          className="containerTab"
        >
          <ParamsPage
            params={params}
            setActiveParam={paramsHandlers.setActiveParam}
            activeParam={activeParam}
            onParamChange={paramsHandlers.onParamChange}
            allowedCtypes={options.params_ctypes}
          />
        </Tab>
        <Tab
          title="Зв'язки"
          eventKey="relations"
          style={{ height: `calc(90vh - ${height}px)` }}
          className="containerTab"
        >
          <RelationsPage
            relations={relations}
            onSetActiveRelation={relationHandlers.setActiveRelation}
            onAddRelation={relationHandlers.onAddRelation}
            onChangeRelation={relationHandlers.onChangeRelation}
            onDeleteRelation={relationHandlers.onDeleteRelation}
            activeRelation={activeRelation}
            tableFields={tableFields}
          />
        </Tab>
        <Tab
          title="Поля"
          eventKey="fields"
          style={{ height: `calc(90vh - ${height}px)` }}
          className="containerTab"
        >
          <FieldsPage
            fields={fields}
            onSetActiveField={fieldHandlers.setActiveField}
            onRemoveField={fieldHandlers.onRemoveField}
            onChangeField={fieldHandlers.onChangeField}
            onAddField={fieldHandlers.onAddField}
            onRenameField={fieldHandlers.onRenameField}
            onFillFields={fieldHandlers.fillFields}
            activeField={activeField}
            tableFields={tableFields}
            allowedCtypes={options.fields_ctypes}
            allowedFilters={options.allowed_filters}
            allowedAggregates={options.allowed_aggregates}
          />
        </Tab>
        <Tab
          title="Обчислювальні поля"
          eventKey="calcs"
          style={{ height: `calc(90vh - ${height}px)` }}
          className="containerTab"
        >
          <CalcsPage
            calcs={calcs}
            onSetActiveCalc={calcHandlers.setActiveCalc}
            onRemoveCalc={calcHandlers.onRemoveCalc}
            onChangeCalc={calcHandlers.onChangeCalc}
            onAddCalc={calcHandlers.onAddCalc}
            onRenameCalc={calcHandlers.onRenameCalc}
            activeCalc={activeCalc}
            allowedCtypes={options.calcs_ctypes}
            fields={fields}
          />
        </Tab>
        <Tab
          title="Метаполя"
          eventKey="meta_fields"
          style={{ height: `calc(90vh - ${height}px)` }}
          className="containerTab"
        >
          <MetaFieldsPage
            metaFields={metaFields}
            // tableFields={}
            onSetActiveField={metaFieldHandlers.setActiveMetaField}
            onRemoveField={metaFieldHandlers.onRemoveField}
            onChangeField={metaFieldHandlers.onChangeField}
            onRenameField={metaFieldHandlers.onRenameField}
            onAddField={metaFieldHandlers.onAddField}
            activeMetaField={activeMetaField}
            fields={allowedFields}
            allowedCtypes={options.meta_ctypes}
            allowedFonts={options.allowed_fonts}
          />
        </Tab>
        <Tab
          title="Просмотр таблиц"
          eventKey="table_browser"
          style={{ height: `calc(90vh - ${height}px)` }}
          className="containerTab"
        >
          <TableBrowser
            params={params}
            tables={tables}
            tableFields={tableFields}
            onGetTableRecords={masterHandlers.onGetTableRecords}
            tableRows={tableRows}
          />
        </Tab>
        <Tab
          title="Налаштування"
          eventKey="settings"
          style={{ height: `calc(90vh - ${height}px)` }}
          className="containerTab"
        >
          <SettingsPage
            settings={settings}
            onChangeSetting={settingHandlers.onChangeSetting}
            onRenameSetting={settingHandlers.onRenameSetting}
            onAddSetting={settingHandlers.onAddSetting}
            onRemoveSetting={settingHandlers.onRemoveSetting}
            activeSetting={activeSetting}
            onSetActiveSetting={settingHandlers.setActiveSetting}
          />
        </Tab>
      </ContainerTabs>

    </>
  );
};

export default Editor;
