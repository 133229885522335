import React, { forwardRef, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, ButtonToolbar, ButtonGroup,
} from 'react-bootstrap';
import {
  NewButton, EditButton, DeleteButton, NewFolderButton, ShowDeletedToggler,
  FiltersButton,
} from '../../../../components/bootStrap/buttons';
import {
  SearchInput,
} from '../../../../components/bootStrap';
import { StyledButtonToolbar } from './styles';
import ListerContext from '../../context';
import CompareComponent from './other_components/compare_modal';

const CatListerCommandPanel = forwardRef(({
  children,
}, ref) => {
  const {
    actions, permissions, showDeleted, searchString, localFilter,
  } = useContext(ListerContext);

  const filterApplied = !!Object.keys(localFilter).filter((k) => localFilter[k].use).length;
  return (
    <Navbar sticky="top" className="px-0 bg-white" ref={ref}>
      <StyledButtonToolbar className="mr-auto">
        <ButtonGroup>
          <NewButton onClick={actions.onNew} disabled={!(permissions.canNew && actions.onNew)} />
          {permissions.foldersUsed && (
            <NewFolderButton
              onClick={actions.onNewFolder}
              disabled={!(permissions.canNewFolder && actions.onNewFolder)}
            />
          )}
          <EditButton
            onClick={actions.onEdit}
            disabled={!(permissions.canEdit && actions.onEdit)}
          />
          <DeleteButton
            onClick={actions.onDelete}
            disabled={!(permissions.canDelete && actions.onDelete)}
          />
        </ButtonGroup>
        <ButtonGroup>
          <FiltersButton
            onClick={() => actions.onToggleFilterOpened()}
            disabled={!permissions.canFilter}
            animation={filterApplied}
          />
          {permissions.canCompare && (
            <CompareComponent />
          )}
        </ButtonGroup>
        {children}
      </StyledButtonToolbar>
      <ButtonToolbar>
        <ShowDeletedToggler
          value={showDeleted}
          disabled={!(permissions.canShowDeleted && actions.onToggleShowDeleted)}
          onChange={actions.onToggleShowDeleted}
        />
        <SearchInput
          onChange={actions.onSearch}
          value={searchString}
          disabled={!permissions.canSearch && !searchString}
        />
      </ButtonToolbar>
    </Navbar>
  );
});

CatListerCommandPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

CatListerCommandPanel.defaultProps = {
  children: null,
};

export default memo(CatListerCommandPanel);
