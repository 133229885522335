import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, OverlayTrigger, Popover } from 'react-bootstrap';

const PhoneInput = forwardRef(
  (
    {
      value,
      disabled,
      readOnly,
      onClick,
      onFocus,
      maxLength,
      onChange,
      id,
      errored,
    },
    ref,
  ) => {
    const [error, setError] = useState(null);
    const [number, setNumber] = useState(null);

    function checkNumber() {
      return number.match(/^((\+3)8)0\d{9}$/) != null;
    }

    const changeHandler = (e) => {
      const newValue = e.target.value;
      if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
        onChange(e, newValue);
        setNumber(e.target.value);
      }
    };

    const onBlurHandler = () => {
      if (number && !checkNumber()) {
        setError('Введіть номер у форматі +380***');
      } else setError(null);
    };

    const popover = (
      <Popover>
        <Popover.Content>
          {error}
        </Popover.Content>
      </Popover>
    );

    return error ? (
      <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
        <FormControl
          ref={ref}
          id={id}
          value={value || ''}
          onChange={changeHandler}
          disabled={disabled}
          readOnly={readOnly}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlurHandler}
          isInvalid={errored || error}
        />
      </OverlayTrigger>
    ) : (
      <FormControl
        ref={ref}
        id={id}
        value={value || ''}
        onChange={changeHandler}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlurHandler}
        isInvalid={errored || error}
      />
    );
  },
);

PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  errored: PropTypes.bool,
};

PhoneInput.defaultProps = {
  value: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  errored: false,
};

export default PhoneInput;
