const frontendURL = '/cat/disposer/';
const backendURL = '/api/references/refdisposer/';
const name = 'Довідник розпорядників бюджетних коштів';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: true,
};

export default instance;
