import React from 'react';
import Editor from './defaultEditor';

const FactExchange = () => (
  <Editor
    title="Завантажити фактичні видатки"
    backendUrl="/api/cityplans/treasurydbf/"
  />
);

export default FactExchange;
