import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import { availableGroupsPropType, groupsPropType, getPreviousGroup } from '../../hooks';
import { StyledRow, SColLeft, SColRight } from '../styles';
import SKDGroupsList from './groupsList';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
  IntoButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';

const GroupEditor = ({ availableGroups, groups, groupsHandlers }) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);

  const onActiveGroupHandler = useCallback(
    (gname) => setActiveGroup(gname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fName) => setActiveAvaField(fName),
    [],
  );

  const groupDragEndHanlder = useCallback(
    ({ name, type }, gname) => {
      if (type === itemTypes.availableField) {
        groupsHandlers.addGroupHandler(name, gname);
      } else if (type === itemTypes.group) {
        groupsHandlers.swapGroupRowHandler(name, gname);
      }
    },
    [groupsHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.group) {
        groupsHandlers.removeGroupHandler(name);
        if (activeGroup === name) setActiveGroup(null);
      }
    },
    [activeGroup, groupsHandlers],
  );
  return (
    <StyledRow>
      <SColLeft _lg={6}>
        <AvailableFieldList
          fields={availableGroups}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={groupsHandlers.addGroupHandler}
          label="Доступні группування"
          onDragEnd={avaDragEndHanlder}
        />
      </SColLeft>
      <Col lg={0.1}>
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => {
              groupsHandlers.clearAllGroupsHandler();
              setActiveGroup(null);
            }}
            disabled={!groups.length}
            title="Очистити"
          />
          <LeftButton
            onClick={() => {
              groupsHandlers.removeGroupHandler(activeGroup);
              setActiveGroup(getPreviousGroup(groups, activeGroup));
            }}
            disabled={activeGroup === null}
            title="Видалити"
          />
          <RightButton
            onClick={() => groupsHandlers.addGroupHandler(activeAvaField, activeGroup)}
            disabled={!activeAvaField}
            title="Додати"
          />
          <IntoButton
            onClick={() => groupsHandlers.insertSubgroupHandler(activeAvaField, activeGroup)}
            disabled={!activeAvaField}
            title="Згрупувати"
          />
        </ButtonGroup>
      </Col>
      <SColRight _lg={6}>
        <SKDGroupsList
          groups={groups}
          activeGroup={activeGroup}
          onClick={onActiveGroupHandler}
          onDblClick={(name) => {
            groupsHandlers.removeGroupHandler(name);
            setActiveGroup(getPreviousGroup(groups, name));
          }}
          onDragEnd={groupDragEndHanlder}
          onSubGroupClick={groupsHandlers.removeSubgroupHandler}
          onChangeHierarchy={groupsHandlers.onChangeHierarchyHandler}
        />
      </SColRight>
    </StyledRow>
  );
};

GroupEditor.propTypes = {
  availableGroups: availableGroupsPropType.isRequired,
  groups: groupsPropType.isRequired,
  groupsHandlers: PropTypes.shape({
    addGroupHandler: PropTypes.func,
    removeGroupHandler: PropTypes.func,
    swapGroupRowHandler: PropTypes.func,
    insertSubgroupHandler: PropTypes.func,
    removeSubgroupHandler: PropTypes.func,
    clearAllGroupsHandler: PropTypes.func,
    onChangeHierarchyHandler: PropTypes.func,
  }).isRequired,
};

export default GroupEditor;
