const frontendURL = '/doc/disposerchainchange/';
const backendURL = '/api/chain/disposerchainchange/';
const printURL = '/api/chain/printform/disposerchainchange/form/';
const name = 'Зміни до мережі розпорядників';

const instance = {
  frontendURL,
  backendURL,
  printURL,
  name,
};

export default instance;
