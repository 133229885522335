import ListerFactory from '../../../../newLister';
import meta from '../../../../../meta';
import { CatTableListerFactory } from '../../../../newLister/tableComponent';

const lister = ListerFactory({
  backendURL: meta.cat.authoritysignvariant.backendURL,
  viewType: 'lister',
})(CatTableListerFactory());

const selector = ListerFactory({
  backendURL: meta.cat.authoritysignvariant.backendURL,
  viewType: 'selector',
})(CatTableListerFactory());

const authoritysignvariant = {
  lister,
  selector,
};

export default authoritysignvariant;
