import React, {
  useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import {
  Navbar, Badge, Modal,
} from 'react-bootstrap';
import { faCheckSquare, faEdit, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import api, { NetworkException } from '../../api/req';
import {
  ErrorMessage, DimableLoader, SearchInput,
} from '../../components/bootStrap';
import { actions } from './commandPanels';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';
import FilterPanel from './filterPanel';
import CatalogsInLogica from './TableComponent/catalogsInLogica';
import { CPButton } from '../../components/bootStrap/buttons/styles';
import { WinManagerContext } from '../../providers/winManagerProvider';

const queryParser = async (response) => {
  if (response.ok) {
    return response.json();
  }
  throw new NetworkException(`${response.status} ${response.statusText}`);
};

const getLister = ({
  backendURL, CommandPanel, actionHandler, useHierarchyPagination,
}) => (WrappedComponent) => {
  const Lister = (props) => {
    const [state, setState] = useState(
      {
        isLoading: true,
        isErrored: false,
        errorText: '',
        data: { count: 0, results: [] }, // Данные с сервера
        options: { name: '', actions: {} },
        columns: {}, // Колонки
        isHierarhical: true, // Иерархический или нет
        openedRows: [], // Перечень всех открырых папаок
        visibleRows: [], // Текущие видимые ряды
        ordering: { column: '', isAscending: true }, // Сортировка
        pages: [1],
        doPrev: false,
        doNext: false,
        searchValue: null,
        selectedItems: [],
        currentItem: null,
        filteringFields: [],
        filterOpened: false,
        localFilter: {},
        compareData: [],
        showModal: false,
        isReload: false,
      },
    );

    // eslint-disable-next-line
    const getOrderQuery = () => {
      const { ordering, options } = state;
      if (!ordering.column) return null;
      const column = options.ordering_fields[ordering.column] || ordering.column;
      return { ordering: `${ordering.isAscending ? '' : '-'}${column}` };
    };

    // eslint-disable-next-line
    const getFilterQuery = () => {
      const { filter } = props;
      const { localFilter, filteringFields } = state;
      const usedFilter = Object.keys(localFilter)
        .filter((lf) => localFilter[lf].use)
        .reduce((R, lf) => {
          const cf = filteringFields.filter((ff) => ff.name === lf).reduce((_R, ff) => ff, null);
          const isFK = 'resource' in cf;
          return {
            ...R,
            [lf]: isFK && localFilter[lf].value ? localFilter[lf].value.id : localFilter[lf].value,
          };
        }, {});

      return {
        ...usedFilter,
        ...filter,
      };
    };

    // eslint-disable-next-line
    const generateVisibleRows = (rows, openedRows, isHierarhical = false, isSearch = false) => {
      const { noHierarchy } = props;
      const getRowsData = (parent = null, level = 0) => rows.filter((r) => !isHierarhical
        || isSearch
        || noHierarchy
        || r.parent === parent
        || (r.parent && r.parent.id === parent))
        .reduce((R, row) => [
          ...R,
          {
            ...row,
            level,
          },
          ...(isHierarhical && openedRows.includes(row.id) && !isSearch
            ? getRowsData(row.id, level + 1)
            : []),
        ], []);
      return getRowsData();
    };

    useEffect(() => {
      const loadOptions = async () => {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        const { authF } = props;

        const r = await api.options$(backendURL, authF);
        const options = await queryParser(r);
        if (options.actions && options.actions.GET) {
          const fields = options.actions ? options.actions.GET || {} : {};
          const filteringFields = options.filtering_fields
            .filter((f) => f.name in fields)
            .map((f) => ({
              ...fields[f.name],
              name: f.name,
            }));
          const oFields = Object.keys(options.ordering_fields || {});
          const columns = Object.keys(fields).reduce((R, f) => (
            {
              ...R,
              [f]: {
                ...fields[f],
                visible: !(options.hidden_fields || []).includes(f),
                orderable: oFields.includes(f),
              },
            }),
          {});
          const isHierarhical = !!columns.parent;

          return {
            columns,
            isHierarhical,
            options,
            filteringFields,
            isReload: !state.isReload,
          };
        }
        return {
          isErrored: true,
          errorText: 'No "GET" action in response from server',
        };
      };

      loadOptions().then((newState) => {
        setState((prevState) => ({
          ...prevState,
          ...newState,
          isReload: !prevState.isReload,
        }));
      });
    },
    // eslint-disable-next-line
      []);

    useEffect(() => {
      const reload = async () => {
        const {
          authF, currentId, settings, noHierarchy,
        } = props;

        const {
          searchValue, openedRows, isHierarhical, columns,
        } = state;

        if (Object.keys(columns).length === 0) return {}; // Еще не отаботал loadOptions

        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        const searchText = searchValue === null ? settings.searchValue : searchValue;

        const page = settings && settings.lastLoadedPage ? settings.lastLoadedPage : 1;
        const params = {
          ...getOrderQuery(),
          ...getFilterQuery(),
          ...(searchText && { search: searchText }),
        };
        const loadParams = useHierarchyPagination && !noHierarchy && !searchText ? [
          {
            ...params,
            parent_null: true,
          },
          ...(openedRows.map((parent) => ({
            ...params,
            parent,
          }))),
        ] : [{
          ...params,
          page,
        }];
        const responses = await Promise.all(loadParams.map((p) => api.get$(backendURL, authF, p)));
        const datas = await Promise.all(responses.map((r) => queryParser(r)));
        const newData = datas.reduce((R, data) => {
          const paginated = !!data.results;

          if (isHierarhical && paginated) {
            throw new Error('Cannot paginate hierarchical data!!!');
          }
          const results = data.results || data;
          const newCurrentData = {
            ...R,
            count: paginated ? data.count : data.length,
            next: paginated ? data.next : null,
            results: [
              ...R.results,
              ...results.map((d) => ({ ...d, page })),
            ],
          };
          return {
            ...R,
            paginated,
            ...newCurrentData,
          };
        }, { results: [] });

        return {
          data: newData,
          isLoading: false,
          currentItem: currentId || settings.currentId,
          selectedItems: currentId || settings.currentId ? [currentId || settings.currentId] : [],
          doNext: !isHierarhical && newData.paginated && !!newData.next,
          doPrev: !isHierarhical && newData.paginated && !!newData.previous,
          pages: [page],
          searchValue: searchText,
          visibleRows: generateVisibleRows(
            newData.results, openedRows, isHierarhical, !!searchValue,
          ),
        };
      };
      reload().then((newState) => {
        setState((prevState) => ({
          ...prevState,
          ...newState,
        }));
      });
    },
    // eslint-disable-next-line
    [ state.isReload ]);

    // const debouncedReload = debounce(reload);

    const loadPage = async (pageNo) => {
      const { authF } = props;
      const {
        data, pages, searchValue, openedRows, isHierarhical,
      } = state;
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const isPrevPage = pageNo < pages[0];
      const params = {
        ...getOrderQuery(),
        ...getFilterQuery(),
        ...(searchValue && { search: searchValue }),
        page: pageNo,
      };
      const rr = await api.get$(backendURL, authF, params);
      const loadedData = await queryParser(rr);
      const oldids = data.results.map((d) => d.id);
      const loadedResults = loadedData.results
        .filter((d) => !oldids.includes(d.id))
        .map((d) => ({ ...d, page: pageNo }));
      const newState = isPrevPage ? {
        isLoading: false,
        data: {
          ...data,
          previous: loadedData.previous,
          results: [
            ...loadedResults,
            ...data.results,
          ],
        },
        pages: [pageNo, ...pages],
        doPrev: !!loadedData.previous,
      } : {
        isLoading: false,
        data: {
          ...data,
          next: loadedData.next,
          results: [...data.results, ...loadedResults],
        },
        pages: [...pages, pageNo],
        doNext: !!loadedData.next,
      };
      setState((prevState) => ({
        ...prevState,
        ...newState,
        visibleRows: generateVisibleRows(
          newState.data.results, openedRows, isHierarhical, !!searchValue,
        ),
      }));
    };

    // eslint-disable-next-line
    const loadChildren = async (parent) => {
      const { authF } = props;
      const {
        data, searchValue, openedRows, isHierarhical,
      } = state;
      setState((prevState) => ({
        ...prevState, isLoading: true,
      }));
      const params = {
        ...getOrderQuery(),
        ...getFilterQuery(),
        ...(searchValue && { search: searchValue }),
        parent,
      };
      const rr = await api.get$(backendURL, authF, params);
      const loadedData = await queryParser(rr);
      const newData = loadedData.results || loadedData;
      const newState = {
        isLoading: false,
        data: {
          ...data,
          results: [
            ...data.results.filter((d) => d.parent !== parent),
            ...newData,
          ],
        },
      };
      setState((prevState) => ({
        ...prevState,
        ...newState,
        visibleRows: generateVisibleRows(
          newState.data.results, openedRows, isHierarhical, !!searchValue,
        ),
      }));
    };

    const newAction = () => {
      const { history } = props;
      // history.push(`${path}create`);
      history.push(`${backendURL}create`);
      // history.push(`${match.url}create`);
    };

    const editAction = (e, id) => {
      const { history, path } = props;
      history.push(`${path}${id}`);
      // history.push(`${match.url}${id}`);
    };

    const deleteAction = () => {
      const { authF } = props;
      const { selectedItems } = state;
      const deleteItem = async (itemId) => {
        const r = await api.patch(`${backendURL}${itemId}/delete/`, authF);
        if (r.ok) {
          return { ok: true, err: '' };
        }
        return { ok: false, err: `${r.status} ${r.statusText}` };
      };

      Promise.all(selectedItems.map((id) => deleteItem(id)))
        .then((r) => {
          // eslint-disable-next-line
            const newErrors = r.reduce((R, res) => res.ok ? R : `${R}${res.err} `, '');
          if (newErrors.length) {
            setState((prevState) => ({
              ...prevState,
              isErrored: true,
              errorText: newErrors,
            }));
          }
          setState((prevState) => ({
            ...prevState,
            isReload: !prevState.isReload,
          }));
        });
    };

    const choiceAction = (e, id) => {
      e.stopPropagation();
      const { onChoice, selectMode } = props;
      const { data } = state;
      if (!selectMode) {
        // eslint-disable-next-line no-console
        console.error('Trying select from not select mode');
        return false;
      }
      const res = data.results.filter((r) => r.id === id)[0];
      return onChoice(e, res);
    };

    const selectAllAction = () => {
      const { data } = state;
      setState((prevState) => ({
        ...prevState,
        selectedItems: data.results.map((d) => d.id),
      }));
    };

    const executeSelectedAction = (e, execute = true) => {
      const { authF } = props;
      const { selectedItems } = state;
      setState((prevState) => ({
        ...prevState,
        isErrored: false,
        errorText: null,
      }));
      const action = execute ? 'execute' : 'unexecute';
      const executeItem = async (itemId) => {
        const r = await api.patch(`${backendURL}${itemId}/${action}/`, authF);
        if (r.ok) {
          return { itemId, result: { ok: true, err: '' } };
        }
        if (r.status === 422) {
          const errData = await r.json();
          return {
            itemId,
            result: { ok: false, err: String(errData.error_data.map((er) => String(er.messages))) },
          };
        }
        return { itemId, result: { ok: false, err: `${r.status} ${r.statusText}` } };
      };
      Promise.all(selectedItems.map(executeItem)).then((execResults) => {
        const errorText = execResults.filter((dr) => !dr.result.ok).reduce((R, dr) => `${R}${dr.result.err}\n`, '');
        if (errorText.length) {
          setState((prevState) => ({
            ...prevState,
            isErrored: true,
            errorText,
            isLoading: false,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            isReload: !prevState.isReload,
          }));
        }
      });
    };

    //
    // executeSetPeriodAction = (e, period) => {
    //   const { options } = state;
    //   setState({
    //     period,
    //   }, () => reload(options));
    // };
    //
    const closeAction = (e) => {
      const { onClose, selectMode } = props;
      if (!selectMode) {
        // eslint-disable-next-line no-console
        console.error('Trying close from not select mode');
        return false;
      }
      return onClose(e);
    };

    const compareAction = async () => {
      const { authF } = props;
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const rr = await api.get$(`${backendURL}compare/`, authF);
      if (rr.ok) {
        const loadedData = await queryParser(rr);
        const newData = loadedData.result.map((el) => {
          el.check = false;
          return el;
        });

        const orderedData = newData.sort((a, b) => a.code - b.code);

        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          compareData: orderedData,
          showModal: true,
          isReload: !prevState.isReload,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          isReload: !prevState.isReload,
        }));
      }
    };

    const compareAndUpdate = async (params) => {
      const { authF } = props;
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (params.to_create.length || params.to_update.length) {
        await api.post(`${backendURL}compare_with_update/`, authF, params);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          showModal: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          showModal: false,
          isReload: !prevState.isReload,
        }));
      }
    };

    const processAction = (e, action, extra) => {
      const { currentItem, filterOpened } = state;
      switch (action) {
        case actions.new:
          newAction(e);
          break;
        case actions.edit:
          editAction(e, currentItem);
          break;
        case actions.delete:
          deleteAction(e, currentItem);
          break;
        case actions.select:
          choiceAction(e, currentItem);
          break;
        case actions.selectAll:
          selectAllAction(e);
          break;
        case actions.close:
          closeAction(e);
          break;
        case actions.executeSelected:
          executeSelectedAction(e, true);
          break;
        case actions.cancelExecuteSelected:
          executeSelectedAction(e, false);
          break;
        case actions.setPeriod:
          // executeSetPeriodAction(e, extra);
          break;
        case actions.exportXLS:
          // exportXLS(e);
          break;
        case actions.toggleFilter:
          setState((prevState) => ({
            ...prevState,
            filterOpened: !filterOpened,
          }));
          break;
        case actions.compare:
          compareAction(e);
          break;
        default:
          if (actionHandler) {
            actionHandler(e, action, extra);
          } else {
            // eslint-disable-next-line no-console
            console.log('Unknow action', action);
          }
      }
    };

    const activateItem = (e, rowId) => {
      const { selectedItems, data } = state;
      const { onSelect } = props;
      const currentItem = data.results.filter((r) => r.id === rowId)[0];
      if (e.ctrlKey) {
        if (selectedItems.includes(rowId)) {
          setState((prevState) => ({
            ...prevState,
            selectedItems: selectedItems.filter((id) => id !== rowId),
            currentItem: rowId,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            selectedItems: [...selectedItems, rowId],
            currentItem: rowId,
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          selectedItems: [rowId],
          currentItem: rowId,
        }));
      }
      onSelect(e, currentItem);
    };

    //   if (isOpen && useHierarchyPagination && !noHierarchy) loadChildren(rowId);
    //   Ниже, переписанное это условие, с использованием useEffect
    useEffect(() => {
      const { openedRows, currentItem } = state;

      const isOpen = !openedRows.includes(currentItem);
      const { noHierarchy } = props;

      if (isOpen && useHierarchyPagination && !noHierarchy) loadChildren(currentItem);
    },
    // eslint-disable-next-line
    [ props, state.openedRows, state.currentItem, state.localFilter]);

    const toggleRow = (e, rowId) => {
      const {
        openedRows, isHierarhical, searchValue, data,
      } = state;
      const { noHierarchy } = props;
      const isOpen = !openedRows.includes(rowId);
      const newOpenedRows = !isOpen
        ? openedRows.filter((r) => r !== rowId)
        : [...openedRows, rowId];
      setState((prevState) => ({
        ...prevState,
        openedRows: newOpenedRows,
        visibleRows: generateVisibleRows(
          data.results, newOpenedRows, isHierarhical, !!searchValue,
        ),
      }));

      if (isOpen && useHierarchyPagination && !noHierarchy) loadChildren(rowId);
    };

    const onHeaderClick = (e, column) => {
      const { ordering } = state;
      setState((prevState) => ({
        ...prevState,
        ordering: {
          column,
          isAscending: ordering.column === column ? !ordering.isAscending : true,
          isReload: !prevState.isReload,
        },
      }));
    };

    const onScrollBottom = () => {
      const { doNext, pages } = state;
      if (doNext) {
        loadPage(pages[pages.length - 1] + 1);
      }
    };

    const onScrollTop = () => {
      const { doPrev, pages } = state;
      if (doPrev) {
        loadPage(pages[0] - 1);
      }
    };

    const onSearchHandler = (e, v) => {
      setState((prevState) => ({
        ...prevState,
        searchValue: v,
        isReload: !prevState.isReload,
      }));
    };

    const rowDoubleClickHandler = (e, id) => {
      const { selectMode } = props;
      const { options } = state;
      if (selectMode) {
        choiceAction(e, id);
      } else if (options.actions.POST) {
        editAction(e, id);
      }
    };

    const setFilter = (nameF, value, use) => {
      const { localFilter } = state;
      setState((prevState) => ({
        ...prevState,
        localFilter: {
          ...localFilter,
          [nameF]: { value, use },
        },
      }));
    };
      // }, () => debouncedReload());
      // TODO: overload filters (props)

    const onCheck = (code) => {
      setState((prevState) => ({
        ...prevState,
        compareData:
          prevState.compareData.map((d) => ({
            ...d,
            check: d.code === code ? !d.check : d.check,
          })),
      }));
    };

    const onCheckAll = () => {
      setState((prevState) => ({
        ...prevState,
        compareData: prevState.compareData.map((d) => ({ ...d, check: true })),
      }));
    };

    const onUncheckAll = () => {
      setState((prevState) => ({
        ...prevState,
        compareData: prevState.compareData.map((d) => ({ ...d, check: false })),
      }));
    };

    const onUpdate = () => {
      const { compareData } = state;
      const checkedData = compareData.filter((el) => el.check);
      const codeNothingToDo = checkedData.filter((el) => el.description.compare_result === 'no_code_logika').map((el) => el.code);
      const codeToCreate = checkedData.filter((el) => el.description.compare_result === 'no_code_minfin').map((el) => el.code);
      const codeToUpdate = checkedData.map((el) => el.code)
        .filter((el) => codeToCreate.indexOf(el) === -1)
        .filter((el) => codeNothingToDo.indexOf(el) === -1);
      const params = {
        to_update: codeToUpdate,
        to_create: codeToCreate,
      };
      compareAndUpdate(params);
    };

    const filterData = (list) => list.filter((i) => i.is_different);

    const {
      isLoading,
      isErrored,
      errorText,
      options,
      selectedItems,
      currentItem,
      columns,
      isHierarhical,
      openedRows,
      ordering,
      searchValue,
      period,
      doNext,
      doPrev,
      visibleRows,
      data,
      filteringFields,
      filterOpened,
      localFilter,
      compareData,
      showModal,
    } = state;
    const { canSelect, selectMode } = props;
    const filterApplied = !!Object.keys(localFilter).filter((lf) => localFilter[lf].use).length;
    const filterLogicaList = filterData(compareData);
    const { dellComponentFromWindowsManager } = useContext(WinManagerContext);

    return (
      <DimableLoader loading={isLoading}>
        <Modal
          show={showModal}
          size="xl"
          onHide={() => setState((prevState) => ({
            ...prevState,
            showModal: false,
          }))}
        >
          <Modal.Header
            closeButton
            style={{
              zIndex: '9', position: 'sticky', top: '0', backgroundColor: 'white', height: '60px',
            }}
          >
            <CPButton
              onClick={onCheckAll}
              content="Обрати всі"
              icon={faCheckSquare}
            />
            <CPButton
              onClick={onUncheckAll}
              content="Зняти відмітки"
              icon={faSquare}
            />
            <CPButton
              onClick={onUpdate}
              content="Оновити відмічені"
              icon={faEdit}
              disabled={!compareData.find((el) => el.check)}
            />
          </Modal.Header>
          <Modal.Body>
            <CatalogsInLogica
              list={filterLogicaList}
              onCheck={onCheck}
              isLoading={isLoading}
              style={{ overflow: 'auto' }}
            />
          </Modal.Body>
        </Modal>

        <div>
          <div className="d-flex">
            <div className="flex-grow-1 d-flex">
              <h3 style={{ color: 'var(--headerTextH)', fontWeight: '700' }}>
                {options.name}
              </h3>
              <span>
                <Badge variant="primary" style={{ backgroundColor: 'var(--labelBackground)', color: 'var(--labelColor)', marginLeft: '3px' }}>{data.count}</Badge>
              </span>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={(e) => {
                  e.preventDefault();
                  dellComponentFromWindowsManager();
                }}
                size="sm"
                className="text-muted ml-1"
              />
            </div>
          </div>
          {isErrored && (<ErrorMessage text={errorText} />)}
          {CommandPanel ? (
            <Navbar style={{ padding: '0.7rem 0' }}>
              <CommandPanel
                onActionClick={processAction}
                canNew={!!options.actions.POST}
                canEdit={!!currentItem && !!options.actions.POST}
                canDelete={!!selectedItems.length && !!options.actions.POST}
                canSelect={selectMode && canSelect && !!currentItem}
                canExecute={!!selectedItems.length}
                canCancelExecute={!!selectedItems.length}
                period={period}
                filterApplied={filterApplied}
              />
              <Navbar.Collapse className="justify-content-end" style={{ marginBottom: '10px' }}>
                <div style={{ width: '300px' }}>
                  <SearchInput onSearch={onSearchHandler} value={searchValue} size="sm" />
                </div>
              </Navbar.Collapse>
            </Navbar>
          ) : null}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 auto', overflow: 'auto' }}>
            <WrappedComponent
              columns={columns}
              data={visibleRows}
              selectedItems={selectedItems}
              onRowClick={activateItem}
              onRowDoubleClick={rowDoubleClickHandler}
              isHierarhical={isHierarhical}
              isSeacrh={!!searchValue}
              openedRows={openedRows}
              onToggle={toggleRow}
              onHeaderClick={onHeaderClick}
              ordering={ordering}
              onScrollBottom={onScrollBottom}
              onScrollTop={onScrollTop}
              readOnly={!options.actions.POST}
              doPrev={doPrev}
              doNext={doNext}
              selectMode={selectMode}
            />
          </div>
          <FilterPanel
            show={filterOpened}
            filteringFields={filteringFields}
            filter={localFilter}
            onSetFilter={setFilter}
          />
          <div className="collapsing width" id="sss" style={{ transitionProperty: 'width' }} />
        </div>
      </DimableLoader>
    );
  };

  Lister.propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    authF: PropTypes.func.isRequired,
    onChoice: PropTypes.func, // Нажатие на конпку выбрать или двойной щелчок
    onSelect: PropTypes.func, // Событие при выделении строки
    onClose: PropTypes.func, // Событие при отказе от вібора
    canSelect: PropTypes.bool, // Можно ли выбрать
    selectMode: PropTypes.bool, // Режим выбора
    filter: PropTypes.shape({}), // фильтр ключ-значение
    currentId: PropTypes.number, // текущее выбраное значение
    noHierarchy: PropTypes.bool,
    settings: PropTypes.shape({
      lastLoadedPage: PropTypes.number,
      searchValue: PropTypes.string,
      currentId: PropTypes.number,
    }),
  };

  Lister.defaultProps = {
    onChoice: () => null,
    onSelect: () => null,
    onClose: () => null,
    canSelect: true,
    selectMode: false,
    filter: null,
    currentId: null,
    settings: {},
    noHierarchy: false,
  };

  const mapStateAuthWithSettings = (state) => ({
    ...mapStateAuth(state),
  });

  const enchance = compose(withRouter, withAuthConsumer(mapStateAuthWithSettings));
  return enchance(Lister);
};

export default getLister;
