import { useBaseInputProps } from './baseInputProps';

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { boolean } isReadOnly - isReadOnly
 * @param {string[]} readOnlyFelds - readOnlyFelds
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  label: string,
 *  value: boolean,
 *  required: boolean,
 *  errors: Array}}
 */
export const useCheckboxInputProps = (
  name, data, fields, errors, onChange, isReadOnly = false, readOnlyFelds = [],
) => useBaseInputProps(name, data, fields, errors, onChange, isReadOnly, readOnlyFelds);

export default useCheckboxInputProps;
