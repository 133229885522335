import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, ButtonGroup } from 'react-bootstrap';
import AvailableFieldList from '../available';
import { availableColumnsPropType, columnsPropType, getPreviousColumn } from '../../hooks';
import { StyledRow, SColLeft, SColRight } from '../styles';
import SKDColumnsList from './columnsList';
import {
  AllLeftButton as ClearAllButton,
  OneLeftButton as LeftButton,
  OneRightButton as RightButton,
  IntoButton,
} from '../../../../components/bootStrap/buttons';
import itemTypes from '../itemTypes';

const ColumnsEditor = ({ availableColumns, columns, columnsHandlers }) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);

  const onActiveColumnHandler = useCallback(
    (cname) => setActiveColumn(cname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fName) => setActiveAvaField(fName),
    [],
  );


  const columnDragEndHanlder = useCallback(
    ({ name, type }, gname) => {
      if (type === itemTypes.availableField) {
        columnsHandlers.addColumnHandler(name, gname);
      } else if (type === itemTypes.column) {
        columnsHandlers.swapColumnRowHandler(name, gname);
      }
    },
    [columnsHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.column) {
        columnsHandlers.removeColumnHandler(name);
        if (activeColumn === name) setActiveColumn(null);
      }
    },
    [activeColumn, columnsHandlers],
  );
  return (
    <StyledRow>
      <SColLeft _lg={5}>
        <AvailableFieldList
          fields={availableColumns}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={columnsHandlers.addColumnHandler}
          label="Доступні групування"
          onDragEnd={avaDragEndHanlder}
        />
      </SColLeft>
      <Col lg={0.1}>
        <ButtonGroup vertical>
          <ClearAllButton
            onClick={() => {
              columnsHandlers.clearAllColumnsHandler();
              setActiveColumn(null);
            }}
            disabled={!columns.length}
            title="Очистити"
          />
          <LeftButton
            onClick={() => {
              columnsHandlers.removeColumnHandler(activeColumn);
              setActiveColumn(getPreviousColumn(columns, activeColumn));
            }}
            disabled={activeColumn === null}
            title="Видалити"
          />
          <RightButton
            onClick={() => columnsHandlers.addColumnHandler(activeAvaField, activeColumn)}
            disabled={!activeAvaField}
            title="Додати"
          />
          <IntoButton
            onClick={() => columnsHandlers.insertSubcolumnHandler(activeAvaField, activeColumn)}
            disabled={!activeAvaField}
            title="Згрупувати"
          />
        </ButtonGroup>
      </Col>
      <SColRight _lg={6}>
        <SKDColumnsList
          columns={columns}
          activeColumn={activeColumn}
          onClick={onActiveColumnHandler}
          onDblClick={(name) => {
            columnsHandlers.removeColumnHandler(name);
            setActiveColumn(getPreviousColumn(columns, name));
          }}
          onDragEnd={columnDragEndHanlder}
          onSubColumnClick={columnsHandlers.removeSubcolumnHandler}
          onChangeHierarchy={columnsHandlers.onChangeHierarchyHandler}
        />
      </SColRight>
    </StyledRow>
  );
};

ColumnsEditor.propTypes = {
  availableColumns: availableColumnsPropType.isRequired,
  columns: columnsPropType.isRequired,
  columnsHandlers: PropTypes.shape({
    addColumnHandler: PropTypes.func,
    removeColumnHandler: PropTypes.func,
    swapColumnRowHandler: PropTypes.func,
    insertSubcolumnHandler: PropTypes.func,
    removeSubcolumnHandler: PropTypes.func,
    clearAllColumnsHandler: PropTypes.func,
    onChangeHierarchyHandler: PropTypes.func,
  }).isRequired,
};

export default ColumnsEditor;
