import React from 'react';
import PropTypes from 'prop-types';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const NewButton = ({ content, onClick, disabled }) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faFile}
  />
);

NewButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

NewButton.defaultProps = {
  onClick: () => null,
  content: 'Створити',
  disabled: false,
};

export default NewButton;
