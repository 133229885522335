import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { getDisplayName } from '../../../api/utils';

const ErrContainer = Styled.div`
  background: #ffffdd;
  position: absolute;
  margin-left: 10px;
  bottom: 100%;
  font-weight: 400;
  border: 1px solid #a2b4e8;
  box-shadow: 0 0 20px rgba(0,0,0,0.16);
  border-radius: 0.3rem;
  padding: 8px;
  color: red;
  width: fit-content;
  ::after{
content: " =";
    position: absolute;
    z-index: 1;
    display: block;
    width: 0px;
    height: 0px;
    border-top: 11px solid #ffffdd;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 100%;  }
`;

const ErrText = Styled.div`
  
`;

const withErrors = (WrappedComponent) => {
  const WithErrors = ({ errors, ...rest }) => (
    <div>
      {errors && (
        <ErrContainer>
          {errors.map((err) => (
            <ErrText key={err}>{err}</ErrText>
          ))}
        </ErrContainer>
      )}
      <WrappedComponent {...rest} errored={!!errors} />
    </div>
  );
  WithErrors.displayName = `${getDisplayName(WrappedComponent)} wrapped by withErrors`;
  WithErrors.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  WithErrors.defaultProps = {
    errors: null,
  };
  return WithErrors;
};


export default withErrors;
