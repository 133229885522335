import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DDColumn from './ddColumn';

const ForeignKeyHeader = ({
  columnName, label, data, value, onChange,
}) => {
  const values = useMemo(
    () => {
      const ids = data.reduce((R, row) => (row[columnName]
        ? { ...R, [row[columnName].id]: row[columnName] }
        : R), {});
      return Object.keys(ids).map((id) => ({
        value: Number(id),
        display_name: ids[id].repr,
        selected: value.includes(Number(id)),
      }));
    },
    [columnName, data, value],
  );
  return (
    <DDColumn
      id={`${columnName}-dropdown`}
      value={value}
      label={label}
      values={values}
      onChange={onChange}
    />
  );
};

ForeignKeyHeader.propTypes = {
  columnName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

ForeignKeyHeader.defaultProps = {
  value: [],
};

export default ForeignKeyHeader;
