const frontendURL = '/doc/finsourceplanchanges/';
const backendURL = '/api/authorityplans/finsourceplanchangesheader/';
// const printURL = '';
const name = 'Зміни до розпису джерел фінансування';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
