import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import FilterField from '../../lister/filterPanel/filterField';


const FilterGrid = ({ filteringFields, onSetFilter, filters }) => (
  <Row style={{ padding: '0 15px 15px 15px' }}>
    {filteringFields.map((ff) => (
      <Col key={ff.name} style={{ padding: '0px' }}>
        <FilterField
          desc={ff}
          value={filters[ff.name]}
          onSetFilter={onSetFilter}
        />
      </Col>
    ))}
  </Row>
);

FilterGrid.propTypes = {
  filteringFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    resource: PropTypes.string,
  })).isRequired,
  filters: PropTypes.shape({}).isRequired,
  onSetFilter: PropTypes.func.isRequired,
};

export default FilterGrid;
