import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import { StyledFieldList } from '../styles';
import itemTypes from '../itemTypes';
import FiltersItem from './filtersItem';
import { filtersPropType } from '../../hooks';

const FiltersList = ({
  filters, activeFilter, onClick, onDblClick, onDragEnd,
  changeFilterUse, changeFilterOperation, changeFilterValue,
}) => {
  const [padded, setPadded] = useState(null);
  const onDragEndHandler = useCallback((item) => {
    onDragEnd(item, padded);
  },
  [onDragEnd, padded]);
  const [{
    isOver, isOverCurrent, didDrop,
  }, ref] = useDrop({
    accept: [itemTypes.availableField, itemTypes.filter],
    drop: (item) => onDragEndHandler(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    if ((!isOver && !isOverCurrent && !didDrop) || (isOver && isOverCurrent && !didDrop)) {
      setPadded(null);
    }
  }, [didDrop, isOver, isOverCurrent]);
  return (
    <Card>
      <Card.Header>Фільтрування</Card.Header>
      <StyledFieldList
        ref={ref}
      >
        {filters.map((f) => (
          <FiltersItem
            key={f.name}
            name={f.name}
            label={f.label}
            use={f.use}
            operation={f.operation}
            value={f.value}
            allowedOperations={f.allowedOperations}
            ctype={f.ctype}
            resource={f.resource}
            isPlural={f.isPlural}
            onClick={onClick}
            onDblClick={onDblClick}
            active={activeFilter === f.name}
            onHover={setPadded}
            padded={padded === f.name}
            errored={f.errored}
            changeFilterUse={changeFilterUse}
            changeFilterOperation={changeFilterOperation}
            changeFilterValue={changeFilterValue}
          />
        ))}
      </StyledFieldList>
    </Card>
  );
};


FiltersList.propTypes = {
  filters: filtersPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  activeFilter: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  changeFilterUse: PropTypes.func.isRequired,
  changeFilterOperation: PropTypes.func.isRequired,
  changeFilterValue: PropTypes.func.isRequired,
};


FiltersList.defaultProps = {
  activeFilter: null,
  onDblClick: () => null,
};

export default FiltersList;
