const frontendURL = '/cat/kbp/';
const backendURL = '/api/references/refkbp/';
const name = 'Кодифікатор бюджетних програм МБ (КБП)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
