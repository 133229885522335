const frontendURL = '/cat/budget/';
const backendURL = '/api/references/refbudget/';
const name = 'Довідник бюджетів';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
