import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import EditorContainer from '../../../newEditor/editorContainer';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import {
  TabContainer,
  LabelTab,
} from '../../../basicEditor/tpController/styles';
import { PrintButton } from './printButton';
import useEditor from '../../../newEditor/hook/editor';

const Editor = ({
  onSave, onClose, id,
}) => {
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields,
  } = useEditor({
    backendURL: meta.doc.finclosing.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });
  const numberProps = editorHooks.useStringInputProps(
    'number',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );
  const dateProps = editorHooks.useDateInputProps(
    'doc_date',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );
  const planKindProps = editorHooks.useSelectorInputProps(
    'plan_kind',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  const fondProps = editorHooks.useItemInputProps(
    'fond_item',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  const authorityAccProps = editorHooks.useItemInputProps(
    'authority_acc',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  const commentOfAutorProps = editorHooks.useTextInputProps(
    'comment_of_author',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  const isFinancedProps = editorHooks.useCheckboxInputProps(
    'is_financed',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  const approvedProps = editorHooks.useCheckboxInputProps(
    'approved',
    data,
    fields,
    fieldErrors,
    actions.onChange,
    false,
    headerReadOnlyFields,
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <DocCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
        executed={data.executed}
      >
        <PrintButton
          id={`${data.id}`}
        />
      </DocCommandPanel>
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.CheckboxInput {...approvedProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.ItemPicker {...fondProps} />
              </Col>

              <Col>
                <EditorControls.ItemPicker {...authorityAccProps} />
              </Col>
            </Row>

            <Row>
              <Col>
                <EditorControls.CheckboxInput {...isFinancedProps} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>

        <LabelTab defaultActiveKey="finclosing" id="uncontrolled-tab-example">
          {fields && fields.finclosingrequestrow_set && (
          <Tab
            eventKey="finclosing"
            title={fields.finclosingrequestrow_set.label}
          >
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>
          )}

          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentOfAutorProps} />
            </TabContainer>
          </Tab>
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
