import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


const getTpController = (WrappedComponent) => {
  class TPController extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        activeRow: null,
        selectedRows: [],
      };
    }

    onAddRow = () => {
      const { onChange, data } = this.props;
      const newRow = { };

      const newData = [
        ...data,
        newRow,
      ];

      if (onChange) {
        onChange(newData);
      }

    };

    onCopyRow = () => {
      const { onChange, data } = this.props;
      const { activeRow } = this.state;

      const newRow = { ...data[activeRow] };

      const newData = [
        ...data,
        newRow,
      ];
      if (onChange) {
        onChange(newData);
        this.setState({ selectedRows: [] });
      }
    };

    onFillInTheTable = (src) => {
      // console.log(src)
      // console.log(this.state)
      // console.log(this.props)
    }

    onChangeRow = (e, rowIndex, partData) => {
      const { onChange, data } = this.props;

      const newRow = { ...data[rowIndex], ...partData };

      const newData = [
        ...data.slice(0, rowIndex),
        newRow,
        ...data.slice(rowIndex + 1),
      ];
      if (onChange) {
        onChange(newData);
      }
    };

    onDeleteRow = (e, rowIndex) => {
      const { onChange, data } = this.props;

      const newData = [
        ...data.slice(0, rowIndex),
        ...data.slice(rowIndex + 1),
      ];
      if (onChange) {
        onChange(newData);
      }
    };

    onDeleteSelectedRows = () => {
      const { onChange, data } = this.props;
      const { selectedRows } = this.state;

      const newData = data.filter((d, rowIndex) => !selectedRows.includes(rowIndex));
      if (onChange) {
        onChange(newData);
        this.setState({ selectedRows: [] });
      }
    };

    onActivateRow = (e, rowId) => {
      const { selectedRows, activeRow } = this.state;
      let newSelectedRows;
      if (e.ctrlKey && !selectedRows.includes(rowId)) {
        newSelectedRows = [...selectedRows, rowId];
      } else if (e.ctrlKey && selectedRows.includes(rowId)) {
        newSelectedRows = selectedRows.filter((sr) => sr !== rowId);
      } else {
        newSelectedRows = [rowId];
      }

      this.setState({ activeRow: e.ctrlKey ? activeRow : rowId, selectedRows: newSelectedRows });
    };

    render() {
      const { ...rest } = this.props;
      const { activeRow, selectedRows } = this.state;
      return (
        <WrappedComponent
          onChangeRow={this.onChangeRow}
          onAddRow={this.onAddRow}
          onCopyRow={this.onCopyRow}
          onFillInTheTable={this.onFillInTheTable}
          onDeleteRow={this.onDeleteRow}
          onDeleteSelectedRows={this.onDeleteSelectedRows}
          onActivateRow={this.onActivateRow}
          activeRow={activeRow}
          selectedRows={selectedRows}
          {...rest}
        />
      );
    }
  }
  TPController.propTypes = {
    onChange: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  return TPController;
};

export default getTpController;
