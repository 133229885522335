const frontendURL = '/doc/assigmentplanningrev/';
const backendURL = '/api/authorityplans/revenueplanheader/';
const name = 'Розпис доходів';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
