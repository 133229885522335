const frontendURL = '/cat/kfk/';
const backendURL = '/api/references/refkfk/';
const name = 'Класифікація функціональна (КФК)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
