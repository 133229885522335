import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { fieldsPropTypes } from './propTypes';
import TPHeader from '../../../../../basicEditor/tpController/TPHeader/header';
import StringFilteredHeader from '../../../../../basicEditor/tpController/TPHeader/filtered/stringHeader';
import ForeignKeyHeader from '../../../../../basicEditor/tpController/TPHeader/filtered/foreignKeyHeader';

const Header = ({
  fields, data, filter, onFilter,
}) => {
  const onEdrpouFilter = useCallback(
    (edrpou) => onFilter({ edrpou }),
    [onFilter],
  );
  const onKVKFilter = useCallback(
    (kvk) => onFilter({ kvk }),
    [onFilter],
  );
  const onKBPFilter = useCallback(
    (kbp) => onFilter({ kbp }),
    [onFilter],
  );
  return (
    <tr>
      <StringFilteredHeader
        onChange={onEdrpouFilter}
        data={data}
        value={filter.edrpou}
        label={fields.edrpou.label}
        columnName="edrpou"
      />
      <TPHeader>
        {fields.name.label}
      </TPHeader>
      <ForeignKeyHeader
        onChange={onKVKFilter}
        data={data}
        value={filter.kvk}
        label={fields.kvk.label}
        columnName="kvk"
      />
      <ForeignKeyHeader
        onChange={onKBPFilter}
        data={data}
        value={filter.kbp}
        label={fields.kbp.label}
        columnName="kbp"
      />
      <TPHeader>
        {fields.role.label}
      </TPHeader>
    </tr>
  );
};

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    edrpou: PropTypes.arrayOf(PropTypes.string),
    kvk: PropTypes.arrayOf(PropTypes.number),
    kbp: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default Header;
