import { useCallback, useMemo } from 'react';

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { boolean } isReadOnly - isReadOnly
 * @param { number } rowIndex - rowIndex
 * @param { boolean } isReadOnly - isReadOnly
 * @param { string[]} readOnlyFelds - readOnlyFelds
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  label: string,
 *  value: string,
 *  required: boolean,
 *  errors: Array}}
 */
export const useTableBaseInputProps = (
  name, data, fields, errors, onChange, rowIndex, isReadOnly = false, readOnlyFelds = [],
) => {
  const value = useMemo(() => data[name], [data, name]);
  const {
    readOnly, required, description,
  } = useMemo(
    () => {
      if (!fields) {
        return {
          readOnly: true,
          required: false,
          description: '',
        };
      }
      if (!(name in fields)) {
        return {
          readOnly: true,
          required: false,
          description: '',
        };
      }
      return {
        readOnly: fields[name].read_only || isReadOnly || readOnlyFelds.includes(name),
        required: fields[name].required,
        description: fields[name].help_text,
      };
    }, [fields, isReadOnly, name, readOnlyFelds],
  );
  const onChangeControl = useCallback(
    (e, v) => onChange(e, rowIndex, { [name]: v }),
    [name, onChange, rowIndex],
  );

  const controlErrors = useMemo(
    () => (name in errors ? errors[name] : null),
    [errors, name],
  );

  return useMemo(
    () => ({
      controlId: `${name}${rowIndex}`,
      value,
      readOnly,
      required,
      onChange: onChangeControl,
      errors: controlErrors,
      description,
    }),
    [controlErrors, description, name, onChangeControl, readOnly, required, rowIndex, value],
  );
};

export default useTableBaseInputProps;
