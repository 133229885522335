import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Cattablerow } from '../../../../newLister/tableComponent/table/row';
import { ACTIVE_FIELD } from '../../../../../constants/backend';

const ClassifierRow = ({ row, className, ...rest }) => {
  const rowClassName = useMemo(
    () => `${ACTIVE_FIELD in row && !row[ACTIVE_FIELD] ? 'text-danger' : ''} ${className}`,
    [className, row],
  );
  return (
    <Cattablerow row={row} className={rowClassName} {...rest} />
  );
};

ClassifierRow.propTypes = {
  row: PropTypes.shape({
    [ACTIVE_FIELD]: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
};

ClassifierRow.defaultProps = {
  className: '',
};

export default memo(ClassifierRow);
