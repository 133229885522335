import React, {
  useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { StyledTable, StyledTableContainer } from './styles';

const getTableComponent = ({
  RowComponent, NoDataComponent, HeaderComponent, FooterComponent,
}) => {
  const TableComponent = ({
    columns, data, isHierarhical, onHeaderClick, ordering, selectedItems,
    onRowClick, onRowDoubleClick, openedRows, onToggle, readOnly, selectMode,
    onScrollTop, onScrollBottom,
  }) => {
    const visibleColumns = useMemo(() => Object.keys(columns).filter((key) => columns[key].visible)
      .map((key) => ({ ...columns[key], name: key })), [columns]);

    const containerRef = useRef(null);

    const onScrollHanlder = (e) => {
      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      if (scrollTop <= 0) {
        // setScrolled('UP');
        onScrollTop(e);
      } else if (scrollHeight - (scrollTop + clientHeight) <= 1) {
        // setScrolled('DOWN');
        onScrollBottom(e);
      }
    };

    return (
      <StyledTableContainer ref={containerRef} onScroll={onScrollHanlder}>
        <StyledTable bordered striped hover size="sm">
          {HeaderComponent && (
          <thead>
            <HeaderComponent
              cols={visibleColumns}
              isHierarhical={isHierarhical}
              onClick={onHeaderClick}
              ordering={ordering}
            />
          </thead>
          )}
          {FooterComponent && (
          <tfoot>
            <FooterComponent
              cols={visibleColumns}
              isHierarhical={isHierarhical}
            />
          </tfoot>
          )}
          <tbody>
            {data.length ? data.map((row) => (
              <RowComponent
                cols={visibleColumns}
                row={row}
              // rowNumber={k + 2}
                level={row.level}
                isHierarhical={isHierarhical}
                key={row.id}
                active={selectedItems.includes(row.id)}
                onClick={onRowClick}
                onDoubleClick={onRowDoubleClick}
                opened={openedRows.includes(row.id)}
                canOpened={isHierarhical}
                onOpen={onToggle}
              />
            )) : NoDataComponent && (
            <NoDataComponent
              colCount={visibleColumns.length + (isHierarhical ? 1 : 0)}
              readOnly={readOnly}
              canAdd={!selectMode}
            />
            )}

          </tbody>
        </StyledTable>
      </StyledTableContainer>
    );
  };

  TableComponent.propTypes = {
    columns: PropTypes.shape().isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.number),
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    isHierarhical: PropTypes.bool,
    openedRows: PropTypes.arrayOf(PropTypes.number),
    onToggle: PropTypes.func,
    onHeaderClick: PropTypes.func,
    ordering: PropTypes.shape({
      column: PropTypes.string,
      isAscending: PropTypes.bool,
    }),
    onScrollTop: PropTypes.func,
    onScrollBottom: PropTypes.func,
    readOnly: PropTypes.bool,
    // doPrev: PropTypes.bool,
    selectMode: PropTypes.bool,
  };

  TableComponent.defaultProps = {
    selectedItems: [],
    onRowClick: () => null,
    onRowDoubleClick: () => null,
    isHierarhical: false,
    openedRows: [],
    onToggle: () => null,
    onHeaderClick: () => null,
    ordering: {
      column: '',
      isAscending: true,
    },
    onScrollTop: () => null,
    onScrollBottom: () => null,
    readOnly: false,
    // doPrev: false,
    selectMode: false,
  };

  return TableComponent;
};

export default getTableComponent;
