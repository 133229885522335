import React, {
  useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { StrNumberRibbon, StyledTableRow } from './styles';

const TableRow = ({
  active, children, rowIndex, className, activeCol,
}) => {
  const rowClassName = useMemo(
    () => `d-flex border-bottom py-2 px-4 position-relative ${className || ''} ${active ? 'active' : ''}`,
    [active, className],
  );

  const ref = useCallback(
    (node) => {
      // Эта функция вызывается один раз при монтировании
      // поэтому искать текущий активный не требуется
      if (node) {
        if (active && node) {
          const selector = activeCol ? `[data-col="${activeCol}"] input` : 'input';
          const i = node.querySelector(selector);
          if (i) {
            i.focus();
          }
        }
      }
    },
    [active, activeCol],
  );
  return (
    <StyledTableRow noGutters className={rowClassName} data-row={rowIndex} ref={ref}>
      <StrNumberRibbon>
        {rowIndex + 1}
      </StrNumberRibbon>
      {children}
    </StyledTableRow>
  );
};

TableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  activeCol: PropTypes.string,
};

TableRow.defaultProps = {
  active: false,
  className: '',
  activeCol: null,
};

export default TableRow;
