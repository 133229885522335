import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen, faMapMarkerAlt, faUser, faCalendarAlt, faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { foreignPropType } from '../../../constants/backend/propTypes';

const StyledFooter = styled.div`
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  padding: 10px;
  margin: 10px 0;
  background: var(--backgroundFooter);
  overflow: hidden;
  display: flex;
  //position: sticky;
  //bottom: -10px;
  //z-index: 2;
`;

const FooterLabel = styled.div`
  margin: 0 5px;
  color: var(--colorTextFooter);
  flex: 1 1 auto;
  font-size: small;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const Footer = ({
  created, updated, budget, authority, author,
}) => {
  const crt = new Date(created);
  const upd = new Date(updated);

  return (
    <StyledFooter>
      <FooterLabel>
        <ImgIcon
          icon={faBookOpen}
        />
        {budget && budget.repr}
      </FooterLabel>
      <FooterLabel>
        <ImgIcon
          icon={faMapMarkerAlt}
        />
        {authority && authority.repr}
      </FooterLabel>
      <FooterLabel>
        <ImgIcon
          icon={faUser}
        />
        {author && author.repr}
      </FooterLabel>
      <FooterLabel>
        <ImgIcon
          icon={faCalendarAlt}
        />
        {/* Створено: */}
        {crt.toLocaleString('uk')}
      </FooterLabel>
      {!!updated && (
        <FooterLabel>
          <ImgIcon
            icon={faEdit}
          />
          {/* Останні зміни: */}
          {upd.toLocaleString('uk')}
        </FooterLabel>
      )}
    </StyledFooter>
  );
};

Footer.propTypes = {
  created: PropTypes.string,
  updated: PropTypes.string,
  budget: foreignPropType,
  authority: foreignPropType,
  author: foreignPropType,
};

Footer.defaultProps = {
  created: null,
  updated: null,
  budget: null,
  authority: null,
  author: null,
};

// TODO: @marina Надо заменить подписи на картинки и стилизировать футер
export default Footer;
