const frontendURL = '/cat/infosource/';
const backendURL = '/api/references/refinfosource/';
const name = 'Джерела інформації';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
