const frontendURL = '/cat/contragentaccount/';
const backendURL = '/api/references/refcontragentaccount/';
const name = 'Банковські рахункі контрагентів';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
