import React, {
  useState, useRef, useEffect, useMemo, forwardRef, useCallback, memo,
} from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { FormControl, InputGroup } from 'react-bootstrap';

const StyledNumberInput = Styled(FormControl)`
  text-align: right;
`;

const DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const MINUS = ['-'];
const DECIMALS = ['.', ','];
const DELETES = ['Delete'];
const BACKSPACES = ['Backspace'];
const INSERT = ['Control', 'v', 'V'];

const ALLOWED_KEYS = [
  ...DIGITS,
  ...MINUS,
  ...DECIMALS,
  ...DELETES,
  ...BACKSPACES,
  ...INSERT,
];

function isNegative0(n) {
  return n === 0 && (1 / n) === -Infinity;
}

const NumberInput = forwardRef(({
  id,
  value,
  onChange,
  minValue,
  maxValue,
  precision,
  disabled,
  readOnly,
  onClick,
  onFocus,
  size,
  errored,
  className,
  append,
  autoSelect,
}, ref) => {
  const [cursor, setCursor] = useState({ start: 0, end: 0 });
  const [error, setError] = useState(null);
  const inputRef = ref || useRef(null);
  const [focused, setFocused] = useState(false);

  const updateValue = useCallback((e, newValue) => {
    if (Number.isNaN(newValue)) return false;
    if (newValue >= minValue && newValue <= maxValue) {
      onChange(e, newValue);
      setError(null);
    } else {
      setError(`Значання має бути між ${minValue.toFixed(precision)} та ${maxValue.toFixed(precision)}`);
    }
    return null;
  }, [maxValue, minValue, onChange, precision]);

  const keyPressHandler = useCallback(
    (e) => {
      if (readOnly || disabled) return null;
      const textValue = e.target.value;
      const currentCursor = { start: e.target.selectionStart, end: e.target.selectionEnd };
      if (ALLOWED_KEYS.includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
        if (DECIMALS.includes(e.key) && precision) {
          const str = textValue.replace(',', '.');
          const dotPos = str.indexOf('.');
          setCursor({ start: dotPos + 1, end: dotPos + 1 });
        } else if (MINUS.includes(e.key)) {
          updateValue(e, -value);
          if (value > 0 && !isNegative0(value)) { // Позитивное число не ноль
            setCursor({
              start: currentCursor.start + 1 || 1,
              end: currentCursor.start + 1 || 1,
            });
          } else if (value === 0 && !isNegative0(value)) { // Позитивный ноль
            setCursor({
              start: currentCursor.start || 2,
              end: currentCursor.start || 2,
            });
          } else if (value === 0) { // Негативный ноль
            setCursor({
              start: currentCursor.start || 1,
              end: currentCursor.start || 1,
            });
          } else {
            setCursor({
              start: currentCursor.start - 1,
              end: currentCursor.start - 1,
            });
          }
        } else if (DIGITS.includes(e.key)) {
          const dotPos = textValue.indexOf('.');
          const override = (textValue.substr(0, dotPos) === '0') && currentCursor.start <= dotPos;
          const newValueText = `${textValue.substr(override ? currentCursor.start : 0, currentCursor.start)}${e.key}${textValue.substr(override ? dotPos : currentCursor.end)}`;
          // Ввод целочисленной части
          if (isNegative0(value) && value === 0) {
            setCursor({
              start: currentCursor.start,
              end: currentCursor.start,
            });
          } else if (currentCursor.start <= dotPos) { // Ввод целочисленной части
            setCursor({
              start: currentCursor.start + 1,
              end: currentCursor.start + 1,
            });
          } else {
            setCursor({
              start: currentCursor.start + 1,
              end: currentCursor.start + 1,
            });
          }
          updateValue(e, Number(newValueText));
        } else if (DELETES.includes(e.key)) {
          const override = currentCursor.start !== currentCursor.end;
          const newValueText = `${textValue.substr(0, currentCursor.start)}${textValue.substr(override ? currentCursor.end : currentCursor.end + 1)}`;
          updateValue(e, Number(newValueText));
          setCursor({
            start: currentCursor.start,
            end: currentCursor.start,
          });
        } else if (BACKSPACES.includes(e.key)) {
          const override = currentCursor.start !== currentCursor.end;
          const newValueText = `${textValue.substr(0, override ? currentCursor.start : currentCursor.start - 1)}${textValue.substr(currentCursor.end)}`;
          updateValue(e, Number(newValueText));
          setCursor({
            start: override ? currentCursor.start : currentCursor.start - 1,
            end: override ? currentCursor.start : currentCursor.start - 1,
          });
        } else if (INSERT.includes(e.key)) {
          if (e.ctrlKey && e.keyCode === 86) {
            navigator.clipboard.readText()
              .then((text) => {
                if (parseInt(text, 10)) {
                  const newValueText = `${textValue.substr(0, currentCursor.start)}${parseInt(text, 10)}${textValue.substr(currentCursor.end)}`;
                  updateValue(e, Number(newValueText));
                  setCursor({
                    start: currentCursor.start,
                    end: currentCursor.start,
                  });
                }
                return false;
                // `text` содержит текст, прочитанный из буфера обмена
              })
              .catch((err) => {
                // возможно, пользователь не дал разрешение на чтение данных из буфера обмена
                console.log('Something went wrong', err);
              });
          }
        }
      }
      return null;
    },
    [disabled, precision, readOnly, updateValue, value],
  );

  const displayValue = useMemo(() => {
    const stringValue = (value || 0).toFixed(precision);
    return focused ? stringValue : stringValue.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  }, [value, precision, focused]);

  const onFocusHandler = useCallback(
    (e) => {
      setFocused(true);
      if (autoSelect) setCursor({ start: 0, end: displayValue.length });
      if (onFocus) onFocus(e);
    },
    [autoSelect, displayValue.length, onFocus],
  );

  const onBlurHandler = useCallback(
    () => {
      setFocused(false);
    }, [],
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = displayValue;
      inputRef.current.setSelectionRange(cursor.start, cursor.end);
      // if (focused) console.log(cursor, inputRef.current);
    }
  }, [cursor, displayValue, inputRef]);

  return (
    <InputGroup className={className}>
      <StyledNumberInput
        id={id}
        size={size}
        ref={inputRef}
        onKeyDown={keyPressHandler}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClick}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onChange={() => false}
        isInvalid={errored || !!error}
      />
      {append}
      {error && (
        <FormControl.Feedback type="invalid" tooltip>{error}</FormControl.Feedback>
      )}
    </InputGroup>
  );
});

NumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'lg']),
  errored: PropTypes.bool,
  className: PropTypes.string,
  append: PropTypes.element,
  autoSelect: PropTypes.bool,
};

NumberInput.defaultProps = {
  value: 0,
  onChange: () => null,
  minValue: Number.MIN_SAFE_INTEGER,
  maxValue: Number.MAX_SAFE_INTEGER,
  disabled: false,
  readOnly: false,
  onClick: null,
  onFocus: null,
  precision: 0,
  size: null,
  errored: false,
  className: null,
  append: null,
  autoSelect: true,
};

export default memo(NumberInput);
