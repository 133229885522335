import React, { Suspense } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { EditorTextInput, EditorControls } from '../../../basicEditor/editorControls';

const SButton = styled(Button)`
    width: 200px;
    margin-bottom: 10px;`;

const TableProps = ({
  onChange, entries, showModal, setShowModal, keyValue, setKeyValue,
}) => (
  <>
    {entries.length > 0 && entries.map((el) => (
      <EditorTextInput
        key={el[0]}
        controlId={el[0]}
        value={el[1]}
        label={el[0]}
        required
        description="Застосування параметрів - :, наприклад :bdate або :year"
        onChange={(e, v) => onChange(v, el[0])}
      />
    ))}
    <Button type="button" onClick={() => setShowModal(true)}>Додати</Button>
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Suspense fallback={<div>Загрузка...</div>}>
          <EditorControls.StringInput controlId="table_addKey_input" label="Введіть ім'я ключа" value={keyValue} onChange={(e, v) => setKeyValue(v)} />
          <SButton onClick={() => { entries.push([keyValue, '']); setShowModal(false); setKeyValue(null); }}>Додати</SButton>
        </Suspense>
      </Modal.Body>
    </Modal>
  </>
);

TableProps.propTypes = {
  onChange: PropTypes.func.isRequired,
  entries: PropTypes.node,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  keyValue: PropTypes.string,
  setKeyValue: PropTypes.func.isRequired,
};

TableProps.defaultProps = {
  entries: [],
  keyValue: null,
};

export default TableProps;
