import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature, faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from './styles';

export const UnExecuteButton = ({ content, onClick, disabled }) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    variant="info"
    size="sm"
  >
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon icon={faSignature} />
      <FontAwesomeIcon
        icon={faTimes}
        size="xs"
        color="red"
        pull="right"
        transform="shrink-2 right-10 down-7"
      />
    </span>
    <span style={{ paddingLeft: '5px' }}>{content}</span>
  </StyledButton>
);

UnExecuteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

UnExecuteButton.defaultProps = {
  onClick: () => null,
  content: 'Відмінити проведення',
  disabled: false,
};

export default UnExecuteButton;
