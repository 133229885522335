import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const kekvProps = tableHooks.useItemInputProps(
    'kekv', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountApprovedplan2Props = tableHooks.useNumberInputProps(
    'amount_approvedplan_2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountApprovedplan1Props = tableHooks.useNumberInputProps(
    'amount_approvedplan_1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountKassaExpenses2Props = tableHooks.useNumberInputProps(
    'amount_kassa_expenses_2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountTotal2Props = tableHooks.useNumberInputProps(
    'amount_total_2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountTotal1Props = tableHooks.useNumberInputProps(
    'amount_total_1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountLimitProps = tableHooks.useNumberInputProps(
    'amount_limit', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountZfRepayment2Props = tableHooks.useNumberInputProps(
    'amount_zf_repayment_2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountZfRepayment1Props = tableHooks.useNumberInputProps(
    'amount_zf_repayment_1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountZfRepaymentProps = tableHooks.useNumberInputProps(
    'amount_zf_repayment0', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSfRepayment2Props = tableHooks.useNumberInputProps(
    'amount_sf_repayment_2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSfRepayment1Props = tableHooks.useNumberInputProps(
    'amount_sf_repayment_1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSfRepaymentProps = tableHooks.useNumberInputProps(
    'amount_sf_repayment0', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountDebt2Props = tableHooks.useNumberInputProps(
    'amount_debt_2', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountDebt1Props = tableHooks.useNumberInputProps(
    'amount_debt_1', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountDebtProps = tableHooks.useNumberInputProps(
    'amount_debt0', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const reasonProps = tableHooks.useStringInputProps(
    'reason', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const takenMeasuresProps = tableHooks.useStringInputProps(
    'taken_measures', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  return (
    <Col>
      <Row noGutters>
        <Col>
          <TabelRowCell column="kekv" highlighted={highlights.includes('kekv')}>
            <TableControls.ItemPicker {...kekvProps} />
          </TabelRowCell>
        </Col>
      </Row>

      <Row noGutters>
        <Col>
          <TabelRowCell column="amount_approvedplan_2" highlighted={highlights.includes('amount_approvedplan_2')}>
            <TableControls.NumberInput {...amountApprovedplan2Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_kassa_expenses_2" highlighted={highlights.includes('amount_kassa_expenses_2')}>
            <TableControls.NumberInput {...amountKassaExpenses2Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_approvedplan_1" highlighted={highlights.includes('amount_approvedplan_1')}>
            <TableControls.NumberInput {...amountApprovedplan1Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_total_2" highlighted={highlights.includes('amount_total_2')}>
            <TableControls.NumberInput {...amountTotal2Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_total_1" highlighted={highlights.includes('amount_total_1')}>
            <TableControls.NumberInput {...amountTotal1Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_limit" highlighted={highlights.includes('amount_limit')}>
            <TableControls.NumberInput {...amountLimitProps} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_zf_repayment_2" highlighted={highlights.includes('amount_zf_repayment_2')}>
            <TableControls.NumberInput {...amountZfRepayment2Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_zf_repayment_1" highlighted={highlights.includes('amount_zf_repayment_1')}>
            <TableControls.NumberInput {...amountZfRepayment1Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_zf_repayment0" highlighted={highlights.includes('amount_zf_repayment0')}>
            <TableControls.NumberInput {...amountZfRepaymentProps} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_sf_repayment_2" highlighted={highlights.includes('amount_sf_repayment_2')}>
            <TableControls.NumberInput {...amountSfRepayment2Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_sf_repayment_1" highlighted={highlights.includes('amount_sf_repayment_1')}>
            <TableControls.NumberInput {...amountSfRepayment1Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_sf_repayment0" highlighted={highlights.includes('amount_sf_repayment0')}>
            <TableControls.NumberInput {...amountSfRepaymentProps} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_debt_2" highlighted={highlights.includes('amount_debt_2')}>
            <TableControls.NumberInput {...amountDebt2Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_debt_1" highlighted={highlights.includes('amount_debt_1')}>
            <TableControls.NumberInput {...amountDebt1Props} />
          </TabelRowCell>
          <TabelRowCell column="amount_debt0" highlighted={highlights.includes('amount_debt0')}>
            <TableControls.NumberInput {...amountDebtProps} />
          </TabelRowCell>
        </Col>
      </Row>

      <Row noGutters>
        <Col>
          <TabelRowCell column="reason" highlighted={highlights.includes('reason')}>
            <TableControls.TextInput {...reasonProps} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="taken_measures" highlighted={highlights.includes('taken_measures')}>
            <TableControls.TextInput {...takenMeasuresProps} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
