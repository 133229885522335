import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const Input = styled.input`
  background: white;
  border: solid 1px #cdd3d9;
  border-radius: 0.25rem;
  height: 38px;
`;


const ColorInput = forwardRef(({
  value, disabled, readOnly,
  onClick, onFocus, maxLength, onChange, id,
  errored, label,
}, ref) => {
  const changeHandler = (e) => {
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      onChange(e, newValue);
    }
  };
  return (
    <p>
      <Form.Label>
        {label}
      </Form.Label>
      <Input
        type="color"
        ref={ref}
        id={id}
        value={value || ''}
        onChange={changeHandler}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClick}
        onFocus={onFocus}
        isInvalid={errored}
      />
    </p>
  );
});

ColorInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  errored: PropTypes.bool,
};

ColorInput.defaultProps = {
  value: '',
  label: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  errored: false,
};


export default ColorInput;
