import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { withAuthConsumer } from '../../providers/authProvider';


const StyledHeader = styled.div`
  grid-area: header;  
  border: none;
  padding: 0 10px;
  color: var(--textForNavBar);
  background: var(--navBarBackground);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  @media (max-width: 600px) {
  flex-direction: column;
  }
`;

const UserInfo = styled.div`
  font-size: 12px;
  font-style: italic;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const ExitIcon = styled(FontAwesomeIcon)`
  margin-left: 20px;
  transition: 0.5s linear;
  :hover {
    color: var(--hoverNavBar);
    cursor: pointer;
  }
`;


const Header = ({ logoutHandler, currentUser }) => (
  <StyledHeader>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <FontAwesomeIcon
        icon={faUser}
        style={{ height: '12px' }}
      />
      <UserInfo>
        {`Доброго дня ${currentUser.first_name || currentUser.username} ${currentUser.last_name}. `}
      </UserInfo>
      <ExitIcon
        icon={faSignOutAlt}
        alt="ExitIcon"
        title="Вийти"
        onClick={logoutHandler}
      />
    </div>
  </StyledHeader>
);

Header.propTypes = {
  logoutHandler: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    is_superuser: PropTypes.bool,
    is_staff: PropTypes.bool,
  }).isRequired,
};

const mapState = (state) => ({
  logoutHandler: state.logoutHandler,
  currentUser: state.currentUser,
});

const enhance = compose(
  withAuthConsumer(mapState),
  memo,
);

export default enhance(Header);
