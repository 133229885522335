import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import {
  FieldErrorPropType,
} from '../../../basicEditor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';

const User = ({
  data, fields, onChange, errors,
}) => {
  const usernameProps = editorHooks.useStringInputProps('username', data, fields, errors, onChange);
  const lastNameProps = editorHooks.useStringInputProps('last_name', data, fields, errors, onChange);
  const firstNameProps = editorHooks.useStringInputProps('first_name', data, fields, errors, onChange);
  const emailProps = editorHooks.useStringInputProps('email', data, fields, errors, onChange);
  const isSuperuserProps = editorHooks.useCheckboxInputProps('is_superuser', data, fields, errors, onChange);
  const isStaffProps = editorHooks.useCheckboxInputProps('is_staff', data, fields, errors, onChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', data, fields, errors, onChange);

  return (
    <Container fluid style={{ padding: '0' }}>
      <Row>
        <Col>
          <EditorControls.StringInput {...usernameProps} />
        </Col>
        <Col>
          <EditorControls.StringInput {...emailProps} />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput {...firstNameProps} />
        </Col>
        <Col>
          <EditorControls.StringInput {...lastNameProps} />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput {...isSuperuserProps} />
        </Col>
        <Col>
          <EditorControls.CheckboxInput {...isStaffProps} />
        </Col>
        <Col>
          <EditorControls.CheckboxInput {...isActiveProps} />
        </Col>
      </Row>
    </Container>
  );
};

const dataPropTypes = {
  username: PropTypes.string,
  last_name: PropTypes.string,
  first_name: PropTypes.string,
  email: PropTypes.string,
  is_superuser: PropTypes.bool,
  is_staff: PropTypes.bool,
  is_active: PropTypes.bool,
};

const fieldsPropTypes = {
  username: PropTypes.object,
  last_name: PropTypes.object,
  first_name: PropTypes.object,
  email: PropTypes.object,
  is_superuser: PropTypes.object,
  is_staff: PropTypes.object,
  is_active: PropTypes.object,
};

const errorsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldErrorPropType }), {});

User.propTypes = {
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  fields: PropTypes.shape(
    fieldsPropTypes,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape(
    errorsPropTypes,
  ),
};

User.defaultProps = {
  errors: {},
};

export default User;
