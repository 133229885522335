import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import meta from '../../../../meta';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer } from '../../../../components/Containers/docs';
import { AppContext } from '../../../../providers/authProvider';

const Editor = (props) => {
  const { id, onClose, onSave } = props;
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors,
    fieldErrors,

  } = useEditor({
    backendURL: meta.cat.responsiblePerson.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  const { currentUser } = useContext(AppContext);
  const isSuperuser = currentUser.is_superuser;

  const lastName = editorHooks.useStringInputProps('last_name', data, fields, fieldErrors, actions.onChange);
  const firsName = editorHooks.useStringInputProps('first_name', data, fields, fieldErrors, actions.onChange);
  const middleName = editorHooks.useStringInputProps('middle_name', data, fields, fieldErrors, actions.onChange);

  const roleKind = editorHooks.useSelectorInputProps('role_kind', data, fields, fieldErrors, actions.onChange);

  const phoneNumber = editorHooks.useStringInputProps('phone_number', data, fields, fieldErrors, actions.onChange);
  const addedPhoneNumber = editorHooks.useStringInputProps('added_phone_number', data, fields, fieldErrors, actions.onChange);
  const email = editorHooks.useStringInputProps('email', data, fields, fieldErrors, actions.onChange);

  const basis = editorHooks.useStringInputProps('basis', data, fields, fieldErrors, actions.onChange);

  const disposer = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <CatCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
      />
      <Container fluid style={{ padding: '0' }}>
        { isSuperuser && (
        <Row>
          <Col>
            <EditorControls.ItemPicker {...disposer} modelType="cat" modelName="disposers" />
          </Col>
        </Row>
        )}
        <Row>
          <Col>
            <EditorControls.StringInput {...lastName} />
          </Col>
          <Col>
            <EditorControls.StringInput {...firsName} />
          </Col>
          <Col>
            <EditorControls.StringInput {...middleName} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.SelectorInput {...roleKind} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput {...phoneNumber} />
          </Col>
          <Col>
            <EditorControls.StringInput {...addedPhoneNumber} />
          </Col>

          <Col>
            <EditorControls.StringInput {...email} />
          </Col>

        </Row>
        <Row>
          <Col>
            <EditorControls.TextInput {...basis} />
          </Col>
        </Row>
      </Container>

      <Footer
        budget={data.budget}
      />

    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
