import { useMemo } from 'react';
import { useBaseInputProps } from './baseInputProps';

/**
 *
 * @param { string } name - имя поля
 * @param { Object } data - Данные
 * @param { Object } fields - Поля
 * @param { Object } errors - Ошибки
 * @param { function } onChange - handler
 * @param { boolean } isReadOnly - isReadOnly
 * @param {string[]} readOnlyFelds - readOnlyFelds
 * @returns {{
 *  onChange: (function(*, *): *),
 *  description: string,
 *  readOnly: boolean,
 *  controlId: string,
 *  label: string,
 *  value: Object,
 *  required: boolean,
 *  errors: Array}}
 */

export const useItemInputProps = (
  name, data, fields, errors, onChange, isReadOnly = false, readOnlyFelds = [],
) => {
  const baseProps = useBaseInputProps(name, data, fields, errors, onChange, isReadOnly, readOnlyFelds);
  const backend = useMemo(
    () => (fields && fields[name] ? fields[name].resource : null),
    [fields, name],
  );
  return useMemo(
    () => ({
      ...baseProps,
      backend,
      canErase: !baseProps.required,
    }),
    [backend, baseProps],
  );
};

export default useItemInputProps;
