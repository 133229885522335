const frontendURL = '/cat/kekv/';
const backendURL = '/api/references/refkekv/';
const name = 'Класифікація економічна (КЕКВ)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
