// Для денег в грн
export const moneysStingOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

// Для денег в тыс. грн
export const moneysThousandStingOptions = {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
};
