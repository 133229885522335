import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';


export const StyledButton = styled(Button)`
  background-color: var(--backgroundWarningButton)!important;
  color: var(--colorTextWarningButton)!important;
  font-weight: 700!important;
    &:hover {
      background-color: var(--hoverWarningButton)!important;
    };
`;

export const TryButton = ({
  content, title, onClick, disabled,
}) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    variant="warning"
    size="sm"
    title={title}
  >
    {content
      && <span style={{ marginLeft: '5px' }}>{content}</span>}
  </StyledButton>
);

TryButton.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

TryButton.defaultProps = {
  onClick: () => null,
  title: '',
  content: 'Повторити спробу',
  disabled: false,
};

export default TryButton;
