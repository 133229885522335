import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import api from '../../api/req';
import { padl } from '../../api/utils';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';
import { ErrorMessage } from '../../components/bootStrap';

export const historicalItemPropType = PropTypes.shape({
  datefrom: PropTypes.string,
});

const withHistory = (WrappedComponent) => {
  class HistoryShower extends PureComponent {
    static getDerivedStateFromProps(props, state) {
      if (state.history_datefrom !== props.initialData) {
        return {
          history_datefrom: props.initialData,
          data: props.initialData || [],
          history_values: null,
          histories: [],
        };
      }
      return null;
    }

    constructor(props) {
      super(props);
      this.state = {
        history_datefrom: null, // не трогать.
        data: [],
        history_values: null,
        isLoading: false,
        histories: [],
        errors: {},
        isModified: false,
        isErrored: false,
        errText: null,
      };
    }

    componentDidMount() {
      const { registerSaveListener } = this.props;
      registerSaveListener(this.save);
    }

    loadHistory = async () => {
      const { authF, url } = this.props;
      this.setState({
        isLoading: true,
      });
      const r = await api.get(`${url}/history/`, authF);
      if (r.ok) {
        const histories = await r.json();
        this.setState({
          isLoading: false,
          history_values: histories.map((h) => ({ datefrom: h.datefrom, dateto: h.dateto })),
          histories,
        });
        return histories;
      }
      this.setState({
        isLoading: false,
        history_values: null,
      });
      return null;
    };

    historyDropDownHandler = () => {
      // eslint-disable-next-line camelcase
      const { history_values } = this.state;
      // eslint-disable-next-line camelcase
      if (!history_values) this.loadHistory();
    };

    historyVariantChangeHandler = (e, historyDatefrom) => {
      const { histories } = this.state;
      const data = histories.filter((h) => h.datefrom === historyDatefrom).reduce((R, r) => r, {});
      this.setState({
        data,
      });
    };

    dataSet = (partialContent) => {
      const { data, errors } = this.state;
      const { onChange, tryBlock } = this.props;
      const newErrors = Object.keys(errors).reduce((R, key) => ({
        ...R,
        ...(key in partialContent) ? {} : { [key]: errors[key] },
      }), {});
      tryBlock().then((blocked) => {
        if (blocked) {
          const newData = {
            ...data,
            ...partialContent,
          };
          this.setState({
            data: newData,
            errors: newErrors,
            isModified: true,
          });
          onChange(newData);
        }
      });
    };

    getStartDate = (datefrom) => {
      const sDate = new Date(datefrom);
      return `${sDate.getFullYear()}${padl(String(sDate.getMonth() + 1), '0', 2)}${padl(String(sDate.getDate()), '0', 2)}`;
    };

    save = async (newData = null) => {
      const { data } = this.state;
      const savableData = newData || data;
      this.setState({ isLoading: true });
      const { authF, url } = this.props;
      const r = await api.put(`${url}/${this.getStartDate(savableData.datefrom)}/history/ondate/`, authF, savableData);
      if (r.ok) {
        const d = await r.json();
        this.setState({
          data: d,
          isLoading: false,
          isErrored: false,
          errText: null,
          isModified: false,
        });
        return d.datefrom;
      } if (r.status === 400) {
        const errs = await r.json();
        this.setState({
          errors: errs,
          isLoading: false,
          isErrored: true,
          errText: null,
        });
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errText: `${r.status} ${r.statusText}`,
        });
      }
      return null;
    };

    create = async (datefrom, newData = null) => {
      const { data } = this.state;
      const savableData = { ...(newData || data), datefrom };
      await this.save(savableData);
      await this.loadHistory();
    };

    delete = async () => {
      const { data } = this.state;
      const { authF, url } = this.props;

      this.setState({ isLoading: true });
      const r = await api.delete(`${url}/${this.getStartDate(data.datefrom)}/history/ondate/`, authF);
      if (r.ok) {
        const newHistory = await this.loadHistory();
        const d = newHistory.reduce((R, h) => h, {});
        this.setState({
          isLoading: false,
          data: d,
        });
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errText: `${r.status} ${r.statusText}`,
        });
      }
    };


    render() {
      const {
        // eslint-disable-next-line camelcase
        data, history_values, isLoading, errors, isModified, isErrored, errText,
      } = this.state;
      const {
        initialData, onChange, url, authF, tryBlock, ...rest
      } = this.props;
      const initialHistory = initialData
        ? { datefrom: initialData.datefrom, dateto: initialData.dateto }
        : {};
      return (
        <>
          {isErrored && (
            <ErrorMessage text={errText} />
          )}
          <WrappedComponent
            data={data}
            /* eslint-disable-next-line camelcase */
            history_values={history_values || [initialHistory]}
            history_loading={isLoading}
            onHistoryDropDown={this.historyDropDownHandler}
            onChangeHistoryVariant={this.historyVariantChangeHandler}
            errors={errors}
            isModified={isModified}
            onChange={this.dataSet}
            onSave={this.save}
            onNew={this.create}
            onDelete={this.delete}
            {...rest}
          />
        </>
      );
    }
  }

  HistoryShower.propTypes = {
    url: PropTypes.string.isRequired,
    authF: PropTypes.func.isRequired,
    initialData: historicalItemPropType.isRequired,
    onChange: PropTypes.func.isRequired,
    tryBlock: PropTypes.func.isRequired,
    registerSaveListener: PropTypes.func.isRequired,
  };

  return withAuthConsumer(mapStateAuth)(HistoryShower);
};

export default withHistory;
