import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolderOpen, faFolder, faFileAlt, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { StyledDiv } from './styles';


const getTogglerImg = (opened, deleted, canOpened) => {
  if (canOpened && opened) return faFolderOpen;
  if (canOpened && !opened) return faFolder;
  if (!canOpened) return faFileAlt;
  return null;
};

const Toggler = ({
  level, opened, deleted, canOpened, onOpen,
}) => (
  <StyledDiv
    level={level}
    opened={opened}
    disabled={!canOpened}
    onClick={onOpen}
  >
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon
        icon={getTogglerImg(opened, deleted, canOpened)}
        size="lg"
        color="var(--colorListerIcon)"
      />
      {deleted && (
      <FontAwesomeIcon
        icon={faTimes}
        size="xs"
        color="red"
        pull="right"
        transform="shrink-2 right-7 down-8"
      />
      )}
    </span>
  </StyledDiv>
);

Toggler.propTypes = {
  onOpen: PropTypes.func.isRequired,
  level: PropTypes.number,
  opened: PropTypes.bool,
  deleted: PropTypes.bool,
  // is_group: PropTypes.bool,
  canOpened: PropTypes.bool,
};

Toggler.defaultProps = {
  level: 0,
  opened: false,
  deleted: false,
  canOpened: false,
};

export default Toggler;
