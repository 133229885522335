import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
} from 'react-bootstrap';
import { TableStingInput } from '../../../basicEditor/tableControls';
import { tablePropType } from '../hooks';

const TableItem = ({
  table, active, onRenameTable, onClick,
}) => (

  <ListGroupItem
    key={table.key}
    active={active}
    onClick={onClick}
  >
    <TableStingInput
      controlId={`input-table-${table.key}`}
      value={table.name}
      readOnly={table.name === 'main'}
      onChange={(e, v) => onRenameTable(table.name, v)}
    />
  </ListGroupItem>
);

TableItem.propTypes = {
  table: tablePropType.isRequired,
  active: PropTypes.bool,
  onRenameTable: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

TableItem.defaultProps = {
  active: false,
};

export default TableItem;
