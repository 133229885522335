import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const StyleTR = styled.tr.attrs(({ visible }) => (
  visible ? {} : { style: { display: 'none' } }
))`
`;

const Row = ({
  cells, expanded, isGroup, onExpandedToggle, visible, onContextMenu,
}) => (
  <StyleTR visible={visible}>
    <td className="d-print-none" style={{ paddingLeft: `calc(${cells[0].level - 1}*10px)` }}>
      {isGroup ? (
        <Button
          variant="outline-secondary"
          className="px-1 py-0 border-0"
          onClick={(e) => onExpandedToggle(e, cells[0].row_num)}
        >
          <FontAwesomeIcon
            icon={expanded ? faMinus : faPlus}
            size="xs"
            transform="shrink-6"
            // fixedWidth
            // listItem
          />
        </Button>
      ) : (
        <span />
      )}
    </td>
    {cells.map((cell, i) => (
      <td
        style={i === 0 ? { paddingLeft: `calc(${cell.level}*10px)` } : {}}
        key={cell.col_num}
        colSpan={cell.colspan}
        rowSpan={cell.rowspan}
        className={`${cell.className || ''} level${cell.level} ${cell.key} `}
        onContextMenu={(e) => onContextMenu(e, cell.groups, cell.data)}
      >
        {cell.repr}
      </td>
    ))}

  </StyleTR>
);

Row.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({}),
    groups: PropTypes.shape({
      row: PropTypes.shape({}),
      column: PropTypes.shape({}),
    }),
    key: PropTypes.string,
    level: PropTypes.number,
    repr: PropTypes.string,
    styles: PropTypes.arrayOf(PropTypes.string),
    row_num: PropTypes.number,
  })).isRequired,
  expanded: PropTypes.bool,
  isGroup: PropTypes.bool,
  onExpandedToggle: PropTypes.func,
  visible: PropTypes.bool,
  onContextMenu: PropTypes.func.isRequired,
};

Row.defaultProps = {
  expanded: true,
  isGroup: false,
  onExpandedToggle: () => null,
  visible: true,
};

export default memo(Row);
