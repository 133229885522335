import { useCallback, useState } from 'react';

/**
 *
 * @param columns { Array }
 */
export const useTPFilter = (columns) => {
  const [filter, setFilter] = useState(columns.reduce((R, c) => ({ ...R, [c]: [] }), {}));
  const onFilterToggle = useCallback(
    (partFilter) => setFilter((oldFilter) => Object.keys(oldFilter).reduce((R, k) => {
      let newF = oldFilter[k];
      if (k in partFilter) {
        if (newF.includes(partFilter[k])) {
          newF = newF.filter((ov) => ov !== partFilter[k]);
        } else {
          newF = [...newF, partFilter[k]];
        }
      }
      return { ...R, [k]: newF };
    }, {})),
    [],
  );
  return [filter, onFilterToggle];
};
