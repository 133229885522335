import React, {
  useMemo, useState, useRef, forwardRef, useContext, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup,
  InputGroup, /* Overlay, */ Modal, Nav, Button,
} from 'react-bootstrap';
import { faEllipsisH, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from '../../../providers/authProvider';
import { ItemField, valuePropType } from './itemField';
import { DataList } from './datalist';
import api from '../../../api/req';
import { debounce } from '../../../api/utils';
import meta, { modelNames, modelTypes, metaBackends } from '../../../meta';
import Selectors from '../../../containers/instances/selector';
import { CPButton } from '../../bootStrap/buttons/styles';

export const ItemPicker = forwardRef(({
  id, value, canErase, onChange, filter, disabled, noHierarchy,
  onClick, onFocus, onDropDown,
  readOnly, modelType, modelName, errored, backend,
  className,
}, ref) => {
  const MetaInfo = useMemo(() => {
    if (modelType && modelName) {
      if (!(modelType in meta) || !(modelName in meta[modelType])) {
        throw new Error(`${modelType} / ${modelName} невідомі. Перевірте Ваш код!`);
      }
      return {
        backend: meta[modelType][modelName].backendURL,
        Selector: Selectors[modelType][modelName],
      };
    }
    if (backend) {
      if (!(backend in metaBackends)) return null;
      const m = metaBackends[backend];
      return {
        backend,
        Selector: Selectors[m.modelType][m.modelName],
      };
    }

    return {
      backend,
      Selector: () => <span role="img" aria-label="Coffee">🍵</span>,
    };

    // throw new Error('no modelType, modelName or backend props in ItemPicker');
  }, [modelName, modelType, backend]);

  const inputRef = useRef(ref);// || ;
  const [editText, setEditText] = useState(null);
  const [loadingState, setLoadingState] = useState(
    { isLoading: false, isErrored: false, errText: false },
  );
  const [activeListItem, setActiveListItem] = useState(null);
  const [items, setItems] = useState([]);
  const [listOpened, setListopened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [curValue, setCurValue] = useState(value);
  const { auth } = useContext(AppContext);
  const makeRequest = useMemo(() => debounce(async (search) => {
    setLoadingState({ isLoading: true, isErrored: false, errText: null });
    const r = await api.get(MetaInfo.backend, auth, { ...filter, search });
    if (r.ok) {
      const data = await r.json();
      const itms = 'results' in data ? data.results : data;
      setLoadingState({ isLoading: false, isErrored: false, errText: null });
      setItems(itms);
      setActiveListItem(itms.length ? 0 : null);
      setListopened(!!itms.length);
      if (onDropDown) onDropDown();
    } else {
      setLoadingState({ isLoading: false, isErrored: true, errText: `${r.status} ${r.statusText}` });
    }
  }), [MetaInfo.backend, auth, filter, onDropDown]);

  const onDataListSelect = useCallback(
    (e, v) => {
      setListopened(false);
      onChange(e, v);
      setEditText(null);
    },
    [onChange],
  );

  const handleUserKeyPress = useCallback(
    (e) => {
      const { key } = e;
      if (inputRef.current.parentElement.contains(e.target)) {
        if (key === 'F4') {
          setModalOpened(true);
        } else if (listOpened) {
          if (key === 'ArrowUp') {
            e.preventDefault();
            e.stopPropagation();
            setActiveListItem((i) => (i ? i - 1 : items.length - 1));
          } else if (key === 'ArrowDown') {
            e.preventDefault();
            e.stopPropagation();
            setActiveListItem((i) => (i < items.length - 1 ? i + 1 : 0));
          } else if (key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onDataListSelect(e, items[activeListItem]);
          } else if (key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            setListopened(false);
            setEditText(null);
          }
        }
      }
    }, [activeListItem, inputRef, items, listOpened, onDataListSelect],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const okEnabled = true;

  const onOk = useCallback(
    (e) => {
      if (okEnabled) {
        setModalOpened(false);
        onChange(e, curValue);
      }
    },
    [curValue, okEnabled, onChange],
  );

  return (
    <>
      <ItemField
        id={id}
        value={value}
        isLoading={loadingState.isLoading}
        editText={editText}
        onChange={(e, v) => {
          setEditText(v);
          makeRequest(v);
        }}
        readOnly={disabled || readOnly}
        onClick={onClick}
        onFocus={onFocus}
        ref={inputRef}
        errored={errored}
        className={className}
      >
        <InputGroup.Append>
          <CPButton
            onClick={() => setModalOpened(true)}
            icon={faEllipsisH}
            variant="outline-secondary"
            disabled={readOnly || disabled}
            tabIndex={-1}
          />
          {canErase
          && (
          <CPButton
            onClick={(e) => {
              onChange(e, null);
              setEditText(null);
            }}
            icon={faTimes}
            disabled={readOnly || disabled}
            tabIndex={-1}
          />
          )}
        </InputGroup.Append>
      </ItemField>
      {/* <Overlay target={inputRef.current} show={listOpened} placement="bottom-start"> */}
      {listOpened
      && (
      <DataList
        data={items}
        activeItem={activeListItem}
        onSelect={onDataListSelect}
      />
      )}
      {/* </Overlay> */}
      <Modal
        show={modalOpened}
        size="xl"
        scrollable
        centered
        onHide={() => setModalOpened(false)}
        container={inputRef.current && inputRef.current.parentElement}
      >
        <Modal.Header>
          <Modal.Title>
            {MetaInfo.label}
          </Modal.Title>
          <Nav className="flex-fill">
            <ButtonGroup className="ml-auto">
              <Button variant="success" disabled={!okEnabled} onClick={onOk}>
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                Обрати
              </Button>
              <Button variant="danger" onClick={() => setModalOpened(false)}>
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                Скасувати
              </Button>
            </ButtonGroup>
          </Nav>
        </Modal.Header>
        <Modal.Body>
          <MetaInfo.Selector
            selectMode
            filter={filter}
            onChoice={(e, v) => {
              onChange(e, v);
              setModalOpened(false);
            }}
            onSelect={(e, v) => {
              setCurValue(v);
            }}
            currentId={value && value.id}
            noHierarchy={noHierarchy}
          />
        </Modal.Body>
      </Modal>
    </>
  );
});

ItemPicker.propTypes = {
  id: PropTypes.string.isRequired,
  value: valuePropType,
  canErase: PropTypes.bool,
  onChange: PropTypes.func,
  filter: PropTypes.shape({}), // key equals value
  disabled: PropTypes.bool,
  noHierarchy: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onDropDown: PropTypes.func,
  readOnly: PropTypes.bool,
  modelType: PropTypes.oneOf(modelTypes),
  modelName: PropTypes.oneOf(modelNames),
  errored: PropTypes.bool,
  className: PropTypes.string,
  backend: PropTypes.oneOf(Object.keys(metaBackends)),
};

ItemPicker.defaultProps = {
  value: { id: null, repr: '' },
  canErase: false,
  onChange: () => null,
  filter: null,
  disabled: false,
  noHierarchy: false,
  onClick: null,
  onFocus: null,
  onDropDown: null,
  readOnly: false,
  errored: false,
  className: null,
  backend: null,
  modelType: null,
  modelName: null,
};

export default ItemPicker;
