import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Button, Form, Alert, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { DimableLoader } from '../../../components/bootStrap';

const H2 = styled.h2`
  ${({ color }) => `color: ${color}`}`;

const StyledDiv = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;`;

const CreateRole = ({ onClose, setUpdateList }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [role, setRole] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/auth/permissions/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const newData = rData.map((el) => (
          {
            ...el,
            check: false,
            input: false,
          }
        ));
        setRole((prev) => ({ ...prev, permissions: [...newData] }));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const updateRoleParams = (e) => {
    const { name, value } = e.target;
    setRole({
      ...role,
      [name]: value,
    });
  };

  const createRole = () => {
    const loader = async () => {
      const filteredPermissions = role.permissions.filter((perm) => perm.input)
        .reduce((prev, cur) => ({
          ...prev,
          [cur.key]: cur.check,
        }), {});
      const params = { ...role, permissions: filteredPermissions };
      const r = await api.post('/api/auth/role/', auth, params);
      if (r.status === 400) {
        const error = await r.json();

        Object.entries(error).map((e) => {
          if (e) {
            throw new Error(`${e[0]}: ${e[1]}`);
          }
          return error;
        });

        return error;
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onClose();
        setUpdateList((prev) => !prev);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  };

  const handleCheckbox = useCallback((type, key, checked) => {
    if (type === 'input') {
      if (key === 'all') {
        setRole({
          ...role,
          permissions: [...role.permissions
            .map((perm) => ({ ...perm, input: true }))],
        });
      } else if (key === 'nothing') {
        setRole({
          ...role,
          permissions: [...role.permissions
            .map((perm) => ({ ...perm, input: false, check: false }))],
        });
      } else {
        setRole({
          ...role,
          permissions: [...role.permissions
            .map((k) => {
              if (k.key === key && !checked) { return { ...k, check: checked, input: checked }; }
              if (k.key === key) { return { ...k, input: checked }; }
              return k;
            })],
        });
      }
    } else if (key === 'all') {
      setRole({
        ...role,
        permissions: [...role.permissions
          .map((perm) => ({ ...perm, check: perm.input }))],
      });
    } else if (key === 'nothing') {
      setRole({
        ...role,
        permissions: [...role.permissions
          .map((perm) => ({ ...perm, check: false }))],
      });
    } else {
      setRole({
        ...role,
        permissions: [...role.permissions
          .map((k) => {
            if (k.key === key) { return { ...k, check: checked }; }
            return k;
          })],
      });
    }
  }, [role]);

  return (
    <>
      <DimableLoader loading={loading}>

        {err
        && (
        <Alert dismissible variant="danger" onClose={() => setErr(null)}>
          {err}
        </Alert>
        )}

        {role && (
          <>
            <Form>
              <Form.Group>
                <Form.Label htmlFor="name">Назва ролі</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  onChange={updateRoleParams}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="description">Опис ролі</Form.Label>
                <Form.Control
                  type="text"
                  id="description"
                  name="description"
                  as="textarea"
                  rows={3}
                  onChange={updateRoleParams}
                />
              </Form.Group>
            </Form>

            <H2 color="blue">Права доступу:</H2>
            <StyledDiv style={{ marginBottom: '10px' }}>
              <div>
                <Button className="mr-2" onClick={() => handleCheckbox('input', 'all')}>
                  <FontAwesomeIcon icon={faCheckSquare} className="mr-2" size="lg" />
                    Включити всі
                </Button>
                <Button variant="outline-primary" onClick={() => handleCheckbox('input', 'nothing')}>
                  <FontAwesomeIcon icon={faSquare} className="mr-2" size="lg" />
                      Виключити всі
                </Button>
              </div>
              <div>
                <Button className="mr-2" variant="info" onClick={() => handleCheckbox('check', 'all')}>
                  <FontAwesomeIcon icon={faCheckSquare} className="mr-2" size="lg" />
                    Надати всі доступи
                </Button>
                <Button variant="outline-info" onClick={() => handleCheckbox('check', 'nothing')}>
                  <FontAwesomeIcon icon={faSquare} className="mr-2" size="lg" />
                      Зняти доступи
                </Button>
              </div>
            </StyledDiv>
            <Table hover>
              <thead>
                <tr>
                  <th style={{ width: '5px' }}>Включити в список</th>
                  <th>Назва</th>
                  <th style={{ width: '5px' }}>Надати доступ</th>
                </tr>
              </thead>
              <tbody>
                {role.permissions && role.permissions.map((el) => (
                  <tr key={el.key}>
                    <td>
                      <Form.Check
                        type="switch"
                        id={el.key}
                        label=""
                        checked={el.input}
                        onChange={(e) => handleCheckbox('input', el.key, e.target.checked)}
                      />
                    </td>
                    <td>{el.label}</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={el.key}
                        label=""
                        onChange={(e) => handleCheckbox('check', el.key, e.target.checked)}
                        checked={el.check}
                        disabled={!el.input}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="outline-success" className="mt-2" onClick={createRole}>Створити</Button>
          </>
        )}
      </DimableLoader>
    </>
  );
};

CreateRole.propTypes = {
  onClose: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
};

export default CreateRole;
