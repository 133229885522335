import {
  useCallback, useContext, useState,
} from 'react';
import { useLoadReport, useLoadShema } from './loader';
import { AppContext } from '../../../providers/authProvider';
import { useGroups } from './groups';
import { useColumns } from './columns';
import { useSelections } from './selections';
import { useOrders } from './orders';
import { useFilters } from './filters';
import { useGenerateReport } from './render';
import { useParams } from './params';
import api from '../../../api/req';
import { downloadFileFromJSON } from '../../../api/utils';

const emptyReportData = {
  name: '',
  description: '',
  schema: {},
  options: {
    group: [],
    column: [],
    order: [],
    fields: [],
  },
  authority: {},
  budget: {},
  user: {},

};

const emptySchema = {
  src: {
    fields: {},
    meta_fields: {},
  },
};

/**
 *
 * @param reportId {number} report id
 * @param reportUrl {string} Префикс api всех отчетов этого типа
 * @param renderReportUrl {string} url для рендеринга отчетов того типа
 * @returns {{
 *  name: string,
 *  description: string,
 *  shema: Object,
 *
 *  groups: [],
 *  onSetGroups: (function(): null),
 *  onSetSelections: (function(): null),
 *  availableGroups: [],
 *  availableSelections: [],
 *  availableParams: [],
 *  columns: [],
 *  onSetColumns: (function(): null),
 *  availableColumns: [],
 *  filters: [],
 *  loading: boolean,
 *  error: string,
 *  selections: [],
 *  availableOrders: [],
 *  onSetFilters: (function(): null),
 *  onGenerateReport: (function(): null),
 *  onSaveReport: (function(): null),
 *  availableFilters: [],
 *  orders: [],
 *  onSetOrders: (function(): null),
 *  results: {},
 *  onGetSettings: (function(): {}),
 *  }}
 */
// eslint-disable-next-line import/prefer-default-export
export const useReportEditor = (reportId, reportUrl, renderReportUrl) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [reportData, setReportData] = useState(emptyReportData);
  const [schema, setSchema] = useState(emptySchema);

  const [results, setResults] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);
  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);

  const onError = useCallback((e) => setErr(e), []);
  useLoadReport(reportId, auth, onLoadStart, onLoadEnd, setReportData, onError, reportUrl);
  useLoadShema(reportData, auth, onLoadStart, onLoadEnd, setSchema, onError);

  const {
    groups,
    availableGroups,
    displayGroups,
    groupsHandlers,
  } = useGroups(reportData, schema);

  const {
    columns,
    availableColumns,
    displayColumns,
    columnsHandlers,
  } = useColumns(reportData, schema);

  const {
    selections,
    availableSelections,
    displaySelections,
    selectionsHandlers,
  } = useSelections(reportData, schema);

  const {
    orders,
    availableOrders,
    displayOrders,
    ordersHandlers,
  } = useOrders(reportData, schema);

  const {
    filters,
    availableFilters,
    displayFilters,
    filtersHandlers,
  } = useFilters(reportData, schema);

  const {
    params,
    availableParams,
    displayParams,
    // changeParams,
    paramsHandlers,
  } = useParams(reportData, schema);

  const onGetSettings = useCallback(
    () => ({
      groups, selections, orders, filters, columns, params,
    }), [columns, filters, groups, orders, params, selections],
  );

  const { onGenerateReport, onAbortReport } = useGenerateReport(
    renderReportUrl, reportId, auth,
    groups, selections, orders, filters, columns, params,
    onLoadStart, onLoadEnd, setResults, onError,
  );

  const onFile = (d) => downloadFileFromJSON(d.file, d.filename);

  const onGenerateReportXLSX = useGenerateReport(
    renderReportUrl, reportId, auth,
    groups, selections, orders, filters, columns, params,
    onLoadStart, onLoadEnd, onFile, onError, 'xlsx',
  );

  const onGenerateReportHTML = useGenerateReport(
    renderReportUrl, reportId, auth,
    groups, selections, orders, filters, columns, params,
    onLoadStart, onLoadEnd, onFile, onError, 'html',
  );

  const onGenerateReportPDF = useGenerateReport(
    renderReportUrl, reportId, auth,
    groups, selections, orders, filters, columns, params,
    onLoadStart, onLoadEnd, onFile, onError, 'pdf',
  );

  const onSaveReport = useCallback(
    (saveParam, isNew) => {
      const loader = async () => {
        // const plainGroups = new Set(groups.reduce((R, g) => [...R, ...Object.keys(g)], []));
        // const fields = [
        //   ...[...plainGroups].map((g) => [g, null]),
        //   ...selections,
        // ];
        const fields = selections;
        const saverReportData = {
          schema,
          ...saveParam,
          options: {
            group: groups,
            column: columns,
            order: orders,
            filters,
            fields,
            params,
          },
        };
        const saver = isNew ? api.post : api.put;
        const URL = isNew ? reportUrl : `${reportUrl}${reportId}/`;
        const r = await saver(URL, auth, saverReportData);
        if (!r.ok) {
          if (r.status === 400) {
            const eData = await r.json();
            if (eData.error_data) {
              const errText = eData.error_data.reduce((R, ed) => `${R}${ed.messages.join(', ')} `, '');
              throw new Error(errText);
            }
          }
          throw new Error(`${r.status} ${r.statusText}`);
        }
        return r.json();
      };
      onLoadStart();
      loader()
        .then((r) => !isNew && setReportData(r))
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    },
    [auth, columns, filters, groups, onError, onLoadEnd, onLoadStart,
      orders, params, reportId, reportUrl, schema, selections],
  );

  return {
    reportData,
    schema,
    loading,
    error: err,
    // groups
    availableGroups,
    groups: displayGroups,
    groupsHandlers,
    // columns
    availableColumns,
    columns: displayColumns,
    columnsHandlers,
    // selections
    availableSelections,
    selections: displaySelections,
    selectionsHandlers,
    // orders
    availableOrders,
    orders: displayOrders,
    ordersHandlers,
    // params
    availableParams,
    params: displayParams,
    paramsHandlers,
    // filters
    availableFilters,
    filters: displayFilters,
    filtersHandlers,
    onGenerateReport,
    onGenerateReportXLSX,
    onGenerateReportHTML,
    onGenerateReportPDF,
    results,
    onSaveReport,
    onGetSettings,
    onAbortReport,
  };
};
