import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import LoadLevelDownButton from './loadLeveldown';
import { PrintButton } from './printButton';
import useEditor from '../../../newEditor/hook/editor';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';

const Editor = ({
  onSave, onClose, id,
}) => {
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields,
  } = useEditor({
    backendURL: meta.doc.allocationplan.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  // if (!data || !fields) return null;
  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerInHeaderProps = editorHooks.useCheckboxInputProps('disposer_in_header', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const onlyYearProps = editorHooks.useCheckboxInputProps('only_year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const vt4brProps = editorHooks.useCheckboxInputProps('vt4br', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const isCreditProps = editorHooks.useCheckboxInputProps('is_credit', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const commentOfAutorProps = editorHooks.useTextInputProps('comment_of_author', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const fondProps = editorHooks.useItemInputProps('fond_item', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const disposerProps = editorHooks.useItemInputProps('disposer', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <DocCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
        executed={data.executed}
      >
        <PrintButton
          id={`${data.id}`}
        />
      </DocCommandPanel>
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...fondProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} />
              </Col>
              {data.disposer_in_header && (
                <Col>
                  <EditorControls.ItemPicker
                    {...disposerProps}
                    filter={{ parent: data.authority.id }}
                    noHierarchy
                  />
                </Col>
              )}
            </Row>
            <hr />
            <Row>
              {!headerReadOnlyFields.includes('disposer_in_header') && (
              <Col>
                <EditorControls.CheckboxInput {...disposerInHeaderProps} />
              </Col>
              )}
              {!headerReadOnlyFields.includes('only_year') && (
              <Col>
                <EditorControls.CheckboxInput {...onlyYearProps} />
              </Col>
              )}
              {!headerReadOnlyFields.includes('vt4br') && (
              <Col>
                <EditorControls.CheckboxInput {...vt4brProps} />
              </Col>
              )}
              {!headerReadOnlyFields.includes('is_credit') && (
              <Col>
                <EditorControls.CheckboxInput {...isCreditProps} />
              </Col>
              )}
            </Row>
            <LoadLevelDownButton
              authority={data.authority}
              fondItem={data.fond_item}
              docDate={data.doc_date}
              year={data.year}
              planKind={data.plan_kind}
              onLoadHandler={(rows) => actions.onDraft(
                data,
                {
                  load_leveldown_allcpln_rows: {
                    rows,
                    clear: true,
                  },
                },
              )}
            />
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="allocationplan" id="uncontrolled-tab-example">
          {fields && fields.allocationplanrow_set && (
          <Tab eventKey="allocationplan" title={fields.allocationplanrow_set.label}>
            <TabContainer>
              <PlanningRows
                data={data}
                fields={fields}
                errors={fieldErrors}
                readOnlyFields={readOnlyFields}
                onChange={actions.onChange}
              />
            </TabContainer>
          </Tab>
          )}
          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...commentOfAutorProps} />
            </TabContainer>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
