import React from 'react';
import PropTypes from 'prop-types';
import { Selector } from '../../components/Controls';
import { longFormat } from '../../constants/date';

const getStringDate = (date) => {
  const D = new Date(date);
  return D.toLocaleString('uk', longFormat);
};

const HistorySelector = ({
  id, values, value, onDropDown, isLoading, onChange, readOnly,
}) => (
  <Selector
    id={id}
    value={value}
    values={values.map((h) => ({
      value: h.datefrom,
      display_name: `${getStringDate(h.datefrom)} - ${getStringDate(h.dateto)}`,
    }))}
    onChange={onChange}
    onDropDown={onDropDown}
    isLoading={isLoading}
    readOnly={readOnly}
  />
);

HistorySelector.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    datefrom: PropTypes.string,
    dateto: PropTypes.string,
  })).isRequired,
  onDropDown: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

HistorySelector.defaultProps = {
  isLoading: false,
  readOnly: false,
};

export default HistorySelector;
