import React from 'react';
import { LinkItem, NavItem } from './styles';

const Reports = () => (
  <NavItem>
    <LinkItem to="/rep/">Панель звітів</LinkItem>

  </NavItem>
);

export default Reports;
