import React from 'react';
import {
  StyledCellContainer,
  StyledCellItemHeader,
  StyledTableHeaderCell,
} from '../../../../../basicEditor';
import { fieldsPropTypes } from './propTypes';

const Header = ({ fields }) => (
  <tr>
    {/* <StyledTableHeaderCell> */}
    {/*  <StyledCellContainer> */}
    {/*    <StyledCellItemHeader>{fields.kpkvk_code.label}</StyledCellItemHeader> */}
    {/*  </StyledCellContainer> */}
    {/* </StyledTableHeaderCell> */}

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>Бюджетна програма</StyledCellItemHeader>
        <StyledCellItemHeader>
          {fields.target_programm.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>


    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.consumption_expenditures_zf.label}
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {fields.utility_bills_zf.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.job_payment_zf.label}
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {fields.development_cost_zf.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.consumption_expenditures_sf.label}
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {fields.utility_bills_sf.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.job_payment_sf.label}
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {fields.development_cost_sf.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.total_zag_fond.label}
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {fields.total_spec_fond.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          {fields.total_development_budget.label}
        </StyledCellItemHeader>
        <StyledCellItemHeader>
          {fields.soc_econimical_programs.label}
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.note.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
  </tr>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
};

export default Header;
