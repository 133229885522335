import React, {
  memo, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';

const HtmlPrintForm = ({ result }) => {
  const resultRef = useRef();
  const htmlResult = useMemo(
    () => ({ __html: result }),
    [result],
  );
  useEffect(
    () => {
      if (result && resultRef.current) {
        const el = resultRef.current.cloneNode(true);
        const newEl = document.body.appendChild(el);
        newEl.className = 'print-area';
      }
    },
    [result],
  );
  return (
    <div className="d-flex overflow-auto border rounded w-100 h-100">
      <div className="w-100" dangerouslySetInnerHTML={htmlResult} ref={resultRef} />
    </div>
  );
};

HtmlPrintForm.propTypes = {
  result: PropTypes.string,
};

HtmlPrintForm.defaultProps = {
  result: null,
};

export default memo(HtmlPrintForm);
