import React from 'react';
import PropTypes from 'prop-types';
import { StyledCommandPanelButton, StyledImg, StyledText } from './styles';
import { GoBackIcon } from '../../../icons';

export const GoBackButton = ({ content, onClick, disabled }) => (
  <StyledCommandPanelButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg style={{ width: '20px' }} src={GoBackIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledCommandPanelButton>
);

GoBackButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

GoBackButton.defaultProps = {
  onClick: () => null,
  content: 'Повернутися',
  disabled: false,
};

export default GoBackButton;
