export const actions = {
  new: 'NEW',
  edit: 'EDIT',
  delete: 'DELETE',
  select: 'SELECT',
  close: 'CLOSE',
  selectAll: 'SELECT_ALL',
  executeSelected: 'EXECUTE_SELECTED',
  cancelExecuteSelected: 'CANCEL_EXECUTE_SELECTED',
  setPeriod: 'SET_PERIOD',
  exportXLS: 'EXPORT_XLS',
  toggleFilter: 'TOGGLE_FILTER_PANEL',
  compare: 'COMPARE',
};

export default actions;
