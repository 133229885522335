import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const activityProps = tableHooks.useItemInputProps('activity', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const explanationProps = tableHooks.useStringInputProps('low_effectiveness_explanation', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  return (
    <>
      <Col sm={12}>
        <Row className="w-100" noGutters>
          <Col>
            <TabelRowCell column="activity" highlighted={highlights.includes('activity')}>
              <TableControls.ItemPicker {...activityProps} />
            </TabelRowCell>
          </Col>
          <Col>
            <TabelRowCell column="low_effectiveness_explanation" highlighted={highlights.includes('low_effectiveness_explanation')}>
              <TableControls.StringInput {...explanationProps} />
            </TabelRowCell>
          </Col>
        </Row>
      </Col>
    </>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
