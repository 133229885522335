import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, { FieldPropType, CatCommandPanel, getErrorsPropTypes } from '../../../basicEditor';
import { HeaderText } from '../../../../components/Styled/Forms';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';

const Editor = ({
  data, options, isNew, isReadOnly, isModified, onSave, onClose, fields, onChange, errors,
}) => {
  const nameProps = editorHooks.useStringInputProps('name', data, fields, errors, onChange);
  const codeProps = editorHooks.useStringInputProps('code', data, fields, errors, onChange);
  const mfoProps = editorHooks.useStringInputProps('mfo', data, fields, errors, onChange);
  const codeUdkProps = editorHooks.useStringInputProps('code_udk', data, fields, errors, onChange);
  const nameUdkProps = editorHooks.useStringInputProps('name_udk', data, fields, errors, onChange);
  const codeVdkProps = editorHooks.useStringInputProps('code_vdk', data, fields, errors, onChange);
  const nameVdkProps = editorHooks.useStringInputProps('name_vdk', data, fields, errors, onChange);
  const treasureNameProps = editorHooks.useStringInputProps('treasure_name', data, fields, errors, onChange);
  const codeTerrProps = editorHooks.useStringInputProps('code_terr', data, fields, errors, onChange);

  return (
    <div>
      <HeaderText>{isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}</HeaderText>
      <CatCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        canDeleted={false}
        onSave={() => onSave()}
        onClose={onClose}
      />
      <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <Container fluid style={{ padding: '0' }}>
              <Row>
                <Col>
                  <EditorControls.StringInput {...codeProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...mfoProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...nameProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.StringInput {...codeUdkProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...nameUdkProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.StringInput {...codeVdkProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...nameVdkProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.StringInput {...treasureNameProps} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...codeTerrProps} />
                </Col>
              </Row>
            </Container>
          </TabContainer>
        </Tab>
      </LabelTab>
    </div>
  );
};

const dataPropTypes = {
  id: PropTypes.number,
  repr: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
  mfo: PropTypes.string,
  code_udk: PropTypes.string,
  name_udk: PropTypes.string,
  code_vdk: PropTypes.string,
  name_vdk: PropTypes.string,
  treasure_name: PropTypes.string,
  code_terr: PropTypes.string,
  history: PropTypes.shape({}),
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,

  }).isRequired,
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  fields: PropTypes.shape(
    fieldsPropTypes,
  ).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.cat.treasurebank.backendURL)(Editor);
