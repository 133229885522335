import budgetrequest from './budgetRequest';
import allocationplan from './allocationplan';
import allocationplanchanges from './allocationplanchanges';
import assignmentplanningrev from './assignmentPlanningRev';
import finrequest from './applicationForFinancing';
import finorder from './financialOrder';
import finclosing from './financialClosing';
import registrationcard from './registrationCard';
import incomeproject from './incomeProject';
import expensesproject from './expensesProject';
import limitreference from './limitReference';
import incomeprojectchanges from './incomeProjectChanges';
import expensesprojectchanges from './expensesProjectChanges';
import budgetpassport from './budgetPassport';
import finsourceplan from './finSourcePlan';
import finsourceplanchanges from './finSourcePlanChanges';
import revenueplan from './revenuePlan';
import revenueplanchanges from './revenuePlanChanges';
import forecastAdd1 from './forecastAdd1';
import bfnInstitutionStructure from './bfnInstitutionStructure';
import bfnAllocation from './bfnAllocation';
import disposerchain from './disposerChain';
import disposerchainchange from './DisposerChainChanges';
import budgetExecution from './budgetExecution';

const documents = {
  budgetrequest,
  budgetExecution,
  allocationplan,
  allocationplanchanges,
  assignmentplanningrev,
  finrequest,
  finorder,
  finclosing,
  registrationcard,
  budgetpassport,
  incomeproject,
  expensesproject,
  limitreference,
  incomeprojectchanges,
  expensesprojectchanges,
  finsourceplan,
  finsourceplanchanges,
  revenueplan,
  revenueplanchanges,
  forecastAdd1,
  bfnInstitutionStructure,
  bfnAllocation,
  disposerchain,
  disposerchainchange,
};

export default documents;
