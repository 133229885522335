import React from 'react';
import PropTypes from 'prop-types';
import { TableSelectorInput, TableCheckboxInput } from '../../../basicEditor/tableControls';
import { relationPropType } from '../hooks';

const RelationItem = ({
  relation, active, onClick, alowedTables, onChange, tableFields,
}) => (

  <tr
    style={active ? { background: 'var(--backgroundActiveRow)' } : {}}
    onClick={onClick}
  >
    <td>
      <TableSelectorInput
        controlId={`table-from-selector-${relation.key}`}
        value={relation.fromTable}
        values={alowedTables}
        onChange={(e, v) => onChange(relation.key, { fromTable: v })}
      />
    </td>
    <td>
      <TableSelectorInput
        controlId={`field-from-selector-${relation.key}`}
        value={relation.fromField}
        values={(tableFields[relation.fromTable] || [])
          .map((value) => ({ value, display_name: value }))}
        onChange={(e, v) => onChange(relation.key, { fromField: v })}
      />
    </td>
    <td>
      <TableSelectorInput
        controlId={`table-to-selector-${relation.key}`}
        value={relation.toTable}
        values={alowedTables}
        onChange={(e, v) => onChange(relation.key, { toTable: v })}
      />
    </td>
    <td>
      <TableSelectorInput
        controlId={`field-to-selector-${relation.key}`}
        value={relation.toField}
        values={(tableFields[relation.toTable] || [])
          .map((value) => ({ value, display_name: value }))}
        onChange={(e, v) => onChange(relation.key, { toField: v })}
      />
    </td>
    <td>
      <TableCheckboxInput
        controlId={`field-mandatory-${relation.key}`}
        label=" "
        value={relation.mandatory}
        onChange={(e, v) => onChange(relation.key, { mandatory: v })}
      />
    </td>
  </tr>

);

RelationItem.propTypes = {
  relation: relationPropType.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  alowedTables: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    display_name: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  tableFields: PropTypes.shape({}).isRequired,
};

RelationItem.defaultProps = {
  active: false,
};

export default RelationItem;
