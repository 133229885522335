import assigmentplaning from './assigmentPlaning';
import commoninStitutionStructureBfn from './commoninStitutionStructureBfn';
import planBallancing from './planBallancing';

const rep = {
  assigmentplaning,
  commoninStitutionStructureBfn,
  planBallancing,
};

export default rep;
