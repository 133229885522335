import SchemaSelector from './selector';


const cat = {
  Selector: SchemaSelector,

};

export { BACKEND_URL as SCHEMA_BACKEND_URL } from './selector';

export default cat;
