import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

const Header = ({
  fields, activeCol, highlights, onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col sm={12} className="px-1 d-flex flex-column justify-content-end">
      <Row noGutters>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'types_of_entry_sf'}
            highlighted={highlights.includes('types_of_entry_sf')}
            onToggleHighlght={() => onToggleHighlght('types_of_entry_sf')}
          >
            {fields.types_of_entry_sf.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_plan'}
            highlighted={highlights.includes('amount_plan')}
            onToggleHighlght={() => onToggleHighlght('amount_plan')}
          >
            {fields.amount_plan.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_fact'}
            highlighted={highlights.includes('amount_fact')}
            onToggleHighlght={() => onToggleHighlght('amount_fact')}
          >
            {fields.amount_fact.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'amount_deviation'}
            highlighted={highlights.includes('amount_deviation')}
            onToggleHighlght={() => onToggleHighlght('amount_deviation')}
          >
            {fields.amount_deviation.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'explanation'}
            highlighted={highlights.includes('explanation')}
            onToggleHighlght={() => onToggleHighlght('explanation')}
          >
            {fields.explanation.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
