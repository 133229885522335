/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TableFooter from '../../../../../newEditor/tablePart/tableFooter';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import TPFooter from './footer';

const PlanningRows = ({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) => {
  const {
    tableData, tableActions, pinHeader, showFooter, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'budgetrequestworkercategoryrow_set', readOnlyFields, errors, fields,
  });

  const {
    year,
  } = data;

  const totals = useMemo(
    () => (tableData.reduce((res, r) => {
      res.amount_zf1 += r.amount_zf1 || 0;
      res.amount_zf2 += r.amount_zf2 || 0;
      res.amount_br_total1 += r.amount_br_total1 || 0;
      res.amount_br_total2 += r.amount_br_total2 || 0;
      res.amount_sf1 += r.amount_sf1 || 0;
      res.amount_sf2 += r.amount_sf2 || 0;
      res.amount_total1 += (r.amount_zf1 || 0) + (r.amount_sf1 || 0);
      res.amount_total2 += (r.amount_zf2 || 0) + (r.amount_sf2 || 0);
      return res;
    },
    {
      amount_zf1: 0,
      amount_zf2: 0,
      amount_br_total1: 0,
      amount_br_total2: 0,
      amount_sf1: 0,
      amount_sf2: 0,
      amount_total1: 0,
      amount_total2: 0,
    })
    ), [tableData],
  );

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <p style={{ margin: 'auto', color: '#6495ed' }}>Для зміни кількості рядків, перейдіть до вкладки з фактичними данними</p>
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          year={year}
          totals={totals}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        {tableData && tableData.map((row, index) => (
          <TableRow key={index} rowIndex={index} active={activeRow === index} activeCol={activeCol}>
            <Row
              rowIndex={index}
              row={row}
              onChange={tableActions.onCellChange}
              readOnly={readOnly}
              highlights={highlights}
              fields={tableFields}
              errors={tableErrors[index]}
              readOnlyFields={tableReadOnlyFields}
            />
          </TableRow>
        ))}
        <Placeholder onFocus={(e) => tableActions.onAddRow(e)} />
      </Card.Body>
      <TableFooter showFooter={showFooter} pinHeader={pinHeader}>
        <TPFooter
          totals={totals}
          activeCol={activeCol}
          highlights={highlights}
        />
      </TableFooter>
    </Card>
  );
};

PlanningRows.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number,
    budgetrequestworkercategoryrow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    budgetrequestworkercategoryrow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    budgetrequestworkercategoryrow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    budgetrequestworkercategoryrow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  ///
};

PlanningRows.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};

export default PlanningRows;
