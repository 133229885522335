import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withPrint, { PrintCommandPanel } from '../../../printArea';
import meta from '../../../../meta';

const Result = styled.div`
    max-height: 75vh;
    overflow: auto;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 10px;
`;

const PrintEditor = ({
  showPrintedResult, print, pageSize, loading, fields, savePrintSettings, savedSettings,
}) => (
  <>
    <PrintCommandPanel
      showPrintedResult={showPrintedResult}
      print={print}
      pageSize={pageSize}
      loading={loading}
      fields={fields}
      savePrintSettings={savePrintSettings}
      savedSettings={savedSettings}
    />
    <Result id="print-area" />
  </>
);


PrintEditor.propTypes = {
  showPrintedResult: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  pageSize: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fields: PropTypes.shape({
  }).isRequired,
  savePrintSettings: PropTypes.func.isRequired,
  savedSettings: PropTypes.shape({
  }),
};
PrintEditor.defaultProps = {
  loading: false,
  savedSettings: null,
};


export default withPrint(meta.doc.incomeproject.printURL)(PrintEditor);
