import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const TableHeader = ({
  pinHeader, className, children,
}) => {
  const clsName = useMemo(
    () => `px-0 pt-0 bg-light ${className || ''} ${pinHeader ? ' position-sticky fixed-top ' : ''}`,
    [className, pinHeader],
  );

  return (
    <Card.Header className={clsName}>
      {children}
    </Card.Header>
  );
};

TableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  pinHeader: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

TableHeader.defaultProps = {
  className: '',
};

export default TableHeader;
