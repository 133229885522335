import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DATATYPES } from './consts';
import { EditorControls } from '../../basicEditor/editorControls';

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-column-gap: 1%;
  align-items: end;
  margin-bottom: 300px
`;

const DKSUData = ({
  dataType, setDataType, date, setDate, getDKSUData, datesByType, lastDownload, logicaPingSuccess,
}) => (
  <ContainerGrid>
    <EditorControls.SelectorInput
      label="Оберіть тип даних для завантаження"
      controlId="dataType"
      required
      value={dataType}
      values={DATATYPES.map((data) => ({ value: data, display_name: data }))}
      onChange={(e, data) => setDataType(data)}
    />

    <EditorControls.StringInput
      label="В останнє завантажено на:"
      value={lastDownload}
      controlId="lastDownload"
      readOnly
      onChange={() => null}
    />

    <EditorControls.SelectorInput
      label="Оберіть дату"
      required
      controlId="date"
      value={date}
      values={datesByType() || []}
      onChange={(e, data) => setDate(data)}
      readOnly={!dataType}
    />

    <Button onClick={getDKSUData} className="mb-3" disabled={!logicaPingSuccess}>
      <FontAwesomeIcon icon={faDownload} className="mr-2" />
      Завантажити
    </Button>
  </ContainerGrid>
);

DKSUData.propTypes = {
  dataType: propTypes.string,
  setDataType: propTypes.func.isRequired,
  date: propTypes.string,
  setDate: propTypes.func.isRequired,
  getDKSUData: propTypes.func.isRequired,
  datesByType: propTypes.func.isRequired,
  lastDownload: propTypes.string,
  logicaPingSuccess: propTypes.bool.isRequired,
};

DKSUData.defaultProps = {
  dataType: null,
  date: null,
  lastDownload: null,
};

export default DKSUData;
