import budgetrequest from './budgetRequest';
import allocationplan from './allocationPlan';
import allocationplanchanges from './allocationPlanChanges';
import finrequest from './finRequest';
import finorder from './finOrder';
import finclosing from './finСlosing';
import registrationcard from './registrationCard';
import incomeproject from './incomeProject';
import expensesproject from './expensesProject';
import limitreference from './limitReference';
import expensesprojectchanges from './expensesProjectChanges';
import incomeprojectchanges from './incomeProjectChanges';
import budgetpassport from './budgetPassport';
import finsourceplan from './finSourcePlan';
import finsourceplanchanges from './finSourcePlanChanges';
import revenueplan from './revenuePlan';
import revenueplanchanges from './revenuePlanChanges';
import forecastAdd1 from './forecastAdd1';
import assignmentplanningrev from './assignmentPlanningRev';
import bfnInstitutionStructure from './bfnInstitutionStructure';
import bfnAllocation from './bfnAllocation';
import disposerchain from './disposerchain';
import disposerchainchange from './disposerchainchange';
import budgetExecution from './budgetExecution';

const docs = {
  budgetrequest,
  budgetpassport,
  allocationplan,
  allocationplanchanges,
  finrequest,
  finorder,
  finclosing,
  registrationcard,
  incomeproject,
  expensesproject,
  limitreference,
  expensesprojectchanges,
  incomeprojectchanges,
  finsourceplan,
  finsourceplanchanges,
  revenueplan,
  revenueplanchanges,
  forecastAdd1,
  assignmentplanningrev,
  bfnInstitutionStructure,
  bfnAllocation,
  budgetExecution,
  disposerchain,
  disposerchainchange,
};

export default docs;
