import React, { useCallback, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Tab, Collapse, Card, Container, Row, Col, Modal, Spinner, Tabs,
} from 'react-bootstrap';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  faFileCode,
  faFileExcel,
  faFilePdf,
  faPhotoVideo,
  faPlus,
  faPrint,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { GenerateButton, SettingsButton } from '../../components/bootStrap/buttons';
import useReportEditor from './hooks';
import ReportSettingsEditor from './ReportSettingsEditor';
import Results from './results';
import { CommandPanel, ErrorMessage } from '../../components/bootStrap';
import { CPButton } from '../../components/bootStrap/buttons/styles';
import ParamsField from './reportParams';
import SaveReport from './saveReport';
import ContextMenu from './results/contextMenu';
import DetailsShower from './detailsShower';
import GetScreen from './getReportAvatar';
import { RENDER_API_URL, REPORTS_API_URL } from './hooks/consts';

const SHOW_MODES = {
  results: 'RESULTS',
  settings: 'SETTINGS',
  saveNew: 'SAVENEW',
  save: 'SAVE',
};

const StyledDiv = styled.div`
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: right;
  background: white;
  box-shadow: #e0e0e0 5px 5px 5px;
  padding: 1rem;
`;

const StdReportEditor = ({
  reportId, reportUrl, renderReportUrl,
  hideGroups, hideColumnGroups, hideFilters, hideSelections, hideOrders, defaultSettingsPage,
}) => {
  const {
    reportData, schema,
    availableGroups, groups, groupsHandlers,
    availableColumns, columns, columnsHandlers,
    availableSelections, selections, selectionsHandlers,
    availableOrders, orders, ordersHandlers,
    availableFilters, filters, filtersHandlers,
    params, availableParams, paramsHandlers,
    onGenerateReport, results, loading,
    onGenerateReportXLSX, onGenerateReportHTML, onGenerateReportPDF,
    onSaveReport, onAbortReport,
    error, onGetSettings,
  } = useReportEditor(reportId, reportUrl, renderReportUrl);
  const [showMode, setShowMode] = useState(SHOW_MODES.results);
  const [updated, setUpdated] = useState(false);
  const [typeOfReport, setTypeOfReport] = useState('report');

  const containerHeaderRef = useRef(null);
  const resultRef = useRef(null);

  const height = containerHeaderRef.current
    ? containerHeaderRef.current.getBoundingClientRect().height : 0;

  const canvasRef = useRef(null);
  const [showAvatarArea, setShowAvatarArea] = useState(false);

  const print = () => {
    if (results && resultRef.current) {
      const el = resultRef.current.cloneNode(true);
      const newEl = document.body.appendChild(el);
      newEl.className = 'print-area';

      window.print();
      document.body.removeChild(newEl);
    }
  };

  const getCapture = () => {
    html2canvas(document.querySelector('#capture')).then((canvas) => {
      canvasRef.current = canvas;
      setShowAvatarArea(true);
    });
  };

  const doAvatar = ({
    x1, y1, ratio, avatarHeight, canvasHeight,
  }) => {
    setShowAvatarArea(false);

    const canvasWidth = Math.round(canvasHeight * ratio);

    const newCanvas = document.createElement('canvas');
    newCanvas.width = canvasWidth;
    newCanvas.height = canvasHeight;

    const newCtx = newCanvas.getContext('2d');

    newCtx.drawImage(canvasRef.current,
      x1, y1,
      Math.round(avatarHeight * ratio), avatarHeight,
      0, 0,
      canvasWidth, canvasHeight);

    newCanvas.toBlob((blob) => {
      const { ClipboardItem } = window;
      const dataImg = [new ClipboardItem({
        [blob.type]: blob,
      })];

      navigator.clipboard.write(dataImg);
    });

    reportData.avatarImg = newCanvas.toDataURL();
  };

  const [contextMenu, setContextMenu] = useState({
    opened: false,
    x: 0,
    y: 0,
    detail: null,
    target: null,
    allowedDetails: [],
    groupsValues: {},
  });

  const onContextMenu = useCallback(
    (e, contextGroups, contextData) => {
      const knowFields = new Set([
        ...Object.keys(contextGroups.row),
        ...Object.keys(contextGroups.column),
      ]);
      const currentField = [...knowFields]
        .filter((kf) => kf in schema.src.meta_fields
          && schema.src.meta_fields[kf].key in contextData)
        .reduce((R, r) => ({
          ...schema.src.meta_fields[r],
          name: r,
        }), null);
      if (!currentField) return false;
      const usedGroups = new Set([
        ...groups.reduce((R, sg) => [...R, ...(sg.items.map((g) => g.name))], []),
        ...columns.reduce((R, sc) => [...R, ...(sc.items.map((c) => c.name))], []),
      ]);
      const allowedGroups = availableGroups.filter((a) => !usedGroups.has(a.name));

      setContextMenu({
        opened: true,
        x: e.clientX,
        y: e.clientY,
        target: e.target,
        detail: {
          currentItem: {
            backend: currentField.resource,
            key: currentField.key,
            name: currentField.name,
            id: contextData[currentField.key],
            repr: contextData.repr,
          },
        },
        allowedDetails: allowedGroups,
        groupsValues: { ...contextGroups.row, ...contextGroups.column },
      });
      e.preventDefault();
      return false;
    },
    [schema, groups, columns, availableGroups],
  );

  const [detail, setDetail] = useState({
    settings: {},
    opened: false,
  });
  const onDetail = useCallback(
    (e, group) => {
      const oldSettings = onGetSettings();
      const newFIlters = [
        ...oldSettings.filters,
        ...Object.keys(contextMenu.groupsValues)
          .map((k) => [k, '=', { id: contextMenu.groupsValues[k].value, repr: contextMenu.groupsValues[k].repr }, true]),
      ];
      const newGroups = [...oldSettings.groups, { [group.name]: { hierarchy: false } }];
      setDetail({
        settings: {
          ...oldSettings,
          groups: newGroups,
          filters: newFIlters,
        },
        opened: true,
      });
      setContextMenu(({ ...r }) => ({
        ...r,
        opened: false,
      }));
    },
    [contextMenu.groupsValues, onGetSettings],
  );

  const handleGenerateReport = useCallback(() => {
    if (typeOfReport === 'Excel') {
      return onGenerateReportXLSX.onAbortReport();
    }
    if (typeOfReport === 'HTML') {
      return onGenerateReportHTML.onAbortReport();
    }
    if (typeOfReport === 'PDF') {
      return onGenerateReportPDF.onAbortReport();
    }
    return onAbortReport();
  }, [
    onAbortReport,
    onGenerateReportHTML,
    onGenerateReportPDF,
    onGenerateReportXLSX,
    typeOfReport,
  ]);

  return (
    <>
      {error && (
        <ErrorMessage text={error} />
      )}
      <DndProvider backend={HTML5Backend}>
        <div ref={containerHeaderRef}>
          <h6>{reportData.name}</h6>
          <p className="text-muted font-italic">
            {reportData.description}
          </p>
          <Container fluid>
            <Row>
              {availableParams && availableParams.map((param) => (
                <Col key={param.name}>
                  <ParamsField
                    param={param}
                    currentParam={params[param.name]}
                    changeParam={paramsHandlers.changeParams}
                  />
                </Col>
              ))}
            </Row>
          </Container>
          <CommandPanel>
            <GenerateButton
              onClick={() => {
                setTypeOfReport('report');
                onGenerateReport();
                setShowMode(SHOW_MODES.results);
                setUpdated(true);
              }}
              content="Сформувати звіт"
            />
            <SettingsButton
              onClick={() => {
                setShowMode(
                  (sm) => (sm === SHOW_MODES.settings ? SHOW_MODES.results : SHOW_MODES.settings),
                );
              }}
            />
            <CPButton
              onClick={() => print()}
              title="Друк"
              icon={faPrint}
              // disabled={!results || showMode !== SHOW_MODES.results}
            />
            <CPButton
              onClick={() => {
                setTypeOfReport('Excel');
                onGenerateReportXLSX.onGenerateReport();
              }}
              // disabled={!results || showMode !== SHOW_MODES.results}
              title="Зберегти в Excel"
              icon={faFileExcel}
            />
            <CPButton
              onClick={() => {
                setTypeOfReport('HTML');
                onGenerateReportHTML.onGenerateReport();
              }}
              // disabled={!results || showMode !== SHOW_MODES.results}
              title="Зберегти в HTML"
              icon={faFileCode}
            />
            <CPButton
              onClick={() => {
                setTypeOfReport('PDF');
                onGenerateReportPDF.onGenerateReport();
              }}
              // disabled={!results || showMode !== SHOW_MODES.results}
              title="Зберегти в PDF"
              icon={faFilePdf}
            />
            <CPButton
              onClick={() => {
                setShowMode(SHOW_MODES.save);
              }}
              title="Зберегти звіт"
              icon={faSave}
            />
            <CPButton
              onClick={() => {
                setShowMode(SHOW_MODES.saveNew);
              }}
              title="Створити новий звіт"
              icon={faPlus}
            />
            <CPButton
              onClick={() => {
                getCapture();
              }}
              title="Зробити аватарку для варіанту звіту"
              icon={faPhotoVideo}
            />
          </CommandPanel>
        </div>
        <Card>
          <Collapse in={showMode === SHOW_MODES.save} appear>
            <Card.Body style={{ padding: '0px', margin: '0!important' }}>
              <Card.Header className="cardHeader">Зберегти звіт</Card.Header>
              <SaveReport
                onHide={() => setShowMode(SHOW_MODES.results)}
                reportData={reportData}
                onSave={onSaveReport}
              />
            </Card.Body>
          </Collapse>
        </Card>
        <Card>
          <Collapse in={showMode === SHOW_MODES.saveNew} appear>
            <Card.Body style={{ padding: '0px', margin: '0!important' }}>
              <Card.Header className="cardHeader">Створити новий звіт</Card.Header>
              <SaveReport
                onHide={() => setShowMode(SHOW_MODES.results)}
                isNew
                onSave={onSaveReport}
                reportData={reportData}
              />
            </Card.Body>
          </Collapse>
        </Card>
        <Card style={{ border: 'none' }}>
          <Collapse in={showMode === SHOW_MODES.settings} appear>
            <Card.Body style={{ padding: '0px', margin: '0!important' }}>
              <Tabs defaultActiveKey={defaultSettingsPage}>
                {!hideGroups && (
                  <Tab style={{ margin: '0!important' }} title="Групування" eventKey="groups">
                    <ReportSettingsEditor.GroupEditor
                      availableGroups={availableGroups}
                      groups={groups}
                      groupsHandlers={groupsHandlers}
                    />
                  </Tab>
                )}
                {!hideColumnGroups && (
                <Tab style={{ margin: '0!important' }} title="Групування колонок" eventKey="columns">
                  <ReportSettingsEditor.ColumnsEditor
                    availableColumns={availableColumns}
                    columns={columns}
                    columnsHandlers={columnsHandlers}
                  />
                </Tab>
                )}
                {!hideSelections && (
                <Tab style={{ margin: '0!important' }} title="Обрані поля" eventKey="selection">
                  <ReportSettingsEditor.SelectionEditor
                    availableSelections={availableSelections}
                    selections={selections}
                    selectionHandlers={selectionsHandlers}
                  />
                </Tab>
                )}
                {!hideOrders && (
                <Tab title="Сортування" eventKey="orders">
                  <ReportSettingsEditor.OrdersEditor
                    availableOrders={availableOrders}
                    orders={orders}
                    ordersHandlers={ordersHandlers}
                  />
                </Tab>
                )}
                {!hideFilters && (
                <Tab title="Фільтрування" eventKey="filters">
                  <ReportSettingsEditor.FiltersEditor
                    availableFilters={availableFilters}
                    filters={filters}
                    filtersHandlers={filtersHandlers}
                  />
                </Tab>
                )}
              </Tabs>
            </Card.Body>
          </Collapse>
        </Card>
      </DndProvider>
      <div style={{
        border: '1px solid var(--borderTab)',
        height: `calc(100vh - ${height}px)`,
        overflow: 'auto',
        display: 'flex',
      }}
      >
        {showAvatarArea ? (
        // Визначаємо розмір аватарки (іконка варіанту звіту):
        // Висота 220, Ширина у "ratio" разів більша від висоти

          <GetScreen
            doAvatar={doAvatar}
            ratio={2.6}
            canvasHeight={220}

          />
        ) : null}

        {loading && updated && (
          <StyledDiv className="border">
            <Spinner animation="border" className="mr-2 text-dark" />
            <div>
              <div className="text-dark h5">Треба трохи зачекати, але воно того варте...</div>
              <Button
                variant="link"
                className="text-danger small"
                onClick={handleGenerateReport}
              >
                <FontAwesomeIcon icon={faTimes} className=" mr-1" />
                Скасувати запит
              </Button>
            </div>
          </StyledDiv>
        )}

        {results && showMode === SHOW_MODES.results ? (
          <>
            <Results
              result={results}
              ref={resultRef}
              caption={reportData.name}
              onContextMenu={onContextMenu}
            />
            <ContextMenu
              top={contextMenu.y}
              left={contextMenu.x}
              allowedColumns={[]}
              onCloseMenu={() => setContextMenu(({ ...r }) => ({
                ...r,
                opened: false,
              }))}
              detail={contextMenu.detail}
              allowedDetails={contextMenu.allowedDetails}
              onDetailProcessing={onDetail}
              opened={contextMenu.opened}
              target={contextMenu.target}
            />
          </>

        ) : <div style={{ height: '100px' }} />}
      </div>
      <Modal show={detail.opened} onHide={() => setDetail((r) => ({ ...r, opened: false }))}>
        <Modal.Header closeButton>
          <h3>Розшифровка</h3>
        </Modal.Header>
        <Modal.Body>
          <DetailsShower reportId={reportId} schema={schema} settings={detail.settings} />
        </Modal.Body>
      </Modal>
    </>
  );
};

StdReportEditor.propTypes = {
  reportId: PropTypes.string,
  reportUrl: PropTypes.oneOf([REPORTS_API_URL]),
  renderReportUrl: PropTypes.oneOf([RENDER_API_URL]),
  hideGroups: PropTypes.bool,
  hideColumnGroups: PropTypes.bool,
  hideFilters: PropTypes.bool,
  hideSelections: PropTypes.bool,
  hideOrders: PropTypes.bool,
  defaultSettingsPage: PropTypes.oneOf(['groups', 'columns', 'selection', 'orders', 'filters']),
};

StdReportEditor.defaultProps = {
  reportId: null,
  reportUrl: REPORTS_API_URL,
  renderReportUrl: RENDER_API_URL,
  hideGroups: false,
  hideColumnGroups: false,
  hideFilters: false,
  hideSelections: false,
  hideOrders: false,
  defaultSettingsPage: 'selection',
};

export default StdReportEditor;
