import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FormControl, InputGroup, Dropdown, Spinner,
} from 'react-bootstrap';
import { foreignPropType } from '../../constants/backend/propTypes';

const SFormControl = styled(FormControl)`
  &[readonly] {
    background: var(--white) !important;
  }
`;

export const DropContainerButton = styled(InputGroup)`
   div button {
      background-color: var(--backgroundButton) !important;
      color: var(--colorTextButton)!important;
        &:hover {
          background-color: var(--hoverButton) !important;
        };
    };
`;

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, foreignPropType]);

const Selector = forwardRef(({
  id, value, values, onChange, disabled, readOnly,
  required, isLoading, nullLabel, onDropDown, errored,
  className,
}, ref) => {
  const valueTest = useMemo(
    () => (typeof value === 'object' && value !== null ? value.id : value),
    [value],
  );
  const displayValues = useMemo(
    () => (required ? values || [] : [{ value: null, display_name: nullLabel }, ...values])
      .map((v) => ({
        ...v,
        valueTest: typeof v.value === 'object' && v.value !== null ? v.value.id : v.value,
      })),
    [nullLabel, required, values],
  );
  const displayValue = useMemo(
    () => displayValues.reduce(
      (R, v) => (v.valueTest === valueTest ? v.display_name : R),
      typeof value === 'object' && value !== null ? value.repr : '',
    ),
    [displayValues, value, valueTest],
  );
  return (
    <DropContainerButton className={className}>
      <SFormControl
        id={id}
        ref={ref}
        value={displayValue}
        isInvalid={errored}
        readOnly
      />
      {isLoading && (
        <div className="border border-left-0 px-2 d-flex align-items-center">
          <Spinner animation="border" size="sm" />
        </div>
      )}
      <Dropdown
        style={{ position: 'unset' }}
        as={InputGroup.Append}
        onToggle={(isOpen, e, md) => {
          if (isOpen && onDropDown) onDropDown(e, md);
        }}
      >
        <Dropdown.Toggle id={`drdn-${id}`} variant="outline-secondary" disabled={disabled || readOnly} split />
        <Dropdown.Menu alignRight style={{ maxHeight: '20em' }} className="overflow-auto">
          {displayValues.map((vv) => (
            <Dropdown.Item
              key={vv.valueTest}
              active={vv.valueTest === valueTest}
              disabled={vv.disabled}
              onClick={(e) => { e.preventDefault(); onChange(e, vv.value); }}
              onSelect={(eventKey, event) => event.preventDefault()}
            >
              {vv.display_name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </DropContainerButton>
  );
});

Selector.propTypes = {
  id: PropTypes.string.isRequired,
  value: valuePropType,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  nullLabel: PropTypes.string,
  onDropDown: PropTypes.func,
  errored: PropTypes.bool,
  className: PropTypes.string,
};

Selector.defaultProps = {
  value: null,
  values: [],
  onChange: () => null,
  disabled: false,
  readOnly: false,
  required: true,
  isLoading: false,
  nullLabel: 'Не обрано',
  onDropDown: null,
  errored: false,
  className: null,
};

export default Selector;
