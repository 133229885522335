import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { InputStyled } from '../../Input/index';
import { SearchIcon } from '../../../../icons';
import { debounce } from '../../../../api/utils';

const StyledSearchContainer = Styled.div`
  background: white;
  border-radius:.28571429rem;
  border: 1px solid rgba(34,36,38,.15);
  padding: 3px 10px;
  width: 30%;
  display: flex;  
  align-self: center;
  box-sizing: border-box;
  
  
  @media (max-width: 720px) {
  width: 100%; 
  margin-bottom: 5px;
  }
`;

const StyledSearch = Styled(InputStyled).attrs({
  placeholder: 'Пошук',
})`
`;

const SearchInput = ({ onSearch, value }) => {
  const debouncedSearch = useMemo(() => debounce(onSearch), [onSearch]);
  return (
    <StyledSearchContainer>
      <img src={SearchIcon} alt="Пошук" />
      <StyledSearch
        onChange={(e) => debouncedSearch(e, e.target.value)}
        defaultValue={value}
      />
    </StyledSearchContainer>
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  value: '',
};

export default SearchInput;
