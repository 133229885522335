import React from 'react';
import PropTypes from 'prop-types';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const SaveButton = ({ content, onClick, disabled }) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faSave}
  />
);

SaveButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SaveButton.defaultProps = {
  onClick: () => null,
  content: 'Зберегти',
  disabled: false,
};

export default SaveButton;
