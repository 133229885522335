import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import withHistory, { HistoryCommandPanel } from '../../../historyValues';
import { FieldPropType, FieldErrorPropType } from '../../../basicEditor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';

const HistoryGenderSegment = ({
  data, fields, errors, onChange, onSave, onNew, onDelete, isNew,
  // eslint-disable-next-line camelcase
  history_values, onHistoryDropDown, history_loading, onChangeHistoryVariant,
}) => {
  const nameProps = editorHooks.useStringInputProps('name', data, fields, errors, onChange);
  const codeProps = editorHooks.useStringInputProps('code', data, fields, errors, onChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', data, fields, errors, onChange);

  return (
    <>
      <HistoryCommandPanel
        history_value={data.datefrom}
            // eslint-disable-next-line camelcase
        history_values={history_values}
        onChangeHistoryVariant={onChangeHistoryVariant}
        onHistoryDropDown={onHistoryDropDown}
            // eslint-disable-next-line camelcase
        history_loading={history_loading}
        onSave={onSave}
        onNew={onNew}
        onDelete={onDelete}
        isNew={isNew}
      />
      <Container fluid style={{ padding: '0' }}>
        <Row>
          <Col>
            <EditorControls.StringInput {...codeProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...nameProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.CheckboxInput {...isActiveProps} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  is_active: PropTypes.bool,
};

const errorsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldErrorPropType }), {});
const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryGenderSegment.propTypes = {
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  errors: PropTypes.shape(
    errorsPropTypes,
  ).isRequired,
  fields: PropTypes.shape(
    fieldsPropTypes,
  ).isRequired,
  history_values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onHistoryDropDown: PropTypes.func.isRequired,
  history_loading: PropTypes.bool,
  onChangeHistoryVariant: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired, // Признак того что создается ноывй элемнт
  // ( не элемент истории а элемент классификатора)
};

HistoryGenderSegment.defaultProps = {
  history_loading: false,
};

export default withHistory(HistoryGenderSegment);
