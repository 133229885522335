import React from 'react';
import PropTypes from 'prop-types';
import { StyledCellContainer, StyledCellItemHeader, StyledTableHeaderCell } from '../../../../../basicEditor';
import { fieldsPropTypes } from './propTypes';

const Header = ({
  fields, quartal,
}) => (
  <tr>
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.targetprogram.label}</StyledCellItemHeader>
        <StyledCellItemHeader>Заповнювати дані з</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

    {quartal
    && (
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.amount_zfq.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_sfq.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
    )}

    {quartal
    && (
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.amount_sf9m.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
    )}


    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.amount_zf0.label}</StyledCellItemHeader>
        <StyledCellItemHeader>{fields.amount_sf0.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>


    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>{fields.amount_total0.label}</StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>
  </tr>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  quartal: PropTypes.bool.isRequired,
};

export default Header;
