import React from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Container, Row, Col,
} from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, { DocCommandPanel, getFieldsPropTypes, getErrorsPropTypes } from '../../../basicEditor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import { stdDocDataPropTypes, foreignPropType } from '../../common';
import { HeaderText } from '../../../../components/Styled/Forms';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { PlanningRows } from './tp';
import { FlexContainer, TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';

const Editor = ({
  data, options, isNew, isReadOnly, isModified, onSave, onClose,
  onExecute, onCancelExecute, fields, errors, onChange,
}) => {
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, errors, onChange);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, errors, onChange);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, errors, onChange);
  const fondProps = editorHooks.useItemInputProps('fonditem', data, fields, errors, onChange);
  const onlyYearProps = editorHooks.useCheckboxInputProps('only_year', data, fields, errors, onChange);
  const vt4brProps = editorHooks.useCheckboxInputProps('vt4br', data, fields, errors, onChange);
  const noteProps = editorHooks.useTextInputProps('note', data, fields, errors, onChange);

  return (
    <FlexContainer>
      <HeaderText>{isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}</HeaderText>
      <DocCommandPanel
        canSaved={!isReadOnly}
        modified={isModified}
        onSave={() => onSave()}
        onClose={onClose}
        executed={data.executed}
        onExecute={() => onExecute()}
        onCancelExecute={() => onCancelExecute()}
      />
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...planKindProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...fondProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput {...onlyYearProps} />
              </Col>
              <Col>
                <EditorControls.CheckboxInput {...vt4brProps} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="revenueplan" id="uncontrolled-tab-example">
          <Tab eventKey="revenueplan" title={fields.revenueplanrow_set.label}>
            <TabContainer>
              <PlanningRows
                data={data.revenueplanrow_set}
                fields={fields.revenueplanrow_set.child.children}
                // readOnly={fields.revenueplanrow_set.read_only}
                errors={errors.revenueplanrow_set}
                onChange={(set) => onChange({ revenueplanrow_set: set })}
                  // Document properties
                onlyYear={data.only_year}
                vt4br={data.vt4br}
                disposerInHeader={data.disposer_in_header}
                authorityId={data.authority.id}
              />
            </TabContainer>
          </Tab>
          <Tab eventKey="note" title="Примітки">
            <TabContainer>
              <EditorControls.TextInput {...noteProps} />
            </TabContainer>
          </Tab>
        </LabelTab>

      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </FlexContainer>
  );
};

const dataPropTypes = {
  ...stdDocDataPropTypes,
  year: PropTypes.number,
  disposer: foreignPropType,
  approved: PropTypes.bool,
  note: PropTypes.string,
  fond: foreignPropType,
  plan_kind: PropTypes.number,
  disposer_in_header: PropTypes.bool,
  comment: PropTypes.string, // автоматическое примечание
  comment_of_author: PropTypes.string, // Примечания пользователя
  only_year: PropTypes.bool,
  vt4br: PropTypes.bool,
  is_credit: PropTypes.bool,

  doc_revenue: foreignPropType,
  doc_source: foreignPropType,

  amount: PropTypes.number,
  amount_br: PropTypes.number,
};

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape(dataPropTypes).isRequired,
  fields: PropTypes.shape(getFieldsPropTypes(dataPropTypes)).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onExecute: PropTypes.func.isRequired,
  onCancelExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.doc.revenueplan.backendURL)(Editor);
