import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortUp, faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { StyledTH } from './styles';

const HeaderRow = ({
  cols, isHierarhical, onClick, ordering,
}) => (
  <tr>
    { (isHierarhical) && (
    <StyledTH>&nbsp;</StyledTH>
    )}
    {cols.map((col) => (
      <StyledTH
        key={col.name}
        onClick={(e) => (col.orderable ? onClick(e, col.name) : null)}
        orderable={col.orderable}
      >
        <span>
          {col.label || col.name || 'Без назви'}
        </span>
        {ordering.column === col.name && (
        <FontAwesomeIcon
          icon={ordering.isAscending ? faSortUp : faSortDown}
          pull="right"
        />
        )}
      </StyledTH>

    ))}
  </tr>
);

HeaderRow.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      orderable: PropTypes.bool,
    }),
  ).isRequired,
  isHierarhical: PropTypes.bool,
  onClick: PropTypes.func,
  ordering: PropTypes.shape({
    column: PropTypes.string,
    isAscending: PropTypes.bool,
  }),
};

HeaderRow.defaultProps = {
  isHierarhical: false,
  onClick: () => null,
  ordering: {
    column: '',
    isAscending: true,
  },
};

export default memo(HeaderRow);
