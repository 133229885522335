import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {
  getTPController, TPGrid, TPCommandPanel,
} from '../../../../../basicEditor';
import Header from './header';
import Row from './row';
import { ExpandButton, CollapseButton } from '../../../../../../components/Styled/Buttons';
import { StyledRightGroupCP } from '../../../../../../components/Styled/Misc';
import { fieldsPropTypes, errorsPropTypes, dataPropTypes } from './propTypes';

const StyledGrid = Styled(TPGrid)`
  grid-template-areas: 
    "kvk3     amount_total_2 amount_total_2    amount_total_1 amount_total_1    amount_total0 amount_total0    amount_total1 amount_total1    amount_total2 amount_total2"
    "kvk3     amount_zf_2    amount_sf_2       amount_zf_1    amount_sf_1       amount_zf0    amount_sf0       amount_zf1    amount_sf1       amount_zf2    amount_sf2"
    "activity amount_zf_2    amount_br_total_2 amount_zf_1    amount_br_total_1 amount_zf0    amount_br_total0 amount_zf1    amount_br_total1 amount_zf2    amount_br_total2"
    ;
`;

const Activity = ({
  onAddRow, onChangeRow, onDeleteSelectedRows, onActivateRow,
  fields, data, errors, activeRow, selectedRows,
  year,
  onHide, onExpand, expanded,
}) => (
  <>
    <TPCommandPanel onAdd={onAddRow} onDelete={onDeleteSelectedRows} selectedRows={selectedRows}>
      <StyledRightGroupCP>
        {expanded ? (
          <CollapseButton onClick={onHide} />
        ) : (
          <ExpandButton onClick={onExpand} />
        )}
      </StyledRightGroupCP>
    </TPCommandPanel>
    <StyledGrid>
      <Header fields={fields} year={year} />
      {data.map((row, rowIndex) => (
        <Row
          // eslint-disable-next-line
          key={rowIndex}
          rowIndex={rowIndex}
          active={activeRow === rowIndex}
          selected={selectedRows.includes(rowIndex)}
          onActivateRow={onActivateRow}
          onChangeRow={onChangeRow}
          row={row}
          fields={fields}
          errors={errors}
        />
      ))}
    </StyledGrid>
  </>
);

Activity.propTypes = {
  onAddRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  onDeleteSelectedRows: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: PropTypes.shape(fieldsPropTypes).isRequired,
  errors: PropTypes.shape(errorsPropTypes),
  activeRow: PropTypes.number,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  year: PropTypes.number.isRequired,
  onActivateRow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};

Activity.defaultProps = {
  errors: {},
  activeRow: null,
  selectedRows: [],
};

export default getTPController(Activity);
