import React from 'react';

import ReportsPanel from '../../reportsPanel';

const Rep = () => (
  <ReportsPanel
    schemaId="allplans"
  />

);

export default Rep;
