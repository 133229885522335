import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTabContainer = styled.div`
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  flex: 0 0 auto; 
`;

const StyledTab = styled.div`
  background: ${({ active }) => active && 'radial-gradient(farthest-side ellipse at top left,white,#cedbebb8)'};
  color: #605b5b;
  font-weight: ${({ active }) => (active ? '700' : '500')};
  // background: ${({ active }) => (active ? 'linear-gradient(0deg,rgba(204,230,255,0.88),rgba(255,255,255,0.88)),#4281C9' : 'inherit')};
  border-radius: 0.3rem 0.3rem 0 0;
  float: left;
  border: ${({ active }) => (active ? '1px solid #ccc' : '1px solid transparent')};
  border-bottom: none;
  outline: none;
  cursor: pointer;
  padding: 8px 15px;
  // transition: 0.3s;
  ${({ disabled }) => disabled && 'filter: grayscale(1) opacity(.275);'}
  &:hover {
    background: radial-gradient(farthest-side ellipse at top left,white,#cedbeb75);
    border-radius: 0.3rem 0.3rem 0 0;
    // background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), rgb(0, 143, 33);
  };
  
   @media (max-width: 600px) {
   padding: 5px;
   font-size: 0.9em;
   }
`;

const StyledTabContent = styled.div`
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-top: none;
  background: white;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  // height: 59vh;
`;

class Tab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  render() {
    const { panes } = this.props;
    const { activeIndex } = this.state;
    return (
      <TabContainer>
        <StyledTabContainer>
          {panes.map((pane, i) => (
            <StyledTab
              active={i === activeIndex}
              key={pane.menuItem}
              disabled={pane.disabled}
              onClick={() => !pane.disabled && this.setState({ activeIndex: i })}
            >
              {pane.menuItem}
            </StyledTab>
          ))}
        </StyledTabContainer>
        <StyledTabContent>
          {panes[activeIndex].render()}
        </StyledTabContent>
      </TabContainer>
    );
  }
}
Tab.propTypes = {
  panes: PropTypes.arrayOf(PropTypes.shape({
    menuItem: PropTypes.string,
    disabled: PropTypes.bool,
    render: PropTypes.func,
  })),
};

Tab.defaultProps = {
  panes: [],
};


export default Tab;
