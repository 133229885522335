import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const kekvProps = tableHooks.useItemInputProps(
    'kekv', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountZf0Props = tableHooks.useNumberInputProps(
    'amount_zf0', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountZf1Props = tableHooks.useNumberInputProps(
    'amount_zf_1',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountZf2Props = tableHooks.useNumberInputProps(
    'amount_zf_2',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountBrTotal0Props = tableHooks.useNumberInputProps(
    'amount_br_total0',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountBrTotal1Props = tableHooks.useNumberInputProps(
    'amount_br_total_1',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountBrTotal2Props = tableHooks.useNumberInputProps(
    'amount_br_total_2',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSf0Props = tableHooks.useNumberInputProps(
    'amount_sf0',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSf1Props = tableHooks.useNumberInputProps(
    'amount_sf_1',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountSf2Props = tableHooks.useNumberInputProps(
    'amount_sf_2',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountTotal0Props = tableHooks.useNumberInputProps(
    'amount_total0',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountTotal1Props = tableHooks.useNumberInputProps(
    'amount_total_1',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountTotal2Props = tableHooks.useNumberInputProps(
    'amount_total_2',
    row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  amountTotal0Props.value = (amountZf0Props.value || 0) + (amountSf0Props.value || 0);
  amountTotal2Props.value = (amountZf2Props.value || 0) + (amountSf2Props.value || 0);
  amountTotal1Props.value = (amountZf1Props.value || 0) + (amountSf1Props.value || 0);

  return (
    <Col>
      <Row className="w-100" noGutters>
        <Col sm={12}>
          <TabelRowCell column="kekv" highlighted={highlights.includes('kekv')}>
            <TableControls.ItemPicker
              {...kekvProps}
            />
          </TabelRowCell>
        </Col>
      </Row>
      <Row className="w-100" noGutters>
        <Col>
          <TabelRowCell column="amount_zf_2" highlighted={highlights.includes('amount_zf_2')}>
            <TableControls.NumberInput {...amountZf2Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_sf_2" highlighted={highlights.includes('amount_sf_2')}>
            <TableControls.NumberInput {...amountSf2Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_br_total_2" highlighted={highlights.includes('amount_br_total_2')}>
            <TableControls.NumberInput {...amountBrTotal2Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_zf_1" highlighted={highlights.includes('amount_zf_1')}>
            <TableControls.NumberInput {...amountZf1Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_sf_1" highlighted={highlights.includes('amount_sf_1')}>
            <TableControls.NumberInput {...amountSf1Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_br_total_1" highlighted={highlights.includes('amount_br_total_1')}>
            <TableControls.NumberInput {...amountBrTotal1Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_zf_0" highlighted={highlights.includes('amount_zf_0')}>
            <TableControls.NumberInput {...amountZf0Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_sf_0" highlighted={highlights.includes('amount_sf_0')}>
            <TableControls.NumberInput {...amountSf0Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_br_total_0" highlighted={highlights.includes('amount_br_total_0')}>
            <TableControls.NumberInput {...amountBrTotal0Props} />
          </TabelRowCell>
        </Col>
      </Row>
      <Row className="w-100" noGutters>
        <Col>
          <TabelRowCell column="amount_total_2" highlighted={highlights.includes('amount_total_2')}>
            <TableControls.NumberInput {...amountTotal2Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_total_1" highlighted={highlights.includes('amount_total_1')}>
            <TableControls.NumberInput {...amountTotal1Props} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_total_0" highlighted={highlights.includes('amount_total_0')}>
            <TableControls.NumberInput {...amountTotal0Props} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
