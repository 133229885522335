const frontendURL = '/cat/user/';
const backendURL = '/api/auth/profile/';
const name = 'Користувачі';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
