import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { StyledCellContainer, StyledCellItemHeader, StyledTableHeaderCell } from '../../styles';

const DDColumn = ({ id, value, values, onChange, label }) => {
  const variant = useMemo(
    () => (value.length ? 'primary' : 'secondary'),
    [value.length],
  );
  return (
    <StyledTableHeaderCell>
      <StyledCellContainer>
        <StyledCellItemHeader>
          <Dropdown>
            <Dropdown.Toggle id={id} className="w-100" variant={variant}>
              {label}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {values.map((v) => (
                <Dropdown.Item
                  eventkey={v.value}
                  active={v.selected}
                  onClick={() => onChange(v.value)}
                >
                  {v.display_name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </StyledCellItemHeader>
      </StyledCellContainer>
    </StyledTableHeaderCell>

  );
};

const valuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

DDColumn.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(valuePropType),
  values: PropTypes.shape({
    value: valuePropType,
    display_name: PropTypes.string,
    selected: PropTypes.bool,
  }),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

DDColumn.defaultProps = {
  value: [],
  values: [],
}

export default DDColumn
