import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';


const TextInput = forwardRef(({
  value, disabled, readOnly,
  onClick, onFocus, maxLength, onChange, rows, id,
  className,
}, ref) => {
  const changeHandler = (e) => {
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      onChange(e, newValue);
    }
  };

  return (
    <InputGroup className={className}>
      <FormControl
        as="textarea"
        id={id}
        value={value || ''}
        onChange={changeHandler}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClick}
        onFocus={onFocus}
        rows={rows}
        ref={ref}
      />
    </InputGroup>
  );
});

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  rows: PropTypes.number,
  className: PropTypes.string,
};

TextInput.defaultProps = {
  value: '',
  disabled: false,
  readOnly: false,
  maxLength: 0,
  onClick: null,
  onFocus: null,
  rows: 3,
  className: null,
};


export default TextInput;
