const frontendURL = '/cat/targetprogram/';
const backendURL = '/api/references/reftargetprogram/';
const name = 'Цільові програми';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
