import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {
  getTPController, TPGrid, TPCommandPanel,
} from '../../../../../basicEditor';
import Header from './header';
import Row from './row';
import { dataPropTypes, errorsPropTypes, fieldsPropTypes } from './propTypes';

const StyledGrid = Styled(TPGrid)`
  grid-template-areas: 
    "indicator     kvk3       general_indicator_code amount_total_2 amount_total_2 amount_total_1 amount_total_1    amount_total0 amount_total0    amount_total1 amount_total1    amount_total2 amount_total2"
    "gender_status gender_age gender_segment         amount_zf_2    amount_sf_2    amount_zf_1    amount_sf_1       amount_zf0    amount_sf0       amount_zf1    amount_sf1       amount_zf2    amount_sf2"
    ;
`;

const Indicators = ({
  onAddRow, onChangeRow, onDeleteSelectedRows, onActivateRow,
  fields, data, errors, activeRow, selectedRows,
  year,
}) => (
  <>
    <TPCommandPanel onAdd={onAddRow} onDelete={onDeleteSelectedRows} selectedRows={selectedRows} />
    <StyledGrid>
      <Header fields={fields} year={year} />
      {data.map((row, rowIndex) => (
        <Row
          /* eslint-disable-next-line react/no-array-index-key */
          key={rowIndex}
          rowIndex={rowIndex}
          active={activeRow === rowIndex}
          selected={selectedRows.includes(rowIndex)}
          onActivateRow={onActivateRow}
          onChangeRow={onChangeRow}
          row={row}
          fields={fields}
          errors={errors}
        />
      ))}
    </StyledGrid>
  </>
);

Indicators.propTypes = {
  onAddRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  onDeleteSelectedRows: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: PropTypes.shape(fieldsPropTypes).isRequired,
  errors: PropTypes.shape(errorsPropTypes).isRequired,
  activeRow: PropTypes.number,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  onActivateRow: PropTypes.func.isRequired,
  year: PropTypes.number,
};

Indicators.defaultProps = {
  activeRow: null,
  selectedRows: [],
  year: new Date().getFullYear(),
};

export default getTPController(Indicators);
// TODO: Добавить фильтр по Activity
