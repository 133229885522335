import React from 'react';
import md from '../../../../../meta/cat/kekv';
import ListerFactory from '../../../../newLister';
import { CatTableListerFactory } from '../../../../newLister/tableComponent';
import ListerSelector from '../_classifierLister/lister_selector';
import ClassifierRow from '../_classifierLister/classifierRow';

const StdLister = ListerFactory({
  backendURL: md.backendURL,
  viewType: 'selector',
})(CatTableListerFactory({
  RowComponent: ClassifierRow,
}));

const Lister = (props) => (
  <ListerSelector {...props}>
    <StdLister />
  </ListerSelector>
);

export default Lister;
