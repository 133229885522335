import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import meta from '../../../../meta';
import HistoricalValues from './history';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';

const Editor = ({ id, onClose, onSave }) => {
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors,

  } = useEditor({
    backendURL: meta.cat.kfb.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <CatCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
      />
      <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <HistoricalValues
              id={id}
              data={data}
              actions={actions}
              fields={fields}
            />
          </TabContainer>
        </Tab>
      </LabelTab>
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
