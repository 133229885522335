import React from 'react';
import PropTypes from 'prop-types';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const IntoButton = ({
  content, onClick, disabled, title,
}) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faLevelDownAlt}
    title={title}
  />
);

IntoButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

IntoButton.defaultProps = {
  onClick: () => null,
  content: '',
  disabled: false,
  title: '',
};

export default IntoButton;
