import React from 'react';
import { Card } from 'react-bootstrap';
import { StyledLinkItem } from './pageItems';

const StyledLink = (props) => (
  <StyledLinkItem
    color="#426f99"
    hover="#0469ce"
    {...props}
  />
);

const Services = () => (
  <Card>
    <Card.Header>
      Робота з Logika
    </Card.Header>
    <Card.Body>
      <StyledLink to="/srv/logicaLogin/">Авторизація в Logika</StyledLink>
      <StyledLink to="/srv/logicaImportTreasureFiles/">Завантаження казначейських файлів</StyledLink>
    </Card.Body>

    <Card.Header>
      Завантаження файлів *.dbf
    </Card.Header>
    <Card.Body>
      <StyledLink to="/srv/exchangePlan/">Завантажити виписку планів</StyledLink>
      <StyledLink to="/srv/exchangeIncome/">Завантажити виписку доходів</StyledLink>
      <StyledLink to="/srv/exchangeExpenses/">Завантажити виписку видатків</StyledLink>
      <StyledLink to="/srv/exchangeSocialPayment/">Завантажити виписку соціальних виплат</StyledLink>
      <StyledLink to="/srv/treasureExport/">Експорт казначейських файлів</StyledLink>
    </Card.Body>

    <Card.Header>
      Доступ до системи
    </Card.Header>
    <Card.Body>
      <StyledLink to="/srv/roleManagement/">Управління Ролями</StyledLink>
      <StyledLink to="/srv/groupManagement/">Управління Групами</StyledLink>
    </Card.Body>

    <Card.Header>
      Звітність
    </Card.Header>
    <Card.Body>
      <StyledLink to="/srv/skd-master/">Конструктор СКД (Система компоновки даних)</StyledLink>
    </Card.Body>

  </Card>
);

// const { currentUser } = useContext(AppContext);
//
// const items = (header) => (Object.keys(meta.srv[header].items)
//   .filter((srvName) => !meta.srv[header].items[srvName].getVisibility
//             || meta.srv[header].items[srvName].getVisibility(currentUser))
//   .map((srvName) => ({ ...meta.srv[header].items[srvName], id: srvName })));
//
// return (
//   <div style={{ zIndex: 9999, marginLeft: 'auto', marginRight: 'auto' }}>
//     <CardColumns>
//       {Object.keys(meta.srv).map((header) => (
//         <Card
//           key={header}
//         >
//           <Card.Header>
//             {meta.srv[header].name}
//           </Card.Header>
//           <Card.Body>
//             {items(header).map((item) => (
//               <StyledLinkItem
//                 key={item.id}
//                 to={item.frontendURL}
//                 color="#426f99"
//                 hover="#0469ce"
//               >
//                 {item.name}
//               </StyledLinkItem>
//             ))}
//           </Card.Body>
//         </Card>
//       ))}
//     </CardColumns>
//   </div>
// );
// };

export default Services;
