import budget from './budget';
import disposers from './disposers';
import kvk from './kvk';
import fond from './fond';
import fonditem from './fonditem';
import kbp from './kbp';
import kbp7 from './kbp7';
import kbp7item from './kbp7item';
import kfk from './kfk';
import kekv from './kekv';
import kekvitem from './kekvitem';
import kdb from './kdb';
import kfb from './kfb';
import infosource from './infosource';
import genderage from './genderage';
import genderstatus from './genderstatus';
import gendersegment from './gendersegment';
import kvk3 from './kvk3';
import minfinactivity from './minfinactivity';
import minfinindicator from './minfinindicator';
import structureoflaborcosts from './structureoflaborcosts';
import workercategory from './workercategory';
import targetprogram from './targetprogram';
import explanation from './explanation';
import law from './law';
import authoritysignvariant from './authoritysignvariant';
import lawrealisebasis from './lawrealisebasis';
import lawregulation from './lawregulation';
import sourceofcoverage from './sourceofcoverage';
import documentbasis from './documentbasis';
import templateddocumentbasis from './templateddocumentbasis';
import executor from './executor';
import treasurebank from './treasurebank';
import contragentbank from './contragentbank';
import treasureaccount from './treasureaccount';
import conrtragent from './conrtragent';
import disposerscontragent from './disposerscontragent';
import contragentaccount from './contragentaccount';
import note from './note';
import kindwork from './kindwork';
import bringdocument from './bringdocument';
import remarknote from './remarknote';
import documentnote from './documentnote';
import user from './user';
import staff from './staff';
import publicpolicytarget from './publicpolicytarget';
import task from './task';
import socialpayment from './socialPayment';
import bfnAmount from './bfnAmount';
import bfnGroupTypes from './bfnGroupTypes';
import bfnGroupAge from './bfnGroupAge';
import bfnGroupFullness from './bfnGroupFullness';
import bfnGoalFondAmount from './bfnGoalFondAmount';
import typesofentrysf from './typesofentrysf';
import responsiblePerson from './responsiblePerson';
import bfnGroupName from './bfnGroupName';
import bfnPeriod from './bfnPeriod';
import bfnBanDoc from './bfnBanDoc';
import shortKEKV from './shortkekv';

const catalogs = {
  budget,
  disposers,
  kvk,
  fond,
  fonditem,
  kbp,
  kbp7,
  kbp7item,
  kfk,
  kekv,
  kekvitem,
  kdb,
  kfb,
  infosource,
  genderage,
  genderstatus,
  gendersegment,
  kvk3,
  minfinactivity,
  minfinindicator,
  structureoflaborcosts,
  workercategory,
  targetprogram,
  explanation,
  law,
  authoritysignvariant,
  lawrealisebasis,
  lawregulation,
  sourceofcoverage,
  documentbasis,
  templateddocumentbasis,
  executor,
  treasurebank,
  contragentbank,
  treasureaccount,
  conrtragent,
  disposerscontragent,
  contragentaccount,
  note,
  kindwork,
  bringdocument,
  remarknote,
  documentnote,
  user,
  staff,
  task,
  publicpolicytarget,
  socialpayment,
  bfnAmount,
  bfnGroupTypes,
  bfnGroupAge,
  bfnGroupFullness,
  bfnGoalFondAmount,
  typesofentrysf,
  bfnGroupName,
  bfnPeriod,
  responsiblePerson,
  bfnBanDoc,
  shortKEKV,
};

export default catalogs;
