import React, {
  useCallback, useEffect, useState, useContext,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button, InputGroup, Row,
} from 'react-bootstrap';
// import { compose } from 'recompose';
// import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../providers/authProvider';
import { EditorControls, EditorItemPicker } from '../../basicEditor/editorControls';
import { CommandPanel } from '../../../components/bootStrap';
import { foreignPropType } from '../../../constants/backend/propTypes';

const Group = styled(InputGroup)`
  margin-bottom: 10px;
  div {
    margin-bottom: 0!important;
  }
  :last-child {
    width: 100%;
    }
`;

const SAVE_MODES = {
  forMe: 0,
  forAuthority: 1,
  forBudget: 2,
  forAll: 3,
};

const SaveReport = ({
  onHide, reportData, isNew, onSave,
}) => {
  const { currentUser } = useContext(AppContext);

  const [newReportData, setReportData] = useState({
    name: '',
    description: '',
    mode: SAVE_MODES.forMe,
    authority: null,
    budget: null,
    user: null,
    title: null,
  });
  const isReadOnly = !isNew && !currentUser.is_superuser
    && reportData.author && reportData.author.id !== currentUser.profile.id;

  useEffect(() => {
    let newMode = SAVE_MODES.forAll;
    if (reportData.user !== null) {
      newMode = SAVE_MODES.forMe;
    } else if (reportData.authority !== null) {
      newMode = SAVE_MODES.forAuthority;
    } else if (reportData.budget !== null) {
      newMode = SAVE_MODES.forBudget;
    } else if (!currentUser.is_superuser) newMode = SAVE_MODES.forMe;
    setReportData((oldParam) => ({
      ...oldParam,
      name: reportData.name,
      description: reportData.description,
      mode: newMode,
      authority: reportData.authority,
      budget: reportData.budget,
      user: reportData.user,
      avatarImg: reportData.avatarImg,
    }));
  },
  [currentUser.is_superuser, reportData.description, reportData.name, reportData.authority, reportData.budget, reportData.user, reportData.avatarImg]);

  const saveHandler = useCallback(
    () => {
      if (!currentUser.profile) throw new Error('У пользователя нет профиля!');
      const bau = currentUser.is_superuser ? {
        budget: newReportData.mode === SAVE_MODES.forBudget ? newReportData.budget : null,
        authority: newReportData.mode === SAVE_MODES.forAuthority ? newReportData.authority : null,
        settings: { title: { display: newReportData.title } },
        user: newReportData.mode === SAVE_MODES.forMe ? newReportData.user : null,
      } : {
        budget: newReportData.mode === SAVE_MODES.forBudget && currentUser.profile.budget
          ? currentUser.profile.budget.id
          : null,
        authority:
          newReportData.mode === SAVE_MODES.forAuthority && currentUser.profile.authority
            ? currentUser.profile.authority.id
            : null,
        user: newReportData.mode === SAVE_MODES.forMe ? currentUser.profile.id : null,
      };
      const saveData = {
        name: newReportData.name,
        description: newReportData.description,
        schema_id: reportData.schema.id,
        avatarImg: reportData.avatarImg,
        ...bau,
      };
      onSave(
        saveData,
        isNew,
      );
    },
    [currentUser.is_superuser, currentUser.profile, isNew, newReportData.authority, newReportData.budget, newReportData.description, newReportData.mode, newReportData.name, newReportData.title, newReportData.user, onSave, reportData.avatarImg, reportData.schema.id],
  );

  return (
    <div
      style={{
        padding: '20px',
      }}
    >
      <EditorControls.StringInput
        controlId={`name_${isNew}`}
        label="Назва"
        value={newReportData.name}
        onChange={(e, v) => setReportData((oldV) => ({
          ...oldV,
          name: v,
        }))}
      />
      <EditorControls.TextInput
        controlId={`description_${isNew}`}
        label="Опис"
        value={newReportData.description}
        onChange={(e, v) => setReportData((oldV) => ({
          ...oldV,
          description: v,
        }))}
      />
      <h5>Додаткові налаштування</h5>
      <hr />
      <EditorControls.StringInput
        label="Заголовок"
        value={newReportData.title}
        onChange={(e, v) => setReportData((oldV) => ({
          ...oldV,
          title: v,
        }))}
        readOnly={isReadOnly}
      />
      <Row>
        <Group>
          <InputGroup.Prepend>
            <InputGroup.Radio
              checked={newReportData.mode === SAVE_MODES.forAll}
              disabled={!currentUser.is_superuser}
              onChange={() => setReportData(({ ...r }) => ({ ...r, mode: SAVE_MODES.forAll }))}
            />
            <InputGroup.Text>Для всіх</InputGroup.Text>
          </InputGroup.Prepend>
        </Group>
        <Group>
          <InputGroup.Prepend>
            <InputGroup.Radio
              checked={newReportData.mode === SAVE_MODES.forBudget}
              onChange={() => setReportData(({ ...r }) => ({ ...r, mode: SAVE_MODES.forBudget }))}
            />
            <InputGroup.Text>{currentUser.is_superuser ? 'Для обраного бюджету' : 'Для мого міста'}</InputGroup.Text>
          </InputGroup.Prepend>
          {currentUser.is_superuser && (
          <EditorItemPicker
            controlId={`budget_selector_${isNew}`}
            required={newReportData.mode === SAVE_MODES.forBudget}
            value={newReportData.budget}
            onChange={(e, v) => setReportData(({ ...r }) => ({ ...r, budget: v }))}
            disabled={newReportData.mode !== SAVE_MODES.forBudget}
            modelName="budget"
            modelType="cat"
          />
          )}
        </Group>
        <Group>
          <InputGroup.Prepend>
            <InputGroup.Radio
              checked={newReportData.mode === SAVE_MODES.forAuthority}
              onChange={() => setReportData(
                ({ ...r }) => ({ ...r, mode: SAVE_MODES.forAuthority }),
              )}
            />
            <InputGroup.Text>{currentUser.is_superuser ? 'Для обраної установи' : 'Для моєі установи'}</InputGroup.Text>
          </InputGroup.Prepend>
          {currentUser.is_superuser && (
          <EditorItemPicker
            controlId={`authority_selector_${isNew}`}
            required={newReportData.mode === SAVE_MODES.forAuthority}
            value={newReportData.authority}
            onChange={(e, v) => setReportData(({ ...r }) => ({ ...r, authority: v }))}
            disabled={newReportData.mode !== SAVE_MODES.forAuthority}
            modelName="disposers"
            modelType="cat"
          />
          )}
        </Group>
        <Group>
          <InputGroup.Prepend>
            <InputGroup.Radio
              checked={newReportData.mode === SAVE_MODES.forMe}
              onChange={() => setReportData(({ ...r }) => ({ ...r, mode: SAVE_MODES.forMe }))}
            />
            <InputGroup.Text>{currentUser.is_superuser ? 'Для обраного користувача' : 'Для мене'}</InputGroup.Text>
          </InputGroup.Prepend>
          {currentUser.is_superuser && (
          <EditorItemPicker
            controlId={`user_selector_${isNew}`}
            required={newReportData.mode === SAVE_MODES.forMe}
            value={newReportData.user}
            onChange={(e, v) => setReportData(({ ...r }) => ({ ...r, user: v }))}
            disabled={newReportData.mode !== SAVE_MODES.forMe}
            modelName="user"
            modelType="cat"
          />
          )}
        </Group>
      </Row>
      <CommandPanel style={{ justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button
          disabled={isReadOnly}
          onClick={() => {
            saveHandler();
            onHide();
          }}
        >
          Зберегти
        </Button>
        <Button onClick={() => {
          onHide();
        }}
        >
          Закрити
        </Button>
      </CommandPanel>
    </div>

  );
};

SaveReport.propTypes = {
  onHide: PropTypes.func.isRequired,
  reportData: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    avatarImg: PropTypes.string,
    schema: PropTypes.shape({
      id: PropTypes.number,
    }),
    authority: foreignPropType,
    budget: foreignPropType,
    user: foreignPropType,
    author: foreignPropType,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

SaveReport.defaultProps = {
  isNew: false,
};

export default SaveReport;
