import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tab } from 'react-bootstrap';
import meta from '../../../../meta';
import HistoricalValues from './history';
import { TabContainer, LabelTab } from '../../../basicEditor/tpController/styles';
import useEditor from '../../../newEditor/hook/editor';
import { CatCommandPanel } from '../../../newEditor/commandPanels';
import EditorContainer from '../../../newEditor/editorContainer';
import { EditorControls, editorHooks, dataHooks } from '../../../basicEditor/editorControls';
import { AppContext } from '../../../../providers/authProvider';

const Editor = ({ id, onClose, onSave }) => {
  const {
    data, actions, fields, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, fieldErrors,

  } = useEditor({
    backendURL: meta.cat.fonditem.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });
  const { currentUser } = useContext(AppContext);

  const classifier = useMemo(
    () => (data && data.history ? data.history.fond : null),
    [data],
  );

  const ownerProps = editorHooks.useItemInputProps('owner', data, fields, fieldErrors, actions.onChange, !currentUser.is_superuser);
  const levelUpProps = dataHooks.useLevelUpProps({
    name: 'levelup', data, fields, errors: fieldErrors, onChange: actions.onChange, owner: data.owner, classifier,
  });
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, fieldErrors, actions.onChange, false);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', data, fields, fieldErrors, actions.onChange, false);

  const parentFilter = useMemo(
    () => ({
      owner: data.owner ? data.owner.id : null,
      is_group: true,
    }),
    [data.owner],
  );
  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <CatCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
      />
      <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="general" title="Основні властивості">
          <TabContainer>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...ownerProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...levelUpProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...parentProps} filter={parentFilter} />
              </Col>
              <Col sm={2} className="d-flex align-items-end justify-content-end">
                <EditorControls.CheckboxInput {...isGroupProps} />
              </Col>
            </Row>
            <HistoricalValues
              id={id}
              data={data}
              actions={actions}
              fields={fields}
            />
          </TabContainer>
        </Tab>
      </LabelTab>
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
