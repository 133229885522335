import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const Header = ({
  fields, activeCol, highlights, onToggleHighlght, activeObjectsBuilding,
  disposerInHeader, ABVisible, isComposeSettingsChecked, totals,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col>
      {!disposerInHeader
          && (
            <Row noGutters>
              <Col
                xl={4}
                sm={12}
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'disposer'}
                  highlighted={highlights.includes('disposer')}
                  onToggleHighlght={() => onToggleHighlght('disposer')}
                >
                  {fields.disposer.label}
                </TableHeaderCell>
              </Col>
              <Col
                xl={8}
                sm={12}
                className="border-right"
              >
                <TableHeaderCell
                  className="text-center text-xl-left"
                  active={activeCol === 'disposer_acc'}
                  highlighted={highlights.includes('disposer_acc')}
                  onToggleHighlght={() => onToggleHighlght('disposer_acc')}
                >
                  {fields.disposer_acc.label}
                </TableHeaderCell>
              </Col>
            </Row>
          )}
      <Row noGutters>
        <Col
          xl={4}
          sm={12}
          className="d-flex flex-column border-right"
        >
          <Row noGutters>
            <Col>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'kbp7_item'}
                highlighted={highlights.includes('kbp7_item')}
                onToggleHighlght={() => onToggleHighlght('kbp7_item')}
              >
                {fields.kbp7_item.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'kekv_item'}
                highlighted={highlights.includes('kekv_item')}
                onToggleHighlght={() => onToggleHighlght('kekv_item')}
              >
                {fields.kekv_item.label}
              </TableHeaderCell>
              {disposerInHeader
          && (
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'disposer_acc'}
              highlighted={highlights.includes('disposer_acc')}
              onToggleHighlght={() => onToggleHighlght('disposer_acc')}
            >
              {fields.disposer_acc.label}
            </TableHeaderCell>
          )}
            </Col>
          </Row>
        </Col>

        {activeObjectsBuilding
    && (
      <Col xl={2} sm={12} className="border-right">
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'buildObgect'}
          highlighted={highlights.includes('buildObgect')}
          onToggleHighlght={() => onToggleHighlght('buildObgect')}
        >
          {fields.buildObgect.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'typeRepair'}
          highlighted={highlights.includes('typeRepair')}
          onToggleHighlght={() => onToggleHighlght('typeRepair')}
        >
          {fields.typeRepair.label}
        </TableHeaderCell>
      </Col>
    )}

        <Col xl={activeObjectsBuilding ? 6 : 8} sm={12}>
          <Row noGutters>

            <Col
              sm={12}
              lg={(ABVisible || isComposeSettingsChecked)
                ? 3 : 4}
              className="border-right"
            >
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'plan_year_amount'}
                highlighted={highlights.includes('plan_year_amount')}
                onToggleHighlght={() => onToggleHighlght('plan_year_amount')}
              >
                {fields.plan_year_amount.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'plan_period_amount'}
                highlighted={highlights.includes('plan_period_amount')}
                onToggleHighlght={() => onToggleHighlght('plan_period_amount')}
              >
                {fields.plan_period_amount.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'plan_month_amount'}
                highlighted={highlights.includes('plan_month_amount')}
                onToggleHighlght={() => onToggleHighlght('plan_month_amount')}
              >
                {fields.plan_month_amount.label}
              </TableHeaderCell>
            </Col>

            <Col
              className="border-right"
              sm={12}
              lg={(ABVisible || isComposeSettingsChecked)
                ? 3 : 4}
            >
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'fact_period_amount'}
                highlighted={highlights.includes('fact_period_amount')}
                onToggleHighlght={() => onToggleHighlght('fact_period_amount')}
              >
                {fields.fact_period_amount.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'balance'}
                highlighted={highlights.includes('balance')}
                onToggleHighlght={() => onToggleHighlght('balance')}
              >
                {fields.balance.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'fin_commitment_amount'}
                highlighted={highlights.includes('fin_commitment_amount')}
                onToggleHighlght={() => onToggleHighlght('fin_commitment_amount')}
              >
                {fields.fin_commitment_amount.label}
              </TableHeaderCell>
            </Col>

            {(ABVisible || isComposeSettingsChecked)
              && (
                <Col className="border-right" sm={12} lg={3}>
                  {ABVisible && (
                    <>
                      <TableHeaderCell
                        className="text-center text-xl-left"
                        active={activeCol === 'balanceRegAccounts'}
                        highlighted={highlights.includes('balanceRegAccounts')}
                        onToggleHighlght={() => onToggleHighlght('balanceRegAccounts')}
                      >
                        {fields.balanceRegAccounts.label}
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="text-center text-xl-left"
                        active={activeCol === 'balancePersAccounts'}
                        highlighted={highlights.includes('balancePersAccounts')}
                        onToggleHighlght={() => onToggleHighlght('balancePersAccounts')}
                      >
                        {fields.balancePersAccounts.label}
                      </TableHeaderCell>
                    </>
                  )}
                  {(ABVisible || isComposeSettingsChecked) && (
                    <TableHeaderCell
                      className="text-center text-xl-left"
                      active={activeCol === 'amount'}
                      title={totals.toLocaleString('uk', moneysStingOptions)}
                      highlighted={highlights.includes('amount')}
                      onToggleHighlght={() => onToggleHighlght('amount')}
                    >
                      {fields.amount.label}
                    </TableHeaderCell>
                  )}
                </Col>
              )}

            <Col
              className="border-right"
              sm={12}
              lg={(ABVisible || isComposeSettingsChecked)
                ? 3 : 4}
            >
              {!isComposeSettingsChecked && !ABVisible && (
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'amount'}
                highlighted={highlights.includes('amount')}
                title={totals.toLocaleString('uk', moneysStingOptions)}
                onToggleHighlght={() => onToggleHighlght('amount')}
              >
                {fields.amount.label}
              </TableHeaderCell>
              )}
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'purpose_of_payment'}
                highlighted={highlights.includes('purpose_of_payment')}
                onToggleHighlght={() => onToggleHighlght('purpose_of_payment')}
              >
                {fields.purpose_of_payment.label}
              </TableHeaderCell>
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'template'}
                highlighted={highlights.includes('template')}
                onToggleHighlght={() => onToggleHighlght('template')}
              >
                {fields.template.label}
              </TableHeaderCell>
              {isComposeSettingsChecked && (
              <TableHeaderCell
                className="text-center text-xl-left"
                active={activeCol === 'isReturn'}
                highlighted={highlights.includes('isReturn')}
                onToggleHighlght={() => onToggleHighlght('isReturn')}
              >
                {fields.isReturn.label}
              </TableHeaderCell>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col sm={12} xl={6} className="border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'order_distribution_remark'}
            highlighted={highlights.includes('order_distribution_remark')}
            onToggleHighlght={() => onToggleHighlght('order_distribution_remark')}
          >
            {fields.order_distribution_remark.label}
          </TableHeaderCell>
        </Col>
        <Col sm={12} xl={6} className="border-right">
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'fullremark'}
            highlighted={highlights.includes('fullremark')}
            onToggleHighlght={() => onToggleHighlght('fullremark')}
          >
            {fields.fullremark.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>

  </TableHeaderRow>

);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  totals: PropTypes.number,
  disposerInHeader: PropTypes.bool,
  activeObjectsBuilding: PropTypes.bool,
  ABVisible: PropTypes.bool,
  isComposeSettingsChecked: PropTypes.bool,
};

Header.defaultProps = {
  activeCol: null,
  disposerInHeader: false,
  activeObjectsBuilding: false,
  ABVisible: false,
  isComposeSettingsChecked: false,
  totals: 0,
};

export default Header;
