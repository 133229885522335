import React from 'react';
import PropTypes from 'prop-types';
import { StyledPrimaryPane, StyledSecondaryPane } from './styles';

const Pane = ({
  vertical, size, percentage, primary, children
}) => (primary ? (
  <StyledPrimaryPane>
    {children}
  </StyledPrimaryPane>
) : (
  <StyledSecondaryPane
    vertical={vertical}
    panelSize={size}
    percentage={percentage}
  >
    {children}
  </StyledSecondaryPane>
));

Pane.propTypes = {
  vertical: PropTypes.bool,
  primary: PropTypes.bool,
  size: PropTypes.number,
  percentage: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Pane.defaultProps = {
  vertical: false,
  primary: false,
  size: 0,
  percentage: false,
  children: []
};

export default Pane;
