const frontendURL = '/cat/note/';
const backendURL = '/api/references/refnote/';
const name = 'Класифікатор приміток';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
