import getLister from './getLister';
import GetTableComponent, { StandartTableComponent } from './TableComponent';
import { catListerCP, selectorCP, DocListerCP } from './commandPanels';

export default getLister;

export {
  GetTableComponent,
  StandartTableComponent,
};

const CommandPanels = {
  catCommandPanel: catListerCP,
  docCommandPanel: DocListerCP,
  selectCommandPanel: selectorCP,
};

export {
  CommandPanels,
};
