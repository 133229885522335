import React from 'react';
import PropTypes from 'prop-types';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const CopyRowButton = ({
  content, title, onClick, disabled,
}) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    title={title}
    icon={faCopy}
  />
);

CopyRowButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

CopyRowButton.defaultProps = {
  onClick: () => null,
  content: 'Скопіювати строку',
  disabled: false,
  title: '',
};

export default CopyRowButton;
