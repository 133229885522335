import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Collapse,
} from 'react-bootstrap';
import { HideButton } from '../Buttons';

const HidableContainer = ({ children }) => {
  const [expaneded, setExpaneded] = useState(true);

  return (
    <>
      <Card style={{ marginBottom: '15px', overflow: 'unset' }}>
        <Collapse in={expaneded} appear>
          <Card.Body style={{ padding: '10px' }}>{children}</Card.Body>
        </Collapse>
        <HideButton
          onClick={() => setExpaneded(!expaneded)}
        />
      </Card>
    </>
  );
};

HidableContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

HidableContainer.defaultProps = {
  children: null,
};

export default HidableContainer;
