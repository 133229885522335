const frontendURL = '/doc/expensesproject/';
const backendURL = '/api/planproject/expensesprojectheader/';
// const printURL = '';
const name = 'Проект видатків';

const instance = {
  frontendURL,
  backendURL,
  // printURL,
  name,
};

export default instance;
