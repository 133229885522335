const frontendURL = '/cat/shortkekv/';
const backendURL = '/api/references/refshortkekv/';
const name = 'Класифікація економічна скорочена (Скорочені КЕКВ)';

const instance = {
  frontendURL,
  backendURL,
  name,
  useHierarchyPagination: false,
};
export default instance;
