const frontendURL = '/doc/bfnAllocation/';
const backendURL = '/api/bfn/bfnallocation/';
const printURL = '';
const fillRowsProcessURI ='/api/bfn/bfnallocationfillrows/';
const name = 'Розподіл БФН по відповідному закладу дошкільної освіти';

const instance = {
  frontendURL,
  backendURL,
  printURL,
  fillRowsProcessURI,
  name,
};

export default instance;
