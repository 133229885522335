import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import withHistory, { HistoryCommandPanel } from '../../../historyValues';
import { FieldPropType, FieldErrorPropType } from '../../../basicEditor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';


const HistoryMinFinIndicator = ({
  data, fields, errors, onChange, onSave, onNew, onDelete, isNew, tryBlock,
  // eslint-disable-next-line camelcase
  history_values, onHistoryDropDown, history_loading, onChangeHistoryVariant,
}) => {
  const nameProps = editorHooks.useStringInputProps('name', data, fields, errors, onChange);
  const codeProps = editorHooks.useStringInputProps('code', data, fields, errors, onChange);
  const fullNameProps = editorHooks.useStringInputProps('fullname', data, fields, errors, onChange);
  const goalProps = editorHooks.useStringInputProps('goal', data, fields, errors, onChange);
  const indicatorTypeProps = editorHooks.useSelectorInputProps('indicator_type', data, fields, errors, onChange);
  const traitTypeProps = editorHooks.useSelectorInputProps('trait_type', data, fields, errors, onChange);
  const shownReportProps = editorHooks.useCheckboxInputProps('shown_report', data, fields, errors, onChange);
  const calcAvgProps = editorHooks.useCheckboxInputProps('calc_avg', data, fields, errors, onChange);
  const trendSignProps = editorHooks.useSelectorInputProps('trend_sign', data, fields, errors, onChange);
  const calculatedProps = editorHooks.useCheckboxInputProps('calculated', data, fields, errors, onChange);
  const formulaProps = editorHooks.useStringInputProps('formula', data, fields, errors, onChange);
  const infosourceProps = editorHooks.useItemInputProps('infosource', data, fields, errors, onChange);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, errors, onChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', data, fields, errors, onChange);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', data, fields, errors, onChange);

  return (
    <>
      <HistoryCommandPanel
        history_value={data.datefrom}
            // eslint-disable-next-line camelcase
        history_values={history_values}
        onChangeHistoryVariant={onChangeHistoryVariant}
        onHistoryDropDown={onHistoryDropDown}
            // eslint-disable-next-line camelcase
        history_loading={history_loading}
        onSave={onSave}
        onNew={onNew}
        onDelete={onDelete}
        isNew={isNew}
        tryBlock={tryBlock}
      />
      <Container fluid style={{ padding: '0' }}>
        <Row>
          <Col>
            <EditorControls.StringInput {...codeProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...nameProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput {...fullNameProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...goalProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.SelectorInput {...indicatorTypeProps} />
          </Col>
          <Col>
            <EditorControls.SelectorInput {...traitTypeProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.CheckboxInput {...shownReportProps} />
          </Col>
          <Col>
            <EditorControls.CheckboxInput {...calcAvgProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.SelectorInput {...trendSignProps} />
          </Col>
          <Col>
            <EditorControls.CheckboxInput {...calculatedProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.StringInput {...formulaProps} />
          </Col>
          <Col>
            <EditorControls.ItemPicker {...infosourceProps} modelType="cat" modelName="infosource" />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.ItemPicker {...parentProps} modelType="cat" modelName="minfinindicator" />
          </Col>
          <Col>
            <Col>
              <EditorControls.CheckboxInput {...isActiveProps} />
            </Col>
            <Col>
              <EditorControls.CheckboxInput {...isGroupProps} />
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  is_active: PropTypes.bool,
  fullname: PropTypes.string,
  goal: PropTypes.string,
  shown_report: PropTypes.bool,
  calc_avg: PropTypes.bool,
  format_string: PropTypes.string,
  calculated: PropTypes.bool,
  formula: PropTypes.string,
  infosource: PropTypes.shape({ }),
  parent: PropTypes.shape({ }),
  is_group: PropTypes.bool,
  indicator_type: PropTypes.number,
  trait_type: PropTypes.number,
  trend_sign: PropTypes.number,
};

const errorsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldErrorPropType }), {});
const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryMinFinIndicator.propTypes = {
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  errors: PropTypes.shape(
    errorsPropTypes,
  ).isRequired,
  fields: PropTypes.shape(
    fieldsPropTypes,
  ).isRequired,
  history_values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onHistoryDropDown: PropTypes.func.isRequired,
  history_loading: PropTypes.bool,
  onChangeHistoryVariant: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired, // Признак того что создается ноывй элемнт
  // ( не элемент истории а элемент классификатора)
  tryBlock: PropTypes.func,
};

HistoryMinFinIndicator.defaultProps = {
  history_loading: false,
  tryBlock: null,
};

export default withHistory(HistoryMinFinIndicator);
