import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Form, InputGroup, Button, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  background: rgba(0,0,0,.3);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SForm = styled(Form)`
  background: white;
  border-radius: .3rem;
  padding: 20px 20px;
  width: 400px;
  box-shadow: 0px 1px 30px -4px rgba(58,140,194,1);

  @media (max-width: 400px) {
    width: 100%;
  }
`;

const Header = styled.div`
  color: #0062cc;
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 10px;
`;

const LoginInput = styled(Form.Control).attrs(() => ({
  placeholder: 'Користувач',
  // autocomplete: 'username',
}))``;

const PasswdInput = styled(Form.Control).attrs(() => ({
  type: 'password',
  // autocomplete: 'current-password',
}))``;

const Login = ({ onLogin, errorMsg }) => {
  const [login, setLogin] = useState('');
  const [passwd, setPasswd] = useState('');
  const loginHandler = () => onLogin(login, passwd);
  return (
    <Container>
      <SForm onSubmit={loginHandler}>
        <Header>Вхід до системи</Header>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUser} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <LoginInput
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
          </InputGroup>
          <Form.Text className="text-muted">Якщо у Вас немає облікового запису, зверніться до служби підтримки</Form.Text>
        </Form.Group>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faKey} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <PasswdInput
              value={passwd}
              onChange={(e) => setPasswd(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
        { errorMsg && (
          <Form.Group>
            <hr />
            <Alert variant="danger">{errorMsg}</Alert>
          </Form.Group>
        )}
        <Button variant="primary" block onClick={loginHandler} type="submit">
          Увійти
        </Button>
      </SForm>
    </Container>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
};

Login.defaultProps = {
  errorMsg: null,
};

export default Login;
