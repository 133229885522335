import React, { useContext } from 'react';

import { WinManagerContext } from '../../providers/winManagerProvider';

const WindowsManager = () => {
  const { linkComponents, currentURL } = useContext(WinManagerContext);
  return (

    <div
      className="windows-manager"
    >
      {linkComponents.map((el) => {
        const Comp = el.component;
        return (
          <div
            key={`windowed-component-of-${el.url}`}
            className={el.url === currentURL ? '' : 'd-none'}
          >
            <Comp {...el.props} />
          </div>
        );
      })}
    </div>
  );
};

export { default as LinkComponent } from './link';

export default WindowsManager;
