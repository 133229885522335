import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import StringInput from './stringInput';
import DateInput from './dates/dateInput';
import Selector from './selector';
import NumberInput from './numberInput';
import { ItemPicker } from './itemField';
import { foreignPropType } from '../../constants/backend/propTypes';

const UnknowTypeComponent = () => (
  <Badge variant="danger">Невідомий тип параметру</Badge>
);

const MultiVaueInput = forwardRef(({
  value, disabled, readOnly,
  onClick, onFocus, onChange, id,
  ctype, resource,
  errored,
}, ref) => {
  const ParamsComponent = useMemo(() => {
    if (ctype === 'String') {
      return StringInput;
    }
    if (ctype === 'Date') {
      return DateInput;
    }
    if (ctype === 'Boolean') {
      return Selector;
    }
    if (ctype === 'Integer') {
      return NumberInput;
    }
    if (ctype === 'Decimal') {
      return NumberInput;
    }
    if (ctype === 'NestedObject' && resource) {
      // eslint-disable-next-line import/no-cycle
      return ItemPicker;
    }
    return UnknowTypeComponent;
  }, [ctype, resource]);

  const fcProps = useMemo(() => {
    const globalProps = {
      id,
      value,
      onChange,
      disabled,
      readOnly,
      onClick,
      onFocus,
      errored,
    };
    if (ctype === 'Boolean') {
      return {
        ...globalProps,
        values: [
          { value: true, display_name: 'Так' },
          { value: false, display_name: 'Ні' },
        ],
      };
    }
    if (ctype === 'NestedObject' && resource) {
      return {
        ...globalProps,
        backend: resource,
        canErase: true,
      };
    }
    return globalProps;
  }, [ctype, disabled, errored, id, onChange, onClick, onFocus, readOnly, resource, value]);
  return (
    <ParamsComponent
      ref={ref}
      {...fcProps}
    />
  );
});

MultiVaueInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.number, foreignPropType,
  ]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  errored: PropTypes.bool,
  ctype: PropTypes.oneOf(['String', 'Date', 'Boolean', 'Integer', 'Decimal', 'NestedObject']).isRequired,
  resource: PropTypes.string,
};

MultiVaueInput.defaultProps = {
  value: null,
  disabled: false,
  readOnly: false,
  onClick: null,
  onFocus: null,
  errored: false,
  resource: null,
};


export default MultiVaueInput;
