import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

export const ContainerLogo = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  font-weight: 700;
  color: var(--textForNavBar);
`;

const menuItemStyle = `
  position: relative;
  display: inline-flex !important;
  align-items: center !important;
  padding: 0 20px 0 20px !important;
  height: 2.5em !important;
  background-color: inherit !important;
  color: var(--textForNavBar) !important;
  font-weight: 700 !important;
  border: none !important;

  :hover {
    color: var(--textForNavBar) !important;
    text-decoration: none;
  }
`;

export const StyledMenuItem = styled(Link)`
  ${menuItemStyle}
  padding-right: 20px !important;
  :hover {
    background-color: var(--hoverNavBar) !important;
    color: white;
    text-decoration: none;
  }
`;

export const StyledMenuIt = styled(Link)`
  ${menuItemStyle}
  :hover {
    background-color: var(--hoverNavBar) !important;
    color: white;
    text-decoration: none;
  }
`;

export const StyledMenuDiv = styled.div`
  cursor: pointer;
  ${menuItemStyle}
  :hover {
    background-color: var(--hoverNavBar) !important;
    color: white;
    text-decoration: none;
  }
`;

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
  background-color: inherit !important;
  border: none !important;
  height: 100%;
`;

export const StyledEm = styled.em`
  margin-left: 5px;
  top: 50%;
  left: 3em;
  right: 2em;
  font-style: unset;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
`;

const rotate = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
    filter: hue-rotate(0deg);
  }
  10% {
    transform: rotate3d(0, 0, 1, 180deg);
    filter: hue-rotate(36deg);
  }
  20% {
    transform: rotate3d(0, 1, 0, 0deg);
    filter: hue-rotate(72deg);
  }
  30% {
    transform: rotate3d(0, 1, 0, 180deg);
    filter: hue-rotate(108deg);
  }
  40% {
    transform: rotate3d(1, 0, 0, 0deg);
    filter: hue-rotate(144deg);
  }
  50% {
    transform: rotate3d(1, 0, 0, 180deg);
    filter: hue-rotate(180deg);
  }
  60% {
    transform: rotate3d(0, 1, 1, 0deg);
    filter: hue-rotate(216deg);
  }
  70% {
    transform: rotate3d(0, 1, 1, 180deg);
    filter: hue-rotate(252deg);
  }
  80% {
    transform: rotate3d(1, 1, 1, 0deg);
    filter: hue-rotate(288deg);
  }
  90% {
    transform: rotate3d(1, 1, 1, 180deg);
    filter: hue-rotate(324deg);
  }
`;
export const StyledImg = styled.img`
  height: 30px;
  margin-right: 5px;
  animation: ${rotate} 10s linear infinite;

  @media (max-width: 600px) {
    // width: 45px;
  }
`;
export const NavDropDown = styled(Dropdown)`
  display: flex;
  
  & ::after {
    color: var(--textForNavBar)
  }
;

  div {
    background-color: var(--dropLinkBackground);
    margin: 0px;
    border-radius: 0;
    width: 100%;
  }

  :hover {
    background: var(--hoverNavBar);
    color: white;
    text-decoration: none;
  }
`;
