import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const rotate = keyframes`
  0% {
   color: red;
  }
  50% {
    color: yellow;
  }
  100% {
    color: green;
  }
`;

export const StyledButton = styled(Button)`
  background-color: var(--backgroundButton)!important;
  color: var(--colorTextButton)!important;
    &:hover {
      background-color: var(--hoverButton)!important;
    };
  // &>:not(:last-child){
  //   margin-right: 5px;
  // }
  & :first-child {
    animation: ${rotate}  ${(props) => (props.animation && '3s infinite linear')};
  }
`;
const Label = styled.span`
@media (max-width: 1175px) {
        display: none;  
    }
`;

export const CPButton = forwardRef(({
  content, title, onClick, disabled, icon, spin, pulse, animation, tabIndex,
}, ref) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    variant="info"
    size="sm"
    title={title || content}
    animation={animation ? 1 : undefined}
    tabIndex={tabIndex}
    ref={ref}
  >
    {icon && (
      <FontAwesomeIcon icon={icon} spin={spin} pulse={pulse} />
    )}
    {content
    && <Label style={{ marginLeft: '5px' }}>{content}</Label>}
  </StyledButton>
));

CPButton.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.shape({}),
  spin: PropTypes.bool,
  pulse: PropTypes.bool,
  animation: PropTypes.bool,
  tabIndex: PropTypes.number,
};

CPButton.defaultProps = {
  onClick: () => null,
  title: '',
  content: '',
  disabled: false,
  icon: null,
  spin: false,
  pulse: false,
  animation: false,
  tabIndex: null,
};

export default CPButton;
