import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../providers/authProvider';
import { REPORTS_API_URL } from '../basicReport/hooks/consts';
import api from '../../api/req';
import { DimableLoader, ErrorMessage, SearchInput } from '../../components/bootStrap';
import ReportsList from './list';
import { useDebounce } from '../../utils/debounce';

const ReportsPanel = ({ schemaId }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [reports, setReports] = useState([]);
  const [search, setSearch] = useState('');
  const [usedSearch, setUsedSearch] = useState(schemaId);

  // const [ordering, setOrdering] = useState('name');
  const ordering = 'name';
  // const [is_active, setIsActive] = useState('true');
  const isActive = true;
  // const [deleted, setDeleted] = useState('false');
  const deleted = false;

  useEffect(() => {
    const loader = async () => {
      const r = await api.get(REPORTS_API_URL, auth, {
        search: usedSearch, ordering, isActive, deleted,
      });
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    setLoading(true);
    loader()
      .then((d) => setReports(d))
      .catch((e) => setErr(e.message))
      .finally(() => setLoading(false));
  }, [auth, deleted, isActive, usedSearch]);

  const debouncedSetUsedSearch = useDebounce(setUsedSearch);

  const onSearchHandler = useCallback((e, v) => {
    setSearch(v);
    debouncedSetUsedSearch(v);
  },
  [debouncedSetUsedSearch]);
  return (
    <DimableLoader loading={loading}>
      <h3 style={{ color: 'var(--headerTextH)', fontWeight: '700' }}>Звіти</h3>
      <SearchInput onChange={onSearchHandler} value={search} />
      {err && <ErrorMessage text={err} />}
      <ReportsList reports={reports} />
    </DimableLoader>
  );
};

export default ReportsPanel;

ReportsPanel.propTypes = {
  schemaId: PropTypes.string,
};

ReportsPanel.defaultProps = {
  schemaId: '',
};
