import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

const Header = ({
  fields, activeCol, highlights, onToggleHighlght, disposerInHeader, totals,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col>
      <Row noGutters>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'treasure_date'}
            highlighted={highlights.includes('treasure_date')}
            onToggleHighlght={() => onToggleHighlght('treasure_date')}
          >
            {fields.treasure_date.label}
          </TableHeaderCell>
          {!disposerInHeader && (
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'disposer'}
            highlighted={highlights.includes('disposer')}
            onToggleHighlght={() => onToggleHighlght('disposer')}
          >
            {fields.disposer.label}
          </TableHeaderCell>
          )}
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'disposer_acc'}
            highlighted={highlights.includes('disposer_acc')}
            onToggleHighlght={() => onToggleHighlght('disposer_acc')}
          >
            {fields.disposer_acc.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'kvk_code'}
            highlighted={highlights.includes('kvk_code')}
            onToggleHighlght={() => onToggleHighlght('kvk_code')}
          >
            {fields.disposer.children.kvk_code.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'kbp7_item'}
            highlighted={highlights.includes('kbp7_item')}
            onToggleHighlght={() => onToggleHighlght('kbp7_item')}
          >
            {fields.kbp7_item.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'kekv_item'}
            highlighted={highlights.includes('kekv_item')}
            onToggleHighlght={() => onToggleHighlght('kekv_item')}
          >
            {fields.kekv_item.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            title={totals.fin_commitment_amount}
            className="text-center text-xl-left"
            active={activeCol === 'fin_commitment_amount'}
            highlighted={highlights.includes('fin_commitment_amount')}
            onToggleHighlght={() => onToggleHighlght('fin_commitment_amount')}
          >
            {fields.fin_commitment_amount.label}
          </TableHeaderCell>
          <TableHeaderCell
            title={totals.balance}
            className="text-center text-xl-left"
            active={activeCol === 'balance'}
            highlighted={highlights.includes('balance')}
            onToggleHighlght={() => onToggleHighlght('balance')}
          >
            {fields.balance.label}
          </TableHeaderCell>
          <TableHeaderCell
            title={totals.amount}
            className="text-center text-xl-left"
            active={activeCol === 'amount'}
            highlighted={highlights.includes('amount')}
            onToggleHighlght={() => onToggleHighlght('amount')}
          >
            {fields.amount.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'funds_direction'}
            highlighted={highlights.includes('funds_direction')}
            onToggleHighlght={() => onToggleHighlght('funds_direction')}
          >
            {fields.funds_direction.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'finrequest'}
            highlighted={highlights.includes('finrequest')}
            onToggleHighlght={() => onToggleHighlght('finrequest')}
          >
            {fields.finrequest.label}
          </TableHeaderCell>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'remark_template'}
            highlighted={highlights.includes('remark_template')}
            onToggleHighlght={() => onToggleHighlght('remark_template')}
          >
            {fields.remark_template.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'remark_note'}
            highlighted={highlights.includes('remark_note')}
            onToggleHighlght={() => onToggleHighlght('remark_note')}
          >
            {fields.remark_note.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

Header.propTypes = {
  activeCol: PropTypes.string,
  onToggleHighlght: PropTypes.func.isRequired,
  fields: fieldsPropTypes.isRequired,
  disposerInHeader: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  totals: PropTypes.shape({
    fin_commitment_amount: PropTypes.number,
    balance: PropTypes.number,
    amount: PropTypes.number,
  }).isRequired,
};

Header.defaultProps = {
  activeCol: null,
  disposerInHeader: false,
};

export default Header;
