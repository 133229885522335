import React from 'react';
import PropTypes from 'prop-types';
import { StyledCommandPanelButton, StyledImg, StyledText } from './styles';
import { DeleteIcon } from '../../../icons';

export const DeleteButton = ({
  content, onClick, disabled, addText,
}) => (
  <StyledCommandPanelButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={DeleteIcon} alt={content} />
    {addText
    && <StyledText>{content}</StyledText>}
  </StyledCommandPanelButton>
);

DeleteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  addText: PropTypes.bool,
};

DeleteButton.defaultProps = {
  onClick: () => null,
  content: 'Видалити',
  disabled: false,
  addText: false,
};

export default DeleteButton;
