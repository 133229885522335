import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledCellContainer, StyledCellItem, StyledTableRow, StyledTD,
} from '../../../../../basicEditor';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';

const Row = ({
  rowIndex, active, selected, onActivateRow, onChangeRow, row, fields, errors, isGoalProgramByYear,
}) => {
  const cashboxExpensesProps = tableHooks.useNumberInputProps('cashbox_expenses', row, fields, errors, onChangeRow, rowIndex);
  const reportPlanProps = tableHooks.useNumberInputProps('report_plan', row, fields, errors, onChangeRow, rowIndex);
  const programEndPredictionProps = tableHooks.useNumberInputProps('program_end_prediction', row, fields, errors, onChangeRow, rowIndex);
  const amountZfCeProps = tableHooks.useNumberInputProps('amount_zf_ce', row, fields, errors, onChangeRow, rowIndex);
  const amountSfCeProps = tableHooks.useNumberInputProps('amount_sf_ce', row, fields, errors, onChangeRow, rowIndex);
  const amountZfRpProps = tableHooks.useNumberInputProps('amount_zf_rp', row, fields, errors, onChangeRow, rowIndex);
  const amountSfRpProps = tableHooks.useNumberInputProps('amount_sf_rp', row, fields, errors, onChangeRow, rowIndex);
  const amountZfPepProps = tableHooks.useNumberInputProps('amount_zf_pep', row, fields, errors, onChangeRow, rowIndex);
  const amountSfPepProps = tableHooks.useNumberInputProps('amount_sf_pep', row, fields, errors, onChangeRow, rowIndex);
  const targetprogramProps = tableHooks.useItemInputProps('targetprogram', row, fields, errors, onChangeRow, rowIndex);
  const isFondProps = tableHooks.useCheckboxInputProps('is_fond', row, fields, errors, onChangeRow, rowIndex);
  const kdbProps = tableHooks.useItemInputProps('kdb', row, fields, errors, onChangeRow, rowIndex);
  const kfbProps = tableHooks.useItemInputProps('kfb', row, fields, errors, onChangeRow, rowIndex);
  const explanationProps = tableHooks.useItemInputProps('explanation', row, fields, errors, onChangeRow, rowIndex);
  const characterizingExplanationProps = tableHooks.useStringInputProps('characterizing_explanation', row, fields, errors, onChangeRow, rowIndex);
  const yearProps = tableHooks.useNumberInputProps('year', row, fields, errors, onChangeRow, rowIndex);
  isFondProps.label = '';

  return (
    <StyledTableRow
      active={active}
      selected={selected}
      onClick={(e) => onActivateRow(e, rowIndex)}
    >
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...targetprogramProps} />
          </StyledCellItem>
          <StyledCellItem style={{ textAlign: 'center' }}>
            <TableControls.CheckboxInput {...isFondProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      {isGoalProgramByYear
      && (
      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...yearProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
      )}

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...kdbProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.ItemPicker {...kfbProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...cashboxExpensesProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountZfCeProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountSfCeProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...reportPlanProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountZfRpProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountSfRpProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.NumberInput {...programEndPredictionProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountZfPepProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.NumberInput {...amountSfPepProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>

      <StyledTD>
        <StyledCellContainer>
          <StyledCellItem>
            <TableControls.ItemPicker {...explanationProps} />
          </StyledCellItem>
          <StyledCellItem>
            <TableControls.StringInput {...characterizingExplanationProps} />
          </StyledCellItem>
        </StyledCellContainer>
      </StyledTD>
    </StyledTableRow>
  );
};

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onActivateRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  isGoalProgramByYear: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  active: false,
  selected: false,
  errors: {},
};

export default memo(Row);
