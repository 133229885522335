import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';

const TRow = ({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) => {
  const normativeActProps = tableHooks.useItemInputProps(
    'normative_act', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const lawItemProps = tableHooks.useItemInputProps(
    'law_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const kekvProps = tableHooks.useItemInputProps(
    'kekv', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountLimitProps = tableHooks.useNumberInputProps(
    'amount_limit', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountRequiredProps = tableHooks.useNumberInputProps(
    'amount_required', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const amountUnsecuredProps = tableHooks.useNumberInputProps(
    'amount_unsecured', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  const actionDescriptionProps = tableHooks.useStringInputProps(
    'action_description', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields,
  );

  return (
    <Col>
      <Row noGutters>
        <Col>
          <TabelRowCell column="normative_act" highlighted={highlights.includes('normative_act')}>
            <TableControls.ItemPicker {...normativeActProps} />
          </TabelRowCell>
          <TabelRowCell column="law_item" highlighted={highlights.includes('law_item')}>
            <TableControls.ItemPicker {...lawItemProps} />
          </TabelRowCell>
          <TabelRowCell column="kekv" highlighted={highlights.includes('kekv')}>
            <TableControls.ItemPicker {...kekvProps} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="amount_limit" highlighted={highlights.includes('amount_limit')}>
            <TableControls.NumberInput {...amountLimitProps} />
          </TabelRowCell>
          <TabelRowCell column="amount_required" highlighted={highlights.includes('amount_required')}>
            <TableControls.NumberInput {...amountRequiredProps} />
          </TabelRowCell>
          <TabelRowCell column="amount_unsecured" highlighted={highlights.includes('amount_unsecured')}>
            <TableControls.NumberInput {...amountUnsecuredProps} />
          </TabelRowCell>
        </Col>
        <Col>
          <TabelRowCell column="action_description" highlighted={highlights.includes('action_description')}>
            <TableControls.StringInput {...actionDescriptionProps} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
};

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

TRow.defaultProps = {
  errors: {},
  readOnly: false,
  readOnlyFields: [],
};

export default memo(TRow);
