import React from 'react';
import PropTypes from 'prop-types';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const AddRowButton = ({
  content, title, onClick, disabled,
}) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    title={title}
    icon={faPlusCircle}
  />
);

AddRowButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

AddRowButton.defaultProps = {
  onClick: () => null,
  content: 'Додати рядок',
  disabled: false,
  title: '',
};

export default AddRowButton;
