import GetLister, { CommandPanels, StandartTableComponent } from '../../lister';

export const BACKEND_URL = '/api/repos/schema/';

const params = {
  backendURL: BACKEND_URL,
  CommandPanel: CommandPanels.selectCommandPanel,
  actionHandler: null,
  useHierarchyPagination: false,
};

const SchemaSelector = GetLister(params)(StandartTableComponent);

export default SchemaSelector;
