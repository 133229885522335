import React, { forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  InputGroup, Dropdown, Spinner, Badge,
} from 'react-bootstrap';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DropContainerButton = styled(InputGroup)`
   div button {
      background-color: var(--backgroundButton) !important;
      color: var(--colorTextButton)!important;
        &:hover {
          background-color: var(--hoverButton) !important;
        };
    };
  div div {
    // transform: translate3d(0px, 38px, 0px) !important;
    right: 0 !important;
    left: auto !important;
    width: 100% !important;
    };
`;

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

const MultiSelector = forwardRef(({
  id, value, values, onChange, disabled, readOnly,
  isLoading, onDropDown, errored,
  className,
}, ref) => {
  const [show, setShow] = useState(false);
  const displayValue = useMemo(
    () => {
      if (!value) return [];
      return value.map((cur) => values.reduce((R, v) => (v.value === cur ? v.display_name : R), ''));
    },
    [values, value],
  );

  const dicClassName = useMemo(
    () => `${className} form-control ${errored ? 'is-invalid' : ''}`,
    [className, errored],
  );
  return (
    <DropContainerButton className={className}>
      <div
        id={id}
        ref={ref}
        className={dicClassName}
      >
        {displayValue.map((v) => (
          <Badge key={v} variant="light" pill>{v}</Badge>
        ))}
      </div>
      {isLoading && (
      <InputGroup.Append>
        <InputGroup.Text>
          <Spinner animation="grow" size="sm" />
        </InputGroup.Text>
      </InputGroup.Append>
      )}
      <Dropdown
        style={{ position: 'unset' }}
        as={InputGroup.Append}
        show={show}
        onToggle={(isOpen, e, md) => {
          if (md.source !== 'select') setShow(!show);
          if (isOpen && onDropDown) onDropDown(e, md);
        }}
        onSelect={(eventKey, e) => {
          const idx = parseInt(eventKey, 10)-1;
          const vv = values[idx].value;

          if (!value) onChange(e, [vv]);
          else if (value.includes(vv)) onChange(e, value.filter((v) => v !== vv));
          else onChange(e, [...value, vv]);
        }}
      >
        <Dropdown.Toggle id={`drdn-${id}`} variant="outline-secondary" disabled={disabled || readOnly} split />
        <Dropdown.Menu alignRight style={{ maxHeight: '20em' }} className="overflow-auto">
          {values.map((vv, idx) => (
            <Dropdown.Item
              key={vv.value}
              disabled={vv.disabled}
              // onClick={(e) => onChange(e, [...new Set([...value, vv.value])])}
              eventKey={idx+1}
            >
              {(value || []).includes(vv.value) ? (
                <FontAwesomeIcon icon={faCheck} size="xs" className="mr-2" color="green" />
              ) : (
                <FontAwesomeIcon icon={faMinus} size="xs" className="mr-2" color="#FFEEEE" />
              )}
              {vv.display_name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </DropContainerButton>
  );
});

MultiSelector.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(valuePropType),
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  onDropDown: PropTypes.func,
  errored: PropTypes.bool,
  className: PropTypes.string,
};

MultiSelector.defaultProps = {
  value: [],
  values: [],
  onChange: () => null,
  disabled: false,
  readOnly: false,
  isLoading: false,
  onDropDown: null,
  errored: false,
  className: null,
};


export default MultiSelector;
