const frontendURL = '/doc/disposerchain/';
const backendURL = '/api/chain/disposerchain/';
const printURL = '/api/chain/printform/disposerchain/form/';
const name = 'Мережа розпорядників';

const instance = {
  frontendURL,
  backendURL,
  printURL,
  name,
};

export default instance;
