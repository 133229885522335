import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { HistoryCommandPanel } from '../../../historyValues';
import { FieldPropType } from '../../../basicEditor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import useHistory from '../../../newEditor/hook/useHistory';
import meta from '../../../../meta';

const HistoryKVK3 = ({
  data, fields, actions, id,
}) => {
  const {
    historyActions, historyData, historiesList, historyFields, historyFieldErrors,
    historyLoading, isNew,
  } = useHistory({
    data,
    registerSaveListener: actions.registerSaveListener,
    backendURL: meta.cat.kvk3.backendURL,
    id,
    fields,
    onChange: actions.onChange,
  });

  const nameProps = editorHooks.useStringInputProps('name', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const codeProps = editorHooks.useStringInputProps('code', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const parentProps = editorHooks.useItemInputProps('parent', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const isActiveProps = editorHooks.useCheckboxInputProps('is_active', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', historyData, historyFields, historyFieldErrors, historyActions.onHistoryChange);

  return (
    <>
      <HistoryCommandPanel
        currentStartDate={historyData.datefrom}
        histories={historiesList}
        historyLoading={historyLoading}
        onLoadHistories={historyActions.onLoadHistories}
        onSelectHistory={historyActions.onSelectHistory}
        onSaveHistory={historyActions.onSaveHistory}
        onCreateHistory={historyActions.onCreateHistory}
        onDeleteHistory={historyActions.onDeleteHistory}
        isNew={isNew}
      />
      <Container fluid style={{ padding: '0' }}>
        <Row>
          <Col>
            <EditorControls.StringInput {...codeProps} />
          </Col>
          <Col>
            <EditorControls.StringInput {...nameProps} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorControls.ItemPicker {...parentProps} m_odelType="cat" m_odelName="kbp" />
          </Col>
          <Col>
            <Col>
              <EditorControls.CheckboxInput {...isActiveProps} />
            </Col>
            <Col>
              <EditorControls.CheckboxInput {...isGroupProps} />
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const dataPropTypes = {
  id: PropTypes.number,
  datefrom: PropTypes.string,
  dateto: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  is_active: PropTypes.bool,
  parent: PropTypes.shape({ }),
  is_group: PropTypes.bool,
};

const fieldsPropTypes = Object.keys(dataPropTypes)
  .reduce((R, r) => ({ ...R, [r]: FieldPropType }), {});

HistoryKVK3.propTypes = {
  data: PropTypes.shape(
    dataPropTypes,
  ).isRequired,
  id: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    history: PropTypes.shape({
      fieldsPropTypes,
    }),
  }).isRequired,
  actions: PropTypes.shape({
    registerSaveListener: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default HistoryKVK3;
