import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import {
  NewButton, EditButton, FiltersButton, CompareButton,
} from '../../../components/bootStrap/buttons';
import { actions } from './actions';
import { DeleteButtonWithModal } from './styles';
import { CommandPanel } from '../../../components/bootStrap';

export const catListerCP = ({
  onActionClick, canNew, canEdit, canDelete, canFilter, filterApplied, canCompare,
}) => (
  <CommandPanel>
    <ButtonGroup>
      <NewButton onClick={(e) => onActionClick(e, actions.new)} disabled={!canNew} />
      <EditButton onClick={(e) => onActionClick(e, actions.edit)} disabled={!canEdit} />
      <DeleteButtonWithModal
        disabled={!canDelete}
        onClick={(e) => onActionClick(e, actions.delete)}
      />
    </ButtonGroup>
    <FiltersButton
      disabled={!canFilter}
      onClick={(e) => onActionClick(e, actions.toggleFilter)}
      animated={filterApplied}
    />
    <CompareButton
      disabled={!canCompare}
      onClick={(e) => onActionClick(e, actions.compare)}
      animated={filterApplied}
    />
  </CommandPanel>
);

catListerCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  canNew: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canFilter: PropTypes.bool,
  filterApplied: PropTypes.bool,
  canCompare: PropTypes.bool,
};

catListerCP.defaultProps = {
  canNew: true,
  canEdit: true,
  canDelete: true,
  canFilter: true,
  filterApplied: false,
  canCompare: true,
};

export default catListerCP;
