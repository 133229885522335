import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import meta from '../../../../meta';
import { DocCommandPanel } from '../../../basicEditor';
import { Footer, DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { LabelTab, TabContainer } from '../../../basicEditor/tpController/styles';
import Tab from '../../../../components/Tab';
import {
  Indicators,
  InvestmentProgram,
  DirectionOfUse,
  RegProgram,
  OwnEntrySF,
  ActivityAnalogySF,
  Note,
  Conclusions,
  LowLewelDoc, EfficiencyСalculation,
} from './tp';
import useEditor from '../../../newEditor/hook/editor';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import EditorContainer from '../../../newEditor/editorContainer';

const Editor = ({
  onSave, onClose, id,
}) => {
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    loading, systemErrors, headerReadOnlyFields, readOnlyFields,
  } = useEditor({
    backendURL: meta.doc.budgetExecution.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
  });

  const numberProps = editorHooks.useStringInputProps('number', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const authoritySignProps = editorHooks.useDateInputProps('authority_sign', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const passportProps = editorHooks.useDateInputProps('passport', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const executionBpLastPeriodProps = editorHooks.useDateInputProps('execution_bp_last_period', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const kbpProps = editorHooks.useDateInputProps('kbp', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const kfkProps = editorHooks.useDateInputProps('kfk', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);
  const approvedProps = editorHooks.useDateInputProps('approved', data, fields, fieldErrors, actions.onChange, false, headerReadOnlyFields);

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
    >
      <DocCommandPanel
        permissions={permissions}
        actions={actions}
        modified={changed}
        executed={data.executed}
      />
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...numberProps} />
              </Col>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col>
                <EditorControls.NumberInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...authoritySignProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...passportProps} />
              </Col>
              <Col>
                <EditorControls.SelectorInput {...executionBpLastPeriodProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...kbpProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...kfkProps} />
              </Col>
              <Col className="mt-auto">
                <EditorControls.CheckboxInput {...approvedProps} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="general" id="uncontrolled-tab-example">
          <Tab eventKey="general" title="Основне">
            <LabelTab defaultActiveKey="executionbpindicatorcost" id="uncontrolled-tab-example">
              {fields && fields.executionbpindicatorcostrow_set && (
              <Tab eventKey="executionbpindicatorcost" title="Напрями використання бюджетних коштів">
                <TabContainer>
                  <DirectionOfUse
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
              {fields && fields.executionbpbasicrow_set && (
              <Tab eventKey="executionbpbasic" title="Показники">
                <TabContainer>
                  <Indicators
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
              {fields && fields.executionbpregprogramrow_set && (
              <Tab eventKey="executionbpregprogram" title="Рег. цільові програми">
                <TabContainer>
                  <RegProgram
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
              {fields && fields.executionbpinvestmentprogramrow_set && (
              <Tab eventKey="executionbpinvestmentprogram" title="Джерела фін. інвест. програм">
                <TabContainer>
                  <InvestmentProgram
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
              {fields && fields.executionbpownentrysfrow_set && (
              <Tab eventKey="executionbpownentrysf" title="Джерела надходжень СФ">
                <TabContainer>
                  <OwnEntrySF
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
              {fields && fields.executionbplowleverdocsrow_set && (
              <Tab eventKey="executionbplowleverdocs" title="Док. розпорядників">
                <TabContainer>
                  <LowLewelDoc
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    readOnlyFields={readOnlyFields}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
              {fields && fields.note && (
              <Tab eventKey="note" title="Примітки">
                <TabContainer>
                  <Note
                    data={data}
                    fields={fields}
                    errors={fieldErrors}
                    onChange={actions.onChange}
                  />
                </TabContainer>
              </Tab>
              )}
            </LabelTab>
          </Tab>

          {fields && fields.executionbpindicatorcostrow_set && (
            <Tab eventKey="executionbpindicatorcost" title="Розрахунок ефективності">
              <TabContainer>
                <EfficiencyСalculation
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.executionbpactivityanalogysfrow_set && (
            <Tab eventKey="executionbpactivityanalogysf" title="Виконання показників порівняно із показниками попереднього року">
              <TabContainer>
                <ActivityAnalogySF
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                  onChange={actions.onChange}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.executionbpactivityanalogysfrow_set && (
            <Tab eventKey="conclusions" title="Висновки по програмі">
              <TabContainer>
                <Conclusions
                  data={data}
                  fields={fields}
                  errors={fieldErrors}
                  onChange={actions.onChange}
                />
              </TabContainer>
            </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
      />
    </EditorContainer>
  );
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};

export default Editor;
