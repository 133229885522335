import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const NewScheme = ({ onCreate, handleChangeNewSchemeParams }) => (
  <Form onSubmit={(e) => { e.preventDefault(); return (onCreate()); }}>
    <Form.Group>
      <Form.Label htmlFor="identifier">Унікальна назва ідентифікатора</Form.Label>
      <Form.Control
        type="text"
        id="identifier"
        name="identifier"
        aria-describedby="identifier"
        onChange={handleChangeNewSchemeParams}
      />
      <Form.Text id="identifier" muted>
    Має складатись з англійських букв в нижньому реєстрі, цифр(не може бути першою в назві) та не містити інших розділових знаків окрім нижнього підкреслення.
      </Form.Text>
    </Form.Group>

    <Form.Group>
      <Form.Label htmlFor="name">Назва схеми</Form.Label>
      <Form.Control
        type="text"
        id="name"
        name="name"
        onChange={handleChangeNewSchemeParams}
      />
    </Form.Group>

    <Form.Group>
      <Form.Label htmlFor="version">Версія схеми</Form.Label>
      <Form.Control
        type="number"
        id="version"
        name="version"
        onChange={handleChangeNewSchemeParams}
      />
    </Form.Group>

    <Form.Group>
      <Form.Label htmlFor="description">Опис схеми</Form.Label>
      <Form.Control
        type="text"
        id="description"
        name="description"
        as="textarea"
        rows={3}
        onChange={handleChangeNewSchemeParams}
      />
    </Form.Group>

    <Form.Group>
      <Form.Check
        type="checkbox"
        id="is_active"
        name="is_active"
        label="Ознака активності"
        onChange={handleChangeNewSchemeParams}
      />
    </Form.Group>

    <Button variant="primary" type="submit">
    Створити
    </Button>

  </Form>
);

NewScheme.propTypes = {
  onCreate: PropTypes.func.isRequired,
  handleChangeNewSchemeParams: PropTypes.func.isRequired,
};

export default NewScheme;
