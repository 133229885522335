import React from 'react';
import PropTypes from 'prop-types';
import { CardColumns } from 'react-bootstrap';
import ReportItem, { reportPropType } from './item';

const ReportsList = ({ reports }) => (
  <CardColumns style={{ marginTop: '15px' }}>
    {reports.map((report) => (
      <ReportItem key={report.id} report={report} />
    ))}
  </CardColumns>
);

ReportsList.propTypes = {
  reports: PropTypes.arrayOf(reportPropType).isRequired,
};

export default ReportsList;
