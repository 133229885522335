import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalButton, SModal } from './style';
import FitlerEditor from './filterEditor';
import { foreignPropType } from '../../../../../constants/backend/propTypes';

const LoadLevelDownButton = ({
  authority, fond, docDate, year, planKind,
  onLoadHandler,
}) => {
  const [opened, setOpened] = useState(false);
  const overrideFilter = useMemo(() => ({
    authority_parent: authority,
    fond,
    year,
    planKind,
  }),
  [authority, fond, planKind, year]);

  const defaultFilter = useMemo(() => {
    const d = docDate ? new Date(docDate) : new Date();
    return {
      begin_date: d.toISOString().substr(0, 10),
      end_date: docDate,
    };
  }, [docDate]);

  return (
    <>
      <ModalButton disabled={!fond} variant="primary" onClick={() => setOpened(true)}>
        Заповнити з даних розпорядників нижчого рівня
      </ModalButton>
      <SModal show={opened} onHide={() => setOpened(false)} scrollable size="xl">
        <FitlerEditor
          overrideFilter={overrideFilter}
          defaultFilter={defaultFilter}
          onClose={() => setOpened(false)}
          onOk={(ids) => {
            onLoadHandler(ids);
            setOpened(false);
          }}
        />
      </SModal>
    </>
  );
};

LoadLevelDownButton.propTypes = {
  authority: foreignPropType.isRequired,
  fond: foreignPropType.isRequired,
  docDate: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  planKind: PropTypes.number.isRequired,
  onLoadHandler: PropTypes.func.isRequired,
};
export default LoadLevelDownButton;
