import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorControls } from '../../basicEditor/editorControls';

const DKSUDates = ({
  year, setYear, getDKSUDates, logicaPingSuccess,
}) => (
  <>
    <EditorControls.StringInput
      label="Рік"
      controlId="year"
      required
      value={year}
      onChange={(e) => setYear(e.target.value)}
    />

    <Button type="submit" onClick={getDKSUDates} className="mb-2 w-25" disabled={!logicaPingSuccess}>
      <FontAwesomeIcon icon={faSearch} className="mr-2" />
      Пошук
    </Button>

  </>
);

DKSUDates.propTypes = {
  year: propTypes.string.isRequired,
  setYear: propTypes.func.isRequired,
  getDKSUDates: propTypes.func.isRequired,
  logicaPingSuccess: propTypes.bool.isRequired,
};

export default DKSUDates;
