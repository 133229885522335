import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes } from './dataPropTypes';
import {
  HeaderTable, ContainerTable, StyleTD, StyleDiv,
} from './style';

const DocDetails = ({ rows, fields }) => {
  const formatter = (f) => f.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  return (
    <ContainerTable>
      <Table bordered hover>
        <thead>
          <tr>
            <HeaderTable>{fields.disposer.label}</HeaderTable>
            <HeaderTable>{fields.kbp7_item.label}</HeaderTable>
            <HeaderTable>{fields.kekv_item.label}</HeaderTable>
            <HeaderTable>{fields.amount_total.label}</HeaderTable>
          </tr>
        </thead>
        <tbody>
          {rows.map((r) => (
            <tr key={r.id}>
              <StyleTD style={{ width: '28%' }}>
                <StyleDiv
                  title={r.disposer.repr}
                >
                  {r.disposer.repr}
                </StyleDiv>
              </StyleTD>
              <StyleTD style={{ width: '28%' }}>
                <StyleDiv
                  title={r.kbp7_item.repr}
                >
                  {r.kbp7_item.repr}
                </StyleDiv>
              </StyleTD>
              <StyleTD style={{ width: '28%' }}>
                <StyleDiv
                  title={r.kekv_item.repr}
                >
                  {r.kekv_item.repr}
                </StyleDiv>
              </StyleTD>
              <StyleTD style={{ textAlign: 'right', width: '16%' }}>
                <StyleDiv>
                  {formatter(r.amount_total.toFixed(2))}
                </StyleDiv>
              </StyleTD>
            </tr>
          ))}
        </tbody>
      </Table>
    </ContainerTable>
  );
};

DocDetails.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
};

export default DocDetails;
