import React from 'react';
import PropTypes from 'prop-types';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import { CPButton } from './styles';

export const CloseButton = ({ content, onClick, disabled }) => (
  <CPButton
    onClick={onClick}
    disabled={disabled}
    content={content}
    icon={faBackspace}
  />
);

CloseButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CloseButton.defaultProps = {
  onClick: () => null,
  content: 'Повернутися',
  disabled: false,
};

export default CloseButton;
