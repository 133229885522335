import PropTypes from 'prop-types';

export const foreignPropType = PropTypes.shape({
  id: PropTypes.number,
  repr: PropTypes.string,
  url: PropTypes.string,
});

const dataPropTypes = {
  foreignPropType,
};

export default dataPropTypes;
