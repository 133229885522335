import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import meta from '../../../../meta';
import getEditor, {
  getFieldsPropTypes,
  getErrorsPropTypes,
} from '../../../basicEditor';
import {
  EditorControls,
  editorHooks,
} from '../../../basicEditor/editorControls';
import { HeaderText } from '../../../../components/Styled/Forms';
import { DocumentBody } from '../../../../components/Containers/docs';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { FlexContainer } from '../../../basicEditor/tpController/styles';
import {
  SaveButton,
  CloseButton,
} from '../../../../components/bootStrap/buttons';
import { CommandPanel } from '../../../../components/bootStrap';

const Editor = ({
  data,
  options,
  isNew,
  isReadOnly,
  isModified,
  onSave,
  onClose,
  fields,
  errors,
  onChange,
}) => {
  const nameProps = editorHooks.useStringInputProps('name', data, fields, errors, onChange);
  const codeProps = editorHooks.useStringInputProps('code', data, fields, errors, onChange);
  const parentProps = editorHooks.useItemInputProps('parent', data, fields, errors, onChange);
  const isGroupProps = editorHooks.useCheckboxInputProps('is_group', data, fields, errors, onChange);

  return (
    <FlexContainer>
      <HeaderText>
        {isNew ? `New ${options.name}` : `${options.name} ${data.repr}`}
      </HeaderText>
      <CommandPanel>
        <SaveButton
          onClick={() => onSave()}
          disabled={isReadOnly || !isModified}
        />
        <CloseButton onClick={onClose} disabled={!onClose} />
      </CommandPanel>
      <DocumentBody>
        <HidableContainer>
          <Container fluid style={{ padding: '0' }}>
            <Row>
              <Col>
                <EditorControls.StringInput {...codeProps} readOnly />
              </Col>
              <Col>
                <EditorControls.StringInput {...nameProps} readOnly />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.ItemPicker {...parentProps} modelType="cat" modelName="socialpayment" readOnly />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.CheckboxInput {...isGroupProps} readOnly />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
      </DocumentBody>
    </FlexContainer>
  );
};

const dataPropTypes = {
  rank: PropTypes.string,
  last_name: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  phone_number: PropTypes.string,
  added_phone_number: PropTypes.string,
  email: PropTypes.string,
  basis: PropTypes.string,
};

Editor.propTypes = {
  options: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape(dataPropTypes).isRequired,
  fields: PropTypes.shape(getFieldsPropTypes(dataPropTypes)).isRequired,
  errors: PropTypes.shape(getErrorsPropTypes(dataPropTypes)),
  isNew: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isModified: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onExecute: PropTypes.func.isRequired,
  onCancelExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  errors: {},
};

export default getEditor(meta.cat.socialpayment.backendURL)(Editor);
