import React, {
  memo, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import TableListerCell from '../tablecell';
// import baseMD from '../../../../../constants/meta/documents/base';

const TableDocListerRow = ({
  row, columns, onFocus, selected, tabIndex, onContextMenu, actions, children,
}) => {
  const onClickHandler = useCallback(
    (e) => {
      onFocus(e, row.id);
    },
    [onFocus, row.id],
  );

  const className = useMemo(
    () => {
      let result = 'docrow';
      result += `${selected ? ' selected' : ''} `;
      result += `${row.deleted ? ' deleted' : ''} `;
      result += `${row.executed ? ' executed' : ' unexecuted'} `;
      return result;
    },
    [row.deleted, row.executed, selected],
  );
  return (
    <tr
      data-id={row.id}
      onClick={onClickHandler}
      className={className}
      tabIndex={tabIndex}
      onDoubleClick={(e) => actions.onRowDoubleClick(e, row.id)}
    >
      {columns.map((c) => (
        <TableListerCell
          key={c.key}
          value={row[c.key]}
          variants={c.variants}
          choices={c.choices}
          type={c.type}
          onContextMenu={(e) => onContextMenu(e, row.id)}
        />
      ))}
      { children }
    </tr>

  );
};

TableDocListerRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf([
      'string', 'email', 'url', 'integer', 'float', 'decimal', 'choice', 'date', 'datetime', 'nested object', 'boolean', 'field',
    ]),
  })).isRequired,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    deleted: PropTypes.bool.isRequired,
    executed: PropTypes.bool.isRequired,
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

TableDocListerRow.defaultProps = {
  selected: false,
  children: null,

};
export default memo(TableDocListerRow);
