import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import {
  getTPController, ExpandableTPCommandPanel,
} from '../../../../../basicEditor';
import Header from './header';
import Row from './row';
import { fieldsPropTypes, dataPropTypes, errorsPropTypes } from './propTypes';

const PlanningRows = ({
  onAddRow, onChangeRow, onDeleteSelectedRows, onActivateRow,
  fields, data, errors, activeRow, selectedRows, readOnly,
  onHide, onExpand, expanded, isGoalProgramByYear,
}) => (
  <>
    <ExpandableTPCommandPanel
      onAdd={onAddRow}
      onDelete={onDeleteSelectedRows}
      selectedRows={selectedRows}
      onHide={onHide}
      expanded={expanded}
      onExpand={onExpand}
      canModified={!readOnly}
    />
    <div>
      <Table bordered>
        <thead>
          <Header
            fields={fields}
            isGoalProgramByYear={isGoalProgramByYear}
          />
        </thead>
        <tbody>
          {data && data.map((row, rowIndex) => (
            <Row
            /* eslint-disable-next-line react/no-array-index-key */
              key={rowIndex}
              rowIndex={rowIndex}
              active={activeRow === rowIndex}
              selected={selectedRows.includes(rowIndex)}
              onActivateRow={onActivateRow}
              onChangeRow={onChangeRow}
              row={row}
              fields={fields}
              errors={errors[rowIndex]}
              readOnly={readOnly}
              isGoalProgramByYear={isGoalProgramByYear}
            />
          ))}
        </tbody>
      </Table>
    </div>
  </>
);

PlanningRows.propTypes = {
  onAddRow: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func.isRequired,
  onDeleteSelectedRows: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: PropTypes.arrayOf(errorsPropTypes),
  activeRow: PropTypes.number,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  onActivateRow: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isGoalProgramByYear: PropTypes.bool.isRequired,

  onHide: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};

PlanningRows.defaultProps = {
  errors: [],
  activeRow: null,
  selectedRows: [],
  readOnly: false,
};

export default getTPController(PlanningRows);
