import React, { useCallback } from 'react';
import { compose } from 'recompose';
import {
  Navbar, Nav,
} from 'react-bootstrap';
import {
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  HomeIcon,
  LogoIcon,
  RepIcon,
  ServiceIcon,
} from '../../icons';

import { withAuthConsumer } from '../../providers/authProvider';
import {
  DefaultPage, RepPage, SrvPage,
} from '../Pages';
import {
  ContainerLogo, StyledEm, StyledMenuItem,
} from './styles';
import { StyledImg } from '../Styled/Buttons/styles';
import MenuItem from './menuItem';

const home = {
  url: '/', label: 'Головна', src: HomeIcon, hasChildren: false,
};
const menus = [
  {
    url: '/rep/', label: 'Звіти', src: RepIcon, navigation: <RepPage />, hasChildren: false, name: 'reports',
  },
  {
    url: '/srv/', label: 'Сервіс', src: ServiceIcon, navigation: <SrvPage />, hasChildren: true,
  },
];

const Menu = ({ currentUser }) => {
  const getSections = useCallback((mainSection) => Object.values(currentUser.available_sections)
    .find((ms) => ms.name === mainSection).children, [currentUser.available_sections]);

  return (
    <div>
      <Navbar
        style={{
          padding: '0 10px', background: 'var(--navBarBackground)', borderTop: '1px solid white',
        }}
      >
        <Navbar.Brand className="p-0">
          <ContainerLogo>
            <StyledImg src={LogoIcon} alt="Logo" height="28px" />
            <span>ЦІАТ</span>
          </ContainerLogo>
        </Navbar.Brand>
        <Nav className="mr-auto" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <>
            <StyledMenuItem to={home.url} key={home.url}>
              <img src={home.src} width="20px" alt="" />
              <StyledEm>{home.label}</StyledEm>
            </StyledMenuItem>
            { Object.values(currentUser.available_sections)
              .map((item) => (
                <MenuItem icon={item.icon} label={item.label} key={item.name} name={item.name}>
                  <DefaultPage
                    sections={getSections(item.name)}
                    menuName={item.name}
                    type="page"
                  />
                </MenuItem>
              ))}
            {menus
              .map((menuItem) => (menuItem.hasChildren ? (
                <MenuItem
                  icon={menuItem.src}
                  label={menuItem.label}
                  key={menuItem.url}
                  name={menuItem.url}
                >
                  {menuItem.navigation}
                </MenuItem>
              ) : (
                <StyledMenuItem to={menuItem.url} key={menuItem.url}>
                  <img src={menuItem.src} width="20px" alt="" />
                  <StyledEm>{menuItem.label}</StyledEm>
                </StyledMenuItem>
              )))}
          </>
        </Nav>
      </Navbar>
    </div>
  );
};

Menu.propTypes = {
  currentUser: PropTypes.shape({
    is_superuser: PropTypes.bool,
    available_sections: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

const mapState = (state) => ({
  currentUser: state.currentUser,
});

const enhance = compose(
  withAuthConsumer(mapState),
  withRouter,
);
export default enhance(Menu);
