import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  faPrint, faImage, faFileImage, faSave,
} from '@fortawesome/free-solid-svg-icons';
import { Accordion, Card } from 'react-bootstrap';
import { Line } from '../../components/Styled/Buttons/styles';
import { CommandPanel } from '../../components/bootStrap';
import { GenerateButton, SettingsButton } from '../../components/bootStrap/buttons';
import { CPButton } from '../../components/bootStrap/buttons/styles';
import { ContentLabel } from '../../components/Styled/Misc';
import { ECheckbox, EItemPicker, ESelector } from '../../components/Controls/withErrors';

const SCard = styled(Card)`
    border: none !important;
    padding: 5px 0 !important;
    >div {
        margin-bottom: 0 !important;
    }
`;
const SCardBody = styled(Card.Body)`
    background: var(--backgroundPrintSettings);
    border-radius: 0 0 4px 4px;
`;
const SmallContainer = styled.div`
    width: 100%;
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-gap: 2%;
    margin-bottom: 15px;
`;
const Container = styled.div`
    width: 100%;
    display: inline-grid;
    grid-template-columns: auto auto auto;
    grid-gap: 2%;
    margin-bottom: 15px;
`;

const decodingKekv = [{ value: 0, display_name: 'Без розшифровки' },
  { value: 1, display_name: 'Задіяні коди' },
  { value: 2, display_name: 'Тільки 5000' }];

const PrintCommandPanel = ({
  showPrintedResult, print, pageSize, fields, savePrintSettings, savedSettings,
}) => {
  const [changePrintsize, setChangePrintsize] = useState(false);
  const [settings, setSettings] = useState({
    signvariant: null,
    all_kekv: false,
    group_kekv: false,
    full_kekv: false,
    print_kdb: false,
    print_kfb: false,
    decoding_kekv: null,
  });

  useEffect(() => {
    setSettings((prev) => ({ ...prev, ...savedSettings }));
  }, [savedSettings]);

  const [activeKey, setActiveKey] = useState(null);

  const handleActiveKey = useCallback((key) => {
    if (activeKey === key) {
      setActiveKey(null);
    } else setActiveKey(key);
  }, [activeKey]);

  return (
    <div>
      <Accordion activeKey={activeKey}>
        <SCard>
          <CommandPanel>
            <GenerateButton
              onClick={() => {
                showPrintedResult({ ...settings });
              }}
            />
            <CPButton
              onClick={() => print()}
              title="Друк"
              icon={faPrint}
            />
            <CPButton
              onClick={() => {
                setChangePrintsize(!changePrintsize);
                pageSize();
              }}
              title={changePrintsize ? 'Альбомна' : 'Портретна'}
              icon={changePrintsize ? faImage : faFileImage}
            />
            <Accordion.Toggle
              as={SettingsButton}
              eventKey="1"
              onClick={() => handleActiveKey('1')}
            />
          </CommandPanel>
          <Accordion.Collapse eventKey="1">
            <SCardBody>
              <SmallContainer>
                <ContentLabel
                  text={fields.signvariant && fields.signvariant.label}
                  required={fields.signvariant && fields.signvariant.required}
                  className="mr-2"
                  style={{ marginRight: '5px' }}
                >
                  <EItemPicker
                    id="authoritysignvariant"
                    value={settings.signvariant}
                    onChange={(e, v) => {
                      setSettings((prev) => ({ ...prev, signvariant: { id: v.id, repr: v.repr } }));
                    }}
                    readOnly={fields.signvariant && fields.signvariant.readOnly}
                    modelType="cat"
                    modelName="authoritysignvariant"
                  />
                </ContentLabel>

                <ContentLabel
                  text={fields.decoding_kekv && fields.decoding_kekv.label}
                  required={fields.decoding_kekv && fields.decoding_kekv.required}
                >
                  <ESelector
                    id="decoding_kekv"
                    value={settings.decoding_kekv}
                    values={decodingKekv}
                    label={fields.decoding_kekv && fields.decoding_kekv.label}
                    onChange={(e, v) => {
                      e.preventDefault();
                      setSettings((prev) => ({ ...prev, decoding_kekv: v }));
                    }}
                  />
                </ContentLabel>
              </SmallContainer>

              <Container>
                <ECheckbox
                  id="all_kekv"
                  value={settings.all_kekv}
                  label={fields.all_kekv && fields.all_kekv.label}
                  onChange={() => {
                    setSettings((prev) => ({ ...prev, all_kekv: !prev.all_kekv }));
                  }}
                  readOnly={fields.all_kekv && fields.all_kekv.readOnly}
                  noHierarchy
                />

                <ECheckbox
                  id="group_kekv"
                  value={settings.group_kekv}
                  label={fields.group_kekv && fields.group_kekv.label}
                  onChange={() => {
                    setSettings((prev) => ({ ...prev, group_kekv: !prev.group_kekv }));
                  }}
                  readOnly={fields.group_kekv && fields.group_kekv.readOnly}
                  noHierarchy
                />

                <ECheckbox
                  id="full_kekv"
                  value={settings.full_kekv}
                  label={fields.full_kekv && fields.full_kekv.label}
                  onChange={() => {
                    setSettings((prev) => ({ ...prev, full_kekv: !prev.full_kekv }));
                  }}
                  readOnly={fields.full_kekv && fields.full_kekv.readOnly}
                  noHierarchy
                />
              </Container>

              <SmallContainer>
                <ECheckbox
                  id="print_kdb"
                  value={settings.print_kdb}
                  label={fields.print_kdb && fields.print_kdb.label}
                  onChange={() => {
                    setSettings((prev) => ({ ...prev, print_kdb: !prev.print_kdb }));
                  }}
                  readOnly={fields.print_kdb && fields.print_kdb.readOnly}
                  noHierarchy
                />

                <ECheckbox
                  id="print_kfb"
                  value={settings.print_kfb}
                  label={fields.print_kfb && fields.print_kfb.label}
                  onChange={() => {
                    setSettings((prev) => ({ ...prev, print_kfb: !prev.print_kfb }));
                  }}
                  readOnly={fields.print_kfb && fields.print_kfb.readOnly}
                  noHierarchy
                />
              </SmallContainer>

              <CPButton
                onClick={() => savePrintSettings(settings)}
                title="Зберегти налаштування"
                icon={faSave}
                content="Зберегти"
              />

            </SCardBody>
          </Accordion.Collapse>
        </SCard>
      </Accordion>
      <Line />
    </div>
  );
};


PrintCommandPanel.propTypes = {
  showPrintedResult: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  pageSize: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    signvariant: PropTypes.shape({
      label: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      readOnly: PropTypes.bool.isRequired,
    }),
    all_kekv: PropTypes.shape({
      label: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      readOnly: PropTypes.bool.isRequired,
    }),
    group_kekv: PropTypes.shape({
      label: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      readOnly: PropTypes.bool.isRequired,
    }),
    full_kekv: PropTypes.shape({
      label: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      readOnly: PropTypes.bool.isRequired,
    }),
    print_kdb: PropTypes.shape({
      label: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      readOnly: PropTypes.bool.isRequired,
    }),
    print_kfb: PropTypes.shape({
      label: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      readOnly: PropTypes.bool.isRequired,
    }),
    decoding_kekv: PropTypes.shape({
      label: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      readOnly: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  savePrintSettings: PropTypes.func.isRequired,
  savedSettings: PropTypes.shape({
    signvariant: PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.string,
    }),
    all_kekv: PropTypes.bool,
    group_kekv: PropTypes.bool,
    full_kekv: PropTypes.bool,
    print_kdb: PropTypes.bool,
    print_kfb: PropTypes.bool,
    decoding_kekv: PropTypes.number,
  }),
};

PrintCommandPanel.defaultProps = {
  savedSettings: null,
};

export default PrintCommandPanel;
