import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

const Header = ({
  fields, activeCol, highlights, onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'document'}
            highlighted={highlights.includes('document')}
            onToggleHighlght={() => onToggleHighlght('document')}
          >
            {fields.document.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'is_approved'}
            highlighted={highlights.includes('is_approved')}
            onToggleHighlght={() => onToggleHighlght('is_approved')}
          >
            {fields.is_approved.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
