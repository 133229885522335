import GetTableComponent from './tableComponent';
// import HeaderRow, { StyledHeaderCell } from './headerRow';
import HeaderRow from './headerRow';
import NoDataRow from './tableRowNoRecords';
import TableRow from './tableRow';

export const StandartTableComponent = GetTableComponent({
  RowComponent: TableRow,
  NoDataComponent: NoDataRow,
  HeaderComponent: HeaderRow,
  FooterComponent: null,
});

export default GetTableComponent;
