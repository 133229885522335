import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'react-bootstrap';
import { useDrag } from 'react-dnd';
import ItemTypes from '../itemTypes';

const Field = ({
  name, label, onClick, active, onDblClick,
}) => {
  const [{ isDragging }, ref] = useDrag({
    item: { name, type: ItemTypes.availableField },
    type: ItemTypes.availableField,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = useMemo(() => ({
    opacity: isDragging ? 0.4 : 1,
  }), [isDragging]);

  return (
    <ListGroupItem
      onClick={() => onClick(name)}
      onDoubleClick={() => onDblClick(name)}
      active={active}
      style={style}
      ref={ref}
    >
      {label}
    </ListGroupItem>
  );
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  active: PropTypes.bool,
};

Field.defaultProps = {
  active: false,
  onDblClick: () => null,
};

export default Field;
